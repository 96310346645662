import {useTheme} from '@mui/material/styles';
import type {ReactElement} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import DocumentViewer from '@refinio/one.ui/lib/ui/components/documentViewer/DocumentViewer.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import type DocumentModel from '@refinio/one.models/lib/models/DocumentModel.js';

import PageNotFound from '@/components/PageNotFound.js';
import {useNavigateBack} from '@/hooks/navigation.js';

export default function DocumentRouter(props: {documentModel: DocumentModel}): ReactElement {
    const i18n = useTranslation();
    const goBack = useNavigateBack();

    const theme = useTheme();

    return (
        <Routes>
            <Route
                path="view"
                element={
                    <AppBarFullScreenPopup
                        background={theme.palette.background.default}
                        onClose={goBack}
                        mode={APP_BAR_MODE.CHEVRON}
                        title={i18n.t('buttons.common.back')}
                    >
                        <DocumentViewer documentModel={props.documentModel} />
                    </AppBarFullScreenPopup>
                }
            />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}
