import type {ReactElement} from 'react';
import {useContext} from 'react';

import NavigationDrawer from '@refinio/one.ui/lib/ui/components/menu/NavigationDrawer.js';

import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {getLeuteMenuItems} from './MenuItems.js';
import {getLeuteDebugMenuItems} from './DebugMenuItems.js';

/**
 * Builds the left menu
 */
export default function Menu(): ReactElement {
    const {contextValue, setContextValue} = useContext(AppBarContext);

    return (
        <NavigationDrawer
            items={getLeuteMenuItems()}
            open={contextValue.isLeftBtnClicked}
            debugItems={getLeuteDebugMenuItems}
            onClose={() => {
                setContextValue({
                    ...contextValue,
                    isLeftBtnClicked: false,
                    isRightBtnClicked: false
                });
            }}
        />
    );
}
