import type { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type Notifications from '@refinio/one.models/lib/models/Notifications.js';

import PageNotFound from '@/components/PageNotFound.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import type { AttachmentViewFactoryEntry } from './attachmentViews/types.js';
import Chat from './chatId/Chat.js';

export default function ChatRouter(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    channelManager: ChannelManager;
    notifications: Notifications;
    attachmentViewAdditions?: AttachmentViewFactoryEntry[];
}): ReactElement {
    return (
        <Routes>
            <Route
                path={':topicID'}
                element={
                    <>
                        <AppBarSettings hide={true} />
                        <Chat
                            topicModel={props.topicModel}
                            leuteModel={props.leuteModel}
                            channelManager={props.channelManager}
                            notifications={props.notifications}
                            attachmentViewAdditions={props.attachmentViewAdditions}
                        />
                    </>
                }
            />
            <Route element={<PageNotFound />} />
        </Routes>
    );
}
