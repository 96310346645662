import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';
import {Button} from '@mui/material';

import i18n from '@/i18n.js';
import Disclaimer from './steps/Disclaimer.js';
import InformationPrompt from './steps/InformationPrompt.js';
import PicturePrompt from './steps/picture/PicturePrompt.js';
import IdentityExportKeysCodePrompt from './steps/identity/IdentityExportKeysCodePrompt.js';
import {DisclaimerBody} from './DisclaimerBody.js';

/**
 * Represents the onboarding steps.
 * At each step the user is asked to provide some information.
 */
export const ONBOARDING_PROGRESS = {
    Disclaimer: 0,
    Name: 1,
    DeviceName: 2,
    Email: 3,
    Password: 4,
    ImportKeys: 5,
    Picture: 6,
    ExportKeys: 7,
    Done: 8
} as const;

/**
 * The type representing the onboarding process values.
 */
export type OnboardingPage = (typeof ONBOARDING_PROGRESS)[keyof typeof ONBOARDING_PROGRESS];

export type OnboardingData = {
    personName: string;
    deviceName: string;
    email?: string;
    password?: string;
    avatar?: ArrayBuffer;
    importKeysCode?: string;
    exportKeysCode?: string;
};

/**
 * Represents every step which is displayed in the onboarding process.
 * @param props.onBoardingCompleted - callback for end of onboarding
 * @param props.createInstance - callback for creation of instance, will show exported keys code if string is returned
 * @param props.logoSrc
 * @param props.includePassword Optional. Default false.
 * @param props.disclaimerBody Optional.
 */
export default function Onboarding(propsIn: {
    onBoardingCompleted: () => void;
    createInstance: (onboardingData: OnboardingData) => Promise<string | undefined | void>;
    logoSrc: string;
    includePassword?: boolean;
    disclaimerBody?: ReactElement | ReactElement[];
}): ReactElement {
    const onboardingProps = {
        includePassword: false,
        ...propsIn
    };
    // all the data collected from the onboarding process
    const [onboardingData, setOnboardingData] = useState<OnboardingData>({
        personName: '',
        deviceName: '',
        email: undefined,
        password: undefined,
        avatar: undefined,
        importKeysCode: undefined,
        exportKeysCode: undefined
    });

    // state which tell us which page should be displayed
    const [onboardingPage, setOnboardingPage] = useState<OnboardingPage>(
        ONBOARDING_PROGRESS.Disclaimer
    );

    useEffect(() => {
        if (onboardingPage === ONBOARDING_PROGRESS.Done) {
            onboardingProps
                .createInstance(onboardingData)
                .then(exportKeysCode => {
                    if (exportKeysCode) {
                        setOnboardingData(old => ({...old, exportKeysCode}));
                        setOnboardingPage(ONBOARDING_PROGRESS.ExportKeys);
                    } else {
                        onboardingProps.onBoardingCompleted();
                    }
                })
                .catch(console.error);
        }
    }, [onboardingPage, onboardingData]);

    function gotoNextPage(): void {
        switch (onboardingPage) {
            case ONBOARDING_PROGRESS.Name:
                setOnboardingPage(ONBOARDING_PROGRESS.DeviceName);
                break;
            case ONBOARDING_PROGRESS.DeviceName:
                setOnboardingPage(ONBOARDING_PROGRESS.Email);
                break;
            case ONBOARDING_PROGRESS.Email:
                if (onboardingProps.includePassword) {
                    setOnboardingPage(ONBOARDING_PROGRESS.Password);
                } else {
                    setOnboardingPage(ONBOARDING_PROGRESS.ImportKeys);
                }
                break;
            case ONBOARDING_PROGRESS.Password:
                setOnboardingPage(ONBOARDING_PROGRESS.ImportKeys);
                break;
            case ONBOARDING_PROGRESS.ImportKeys:
                setOnboardingPage(ONBOARDING_PROGRESS.Picture);
                break;
            case ONBOARDING_PROGRESS.Picture:
                setOnboardingPage(ONBOARDING_PROGRESS.Done);
                break;
        }
    }

    /**
     * The function that displays the onboarding step based on the "onboarding page" state.
     */
    function buildOnboardingStep(): ReactElement {
        switch (onboardingPage) {
            case ONBOARDING_PROGRESS.Disclaimer:
                return (
                    <Disclaimer
                        onDone={() => {
                            setOnboardingPage(ONBOARDING_PROGRESS.Name);
                        }}
                        logoSrc={onboardingProps.logoSrc}
                    >
                        {onboardingProps.disclaimerBody === undefined ? (
                            <DisclaimerBody />
                        ) : (
                            onboardingProps.disclaimerBody
                        )}
                    </Disclaimer>
                );
            case ONBOARDING_PROGRESS.Name:
                return (
                    <InformationPrompt
                        onDone={value => {
                            if (value !== '') {
                                setOnboardingData(prevState => {
                                    return {...prevState, personName: value};
                                });
                                gotoNextPage();
                            }
                        }}
                        question={i18n.t('onboarding.informationPrompt.askForName')}
                        label={i18n.t('onboarding.informationPrompt.name')}
                        currentStep={ONBOARDING_PROGRESS.Name}
                        isDefaultDisabled={true}
                        logoSrc={onboardingProps.logoSrc}
                    />
                );
            case ONBOARDING_PROGRESS.DeviceName:
                return (
                    <InformationPrompt
                        onDone={value => {
                            if (value !== '') {
                                setOnboardingData(prevState => {
                                    return {...prevState, deviceName: value};
                                });
                                gotoNextPage();
                            }
                        }}
                        question={i18n.t('onboarding.informationPrompt.askForDeviceName')}
                        label={i18n.t('onboarding.informationPrompt.deviceName')}
                        currentStep={ONBOARDING_PROGRESS.DeviceName}
                        defaultValue={
                            i18n.t('onboarding.informationPrompt.defaultDeviceName') +
                            onboardingData.personName
                        }
                        isDefaultDisabled={true}
                        logoSrc={onboardingProps.logoSrc}
                    />
                );
            case ONBOARDING_PROGRESS.Email:
                return (
                    <InformationPrompt
                        key="email"
                        onDone={value => {
                            setOnboardingData(prevState => {
                                return {...prevState, email: value};
                            });
                            gotoNextPage();
                        }}
                        question={i18n.t('onboarding.informationPrompt.askForEmail')}
                        label={i18n.t('onboarding.informationPrompt.email')}
                        currentStep={ONBOARDING_PROGRESS.Email}
                        defaultValue={onboardingData.email}
                        isDefaultDisabled={onboardingProps.includePassword}
                        logoSrc={onboardingProps.logoSrc}
                    />
                );
            case ONBOARDING_PROGRESS.Password:
                return (
                    <InformationPrompt
                        key="passowrd"
                        onDone={value => {
                            setOnboardingData(prevState => {
                                return {...prevState, password: value};
                            });
                            gotoNextPage();
                        }}
                        question={i18n.t('onboarding.informationPrompt.askForPassword')}
                        label={i18n.t('onboarding.informationPrompt.password')}
                        currentStep={ONBOARDING_PROGRESS.Password}
                        defaultValue={''}
                        isDefaultDisabled={true}
                        logoSrc={onboardingProps.logoSrc}
                    />
                );
            case ONBOARDING_PROGRESS.ImportKeys:
                return (
                    <InformationPrompt
                        key="importKeys"
                        onDone={value => {
                            if (value !== '') {
                                setOnboardingData(prevState => {
                                    return {...prevState, importKeysCode: value};
                                });
                            }
                            gotoNextPage();
                        }}
                        question={i18n.t('onboarding.informationPrompt.askForImportKeys')}
                        label={i18n.t('onboarding.informationPrompt.importKeys')}
                        currentStep={ONBOARDING_PROGRESS.ImportKeys}
                        defaultValue={onboardingData.importKeysCode}
                        leftButtons={
                            <Button
                                onClick={() => {
                                    gotoNextPage();
                                }}
                                variant="outlined"
                            >
                                {i18n.t('onboarding.skip')}
                            </Button>
                        }
                        logoSrc={onboardingProps.logoSrc}
                    />
                );
            case ONBOARDING_PROGRESS.Picture:
                return (
                    <PicturePrompt
                        currentStep={ONBOARDING_PROGRESS.Picture}
                        onDone={avatar => {
                            setOnboardingData(prevState => {
                                return {
                                    ...prevState,
                                    avatar: avatar
                                };
                            });
                            gotoNextPage();
                        }}
                        onDoneButtonText={
                            onboardingData.importKeysCode === undefined
                                ? i18n.t('onboarding.createInstance')
                                : i18n.t('buttons.common.startOne')
                        }
                        logoSrc={onboardingProps.logoSrc}
                    />
                );
            case ONBOARDING_PROGRESS.ExportKeys:
                if (!onboardingData.exportKeysCode) {
                    setOnboardingPage(ONBOARDING_PROGRESS.Done);
                    onboardingProps.onBoardingCompleted();
                    return <></>;
                }
                return (
                    <IdentityExportKeysCodePrompt
                        code={onboardingData.exportKeysCode}
                        onDone={onboardingProps.onBoardingCompleted}
                        currentStep={ONBOARDING_PROGRESS.ExportKeys}
                        logoSrc={onboardingProps.logoSrc}
                    />
                );
            case ONBOARDING_PROGRESS.Done:
            default:
                return <CircularProgress className="circular-progress" size={35} />;
        }
    }

    return <>{buildOnboardingStep()}</>;
}
