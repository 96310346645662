import {useCallback, type ReactElement} from 'react';
import {useParams} from 'react-router-dom';
import {useNavigateBack} from '@/hooks/navigation';

import DiaryModel from '@refinio/one.models/lib/models/DiaryModel.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';

import CreateDiaryEntry from './CreateDiaryEntry.js';

export default function CreateDiaryEntryOwnerAdapter(props: {
    diaryModel: DiaryModel;
    channelManager: ChannelManager;
}): ReactElement {
    const params = useParams<{owner: SHA256IdHash<Person>}>();
    const navigateBack = useNavigateBack();
    const post = useCallback(
        async (diaryEntry: string) => {
            if (
                params.owner &&
                !(await props.channelManager.hasChannel(DiaryModel.channelId, params.owner))
            ) {
                await props.channelManager.createChannel(DiaryModel.channelId, params.owner);
            }
            await props.diaryModel.addEntry(diaryEntry, params.owner);
            navigateBack();
        },
        [props.diaryModel]
    );

    return <CreateDiaryEntry diaryModel={props.diaryModel} overwritePost={post} />;
}
