import type {ReactElement} from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Group} from '@refinio/one.core/lib/recipes.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {useEditPictureContext} from '@/context/EditPictureContext.js';
import {useGroup} from '@/hooks/contact/groupHooks.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import {useEditMode} from '@/hooks/appBar/common.js';
import i18n from '@/i18n.js';
import type {DetailedGroup} from '@/root/group/create/CreateGroup.js';
import GroupDetailsForm from '@/root/group/common/GroupDetailsForm.js';

import '@/root/group/groupId/Group.css';

/**
 * Edit Group Details Component
 */
export default function EditGroupDetails(): ReactElement {
    const navigate = useNavigate();
    const goBack = useNavigateBack();

    const appBarContext = useContext(AppBarContext);
    const params = useParams<{groupId: SHA256IdHash<Group>}>();

    const group = useGroup(params.groupId);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    const DEFAULT_GROUP_DETAILS: DetailedGroup = {
        name: '',
        picture: undefined
    };

    const [groupProfile, setGroupProfile] = useState<DetailedGroup>(
        group === undefined
            ? DEFAULT_GROUP_DETAILS
            : {
                  name: group.name,
                  picture: group.picture
              }
    );

    const {editedPicture} = useEditPictureContext();

    /**
     * Edits the group
     */
    const updateGroupDetails = useCallback(async () => {
        if (group === undefined) {
            setNotificationMessage(i18n.t('errors.group.update'));
            setNotificationType(NOTIFICATION.Error);
            navigate('/', {replace: true});
            return;
        }

        try {
            group.name = groupProfile.name;
            group.picture = groupProfile.picture;
            await group.saveAndLoad();
            goBack();
        } catch (e) {
            setNotificationMessage(i18n.t('errors.group.update'));
            setNotificationType(NOTIFICATION.Error);
            navigate('/', {replace: true});
        }
    }, [
        goBack,
        group,
        groupProfile.name,
        groupProfile.picture,
        navigate,
        setNotificationMessage,
        setNotificationType
    ]);

    useEditMode(goBack, updateGroupDetails);

    useEffect(() => {
        if (group === undefined) {
            return;
        }
        setGroupProfile({name: group.name, picture: group.picture});
    }, [group]);

    /**
     * Each time when the users change the group picture, update the onDoneCallback
     */
    useEffect(() => {
        if (editedPicture !== undefined) {
            setGroupProfile({...groupProfile, picture: editedPicture});
            appBarContext.setContextValue({
                ...appBarContext.contextValue,
                rightBtnCallback: updateGroupDetails,
                leftBtnCallback: () => {
                    appBarContext.setContextValue({
                        ...appBarContext.contextValue,
                        mode: APP_BAR_MODE.VIEW,
                        title: 'contacts:title'
                    });
                    goBack();
                }
            });
        }
    }, [editedPicture, goBack]);

    /**
     * Each time when the users change the group details, update the onDoneCallback
     */
    useEffect(() => {
        if (appBarContext.contextValue.mode === APP_BAR_MODE.EDIT) {
            appBarContext.setContextValue({
                ...appBarContext.contextValue,
                rightBtnCallback: updateGroupDetails,
                leftBtnCallback: () => {
                    appBarContext.setContextValue({
                        ...appBarContext.contextValue,
                        mode: APP_BAR_MODE.VIEW,
                        title: 'contacts:title'
                    });
                    goBack();
                }
            });
        }
    }, [groupProfile, goBack]);

    return (
        <GroupDetailsForm
            groupDetails={groupProfile}
            onDone={(name: string) => {
                setGroupProfile({
                    ...groupProfile,
                    name: name
                });
            }}
        />
    );
}
