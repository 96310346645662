import React from 'react';
import type {ReactElement} from 'react';

import IconButton from '@mui/material/IconButton/IconButton.js';

import DefaultAttachmentDetails from './DefaultAttachmentDetails.js';

/**
 * @param props.text
 * @param props.getCertificatePopupView callback to get certificate popup view
 * @param props.onError Optional. Default console.error
 * @param props.onClick Optional. Default undefined. When undefined, clicking is not allowed
 * @param props.icon Optional. Default undefined. When provided, replaced the default icon
 * @returns
 */
export default function DefaultAttachmentView(props: {
    text: string;
    getCertificatePopupView: (onClose: () => void) => ReactElement;
    onError?: (e: Error) => void;
    onClick?: () => void;
    icon?: ReactElement;
}): ReactElement {
    return (
        <div className="attachment-box hash-attachment">
            <IconButton
                className="attachment-button"
                sx={{'&:hover': {backgroundColor: 'transparent'}, padding: 0}}
                onClick={props.onClick}
            >
                {props.icon}
                <div className="text">{props.text}</div>
            </IconButton>
            <DefaultAttachmentDetails
                getCertificatePopupView={props.getCertificatePopupView}
                onViewObjectRequest={props.onClick}
            />
        </div>
    );
}
