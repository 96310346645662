import type {ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';

import BaseAddItem from './BaseAddItem.js';

export default function WBCItem(props: {dataOwner?: SHA256IdHash<Person>}): ReactElement {
    const navigate = useNavigate();
    return (
        <BaseAddItem
            type={'wbc'}
            onClick={() => navigate(`/wbc/${props.dataOwner ? `${props.dataOwner}/` : ''}create`)}
        />
    );
}
