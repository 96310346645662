import type {ReactElement} from 'react';
import {Fragment, useEffect, useState, useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import Divider from '@mui/material/Divider/Divider.js';
import List from '@mui/material/List/List.js';

import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import SomeoneModel from '@refinio/one.models/lib/models/Leute/SomeoneModel.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type {Someone} from '@refinio/one.models/lib/recipes/Leute/Someone.js';
import ListElement from '@refinio/one.ui/lib/ui/components/list/ListElement.js';
import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import i18n from '@/i18n.js';
import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import StartChat from '@/components/startChat/StartChat.js';
import {useMoreMenu} from '@/hooks/menu/menu.js';
import {useProfileDetails} from '@/hooks/contact/profileHooks.js';
import CollapsibleProfilesList from '@/root/collapsibles/CollapsibleProfilesList.js';
import SomeoneProfileDetails from '@/root/profile/common/SomeoneProfileDetails.js';
import CollapsibleCouponList from '@/components/one.euro/collapsibleCouponList/CollapsibleCouponList.js';
import {getPersonEmail} from '@/hooks/contact/utils.js';
import './SomeoneView.css';

type SomeoneData = {
    identities: Array<SHA256IdHash<Person>>;
    profiles: Array<ProfileModel>;
    mainProfile: ProfileModel;
};

/**
 * listens to changes in someoneModel and notifies by callback
 * @param someoneId
 * @param onData
 * @param onError
 * @returns unlisten callback
 */
function loadAndMonitorSomeoneData(
    someoneId: SHA256IdHash<Someone>,
    onData: (data: SomeoneData) => void,
    onError: (e: unknown) => void
): () => void {
    const someone = new SomeoneModel(someoneId);

    async function updateSomeoneData(): Promise<void> {
        try {
            await someone.loadLatestVersion();
            onData({
                identities: someone.identities(),
                profiles: await someone.profiles(),
                mainProfile: await someone.mainProfile()
            });
        } catch (e) {
            onError(e);
        }
    }

    // the catch will never be called, unless somebody throws in the onError callback
    updateSomeoneData().catch(console.error);
    return someone.onUpdate.listen(updateSomeoneData);
}

// defined outside of component to not cause re-renders
const MORE_MENU_ENTRIES = [MENU_ENTRY.ADD_PROFILE, MENU_ENTRY.CHANGE_MAIN_PROFILE];

export default function SomeoneView(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    getInformationAddon?: (hashes: (SHA256Hash | SHA256IdHash)[]) => ReactElement;
}): ReactElement {
    const [identitiesData, setIdentitiesData] = useState<
        {id: SHA256IdHash<Person>; email: string}[]
    >([]);
    const [profiles, setProfiles] = useState<ProfileModel[]>([]);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const navigate = useNavigate();
    const params = useParams<{someoneId: SHA256IdHash<Someone>}>();
    const [mainProfile, setMainProfile] = useState<ProfileModel | undefined>();
    const [profileDetails] = useProfileDetails(mainProfile, props.leuteModel);

    const onEntrySelected = useCallback(
        (selectedMenuEntry: string) => {
            switch (selectedMenuEntry) {
                case MENU_ENTRY.ADD_PROFILE:
                    navigate(`/profile/create/${params.someoneId}`);
                    break;
                case MENU_ENTRY.CHANGE_MAIN_PROFILE:
                    navigate(`/profile/change/${params.someoneId}`);
                    break;
            }
        },
        [navigate, params.someoneId]
    );
    useMoreMenu(MORE_MENU_ENTRIES, onEntrySelected);

    useEffect(() => {
        if (!params.someoneId) {
            return;
        }

        let stopped = false;

        async function setData(data: SomeoneData): Promise<void> {
            if (stopped) {
                return;
            }
            setIdentitiesData(
                await Promise.all(
                    data.identities.map(async id => ({id, email: await getPersonEmail(id)}))
                )
            );
            setProfiles(data.profiles);
            setMainProfile(data.mainProfile);
        }

        function handleError(error: unknown) {
            console.error(error);
            if (stopped) {
                return;
            }
            setNotificationMessage(i18n.t('errors.someone.profile'));
            setNotificationType(NOTIFICATION.Error);
            navigate('/', {replace: true});
        }

        const stopMonitoring = loadAndMonitorSomeoneData(params.someoneId, setData, handleError);

        return () => {
            stopped = true;
            stopMonitoring();
        };
    }, [navigate, params.someoneId, setNotificationMessage, setNotificationType]);

    function selectProfile(profileModel: ProfileModel): void {
        navigate(`/profile/${profileModel.idHash}`);
    }

    function openIdentityView(identity: SHA256IdHash<Person>) {
        return () => {
            navigate(`/identity/${params.someoneId}/${identity}`);
        };
    }

    function displayIdentities(): ReactElement {
        return (
            <CollapsibleComponent
                headline={
                    <div className="collapsible-headline">
                        {i18n.t('leute.lists.identities')}
                        <div>({identitiesData.length})</div>
                    </div>
                }
                content={
                    <List className="identities-list">
                        {identitiesData.length > 0 &&
                            identitiesData.map((identityData, index) => (
                                <Fragment key={index}>
                                    <ListElement
                                        onClick={openIdentityView(identityData.id)}
                                        extra={
                                            <div className="identity-container">
                                                <span className="identity-email">
                                                    {identityData.email}
                                                </span>
                                                <span className="identity-id">
                                                    {identityData.id}
                                                </span>
                                            </div>
                                        }
                                    />
                                    {index !== identitiesData.length - 1 && (
                                        <Divider variant={'middle'} />
                                    )}
                                </Fragment>
                            ))}
                    </List>
                }
            />
        );
    }

    function displayMainProfilePreview(): ReactElement {
        return (
            <>
                {mainProfile && (
                    <SomeoneProfileDetails
                        profileDetails={profileDetails}
                        title={i18n.t('leute.someone.title')}
                        instanceInformationAddon={
                            props.getInformationAddon !== undefined && mainProfile
                                ? props.getInformationAddon(
                                      mainProfile.loadedVersion
                                          ? [mainProfile.loadedVersion, mainProfile.personId]
                                          : [mainProfile.personId]
                                  )
                                : undefined
                        }
                        sendMessageElement={
                            <StartChat
                                leuteModel={props.leuteModel}
                                receiverProfile={mainProfile}
                                topicModel={props.topicModel}
                            />
                        }
                        hideStatus={true}
                        hideCommunicationEndpoints={true}
                    />
                )}
            </>
        );
    }

    return (
        <>
            {displayMainProfilePreview()}
            {displayIdentities()}
            <CollapsibleProfilesList
                leuteModel={props.leuteModel}
                profiles={profiles}
                mainProfileHash={mainProfile?.idHash}
                topicModel={props.topicModel}
                onSelectProfile={selectProfile}
            />
            <CollapsibleCouponList leuteModel={props.leuteModel} topicModel={props.topicModel} />
        </>
    );
}
