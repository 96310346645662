import type {ReactElement} from 'react';

import type {Instance} from '@refinio/one.core/lib/recipes.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import ListElement from '@refinio/one.ui/lib/ui/components/list/ListElement.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import AvatarPreview from '@/components/avatarPreview/AvatarPreview.js';
import {GREEN_CHECK_MARK_BUTTON} from '@/components/Constants.js';
import {useAvatarUrl, useIsProfileSigned} from '@/hooks/contact/profileHooks.js';

export type InstanceListItem = {
    mainProfile: ProfileModel;
    instanceId?: SHA256IdHash<Instance>;
};

export default function InstanceListEntry(props: {
    leuteModel: LeuteModel;
    instance: InstanceListItem;
    onSelect: (instance: InstanceListItem) => void;
    showHashOnly?: boolean;
}): ReactElement {
    const mainProfile = props.instance.mainProfile;
    const isSigned = useIsProfileSigned(mainProfile, props.leuteModel.trust);
    const avatarUrl = useAvatarUrl(mainProfile);
    const name = props.leuteModel.getPersonName(mainProfile.personId);

    return (
        <ListElement
            onClick={() => props.onSelect(props.instance)}
            extra={
                <>
                    {props.showHashOnly ? (
                        props.instance.instanceId
                    ) : (
                        <>
                            <AvatarPreview src={avatarUrl} />
                            {name}
                            <div className="trusted">{isSigned && GREEN_CHECK_MARK_BUTTON}</div>
                        </>
                    )}
                </>
            }
        />
    );
}
