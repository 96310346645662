import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import FormControl from '@mui/material/FormControl/FormControl.js';
import InputLabel from '@mui/material/InputLabel/InputLabel.js';
import MenuItem from '@mui/material/MenuItem/MenuItem.js';
import Select from '@mui/material/Select/Select.js';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';

import './SomeoneProfileDetails.css';

/**
 * @param props.value currently selected value from options
 * @param props.options selectable option choices
 * @param props.onSelect callback
 * @param props.notSelectedValueLabel when no value from options is selected. Can be used as a hint text.
 * @returns
 */
export default function PersonIdSelector(props: {
    value: SHA256IdHash<Person> | undefined;
    options: SHA256IdHash<Person>[];
    onSelect: (personId: SHA256IdHash<Person> | undefined) => void;
}): ReactElement {
    const i18n = useTranslation();
    const notSelectedValueLabel = i18n.t('leute.profile.newRandomIdentity');
    const selectedValue = props.value ? props.value : notSelectedValueLabel;

    return (
        <FormControl variant="standard" sx={{minWidth: '100%'}}>
            <InputLabel id="demo-simple-select-standard-label">
                {i18n.t('common.personId')}
            </InputLabel>
            <Select
                value={selectedValue}
                onChange={e => {
                    if (props.onSelect) {
                        if (e.target.value === '' || e.target.value === notSelectedValueLabel) {
                            props.onSelect(undefined);
                        } else {
                            props.onSelect(e.target.value as SHA256IdHash<Person>);
                        }
                    }
                }}
                label={i18n.t('common.personId')}
            >
                <MenuItem value={notSelectedValueLabel}>{notSelectedValueLabel}</MenuItem>
                {props.options.map(personId => (
                    <MenuItem key={personId} value={personId}>
                        {personId}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
