import {type ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useTheme} from '@mui/material/styles';

import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';

import type {CertificateSetting} from './common/types.js';
import CertificatesView from './CertificatesView.js';
import CertificateCreate from './CertificateCreate.js';

import './TrustView.css';
import {getDefaultCertificateSettings} from './common/utils.js';

export default function CertificatePopup(props: {
    hashes: (SHA256Hash | SHA256IdHash)[] | SHA256Hash | SHA256IdHash | undefined;
    leuteModel: LeuteModel;
    onClose: () => void;
    createOnlyHashes?: (SHA256Hash | SHA256IdHash)[] | SHA256Hash | SHA256IdHash | undefined;
    isOpened?: boolean | undefined;
    onError?: (error: Error) => void;
    overwriteCertificateSettings?: CertificateSetting[];
}): ReactElement {
    const i18n = useTranslation();

    if (props.isOpened !== undefined && !props.isOpened) {
        return <></>;
    }

    const certificateSettings = props.overwriteCertificateSettings
        ? props.overwriteCertificateSettings
        : getDefaultCertificateSettings(props.leuteModel.trust);

    const theme = useTheme();

    return (
        <AppBarFullScreenPopup
            background={theme.palette.background.default}
            onClose={props.onClose}
            mode={APP_BAR_MODE.CHEVRON}
            title={i18n.t('trust.title')}
        >
            <>
                <CertificateCreate
                    trustedKeysManager={props.leuteModel.trust}
                    hashes={
                        !props.createOnlyHashes && props.hashes
                            ? props.hashes
                            : props.createOnlyHashes
                    }
                    onError={props.onError}
                    overwriteCertificateSettings={certificateSettings}
                />
                <CertificatesView
                    hashes={props.hashes}
                    leuteModel={props.leuteModel}
                    onError={props.onError}
                    certificateSettings={certificateSettings}
                />
            </>
        </AppBarFullScreenPopup>
    );
}
