import AssignmentIcon from '@mui/icons-material/Assignment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import type {ReactElement} from 'react';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined.js';

import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import {
    type QuestionnaireResponses,
    questionnaireResponsesTypes
} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type {JournalEntry} from '@refinio/one.models/lib/models/JournalModel.js';
import type {EventTypes} from '@refinio/one.ui/lib/ui/views/journal/Journal.js';

import {useQuestionnaireTitlesByUrlMap} from '@/hooks/questionnaire/hooks.js';

// extracted literal string
export const documentType = 'Document';
export const questionnaireTypeDisplay = 'QuestionnaireResponses';
export const personStatusType = 'PersonStatus';
export const personImageType = 'PersonImage';
export const bodyTemperatureType = 'BodyTemperature';
export const wbcDiffType = 'WbcDiff';
export const diaryType = 'Diary';

/**
 * @param questionnaireModel
 * @param addonRenderFn Optional, additional react element on the event list item
 * @returns
 */
export function useQuestionnaireEventType(
    questionnaireModel: QuestionnaireModel,
    addonRenderFn?: (entry: JournalEntry) => ReactElement | void
): EventTypes {
    const i18n = useTranslation();
    const questionnaireTitlesByUrlMap = useQuestionnaireTitlesByUrlMap(questionnaireModel);

    /**
     * Callback for setting Journal list title for the questionnaire type
     */
    const getQuestionnaireTitle = useCallback(
        (data: unknown) => {
            if (data) {
                const questionnaireResponses = data as QuestionnaireResponses;
                if (
                    questionnaireTitlesByUrlMap &&
                    questionnaireResponsesTypes.includes(questionnaireResponses.$type$)
                ) {
                    const questionnaireUrl = questionnaireResponses.response[0].questionnaire;
                    if (questionnaireUrl) {
                        return <>{questionnaireTitlesByUrlMap.get(questionnaireUrl)}</>;
                    }
                }
            }
            return <>{questionnaireTypeDisplay}</>;
        },
        [questionnaireTitlesByUrlMap]
    );

    return {
        type: questionnaireTypeDisplay,
        name: i18n.t('journal.eventTypes.questionnaireTitle'),
        icon: <AssignmentOutlinedIcon />,
        path: '/questionnaire/view?response=:id',
        titleRenderFn: getQuestionnaireTitle,
        addonRenderFn: addonRenderFn
    };
}

/**
 * @param questionnaireModel
 * @returns
 */
export function useEventTypes(questionnaireModel: QuestionnaireModel): EventTypes[] {
    const i18n = useTranslation();
    const questionnaireTitlesByUrlMap = useQuestionnaireTitlesByUrlMap(questionnaireModel);
    /**
     * Callback for setting Journal list title for the questionnaire type
     */
    const getQuestionnaireTitle = useCallback(
        (data: unknown) => {
            if (data) {
                const questionnaireResponses = data as QuestionnaireResponses;
                if (
                    questionnaireTitlesByUrlMap &&
                    questionnaireResponsesTypes.includes(questionnaireResponses.$type$)
                ) {
                    const questionnaireUrl = questionnaireResponses.response[0].questionnaire;
                    if (questionnaireUrl) {
                        return <>{questionnaireTitlesByUrlMap.get(questionnaireUrl)}</>;
                    }
                }
            }
            return <>{questionnaireTypeDisplay}</>;
        },
        [questionnaireTitlesByUrlMap]
    );

    return [
        {
            type: questionnaireTypeDisplay,
            name: i18n.t('journal.eventTypes.questionnaireTitle'),
            icon: <AssignmentIcon />,
            path: '/questionnaire/view?response=:id',
            titleRenderFn: getQuestionnaireTitle
        },
        {
            type: personStatusType,
            name: i18n.t('journal.eventTypes.personStatusTitle')
        },
        {
            type: personImageType,
            name: i18n.t('journal.eventTypes.personImageTitle')
        },
        {
            type: documentType,
            name: i18n.t('journal.eventTypes.documentTitle'),
            icon: <InsertDriveFileIcon />,
            path: '/document/view?document=:id'
        },
        {
            type: diaryType,
            name: i18n.t('journal.eventTypes.diaryTitle'),
            icon: <MenuBookIcon />,
            path: '/diary/:id'
        },
        {
            type: wbcDiffType,
            name: i18n.t('journal.eventTypes.wbcDiffTitle'),
            icon: <WaterDropIcon />,
            path: '/wbc/:id'
        },
        {
            type: bodyTemperatureType,
            name: i18n.t('journal.eventTypes.bodyTemperatureTitle'),
            icon: <ThermostatIcon />,
            path: '/bodyTemperature/:id'
        }
    ];
}
