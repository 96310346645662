import type {ReactElement} from 'react';
import {Route, Routes} from 'react-router-dom';

import type WbcDiffModel from '@refinio/one.models/lib/models/WbcDiffModel.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';

import CreateWBCDiffOwnerAdapter from './create/CreateWBCDiffOwnerAdapter.js';
import CreateWBCDiff from './create/CreateWBCDiff.js';
import ViewWBCDiff from './view/ViewWBCDiff.js';

export default function WBCRouter(props: {
    wbcDiffModel: WbcDiffModel;
    channelManager: ChannelManager;
}): ReactElement {
    return (
        <Routes>
            <Route path="create" element={<CreateWBCDiff wbcDiffModel={props.wbcDiffModel} />} />
            <Route
                path=":owner/create"
                element={
                    <CreateWBCDiffOwnerAdapter
                        wbcDiffModel={props.wbcDiffModel}
                        channelManager={props.channelManager}
                    />
                }
            />
            <Route path=":id" element={<ViewWBCDiff wbcDiffModel={props.wbcDiffModel} />} />
            <Route element={<CreateWBCDiff wbcDiffModel={props.wbcDiffModel} />} />
        </Routes>
    );
}
