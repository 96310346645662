import {useState, type ReactElement, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';

import type BodyTemperatureModel from '@refinio/one.models/lib/models/BodyTemperatureModel.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import BodyTemperature from '../BodyTemperature.js';
import '../BodyTemperature.css';

export default function CreateBodyTemperature(props: {
    bodyTemperatureModel: BodyTemperatureModel;
    overwritePost?: (temp: number) => Promise<void>;
}): ReactElement {
    const i18n = useTranslation();
    const [temperature, setTemperature] = useState<string>('');
    const goBack = useNavigateBack();
    const navigate = useNavigate();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const theme = useTheme();

    const onFinish = useCallback(async () => {
        const temp = Number(temperature);
        if (temperature !== '' && temp >= 35 && temp <= 45) {
            if (props.overwritePost) {
                await props.overwritePost(temp);
            } else {
                await props.bodyTemperatureModel.addBodyTemperature(temp);
                navigate('/journal');
            }
        } else {
            setNotificationMessage(i18n.t('bodyTemperature.error.invalidTemperature'));
            setNotificationType(NOTIFICATION.Error);
        }
    }, [temperature, props.bodyTemperatureModel]);

    function handleChange(value: string): void {
        const regexNumber = /^[0-9\\.\b]+$/;
        if (value === '') {
            setTemperature('');
        }
        if (regexNumber.test(value)) {
            setTemperature(value);
        }
    }

    return (
        <AppBarFullScreenPopup
            mode={APP_BAR_MODE.EDIT}
            title={i18n.t('bodyTemperature.title')}
            onClose={goBack}
            onFinish={onFinish}
            background={theme.palette.background.default}
        >
            <BodyTemperature value={String(temperature)} onChange={handleChange} />
        </AppBarFullScreenPopup>
    );
}
