import type {Invitation} from '@refinio/one.models/lib/misc/ConnectionEstablishment/PairingManager';
import {isInvitation} from '@refinio/one.models/lib/misc/ConnectionEstablishment/PairingManager';
import type {ConnectionsModel} from '@refinio/one.models/lib/models';
import {wait} from '@refinio/one.core/lib/util/promise';
import {useEffect, useState} from 'react';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks';
import type {Instance, Person} from '@refinio/one.core/lib/recipes';
import type IoMRequestManager from '@refinio/one.models/lib/models/IoM/IoMRequestManager';

export function useParing(
    connectionsModel: ConnectionsModel,
    ioMRequestManager: IoMRequestManager,
    setError: (error: Error) => void = console.error
): (invitationLink: string, maxTries?: number) => Promise<boolean> {
    const [mode, setMode] = useState<'IoM' | 'IoP' | undefined>(undefined);
    const [currentInvitation, setCurrentInvitation] = useState<Invitation | undefined>(undefined);

    /**
     * @param maxTries maximum amount of retries
     */
    async function tryPairing(invitationLink: string, maxTries: number = 4): Promise<boolean> {
        let error: Error | undefined = undefined;
        const invitation = getPairingInformation(invitationLink);
        setCurrentInvitation(invitation);
        if (!invitation) {
            return false;
        }

        for (let i = 0; i <= maxTries && error === undefined; ++i) {
            try {
                await connectionsModel.pairing.connectUsingInvitation(invitation);
                return true;
            } catch (e) {
                console.error(`Pairing ${i}`, e);
                if (i === maxTries) {
                    error = e as Error;
                }
                await wait(2000);
            }
        }
        if (error) {
            setError(error);
            return false;
        }
        return true;
    }

    /**
     * @param invitationLink string format
     */
    function getPairingInformation(invitationLink: string): Invitation | undefined {
        if (invitationLink.includes('invites/inviteDevice/?invited=true')) {
            setMode('IoM');
        } else if (invitationLink.includes('invites/invitePartner/?invited=true')) {
            setMode('IoP');
        } else {
            return undefined;
        }

        try {
            const invitation = JSON.parse(
                decodeURIComponent(invitationLink.split('#')[1])
            ) as Invitation;

            if (isInvitation(invitation)) {
                return invitation;
            } else {
                return undefined;
            }
        } catch (_e) {
            return undefined;
        }
    }

    useEffect(() => {
        if (mode !== 'IoM' || !currentInvitation) {
            return;
        }

        return connectionsModel.pairing.onPairingSuccess(
            (
                _initiatedLocally: boolean,
                localPersonId: SHA256IdHash<Person>,
                _localInstanceId: SHA256IdHash<Instance>,
                remotePersonId: SHA256IdHash<Person>,
                _remoteInstanceId: SHA256IdHash<Instance>,
                token: string
            ) => {
                if (mode !== 'IoM' || !currentInvitation) {
                    return;
                }

                if (token === currentInvitation.token) {
                    ioMRequestManager
                        .createIoMRequest(localPersonId, remotePersonId, localPersonId, 'light')
                        .catch(console.error);
                }
            }
        );
    }, [connectionsModel, currentInvitation, ioMRequestManager, mode]);

    return tryPairing;
}
