import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const EcpireArmpitLeftQuestionnaireUrl =
    'http://refinio.one/questionaire/ecpire_armpit_left';
export const EcpireArmpitLeftQuestionnaireName = 'ecpire_armpit_left';
export const EcpireArmpitLeftQuestionnaire: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: EcpireArmpitLeftQuestionnaireUrl,
    name: EcpireArmpitLeftQuestionnaireName,
    title: 'Left Armpit',
    status: 'active',
    item: [
        {
            linkId: 'date',
            prefix: '1',
            text: 'Date',
            type: 'date',
            required: true,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'changes',
            prefix: '2',
            text: 'Haben Sie Veränderungen im Bereich der Achselhöhle wahrgenommen?',
            type: 'choice',
            required: true,
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Ja',
                        system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/YesNo_en'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Nein',
                        system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/YesNo_en'
                    }
                }
            ]
        },
        {
            linkId: 'signs',
            prefix: '3',
            text: 'Sind Ihre Achselhöhle und Lymphknoten geschwollen?',
            type: 'choice',
            required: true,
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Ja',
                        system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/YesNo_en'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Nein',
                        system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/YesNo_en'
                    }
                }
            ]
        }
    ]
};
