import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {Recipe, OneObjectTypeNames} from '@refinio/one.core/lib/recipes.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {License} from '@refinio/one.models/lib/recipes/Certificates/License.js';
import {registerLicense} from '@refinio/one.models/lib/misc/Certificates/LicenseRegistry.js';

/**
 * License for giving somebody else access to data.
 */
export const SignupLicense: License = Object.freeze({
    $type$: 'License',
    name: 'Signup',
    description:
        '[signature.issuer] affirms that [person] has agreed to be signed up with edda.one by [signature.issuer] and store her data in the [person]s name.'
});

registerLicense(SignupLicense, 'SignupCertificate');

export interface SignupCertificate {
    $type$: 'SignupCertificate';
    person: SHA256IdHash<Person>;
    license: SHA256Hash<License>;
}

export const SignupCertificateRecipe: Recipe = {
    $type$: 'Recipe',
    name: 'SignupCertificate',
    rule: [
        {
            itemprop: 'person',
            itemtype: {type: 'referenceToId', allowedTypes: new Set(['Person'])}
        },
        {
            itemprop: 'license',
            itemtype: {type: 'referenceToObj', allowedTypes: new Set(['License'])}
        }
    ]
};

export const SignupCertificateReverseMap: [OneObjectTypeNames, Set<string>] = [
    'SignupCertificate',
    new Set(['*'])
];

// #### one.core interfaces ####

declare module '@OneObjectInterfaces' {
    export interface OneCertificateInterfaces {
        SignupCertificate: SignupCertificate;
    }
}
