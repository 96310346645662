import {useEffect, useContext, useCallback} from 'react';

import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {useNavigateBack} from '@/hooks/navigation.js';

/**
 * This handles the navigation of the chat component including the appbar and such stuff.
 *
 * @returns a function that when called will navigate back.
 */
export default function useChatNavigation(): () => void {
    const navigateBack = useNavigateBack();

    const {contextValue, setContextValue} = useContext(AppBarContext);

    const appBarCleanup = useCallback((): void => {
        setContextValue({
            ...contextValue,
            isLeftBtnClicked: false,
            hide: false
        });
        // can not have a dependency on contextValue, as it changes contextValue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setContextValue]);

    /**
     * navigation go back cleanup
     */
    const goBack = useCallback(() => {
        appBarCleanup();
        navigateBack();
    }, [appBarCleanup, navigateBack]);

    /**
     * Show the action bar on back
     */
    useEffect(() => {
        return appBarCleanup;
    }, [appBarCleanup]);

    return goBack;
}
