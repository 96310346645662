import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

import noPainFaceBase64ImageData from './schmerzerfassungResources/noPainFace.js';
import lowPainFaceBase64ImageData from './schmerzerfassungResources/lowPainFace.js';
import lowToMediumPainFaceBase64ImageData from './schmerzerfassungResources/lowToMediumPainFace.js';
import mediumToHighPainFaceBase64ImageData from './schmerzerfassungResources/mediumToHighPainFace.js';
import highPainFaceBase64ImageData from './schmerzerfassungResources/highPainFace.js';
import extremePainFaceBase64ImageData from './schmerzerfassungResources/extremePainFace.js';

export const SchmerzerfassungQuestionnaireFaceAssessmentDE: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/Schmerzerfassung_FaceAssessment',
    name: 'Schmerzerfassung_FaceAssessment',
    title: 'Schmerzerfassung Face Assessment',
    status: 'active',
    item: [
        {
            linkId: 'faceAssessment',
            prefix: '2',
            type: 'group',
            text: 'Numerische Einschätzung',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'faceResting',
                    prefix: '2_1',
                    type: 'choice',
                    text: 'Im Ruhezustand',
                    required: true,
                    disabledDisplay: 'protected',
                    initial: [
                        {
                            valueAttachment: {
                                title: 'no pain',
                                contentType: 'image/jpeg',
                                data: noPainFaceBase64ImageData
                            }
                        },
                        {
                            valueAttachment: {
                                title: 'low pain',
                                contentType: 'image/jpeg',
                                data: lowPainFaceBase64ImageData
                            }
                        },
                        {
                            valueAttachment: {
                                title: 'low to medium pain',
                                contentType: 'image/jpeg',
                                data: lowToMediumPainFaceBase64ImageData
                            }
                        },
                        {
                            valueAttachment: {
                                title: 'medium to high pain',
                                contentType: 'image/jpeg',
                                data: mediumToHighPainFaceBase64ImageData
                            }
                        },
                        {
                            valueAttachment: {
                                title: 'high pain',
                                contentType: 'image/jpeg',
                                data: highPainFaceBase64ImageData
                            }
                        },
                        {
                            valueAttachment: {
                                title: 'extreme pain',
                                contentType: 'image/jpeg',
                                data: extremePainFaceBase64ImageData
                            }
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: '0',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '2',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '4',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '6',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '8',
                                version: '1.0',
                                display: '8',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '10',
                                version: '1.0',
                                display: '10',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'faceLoad',
                    prefix: '2_2',
                    type: 'choice',
                    text: 'Bei Belastung',
                    required: true,
                    disabledDisplay: 'protected',
                    initial: [
                        {
                            valueAttachment: {
                                title: 'no pain',
                                contentType: 'image/jpeg',
                                data: noPainFaceBase64ImageData
                            }
                        },
                        {
                            valueAttachment: {
                                title: 'low pain',
                                contentType: 'image/jpeg',
                                data: lowPainFaceBase64ImageData
                            }
                        },
                        {
                            valueAttachment: {
                                title: 'low to medium pain',
                                contentType: 'image/jpeg',
                                data: lowToMediumPainFaceBase64ImageData
                            }
                        },
                        {
                            valueAttachment: {
                                title: 'medium to high pain',
                                contentType: 'image/jpeg',
                                data: mediumToHighPainFaceBase64ImageData
                            }
                        },
                        {
                            valueAttachment: {
                                title: 'high pain',
                                contentType: 'image/jpeg',
                                data: highPainFaceBase64ImageData
                            }
                        },
                        {
                            valueAttachment: {
                                title: 'extreme pain',
                                contentType: 'image/jpeg',
                                data: extremePainFaceBase64ImageData
                            }
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: '0',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '2',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '4',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '6',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '8',
                                version: '1.0',
                                display: '8',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '10',
                                version: '1.0',
                                display: '10',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'faceComment',
                    prefix: '2_3',
                    type: 'string',
                    text: 'Bemerkung:',
                    required: false,
                    disabledDisplay: 'protected'
                }
            ]
        }
    ]
};
