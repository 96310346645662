import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import type {
    PersonImage,
    PersonStatus
} from '@refinio/one.models/lib/recipes/Leute/PersonDescriptions.js';

import ContactCard from '@/components/card/ContactCard.js';
import ProfileStatus from '@/root/profile/profileId/status/create/ProfileStatus.js';
import type {StatusInformation} from '@/root/profile/profileId/status/create/SomeoneProfileStatus.js';
import {STATUS_INFORMATION} from '@/root/profile/profileId/status/create/SomeoneProfileStatus.js';

import './SomeoneProfileDetails.css';

export default function StatusSection(props: {
    status: PersonStatus | PersonImage | undefined;
    addStatusInformation: (selectedStatus: StatusInformation) => void;
}): ReactElement {
    const i18n = useTranslation();

    return (
        <>
            <div className="cnt-title">{i18n.t('leute.status.title')}</div>
            <ContactCard
                content={
                    <div className="add-status-card">
                        <div className="add-status">{i18n.t('buttons.common.add')}</div>
                        <div className="emojis">
                            <div
                                onClick={() => props.addStatusInformation(STATUS_INFORMATION.TEXT)}
                            >
                                {i18n.t('leute.status.chatBalloonEmoji')}
                            </div>
                            <div
                                onClick={() => props.addStatusInformation(STATUS_INFORMATION.IMAGE)}
                            >
                                {i18n.t('leute.status.cameraEmoji')}
                            </div>
                        </div>
                    </div>
                }
            />
            {props.status !== undefined && <ProfileStatus statusData={props.status} />}
        </>
    );
}
