import {useEffect, useState} from 'react';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';

export function usePersonId(leuteModel: LeuteModel): SHA256IdHash<Person> | undefined {
    const [personId, setPersonId] = useState<SHA256IdHash<Person> | undefined>();

    useEffect(() => {
        leuteModel.me().then(me => {
            me.mainProfile().then(mainProfile => setPersonId(mainProfile.personId));
        });
    }, [leuteModel]);

    return personId;
}

export function useUpdateLeuteData(
    leuteModel: LeuteModel,
    updateCallback: () => Promise<void>
): void {
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    useEffect(() => {
        updateCallback().catch(error => {
            setNotificationMessage(error.message);
            setNotificationType(NOTIFICATION.Error);
        });

        return leuteModel.onUpdated(() => {
            updateCallback().catch(error => {
                setNotificationMessage(error.message);
                setNotificationType(NOTIFICATION.Error);
            });
        });
    }, [leuteModel]);
}
