import React, {type ReactElement, useState} from 'react';
import Button from '@mui/material/Button/Button.js';

import type {SupportedLanguage} from '@refinio/one.ui/lib/ui/components/languageSelector/LanguageSelector';
import LanguageSelector from '@refinio/one.ui/lib/ui/components/languageSelector/LanguageSelector';

import leute_logo from '@/resources/images/leute-logo.svg';

import '../Onboarding.css';
import {useTranslation} from 'react-i18next';

/**
 *
 * @param props.onDone callback
 * @param props.logoSrc Optional. Default leute logo src.
 * @param props.disableLanguageSelect Optional. Default false.
 * @returns
 */
export default function Disclaimer(props: {
    onDone: (value: boolean) => void;
    logoSrc?: string;
    children?: ReactElement | ReactElement[];
    disableLanguageSelect?: boolean;
}): ReactElement {
    const {i18n} = useTranslation();
    const [language, setLanguage] = useState<SupportedLanguage>(i18n.language as SupportedLanguage);
    async function handleChange(lang: SupportedLanguage): Promise<void> {
        await i18n.changeLanguage(lang);
        setLanguage(lang);
    }

    return (
        <div className="onboarding">
            <LanguageSelector language={language} onChange={handleChange} />
            <div className="disclaimer-middle-container">
                <img
                    className="logo"
                    alt="app-logo"
                    src={props.logoSrc ? props.logoSrc : leute_logo}
                />
                {props.children}
            </div>
            <div className="disclaimer-advertise">
                <Button
                    color="primary"
                    variant="contained"
                    className="body go-btn"
                    onClick={() => {
                        props.onDone(true);
                    }}
                >
                    {i18n.t('onboarding.disclaimer.proceedToApplication')}
                </Button>
                <div className="body2">{i18n.t('onboarding.disclaimer.advertise')}</div>
            </div>
        </div>
    );
}
