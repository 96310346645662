import {useTheme} from '@mui/material/styles';
import {useState, type ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useNavigateBack} from '@/hooks/navigation';

import type WbcDiffModel from '@refinio/one.models/lib/models/WbcDiffModel.js';
import type {WbcObservation} from '@refinio/one.models/lib/recipes/WbcDiffRecipes.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import WBCMeasurements from '../WBCMeasurements.js';
import '../WBC.css';

export default function ViewWBCDiff(props: {
    wbcDiffModel: WbcDiffModel;
    id?: string;
    onClose?: () => void | Promise<void>;
}): ReactElement {
    const i18n = useTranslation();
    const goBack = useNavigateBack();
    const [wbcObservation, setWbcObservation] = useState<WbcObservation | undefined>(undefined);
    const {id} = useParams<{id: string}>();
    const theme = useTheme();

    useEffect(() => {
        async function getObservation(): Promise<void> {
            if (!id && !props.id) {
                return;
            }

            const tempId = props.id ? props.id : id ? id : 'error';
            const dataObject = await props.wbcDiffModel.observationById(tempId);
            setWbcObservation(dataObject.data);
        }

        getObservation().catch(console.error);
        return props.wbcDiffModel.onUpdated(getObservation);
    }, [props.wbcDiffModel, id]);

    return (
        <AppBarFullScreenPopup
            background={theme.palette.background.default}
            mode={APP_BAR_MODE.CHEVRON}
            onClose={props.onClose ? props.onClose : goBack}
            title={i18n.t('wbc.title')}
        >
            {wbcObservation ? <WBCMeasurements value={wbcObservation} /> : <></>}
        </AppBarFullScreenPopup>
    );
}
