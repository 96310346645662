import type {Dispatch, SetStateAction} from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import {getInstanceOwnerIdHash} from '@refinio/one.core/lib/instance.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile.js';
import type TrustedKeysManager from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import {getIdObject} from '@refinio/one.core/lib/storage-versioned-objects.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import i18n from '@/i18n.js';
import type {ProfileDetails} from '@/root/profile/common/types.js';
import {onNewAffirmation, onNewSignupCert} from '@/utils/trust.js';
import {
    getProfileAvatar,
    loadProfileCommunicationEndpoints,
    loadProfilePersonDescription
} from './utils.js';

/**
 * Returns if the person has a trusted signup certificate.
 *
 * @param personId
 * @param trust
 */
export function useIsPersonSignedUp(
    personId: SHA256IdHash<Person> | undefined,
    trust: TrustedKeysManager
): boolean {
    const [isSigned, setSigned] = useState(false);

    /**
     * Check if the profile is signed by you when the profile change
     */
    useEffect(() => {
        let ignore = false;

        async function isProfileSigned() {
            if (!ignore && personId !== undefined) {
                const me = getInstanceOwnerIdHash();
                if (me === undefined) {
                    setSigned(false);
                    return;
                }
                setSigned(await trust.isCertifiedBy(personId, 'SignupCertificate', me));
            }
        }

        isProfileSigned().catch(console.error);

        const disconnect =
            personId !== undefined
                ? onNewSignupCert(
                      personId,
                      isProfileSigned,
                      'useIsPersonSignedUp: New signup certificate'
                  )
                : () => {};

        return () => {
            ignore = true;
            disconnect();
        };
    }, [personId, trust]);

    return isSigned;
}

export function useIsProfileSigned(profile: ProfileModel, trust: TrustedKeysManager): boolean {
    const [isSigned, setSigned] = useState(false);

    /**
     * Check if the profile is signed by you when the profile change
     */
    useEffect(() => {
        const profileHash = profile.loadedVersion;

        let ignore = false;

        async function isProfileSigned() {
            if (!ignore && profileHash !== undefined) {
                const me = getInstanceOwnerIdHash();
                if (me === undefined) {
                    setSigned(false);
                    return;
                }
                setSigned(await trust.isAffirmedBy(profileHash, me));
            }
        }

        isProfileSigned().catch(console.error);

        const disconnect =
            profileHash !== undefined
                ? onNewAffirmation(
                      profileHash,
                      isProfileSigned,
                      'useIsProfileSigned: New affirmation certificate'
                  )
                : () => {};

        return () => {
            ignore = true;
            disconnect();
        };
    }, [profile.loadedVersion, trust]);

    return isSigned;
}

export function useAvatarUrl(profileModel: ProfileModel): string | undefined {
    const [avatar, setAvatar] = useState<string | undefined>(undefined);

    useEffect(() => {
        async function getAvatar() {
            setAvatar(await getProfileAvatar(profileModel));
        }
        getAvatar().catch(err => console.error(err));
    }, [profileModel]);

    return avatar;
}

/**
 * Used to get the profiles details whenever the profile is updated.
 * @param profileModel
 * @param leuteModel
 */
export function useProfileDetails(
    profileModel: ProfileModel | undefined,
    leuteModel?: LeuteModel | undefined
): [ProfileDetails, Dispatch<SetStateAction<ProfileDetails>>] {
    const [profileDetails, setProfileDetails] = useState<ProfileDetails>({
        name: '',
        personEmail: '',
        avatar: undefined
    });
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const navigate = useNavigate();

    useEffect(() => {
        let cancelled = false;

        async function updateSomeoneProfile() {
            if (profileModel === undefined) {
                return;
            }
            await profileModel.loadLatestVersion();
            const descriptions = await loadProfilePersonDescription(profileModel);
            const descriptionsWithCommunicationEndpoints = await loadProfileCommunicationEndpoints(
                descriptions,
                profileModel
            );

            const details = {
                owner: profileModel.owner,
                personId: profileModel.personId,
                ...descriptionsWithCommunicationEndpoints
            };

            if (details.owner && leuteModel) {
                details.ownerName = leuteModel.getPersonName(details.owner);
            }

            if (!cancelled) {
                setProfileDetails(details);
            }
        }

        updateSomeoneProfile().catch(_ => {
            setNotificationMessage(i18n.t('errors.someone.profile'));
            setNotificationType(NOTIFICATION.Error);
            navigate('/', {replace: true});
        });

        const disconnect =
            profileModel === undefined ? () => {} : profileModel.onUpdate(updateSomeoneProfile);

        return () => {
            disconnect();
            cancelled = true;
        };
    }, [profileModel, leuteModel, navigate, setNotificationType, setNotificationMessage]);

    return [profileDetails, setProfileDetails];
}

/**
 * Used to get the profile model by profile groupId
 * @param profileId
 */
export function useProfileModel(
    profileId: SHA256IdHash<Profile> | undefined
): ProfileModel | undefined {
    const [profileModel, setProfileModel] = useState<ProfileModel>();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    const navigate = useNavigate();

    useEffect(() => {
        let cancelled = false;

        async function fetchProfileModel(forProfileId: SHA256IdHash<Profile>) {
            const profile = await ProfileModel.constructFromLatestVersion(forProfileId);
            if (!cancelled) {
                setProfileModel(profile);
            }
        }

        if (profileId) {
            fetchProfileModel(profileId).catch(_ => {
                setNotificationMessage(i18n.t('errors.someone.profile'));
                setNotificationType(NOTIFICATION.Error);
                navigate('/', {replace: true});
            });
        }

        return () => {
            cancelled = true;
        };
    }, [profileId, navigate, setNotificationType, setNotificationMessage]);

    return profileModel;
}
