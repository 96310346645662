export async function generateThumbnail(file: File, boundBox: [number, number]): Promise<File> {
    if (!boundBox || boundBox.length !== 2) {
        throw new Error('You need to give the boundBox');
    }
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        throw new Error('Context not available');
    }

    return new Promise<File>((resolve, reject) => {
        const img = new Image();
        img.onerror = reject;
        img.onload = function () {
            const scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height);
            const w = img.width * scaleRatio;
            const h = img.height * scaleRatio;
            canvas.width = w;
            canvas.height = h;
            ctx.drawImage(img, 0, 0, w, h);
            canvas.toBlob(blob =>
                blob
                    ? resolve(
                          new File([blob], file.name, {
                              type: file.type
                          })
                      )
                    : reject('No blob provided')
            );
        };
        img.src = window.URL.createObjectURL(file);
    });
}

export async function generateThumbnails(
    files: File[],
    boundBox: [number, number]
): Promise<File[]> {
    return Promise.all<File>(files.map(file => generateThumbnail(file, boundBox)));
}
