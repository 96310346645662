import {createContext, useContext} from 'react';
import type {ReactElement} from 'react';
import {Dialog, DialogContent, DialogContentText, IconButton} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';

import './PopupBox.css';

export const POPUP_BOX_TYPE = {
    Success: 'success',
    Info: 'info',
    Error: 'error'
} as const;

/** The type definition based on the POPUP_BOX_TYPE value. **/
export type PopupType = (typeof POPUP_BOX_TYPE)[keyof typeof POPUP_BOX_TYPE];

export type PopupText = {
    setPopupText: (value: string) => void;
};

export const PopupContext = createContext<PopupText>({
    setPopupText: () => undefined
});

/**
 * Forward the popup context.
 */
export function usePopupContext(): PopupText {
    return useContext(PopupContext);
}

/**
 * Render the Pop up Message box
 * @param props
 * @param props.popupType
 * @param props.popupText
 * @param props.setPopupText
 * @returns {ReactElement}
 */
export default function PopupBox(props: {
    popupType: PopupType;
    popupText: string;
    setPopupText: (value: string) => void;
}): ReactElement {
    /**
     * Triggered when a the close icon of the popup
     * is clicked or when a click outside of the popup is detected.
     */
    function closePopup(): void {
        props.setPopupText('');
    }

    return (
        <Dialog
            open={props.popupText !== ''}
            className={`popup-box-container ${props.popupType}Popup`}
            onClose={closePopup}
        >
            <IconButton className="popup-close-button" onClick={closePopup}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {props.popupText !== '' && <DialogContentText>{props.popupText}</DialogContentText>}
            </DialogContent>
        </Dialog>
    );
}
