import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import Chip from '@mui/material/Chip/Chip.js';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';

import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {availableLanguages} from '@/i18n.js';

import './QuestionnaireDisplay.css';

const QuestionnaireIssueType = {
    language: 'language',
    missing: 'missing'
} as const;

export default function QuestionnaireIssueResolver(props: {
    questionnaire: Questionnaire;
    questionnaireModel: QuestionnaireModel;
    children: ReactElement | ReactElement[];
    questionnaireLanguage?: string;
}): ReactElement {
    const i18n = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryLanguage = queryParams.get('language');
    const questionnaireLanguage =
        queryLanguage === null ? props.questionnaire.language : queryLanguage;
    const [availableLanguagesOptions, setAvailableLanguagesOptions] = useState<string[]>([]);
    const [checkedForResolvableIssues, setCheckedForResolvableIssues] = useState<boolean>(false);
    const [questionnaireIssue, setQuestionnaireIssue] = useState<
        keyof typeof QuestionnaireIssueType | undefined
    >(undefined);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    /**
     * Check for language support on requested questionnaire
     */
    useEffect(() => {
        async function checkForResolvableErrors() {
            if (!props.questionnaire.name) {
                return;
            }
            try {
                await props.questionnaireModel.questionnaireByUrl(
                    await props.questionnaireModel.questionnaireUrlByName(
                        props.questionnaire.name,
                        props.questionnaireLanguage
                            ? props.questionnaireLanguage
                            : questionnaireLanguage
                    )
                );
            } catch (e) {
                if (e.message.includes('does not exist')) {
                    const questionnaires = await props.questionnaireModel.questionnaires();
                    const requiredQuestionnaire = questionnaires.filter(
                        questionnaire => questionnaire.name === props.questionnaire.name
                    );
                    if (props.questionnaire.name) {
                        setAvailableLanguagesOptions(
                            requiredQuestionnaire.reduce((languages: string[], questionnaire) => {
                                if (
                                    questionnaire.language &&
                                    availableLanguages.includes(questionnaire.language)
                                ) {
                                    languages.push(questionnaire.language);
                                }
                                return languages;
                            }, [])
                        );
                        setQuestionnaireIssue(QuestionnaireIssueType.language);
                        setNotificationType(NOTIFICATION.Error);
                        setNotificationMessage(i18n.t('errors.questionnaire.languageUnavailable'));
                    } else {
                        setQuestionnaireIssue(QuestionnaireIssueType.missing);
                        setNotificationType(NOTIFICATION.Error);
                        setNotificationMessage(i18n.t('errors.questionnaire.missing'));
                    }
                    return;
                }
                throw e;
            }
        }
        if (questionnaireLanguage) {
            checkForResolvableErrors()
                .catch(console.error)
                .finally(() => {
                    setCheckedForResolvableIssues(true);
                });
        }
    }, [questionnaireLanguage, location]);

    /**
     * Rerender component with new questionnaire language
     * @param language
     */
    function switchQuestionnaireLanguage(language: string) {
        const paramQuestionnaire = queryParams.get('questionnaires');
        setAvailableLanguagesOptions([]);
        setQuestionnaireIssue(undefined);
        navigate(
            {
                search: `?language=${language}${
                    paramQuestionnaire ? `&questionnaires=${paramQuestionnaire}` : ''
                }`
            },
            {replace: true}
        );
    }

    if (!checkedForResolvableIssues) {
        return (
            <div className="circular-progress-container error-checker">
                <CircularProgress className="circular-progress" size={35} />
            </div>
        );
    } else if (
        questionnaireIssue &&
        questionnaireIssue === QuestionnaireIssueType.language &&
        availableLanguagesOptions
    ) {
        return (
            <div className="languages">
                <div>{i18n.t('questionnaires.languagesAvaliable')}</div>
                <div className="chips">
                    {availableLanguagesOptions.map(
                        (lang: string) =>
                            availableLanguages.includes(lang) && (
                                <Chip
                                    key={lang}
                                    className="chip"
                                    clickable={true}
                                    label={i18n.t(`common.languages.${lang}`)}
                                    onClick={() => switchQuestionnaireLanguage(lang)}
                                />
                            )
                    )}
                </div>
            </div>
        );
    }

    return <>{props.children}</>;
}
