import type {ReactElement} from 'react';
import {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {useNavigateBack} from '@/hooks/navigation';

import WbcDiffModel from '@refinio/one.models/lib/models/WbcDiffModel.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {WbcObservation} from '@refinio/one.models/lib/recipes/WbcDiffRecipes.js';

import CreateWBCDiff from './CreateWBCDiff.js';

export default function CreateWBCDiffOwnerAdapter(props: {
    wbcDiffModel: WbcDiffModel;
    channelManager: ChannelManager;
}): ReactElement {
    const params = useParams<{owner: SHA256IdHash<Person>}>();
    const navigateBack = useNavigateBack();
    const post = useCallback(
        async (wbcObservation: WbcObservation) => {
            if (
                params.owner &&
                !(await props.channelManager.hasChannel(WbcDiffModel.channelId, params.owner))
            ) {
                await props.channelManager.createChannel(WbcDiffModel.channelId, params.owner);
            }
            await props.wbcDiffModel.postObservation(wbcObservation, params.owner);
            navigateBack();
        },
        [props.wbcDiffModel]
    );

    return <CreateWBCDiff wbcDiffModel={props.wbcDiffModel} overwritePost={post} />;
}
