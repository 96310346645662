import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';

import * as dateFns from 'date-fns';

import {readBlobAsArrayBuffer} from '@refinio/one.core/lib/storage-blob.js';
import type {
    PersonImage,
    PersonStatus
} from '@refinio/one.models/lib/recipes/Leute/PersonDescriptions.js';

import {getURL} from '@/utils/Utils.js';
import ContactCard from '@/components/card/ContactCard.js';

import './ProfileStatus.css';

/**
 * Represents the status section which is displayed within the profile view.
 * It can contain Location, Text, Location and Text, Image or Location and image.
 * @param props
 */
export default function ProfileStatus(props: {
    statusData: PersonStatus | PersonImage;
}): ReactElement {
    const [statusImage, setStatusImage] = useState<ArrayBuffer | undefined>();

    // if we have an image then we need to load it asynchronously
    useEffect(() => {
        if (props.statusData.$type$ === 'PersonImage') {
            readBlobAsArrayBuffer(props.statusData.image).then(image => setStatusImage(image));
        }
    }, [props.statusData]);

    /**
     * Render the text section of the status if exists.
     */
    function statusText(): ReactElement {
        return (
            <>
                {props.statusData.$type$ === 'PersonStatus' && props.statusData.value !== '' && (
                    <div className="status-value">{props.statusData.value}</div>
                )}
            </>
        );
    }

    /**
     * Render the image section of the status if exists.
     */
    function statusImageView(): ReactElement {
        return (
            <>
                {props.statusData.$type$ === 'PersonImage' && statusImage !== undefined && (
                    <img className="image-status" src={getURL(statusImage)} alt="status-preview" />
                )}
            </>
        );
    }

    /**
     * Render the location section of the status if exists.
     */
    function statusLocation(): ReactElement {
        return (
            <>
                {props.statusData.location !== '' && (
                    <div className="status-value">{props.statusData.location}</div>
                )}
            </>
        );
    }

    return (
        <ContactCard
            content={
                <div className="status">
                    <div className="status-date">
                        {dateFns.format(props.statusData.timestamp, 'HH:mm dd.MM.yyyy')}
                    </div>
                    {statusLocation()}
                    {statusText()}
                    {statusImageView()}
                </div>
            }
        />
    );
}
