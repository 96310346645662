import {useState, type ReactElement, useCallback, useEffect} from 'react';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';

import {getDefaultCertificateSettings} from '@/components/trust/common/utils.js';
import CertificatePopup from './CertificatePopup.js';
import SignedIndicator from './SignedIndicator.js';
import {useTrustObjects} from './common/hooks.js';
import type {CertificateSetting, SettingIconType, TrustObject} from './common/types.js';

import './TrustView.css';

/**
 *
 * @param props.hashes trust viewer/creator work is based on those
 * @param props.leuteModel
 * @param props.onError Optional. Default console.error
 * @param props.noCertificateIcon Optional. Default false (show). Flag to turn off the no-indicator icon.
 * @param props.getOverwriteCertificateSettings Optional. Default @see getDefaultCertificateSettings Can be a CertificateSetting array or (async) callback that returns that
 * @param props.getAdditionalIndicators Optional. Default none. Can be a string/react element array or (async) callback that returns that
 * @returns
 */
export default function TrustView(props: {
    hashes: (SHA256Hash | SHA256IdHash)[] | SHA256Hash | SHA256IdHash | undefined;
    leuteModel: LeuteModel;
    noCertificateIcon?: boolean;
    onError?: (error: Error) => void;
    getOverwriteCertificateSettings?:
        | (() => Promise<CertificateSetting[]> | CertificateSetting[])
        | CertificateSetting[];
    getAdditionalIndicators?:
        | ((
              trustObjects: TrustObject[],
              settings: CertificateSetting[]
          ) => SettingIconType[] | Promise<SettingIconType[]>)
        | SettingIconType[];
}): ReactElement {
    const [popupOpened, setPopupOpened] = useState<boolean>(false);
    const closePopup = useCallback(() => setPopupOpened(false), [setPopupOpened]);
    const openPopup = useCallback(() => setPopupOpened(true), [setPopupOpened]);
    const trustObjects = useTrustObjects(
        props.hashes ? (typeof props.hashes === 'object' ? props.hashes : [props.hashes]) : []
    );

    // certificate settings overwrite
    const [certificateSettings, setCertificateSettings] = useState<CertificateSetting[]>([]);
    useEffect(() => {
        async function getOverwriteCertificateSettings() {
            if (props.getOverwriteCertificateSettings) {
                if (typeof props.getOverwriteCertificateSettings === 'function') {
                    setCertificateSettings(await props.getOverwriteCertificateSettings());
                } else {
                    setCertificateSettings(props.getOverwriteCertificateSettings);
                }
            } else {
                setCertificateSettings(getDefaultCertificateSettings(props.leuteModel.trust));
            }
        }
        getOverwriteCertificateSettings().catch(console.error);
    }, [props.getOverwriteCertificateSettings]);

    // additinal indicators
    const [additinalIndicators, setAdditinalIndicators] = useState<SettingIconType[]>([]);
    useEffect(() => {
        async function getAdditinalIndicators() {
            if (props.getAdditionalIndicators) {
                if (typeof props.getAdditionalIndicators === 'function') {
                    setAdditinalIndicators(
                        await props.getAdditionalIndicators(trustObjects, certificateSettings)
                    );
                } else {
                    setAdditinalIndicators(props.getAdditionalIndicators);
                }
            }
        }
        getAdditinalIndicators().catch(console.error);
    }, [props.getAdditionalIndicators, trustObjects, certificateSettings]);

    return (
        <div className="trust-container">
            <>
                <SignedIndicator
                    trustObjects={trustObjects}
                    trustedKeysManager={props.leuteModel.trust}
                    onClick={openPopup}
                    noCertificateIcon={
                        props.noCertificateIcon === undefined || props.noCertificateIcon
                    }
                    certificateSettings={certificateSettings}
                    additinalIndicators={additinalIndicators}
                />
                {popupOpened && (
                    <CertificatePopup
                        leuteModel={props.leuteModel}
                        hashes={props.hashes}
                        onClose={closePopup}
                        overwriteCertificateSettings={certificateSettings}
                    />
                )}
            </>
        </div>
    );
}
