import type {ReactElement} from 'react';
import {useEffect, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import Badge from '@mui/material/Badge/Badge.js';

import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type Notifications from '@refinio/one.models/lib/models/Notifications.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {usePersonId} from '@/hooks/contact/commonHooks.js';
import {useTopicId} from '@/hooks/chat/topicHooks.js';
import {CHAT} from '@/components/Constants.js';

import './StartChat.css';

/**
 * When notifications is missing, does not load unread messages indicator
 * @param props.leuteModel
 * @param props.topicModel
 * @param props.receiverProfile
 * @param props.notifications
 * @returns
 */
export default function StartChat(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    receiverProfile: ProfileModel;
    notifications?: Notifications;
}): ReactElement {
    const navigate = useNavigate();
    const {contextValue, setContextValue} = useContext(AppBarContext);
    const [unreadMessages, setUnreadMessages] = useState<number>(0);
    const myPersonId = usePersonId(props.leuteModel);
    const topicId = useTopicId(props.topicModel, myPersonId, props.receiverProfile.personId);

    useEffect(() => {
        // wait for dependencies
        if (
            props.notifications === undefined ||
            myPersonId === undefined ||
            topicId === undefined
        ) {
            return;
        }
        function updateUnreadMessages(): void {
            if (props.notifications === undefined || topicId === undefined) {
                return;
            }
            setUnreadMessages(props.notifications.getNotificationCountForTopic(topicId));
        }
        updateUnreadMessages();
        return props.topicModel.onUpdated.listen(updateUnreadMessages);
    }, [props.notifications, myPersonId, topicId, props.topicModel.onUpdated]);

    function startChat(): void {
        if (myPersonId === undefined) {
            return;
        }
        setContextValue({
            ...contextValue,
            isLeftBtnClicked: false,
            isRightBtnClicked: false
        });
        navigate(`/chat/${topicId}`);
    }

    return (
        <div className="signed-container">
            <Badge
                badgeContent={unreadMessages}
                variant={'dot'}
                color="secondary"
                className="unread-message-indicator"
            >
                <div className="start-chat-icon" onClick={startChat}>
                    {CHAT}
                </div>
            </Badge>
        </div>
    );
}
