import type {ReactElement} from 'react';
import {useState, useEffect, Fragment} from 'react';

import Divider from '@mui/material/Divider/Divider.js';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';

import {getURL, useBackgroundColor} from '@/utils/Utils.js';
import {DARK_ORANGE} from '@/components/Constants.js';
import i18n from '@/i18n.js';
import {getPersonEmail, loadProfilePersonDescription} from '@/hooks/contact/utils.js';
import SelectableGroupMemberListEntry from './SelectableGroupMemberListEntry.js';

import '@/root/group/groupId/Group.css';
import {Checkbox, FormControlLabel} from '@mui/material';
import ListElement from '@refinio/one.ui/lib/ui/components/list/ListElement.js';

export type GroupPickMember = {
    name: string;
    imgURL: string | undefined;
    idHash: SHA256IdHash<Person>;
    profileModel: ProfileModel;
    email: string;
};

/**
 * Used to display the group profiles as a list of checkboxes.
 * For now, it is used while creating a group and while editing a group members(persons/profiles).
 * @param props
 */
export default function GroupMembersPicker(props: {
    profiles: ProfileModel[];
    leuteModel: LeuteModel;
    updateGroupMembers: (groupMembers: SHA256IdHash<Person>[]) => void;
    groupMembers: readonly SHA256IdHash<Person>[];
}): ReactElement {
    useBackgroundColor(DARK_ORANGE);

    const [profiles, setProfiles] = useState<GroupPickMember[]>([]);

    /**
     * Load the Group Members profiles
     */
    useEffect(() => {
        let cancel = false;

        /**
         * Fetch the group members profiles
         */
        async function loadProfiles(): Promise<void> {
            if (cancel) {
                return;
            }
            const newProfiles = await Promise.all(
                props.profiles.map(async profile => {
                    const profileDetails = await loadProfilePersonDescription(profile);
                    const name = profileDetails.personId
                        ? props.leuteModel.getPersonName(profileDetails.personId)
                        : 'N/A';
                    return {
                        name,
                        imgURL:
                            profileDetails.avatar !== undefined
                                ? getURL(profileDetails.avatar)
                                : undefined,
                        idHash: profile.personId,
                        profileModel: profile,
                        email: profileDetails.personId
                            ? await getPersonEmail(profileDetails.personId)
                            : ''
                    };
                })
            );
            if (!cancel) {
                setProfiles(newProfiles);
            }
        }

        loadProfiles().catch(console.error);

        return () => {
            cancel = true;
        };
    }, [props.profiles]);

    return (
        <>
            <div className="cnt-title">{i18n.t('leute.lists.groupMembers')}</div>
            {profiles.length > 0 && (
                <ListElement
                    className="group-members-all"
                    extra={
                        <>
                            <FormControlLabel
                                className="group-member all"
                                control={
                                    <Checkbox
                                        color="default"
                                        checked={props.groupMembers.length === profiles.length}
                                    />
                                }
                                label={<>{i18n.t('leute.lists.selectAll')}</>}
                                labelPlacement="start"
                                onChange={(_, checked) => {
                                    if (checked) {
                                        props.updateGroupMembers(
                                            props.profiles.map(p => p.personId)
                                        );
                                    } else {
                                        props.updateGroupMembers([]);
                                    }
                                }}
                                color="primary"
                            />
                        </>
                    }
                />
            )}
            {profiles.map((profile, index) => (
                <Fragment key={profile.profileModel.idHash}>
                    <SelectableGroupMemberListEntry
                        groupMember={profile}
                        leuteModel={props.leuteModel}
                        onChange={(_, checked) => {
                            let newSetOfPersons = [...props.groupMembers];
                            const extractedPersonId = profile.idHash;

                            if (checked) {
                                newSetOfPersons.push(extractedPersonId);
                            } else {
                                newSetOfPersons = newSetOfPersons.filter(
                                    personId => personId !== extractedPersonId
                                );
                            }

                            props.updateGroupMembers(newSetOfPersons);
                        }}
                        isChecked={props.groupMembers.includes(profile.idHash)}
                    />
                    {index !== profiles.length - 1 && <Divider variant={'middle'} />}
                </Fragment>
            ))}
        </>
    );
}
