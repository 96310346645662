import type {ReactElement} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BrowserRouter, Navigate, Outlet, Route, Routes} from 'react-router-dom';

import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import CreateInvitation from '@refinio/one.ui/lib/ui/views/invitation/CreateInvitation.js';

import PageNotFound from '@/components/PageNotFound.js';
import PageTemplate from '@/components/PageTemplate.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import QrScanner from '@/components/qrScanner/QrScanner.js';
import TrustView from '@/components/trust/TrustView.js';
import {useAuthenticationState} from '@/hooks/authenticator/hooks.js';
import {useIndexedDBNotificationOrError} from '@/hooks/indexedDB/errorHandling.js';
import type Model from '@/model/Model.js';
import BodyTemperatureRouter from '@/root/bodyTemp/BodyTemperatureRouter.js';
import DiaryRouter from '@/root/diary/DiaryRouter.js';
import WBCRouter from '@/root/wbc/WBCRouter.js';
import {RootRoute} from './RootRoute.js';
import EventCalendar from './calendar/EventCalendar.js';
import ChatRouter from './chat/ChatRouter.js';
import ChatMessageDetailsRouter from './chat/ChatMessageDetailsRouter.js';
import {getAdditionalAttachmentViews} from './chat/attachmentViews/attachmentViewRenderers.js';
import ConnectionsView from './connections/ConnectionsView.js';
import LeuteView from './contacts/LeuteView.js';
import DebugRouter from './debug/DebugRouter.js';
import DocumentRouter from './document/DocumentRouter.js';
import Downloads from './downloads/Downloads.js';
import Ecpire from './ecpire/Ecpire.js';
import GroupRouter from './group/GroupRouter.js';
import Home from './home/Home.js';
import IdentityRouter from './identity/IdentityRouter.js';
import IoMRouter from './iom/IoMRouter.js';
import JournalRouter from './journal/JournalRouter.js';
import PatientsRouter from './malawi_demo/patients/PatientsRouter.js';
import ProfileRouter from './profile/ProfileRouter.js';
import {QuestionnaireRouter} from './questionnaires/QuestionnaireRouter.js';
import SettingsRouter from './settings/SettingsRouter.js';
import SignKeysRouter from './sign_keys/SignKeysRouter.js';
import startGroupChat from './contacts/StartGroupChat.js';
import SomeoneRouter from './someone/SomeoneRouter.js';

import '../Primary.css';
import '../Ui.css';

/**
 * Leute specific Router
 * @param props
 * @constructor
 */
export function AppRouter(props: {model: Model; app: string}): ReactElement {
    useIndexedDBNotificationOrError();
    const i18n = useTranslation();
    const {
        one,
        journalModel,
        leuteModel,
        connections,
        topicModel,
        channelManager,
        iom,
        questionnaireModel,
        notifications,
        documentModel,
        blacklistModel,
        wbcDiffModel,
        diaryModel,
        bodyTemperatureModel
    } = props.model;
    const authenticationState = useAuthenticationState(one);
    const [onboardingProcess, setOnboardingProcess] = useState<boolean>(
        authenticationState === 'logged_in'
    );

    return (
        <BrowserRouter>
            <Routes>
                {!onboardingProcess ? (
                    <Route
                        path="*"
                        element={
                            <PageTemplate>
                                <RootRoute
                                    app={props.app}
                                    leuteModel={leuteModel}
                                    one={one}
                                    onDone={() => setOnboardingProcess(true)}
                                />
                            </PageTemplate>
                        }
                    />
                ) : (
                    <>
                        <Route
                            element={
                                <PageTemplate>
                                    <Outlet />
                                </PageTemplate>
                            }
                        >
                            <Route index element={<Navigate to="/home" />} />
                            <Route
                                path="home"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.home')}
                                        />
                                        <Home
                                            leuteModel={leuteModel}
                                            documentModel={documentModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="ecpire"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('ecpire.title')}
                                        />
                                        <Ecpire
                                            questionnaireModel={questionnaireModel}
                                            leuteModel={leuteModel}
                                            topicModel={topicModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="calendar"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('calendar.title')}
                                        />
                                        <EventCalendar
                                            journalModel={journalModel}
                                            questionnaireModel={questionnaireModel}
                                            leuteModel={leuteModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="settings/*"
                                element={
                                    <>
                                        <AppBarSettings
                                            title={i18n.t('menu.settings')}
                                            mode={APP_BAR_MODE.BROWSE}
                                        />
                                        <SettingsRouter
                                            leuteModel={leuteModel}
                                            one={one}
                                            connections={connections}
                                            channelManager={channelManager}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="chat/*"
                                element={
                                    <ChatRouter
                                        topicModel={topicModel}
                                        leuteModel={leuteModel}
                                        channelManager={channelManager}
                                        notifications={notifications}
                                        attachmentViewAdditions={getAdditionalAttachmentViews(
                                            questionnaireModel,
                                            bodyTemperatureModel,
                                            wbcDiffModel,
                                            diaryModel
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="message/*"
                                element={
                                    <ChatMessageDetailsRouter
                                        leuteModel={leuteModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route
                                path="profile/*"
                                element={
                                    <ProfileRouter
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        getTrustView={hashes => (
                                            <TrustView leuteModel={leuteModel} hashes={hashes} />
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="someone/*"
                                element={
                                    <SomeoneRouter
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        getTrustView={hashes => (
                                            <TrustView leuteModel={leuteModel} hashes={hashes} />
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="group/*"
                                element={
                                    <GroupRouter
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        blacklistModel={blacklistModel}
                                    />
                                }
                            />
                            <Route
                                path="iom/*"
                                element={
                                    <IoMRouter
                                        leuteModel={leuteModel}
                                        iomRequestManager={iom.requestManager}
                                    />
                                }
                            />
                            <Route
                                path="debug/*"
                                element={
                                    <DebugRouter
                                        leuteModel={leuteModel}
                                        connectionsModel={connections}
                                        channelManager={channelManager}
                                        iomManager={iom}
                                    />
                                }
                            />
                            <Route
                                path="contacts"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.VIEW}
                                            title={i18n.t('menu.contacts')}
                                        />
                                        <LeuteView
                                            topicModel={topicModel}
                                            leuteModel={leuteModel}
                                            notifications={notifications}
                                            blacklistModel={blacklistModel}
                                            getTrustView={hashes => (
                                                <TrustView
                                                    leuteModel={leuteModel}
                                                    hashes={hashes}
                                                />
                                            )}
                                            startGroupChat={startGroupChat}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="connections"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.connections')}
                                        />
                                        <ConnectionsView
                                            connectionsModel={connections}
                                            leuteModel={leuteModel}
                                            iomManager={iom}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="invitePartner"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.CHEVRON}
                                            title={i18n.t('invitations.person')}
                                            navigateBackOnClose={'/connections'}
                                        />
                                        <CreateInvitation
                                            connectionsModel={connections}
                                            iomRequestManager={iom.requestManager}
                                            inviteAcceptedRoute="/connections"
                                            mode="iop"
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="inviteDevice"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.CHEVRON}
                                            title={i18n.t('invitations.device')}
                                            navigateBackOnClose={'/connections'}
                                        />
                                        <CreateInvitation
                                            connectionsModel={connections}
                                            iomRequestManager={iom.requestManager}
                                            inviteAcceptedRoute="/connections"
                                            mode="iom"
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="invites/*"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.connections')}
                                        />
                                        <ConnectionsView
                                            connectionsModel={connections}
                                            leuteModel={leuteModel}
                                            iomManager={iom}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="qr-scanner"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.CHEVRON}
                                            title={i18n.t('invitations.qrScanner.title')}
                                            navigateBackOnClose={'/connections'}
                                        />
                                        <QrScanner
                                            connectionsModel={connections}
                                            iomManager={iom}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="identity/*"
                                element={
                                    <IdentityRouter
                                        connectionsModel={connections}
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        getTrustView={hashes => (
                                            <TrustView leuteModel={leuteModel} hashes={hashes} />
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="journal/*"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.journal')}
                                        />
                                        <JournalRouter
                                            journalModel={journalModel}
                                            questionnaireModel={questionnaireModel}
                                            leuteModel={leuteModel}
                                            topicModel={topicModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="questionnaire/*"
                                element={
                                    <QuestionnaireRouter questionnaireModel={questionnaireModel} />
                                }
                            />
                            <Route
                                path="patients/*"
                                element={
                                    <PatientsRouter
                                        questionnaireModel={questionnaireModel}
                                        leuteModel={leuteModel}
                                        channelManager={channelManager}
                                        topicModel={topicModel}
                                        notifications={notifications}
                                        journalModel={journalModel}
                                        documentModel={documentModel}
                                        getTrustView={hashes => (
                                            <TrustView leuteModel={leuteModel} hashes={hashes} />
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="document/*"
                                element={<DocumentRouter documentModel={documentModel} />}
                            />
                            <Route
                                path="wbc/*"
                                element={
                                    <WBCRouter
                                        wbcDiffModel={wbcDiffModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route
                                path="diary/*"
                                element={
                                    <DiaryRouter
                                        diaryModel={diaryModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route
                                path="bodyTemperature/*"
                                element={
                                    <BodyTemperatureRouter
                                        bodyTemperatureModel={bodyTemperatureModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route path="signkeys/*" element={<SignKeysRouter />} />
                            <Route
                                path="Downloads"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.downloads')}
                                        />
                                        <Downloads />
                                    </>
                                }
                            />
                            <Route path="*" element={<PageNotFound />} />
                        </Route>
                    </>
                )}
            </Routes>
        </BrowserRouter>
    );
}
