import {useCallback, useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, List, ListItem} from '@mui/material';
import {Slider} from '@mui/material';

import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import {useForceRender} from '@refinio/one.ui/lib/ui/views/utils/Utils.js';
import type {
    QuestionnaireMaxValueExtension,
    QuestionnaireMinValueExtension
} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

import HealthInfoPage from './HealthInfoPage.js';
import FinalizePage from './FinalizePage.js';
import InformativePage from './InformativePage.js';
import type {QuestionnaireResponseBuilderItem} from './QuestionnaireResponseBuilder.js';
import {changeBackgroundColorToWhite, removeWhiteBackgroundColor, useWindowSize} from './utils.js';
import {
    useEQ5D3LQuestionnaireAndResponse,
    useQuestionnairesBuilder
} from './QuestionnaireModelHelper.js';
import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import PopupBox, {POPUP_BOX_TYPE} from '../popupBox/PopupBox.js';
import './Questionnaire.css';
import {useForceHidenAppBar} from '@/hooks/appBar/common.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';

const PAGES = {
    Informative: 0,
    Question_1: 1,
    Question_2: 2,
    Question_3: 3,
    Question_4: 4,
    Question_5: 5,
    Health_Info: 6,
    Slider: 7,
    Finalize: 8
} as const;

/**
 * This component build the EQ-5D-3L.
 * @param props - Properties of this view.
 * @param props.questionnaireModel
 * @returns - EQ-5D-3L questionnaire.
 */
export default function EQ5D3LQuestionnaire(props: {
    questionnaireModel: QuestionnaireModel;
}): ReactElement {
    const i18n = useTranslation();
    /** single questionnaire accepted by the component **/
    const questionnaireType = 'EQ5D3L';
    useForceHidenAppBar({hide: false});
    const goBack = useNavigateBack();

    /** Component specific **/
    const [index, setIndex] = useState(0);
    const {action, owner} = useParams<{action: string; owner?: SHA256IdHash<Person>}>();
    const viewMode = action !== 'new';

    /** Error handling **/
    const [questionNotAnswered, setQuestionNotAnswered] = useState(false);
    const [answerQuestionMessage, setAnswerQuestionMessage] = useState('');

    /** Hooks && variables for EQ5D3L questionnaire **/
    const [width] = useWindowSize();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramResponse = queryParams.get('response');
    const paramLanguage = queryParams.get('language');
    const forceRender = useForceRender();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const onError = useCallback(
        (error: string) => {
            setNotificationMessage(i18n.t(error));
            setNotificationType(NOTIFICATION.Error);
        },
        [setNotificationMessage, setNotificationType]
    );
    const [questionnaireMap, responsesMap] = useEQ5D3LQuestionnaireAndResponse(
        props.questionnaireModel,
        onError,
        questionnaireType,
        paramLanguage ? paramLanguage : undefined,
        paramResponse ? paramResponse : undefined
    );
    const questionnaire = questionnaireMap.get(questionnaireType);
    const builderMap = useQuestionnairesBuilder(responsesMap, forceRender, questionnaire);
    const builder = builderMap.get(questionnaireType);

    // ---------------------------------------------------------------------------------
    // -------------------------------- Use Effects ------------------------------------
    // ---------------------------------------------------------------------------------

    /**
     * Set the index to 0 if the viewMode change (going from completed to new)
     */
    useEffect(() => {
        setIndex(0);
    }, [viewMode]);

    /**
     * Used to set the background color of the entire page to white and to display/hide the footer.
     */
    useEffect(() => {
        changeBackgroundColorToWhite();

        return () => {
            removeWhiteBackgroundColor();
        };
    }, []);

    // ---------------------------------------------------------------------------------
    // -------------------------------- Utilities --------------------------------------
    // ---------------------------------------------------------------------------------

    /**
     * Returns the marks for the VAS Thermometer.
     * @param {boolean} isForMobile - flag to determine if the returned marks are for mobile or desktop view.
     * @param {number} start - first possible answer.
     * @param {number} end - last possible answer.
     */
    function createMarks(
        isForMobile: boolean,
        start: number,
        end: number
    ): {value: number; label?: number}[] {
        const marks = [];
        const labelStep = isForMobile ? 50 : 25;

        for (let i = start; i <= end; i += 1) {
            if (i % labelStep === 0) {
                marks.push({value: i, label: i});
            } else {
                marks.push({value: i});
            }
        }

        return marks;
    }

    // ---------------------------------------------------------------------------------
    // -------------------------------- Button handlers --------------------------------
    // ---------------------------------------------------------------------------------

    /**
     * Used to store the EQ5D3L questionnaire.
     */
    async function saveQuestionnaire(): Promise<void> {
        if (!builder) {
            return;
        }

        /** Check if questionnaire is valid then store it **/
        if (builder.validate()) {
            builder.setStatus('completed');

            const res = builder.buildResponse();
            await props.questionnaireModel.postResponseCollection(
                [res],
                undefined,
                questionnaireType,
                owner
            );

            navigate('/journal');
        } else {
            setNotificationMessage(i18n.t('eq5.errors.answerAllQuestions'));
            setNotificationType(NOTIFICATION.Error);
        }
    }

    // ---------------------------------------------------------------------------------
    // -------------------------------- Rendering --------------------------------------
    // ---------------------------------------------------------------------------------

    /**
     * Renders the current question component(Slider/Question).
     * @param currentIndex - the index of the question that will be built.
     */
    function renderCurrentQuestion(currentIndex: number): ReactElement {
        if (questionnaire) {
            if (builder !== undefined) {
                const questionnaireResponseBuilderItems = Array.from(
                    builder.questionIterator({hideDisabledValues: true})
                );

                return (
                    <>{buildCurrentQuestionView(questionnaireResponseBuilderItems[currentIndex])}</>
                );
            }
        }

        return <div />;
    }

    /**
     * Creates the question view for 'choice' and 'integer' questions. (e.g Question/Slider)
     * @param questionResponseBuilderItem
     */
    function buildCurrentQuestionView(
        questionResponseBuilderItem: QuestionnaireResponseBuilderItem
    ): ReactElement | null {
        const answers = questionResponseBuilderItem.question.answerOption
            ? questionResponseBuilderItem.question.answerOption
            : [];

        /** need it for disabling the next button until the user answer the question. **/
        if (
            index > 0 &&
            index < 8 &&
            questionNotAnswered !== questionResponseBuilderItem.validationFailed
        ) {
            setQuestionNotAnswered(questionResponseBuilderItem.validationFailed);
        }

        // Only implemented for 'choice' or 'integer'
        switch (questionResponseBuilderItem.question.type) {
            case 'choice': {
                return (
                    <>
                        <div
                            className="question-header"
                            key={questionResponseBuilderItem.question.linkId}
                        >
                            {questionResponseBuilderItem.question.text}
                            {questionResponseBuilderItem.question.linkId === 'usualactivity' && (
                                <i className="remove-bold">{i18n.t('eq5.additionalInfo')}</i>
                            )}
                        </div>
                        <List className="answer-list">
                            {answers.map((answer, idx) => {
                                const displayedAnswer = answer.valueCoding
                                    ? answer.valueCoding.display
                                    : '';

                                const value =
                                    questionResponseBuilderItem.value[0] &&
                                    questionResponseBuilderItem.value[0].valueCoding
                                        ? questionResponseBuilderItem.value[0].valueCoding.display
                                        : '';

                                return (
                                    <ListItem
                                        key={
                                            questionResponseBuilderItem.question.linkId +
                                            idx.toString()
                                        }
                                    >
                                        <Button
                                            variant="contained"
                                            value={value}
                                            key={idx}
                                            className={`answer-button ${
                                                value === displayedAnswer
                                                    ? 'selected'
                                                    : 'unanswered-button'
                                            }`}
                                            onClick={() => {
                                                questionResponseBuilderItem.setAnswer([answer]);
                                            }}
                                        >
                                            {displayedAnswer}
                                        </Button>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </>
                );
            }
            case 'integer': {
                const value =
                    questionResponseBuilderItem.value[0] &&
                    questionResponseBuilderItem.value[0].valueInteger
                        ? Number(questionResponseBuilderItem.value[0].valueInteger)
                        : -1;

                // default values
                let minValue = 0;
                let maxValue = 100;

                if (questionResponseBuilderItem.question.extension !== undefined) {
                    const minValueExtensions =
                        questionResponseBuilderItem.question.extension.filter(
                            e => e.url === 'http://hl7.org/fhir/StructureDefinition/minValue'
                        ) as QuestionnaireMinValueExtension[];
                    const maxValueExtensions =
                        questionResponseBuilderItem.question.extension.filter(
                            e => e.url === 'http://hl7.org/fhir/StructureDefinition/maxValue'
                        ) as QuestionnaireMaxValueExtension[];
                    if (minValueExtensions.length > 0 && maxValueExtensions.length > 0) {
                        maxValue = Number(maxValueExtensions[0].valueInteger);
                        minValue = Number(minValueExtensions[0].valueInteger);
                    }
                }

                return (
                    <>
                        <div className="health-info">{i18n.t('eq5.indication')}</div>
                        <div
                            className="thermometer-view"
                            key={questionResponseBuilderItem.question.linkId}
                        >
                            <div className="textContainer">
                                <div className="text-container-align-middle">
                                    <p className="par health-meter">
                                        {questionResponseBuilderItem.question.text}
                                        {'= '}
                                        {value === -1 ? null : value}
                                    </p>
                                </div>
                            </div>
                            <div className="slider-container">
                                <div className="thermometer-info margin-bottom">
                                    {i18n.t('eq5.healthStatusG')}{' '}
                                </div>
                                <Slider
                                    className={`${value === -1 ? 'hide-thumb' : ''}`}
                                    track={false}
                                    aria-labelledby="track-false-slider"
                                    valueLabelDisplay="on"
                                    orientation="vertical"
                                    value={value}
                                    valueLabelFormat={() => (value === -1 ? '' : value.toString())}
                                    marks={
                                        width && width <= 768
                                            ? createMarks(true, minValue, maxValue)
                                            : createMarks(false, minValue, maxValue)
                                    }
                                    onChange={(_, newValue: number | number[]) => {
                                        questionResponseBuilderItem.setAnswer([
                                            {
                                                valueInteger: (newValue as number).toString()
                                            }
                                        ]);
                                    }}
                                />
                                <div className="thermometer-info top-margin">
                                    {i18n.t('eq5.healthStatusW')}{' '}
                                </div>
                            </div>
                        </div>
                    </>
                );
            }
            default:
                return null;
        }
    }

    /**
     * Renders the navigation buttons
     */
    function renderNavigationButtons(): ReactElement {
        return (
            <div className="navButtons">
                {index === PAGES.Finalize ? null : (
                    <div className="copyrights-footer">{i18n.t('eq5.footer.copyrights')}</div>
                )}
                <div className="control-buttons-container">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (index === PAGES.Informative) {
                                goBack();
                            } else {
                                setIndex(prevIndex => prevIndex - 1);
                                setQuestionNotAnswered(false);
                            }
                        }}
                        className="control-button"
                    >
                        <div className={'arrow-back'} />
                        {i18n.t('eq5.buttons.previous')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            if (index === PAGES.Finalize) {
                                if (!viewMode) {
                                    await saveQuestionnaire();
                                }
                            } else if (questionNotAnswered) {
                                setAnswerQuestionMessage(i18n.t('eq5.answerNotProvided'));
                            } else {
                                setIndex(prevIndex => prevIndex + 1);
                            }
                        }}
                        className="control-button next"
                    >
                        {index === PAGES.Finalize
                            ? viewMode
                                ? i18n.t('eq5.buttons.backToJournal')
                                : i18n.t('eq5.buttons.finalize')
                            : i18n.t('eq5.buttons.next')}
                        <div
                            className={
                                index === PAGES.Finalize && !viewMode ? 'tick-mark' : 'arrow-next'
                            }
                        />
                    </Button>
                </div>
            </div>
        );
    }

    /**
     *  Getting the current displayed page from the EQ5D3L questionnaire.
     * @returns {ReactElement} the content from a specific page based on the index.
     */
    function renderCurrentPageView(): ReactElement {
        switch (index) {
            case PAGES.Informative:
                return <InformativePage />;
            case PAGES.Question_1:
            case PAGES.Question_2:
            case PAGES.Question_3:
            case PAGES.Question_4:
            case PAGES.Question_5:
                return <>{renderCurrentQuestion(index - 1)}</>;
            case PAGES.Health_Info:
                return <HealthInfoPage />;
            case PAGES.Slider:
                return <>{renderCurrentQuestion(index - 2)}</>;
            case PAGES.Finalize:
                return <FinalizePage />;
            default:
                setIndex(0);
                return renderCurrentPageView();
        }
    }

    return (
        <div className="questionnaire-eq5d3l-page-container">
            <div className="questionnaire-eq5d3l-container eq-5d-3l-font-family">
                <h2 className="headline">{i18n.t('eq5.title')}</h2>
                <div className={`surveyContainer ${viewMode ? 'disable-user-interaction' : ''}`}>
                    {renderCurrentPageView()}
                </div>
                {renderNavigationButtons()}
            </div>
            <PopupBox
                popupType={POPUP_BOX_TYPE.Info}
                popupText={answerQuestionMessage}
                setPopupText={setAnswerQuestionMessage}
            />
        </div>
    );
}
