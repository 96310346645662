import type {ReactElement} from 'react';
import {createContext, useContext, Fragment} from 'react';

import IconButton from '@mui/material/IconButton/IconButton.js';
import Snackbar from '@mui/material/Snackbar/Snackbar.js';
import CloseIcon from '@mui/icons-material/Close.js';

import i18n from '@/i18n.js';

import './SnackbarNotification.css';

export const NOTIFICATION = {
    Info: 'info',
    Success: 'success',
    Error: 'error'
} as const;

/** The type definition based on the NOTIFICATION value. **/
export type NotificationType = (typeof NOTIFICATION)[keyof typeof NOTIFICATION];

export type NotificationContent = {
    setNotificationMessage: (message: string) => void;
    setNotificationType: (notificationType: NotificationType) => void;
};

export const NotificationContext = createContext<NotificationContent>({
    setNotificationMessage: () => {},
    setNotificationType: () => {}
});

/**
 * Forward the notification content context.
 */
export function useNotificationContext(): NotificationContent {
    return useContext(NotificationContext);
}

/**
 * Represents the generic snackbar notification component.
 * @param {{}} props - Properties of this view.
 * @param props.message - the message that will be displayed inside of the notification.
 * @param props.setMessageCallback - callback function for resetting the message value and implicitly close the notification.
 * @param props.type - type of the notification.
 * @returns {ReactElement} the news reader view.
 */
export default function SnackbarNotification(props: {
    message: string;
    setMessageCallback: (message: string) => void;
    type: NotificationType;
}): ReactElement {
    /**
     * Used to close the notification by triggering the set
     * message callback with empty value.
     * In this way the open condition will be false.
     */
    function closeNotification(): void {
        props.setMessageCallback('');
    }

    return (
        <Snackbar
            className={`notification-${props.type}`}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={props.message !== ''}
            onClose={closeNotification}
            autoHideDuration={6000}
            TransitionProps={{
                appear: false
            }}
            action={
                <Fragment>
                    <IconButton
                        className="notification-close-button"
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={closeNotification}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Fragment>
            }
            message={i18n.t(`${props.message}`)}
        />
    );
}
