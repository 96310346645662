import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Checkbox from '@mui/material/Checkbox/Checkbox.js';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel.js';

import type IoMRequestManager from '@refinio/one.models/lib/models/IoM/IoMRequestManager.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {useSomeonesPreview} from '@/hooks/contact/someoneHooks.js';
import GroupMembersPicker from '@/root/group/groupId/edit/members/GroupMembersPicker.js';
import {getDefaultProfilesOfAllIdentities} from '@/utils/Utils';

export function useOthersMainProfiles(leuteModel: LeuteModel): ProfileModel[] {
    const [profiles, setProfiles] = useState<ProfileModel[]>([]);
    const {someones} = useSomeonesPreview(leuteModel);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const i18n = useTranslation();

    useEffect(() => {
        let ignored = false;

        async function createNewGroup(): Promise<void> {
            const me = await leuteModel.me();
            const myId = await me.mainIdentity();

            const newProfiles = await getDefaultProfilesOfAllIdentities(leuteModel);
            if (!ignored) {
                setProfiles(newProfiles.filter(profile => profile.personId !== myId));
            }
        }

        createNewGroup().catch(_ => {
            setNotificationMessage(i18n.t('errors.someone.profiles'));
            setNotificationType(NOTIFICATION.Error);
        });

        return () => {
            ignored = true;
        };
    }, [someones, setNotificationMessage, setNotificationType, leuteModel]);

    return profiles;
}

export default function IomCreate(props: {
    leuteModel: LeuteModel;
    iomRequestManager: IoMRequestManager;
}): ReactElement {
    const [error, setError] = useState<string | Error>('');
    const [mode, setMode] = useState<'full' | 'light'>('light');
    const profiles = useOthersMainProfiles(props.leuteModel);
    const navigate = useNavigate();

    return (
        <>
            {error}
            <FormControlLabel
                className="group-member"
                control={<Checkbox color="default" checked={mode === 'full'} />}
                label="Full IoM Mode"
                labelPlacement="start"
                onChange={(_ev, checked) => {
                    setMode(checked ? 'full' : 'light');
                }}
                color="primary"
            />
            <GroupMembersPicker
                profiles={profiles}
                leuteModel={props.leuteModel}
                updateGroupMembers={newParticipants => {
                    if (newParticipants.length > 0) {
                        (async () => {
                            const me = await props.leuteModel.me();
                            const myId = await me.mainIdentity();
                            await props.iomRequestManager.createIoMRequest(
                                myId,
                                myId,
                                newParticipants[0],
                                mode
                            );
                            navigate(-1);
                        })().catch(setError);
                    }
                }}
                groupMembers={[]}
            ></GroupMembersPicker>
        </>
    );
}
