import {useTheme} from '@mui/material/styles';
import type {ReactElement} from 'react';
import {useEffect} from 'react';
import {useCallback} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {addDays, isWithinInterval, subDays} from 'date-fns';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type {JournalEntry} from '@refinio/one.models/lib/models/JournalModel.js';
import type {QuestionnaireResponses} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import QuestionnaireView from '@refinio/one.ui/lib/ui/views/questionnaire/QuestionnaireView.js';

import {questionnaireTypeDisplay} from '@/hooks/journal/hooks.js';
import type {BodySector} from './ExaminationBodySectorSelector.js';
import ExaminationBodySectorSelector from './ExaminationBodySectorSelector.js';
import QuestionnaireListWithSharing from './QuestionnaireListWithSharing.js';
import {
    EcpireArmpitLeftQuestionnaireUrl,
    EcpireArmpitLeftQuestionnaireName
} from './resources/questionnaires/EcpireArmpitLeftQuestionnaire.js';
import {
    EcpireArmpitRightQuestionnaireUrl,
    EcpireArmpitRightQuestionnaireName
} from './resources/questionnaires/EcpireArmpitRightQuestionnaire.js';
import {
    EcpireBreastLeftQuestionnaireUrl,
    EcpireBreastLeftQuestionnaireName
} from './resources/questionnaires/EcpireBreastLeftQuestionnaire.js';
import {
    EcpireBreastRightQuestionnaireUrl,
    EcpireBreastRightQuestionnaireName
} from './resources/questionnaires/EcpireBreastRightQuestionnaire.js';

import './Ecpire.css';

const QuestionnaireNameForBodySector: Record<BodySector, string> = {
    'left-breast': EcpireBreastLeftQuestionnaireName,
    'right-breast': EcpireBreastRightQuestionnaireName,
    'left-armpit': EcpireArmpitLeftQuestionnaireName,
    'right-armpit': EcpireArmpitRightQuestionnaireName
} as const;

const QuestionnaireUrlForBodySector: Record<BodySector, string> = {
    'left-breast': EcpireBreastLeftQuestionnaireUrl,
    'right-breast': EcpireBreastRightQuestionnaireUrl,
    'left-armpit': EcpireArmpitLeftQuestionnaireUrl,
    'right-armpit': EcpireArmpitRightQuestionnaireUrl
} as const;

/**
 * @param questionnaireModel
 * @param questionnaireUrlFilter
 * @param onError Optional. Default console.error
 * @returns undefineds on still loading
 */
function useEcpireQuestionnaireEventList(
    questionnaireModel: QuestionnaireModel,
    questionnaireUrlFilter: string[] = [],
    onError: (e: Error) => void = console.error
): [JournalEntry[] | undefined, Record<BodySector, Date | undefined> | undefined] {
    const [eventsList, setEventsList] = useState<JournalEntry[] | undefined>(undefined);
    const [nextExaminationDateForSectors, setNextExaminationDateForSectors] = useState<
        Record<BodySector, Date | undefined> | undefined
    >(undefined);

    useEffect(() => {
        const timeouts: ReturnType<typeof setTimeout>[] = [];
        let mounted = true;
        async function getEventList() {
            // set state to loading
            setEventsList(undefined);

            const newDates: Record<BodySector, Date | undefined> = {
                'left-breast': undefined,
                'right-breast': undefined,
                'left-armpit': undefined,
                'right-armpit': undefined
            };
            const newEventList: JournalEntry[] = [];

            const now = new Date();
            const twoWeeksAgo = subDays(new Date(), 14);
            const questionnaireUrls = Object.values(QuestionnaireUrlForBodySector);
            const questionnaireUrlsKeys = Object.keys(
                QuestionnaireUrlForBodySector
            ) as BodySector[];

            for await (const event of questionnaireModel.responsesIterator()) {
                if (
                    event.data.response[0].questionnaire &&
                    questionnaireUrls.includes(event.data.response[0].questionnaire)
                ) {
                    newEventList.push({type: questionnaireTypeDisplay, data: event});
                    if (
                        isWithinInterval(event.creationTime, {
                            start: twoWeeksAgo,
                            end: now
                        })
                    ) {
                        const sector =
                            questionnaireUrlsKeys[
                                questionnaireUrls.indexOf(event.data.response[0].questionnaire)
                            ];
                        const prevDate = newDates[sector];
                        if (
                            sector &&
                            (prevDate === undefined ||
                                !isWithinInterval(prevDate, {
                                    start: twoWeeksAgo,
                                    end: now
                                }))
                        ) {
                            newDates[sector] = addDays(event.creationTime, 14);
                        }
                    }
                }
            }

            if (mounted) {
                setEventsList(newEventList);
                setNextExaminationDateForSectors(newDates);
                // setup refresh on date expires
                timeouts.forEach(clearTimeout);
                const nowMs = new Date().getTime();
                Object.values(newDates).forEach(date => {
                    if (date) {
                        timeouts.push(setTimeout(getEventList, date.getTime() - nowMs));
                    }
                });
            }
        }
        getEventList().catch(onError);
        const updateListener = questionnaireModel.onUpdated(getEventList);
        return () => {
            updateListener();
            timeouts.forEach(clearTimeout);
            mounted = false;
        };
    }, [onError, questionnaireModel]);

    if (eventsList === undefined || nextExaminationDateForSectors === undefined) {
        // still loading
        return [undefined, undefined];
    }

    return [
        questionnaireUrlFilter.length === 0
            ? eventsList
            : eventsList.filter(e => {
                  const data = e.data.data as QuestionnaireResponses;
                  return (
                      data.response[0].questionnaire &&
                      questionnaireUrlFilter.includes(data.response[0].questionnaire)
                  );
              }),
        nextExaminationDateForSectors
    ];
}

/**
 * @param props.leuteModel
 * @param props.topicModel
 * @param props.questionnaireModel
 * @param props.onError Optional. Default console.error
 * @returns
 */
export default function Ecpire(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    questionnaireModel: QuestionnaireModel;
    onError?: (e: Error) => void;
}): ReactElement {
    const i18n = useTranslation();
    const [bodySectorExamination, setBodySectorExamination] = useState<boolean>(false);
    const [selectedBodySector, setSelectedBodySector] = useState<BodySector | undefined>(undefined);
    const [eventList, nextExaminationDate] = useEcpireQuestionnaireEventList(
        props.questionnaireModel,
        selectedBodySector
            ? [QuestionnaireUrlForBodySector[selectedBodySector]]
            : Array.from(Object.values(QuestionnaireUrlForBodySector)),
        props.onError
    );
    const theme = useTheme();

    // in useCallback as to not cause re-renders of useEffects in AppBarFullScreenPopup
    const closeExamination = useCallback(() => {
        setBodySectorExamination(false);
    }, []);

    /**
     * @param newBodySector
     */
    function changeSelectedBodySector(newBodySector: BodySector | undefined): void {
        setSelectedBodySector(newBodySector);
    }

    return (
        <>
            {nextExaminationDate && eventList ? (
                <>
                    <div className="ecpire-container">
                        <ExaminationBodySectorSelector
                            selectedBodySector={selectedBodySector}
                            nextExaminationDates={nextExaminationDate}
                            onSelectedSection={changeSelectedBodySector}
                            startExamination={() => setBodySectorExamination(true)}
                        />
                        <QuestionnaireListWithSharing
                            leuteModel={props.leuteModel}
                            topicModel={props.topicModel}
                            questionnaireModel={props.questionnaireModel}
                            eventList={eventList}
                        />
                    </div>
                </>
            ) : (
                <CircularProgress className="circular-progress" />
            )}

            {selectedBodySector && bodySectorExamination && (
                <div className="ecpire-container">
                    <AppBarFullScreenPopup
                        background={theme.palette.background.default}
                        onClose={closeExamination}
                        mode="chevron"
                        title={i18n.t(`ecpire.questionnaires.titles.${selectedBodySector}`)}
                    >
                        <QuestionnaireView
                            questionnaireModel={props.questionnaireModel}
                            questionnaireName={QuestionnaireNameForBodySector[selectedBodySector]}
                            language={'en'}
                            redirectAfterSubmit={closeExamination}
                            autoscroll={false}
                        />
                    </AppBarFullScreenPopup>
                </div>
            )}
        </>
    );
}
