import React, {useCallback, useContext, useRef, useState, type ReactElement} from 'react';
import {isMobile} from 'react-device-detect';

import ImageCapturePopup from '@refinio/one.ui/lib/ui/views/imageCapture/ImageCapturePopup.js';

import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import {MenuContext} from '@/context/MenuContext.js';
import BaseAddItem from './BaseAddItem.js';

/**
 * ImageItem component for handling image uploads and camera capture.
 *
 * @param props - The component props.
 * @param props.onDocumentSubmit - Callback function to handle document submission.
 * @returns ReactElement
 */
export default function ImageItem(props: {
    onDocumentSubmit: (file: File) => void | Promise<void>;
}): ReactElement {
    const imageItemRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLInputElement>(null);
    const {
        setMenuEntries,
        selectMenuEntry,
        isOpen,
        setMenuReference,
        setMenuClassName,
        setMenuAnchorOrigin
    } = useContext(MenuContext);
    const [showCamera, setShowCamera] = useState<boolean>(false);

    const openMenu = useCallback(() => {
        console.error('open menu');
        setMenuReference(imageItemRef.current);
        setMenuAnchorOrigin({horizontal: 'center', vertical: 'center'});
        setMenuClassName('app-bar-cnt-add-menu');
        setMenuEntries([MENU_ENTRY.SELECT_IMAGES, MENU_ENTRY.TAKE_IMAGE]);
        selectMenuEntry(() => (entrySelected: string) => {
            setMenuClassName('app-bar-cnt-menu');
            isOpen(false);
            switch (entrySelected) {
                case MENU_ENTRY.SELECT_IMAGES:
                    if (imageRef.current === null) {
                        console.error(
                            `Unable to handle entrySelected '${entrySelected}', due to no imageRef detected`
                        );
                        return;
                    }
                    imageRef.current.click();
                    break;
                case MENU_ENTRY.TAKE_IMAGE:
                    setShowCamera((s: boolean) => !s);
                    break;
            }
        });
        isOpen(true);
    }, []);

    const handleFileChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
            if (!event.target.files) {
                return;
            }

            try {
                for (const file of event.target.files) {
                    await props.onDocumentSubmit(file);
                }
            } catch (e) {
                console.error(e);
            }
        },
        [props.onDocumentSubmit]
    );

    const handleClick = useCallback(() => {
        if (isMobile) {
            if (imageRef.current === null) {
                console.error('Unable to handle onClick, due to no imageItemRef detected');
                return;
            }
            imageRef.current.click();
        } else {
            openMenu();
        }
    }, [openMenu]);

    return (
        <>
            <input
                ref={imageRef}
                type="file"
                style={{display: 'none'}}
                accept="image/*"
                onChange={handleFileChange}
                multiple
            />
            {showCamera && (
                <ImageCapturePopup
                    onFileChange={props.onDocumentSubmit}
                    active={showCamera}
                    onClose={setShowCamera.bind(setShowCamera, (s: boolean) => !s)}
                />
            )}
            <div ref={imageItemRef}>
                <BaseAddItem type={'image'} onClick={handleClick} />
            </div>
        </>
    );
}
