import {useState, useEffect} from 'react';

import type SingleUserNoAuth from '@refinio/one.models/lib/models/Authenticator/SingleUserNoAuth.js';
import type {AuthState} from '@refinio/one.models/lib/models/Authenticator/Authenticator.js';
import type Authenticator from '@refinio/one.models/lib/models/Authenticator/Authenticator.js';

/**
 * Provides the current authentication state from the Authenticator
 *
 * @param one - The one instance model
 * @returns the current authentication state
 */
export function useAuthenticationState(one: Authenticator): AuthState | undefined {
    const [authenticationState, setAuthenticationState] = useState<AuthState>();

    useEffect(() => {
        function fetchCurrentAuthenticationState(): void {
            setAuthenticationState(one.authState.currentState);
        }

        const disconnectOnAuthStateChanged = one.authState.onEnterState(
            fetchCurrentAuthenticationState
        );
        fetchCurrentAuthenticationState();

        return () => {
            disconnectOnAuthStateChanged();
        };
    }, [one]);

    return authenticationState;
}

export function useIsRegistered(one: SingleUserNoAuth): boolean | undefined {
    const [isRegistered, setRegistered] = useState<boolean>(false);

    useEffect(() => {
        let ignore = false;
        function fetchCurrentAuthenticationState(): void {
            if (!ignore) {
                one.isRegistered()
                    .then(res => {
                        setRegistered(res);
                    })
                    .catch(_ => setRegistered(false));
            }
        }

        fetchCurrentAuthenticationState();

        return () => {
            ignore = true;
        };
    }, [one]);

    return isRegistered;
}
