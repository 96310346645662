import type {ReactElement} from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import SomeoneModel from '@refinio/one.models/lib/models/Leute/SomeoneModel.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {DARK_ORANGE} from '@/components/Constants.js';
import {useEditPictureContext} from '@/context/EditPictureContext.js';
import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {
    updateProfileCommunicationEndpoints,
    updateProfilePersonDescription
} from '@/hooks/contact/utils.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import {useEditMode} from '@/hooks/appBar/common.js';
import i18n from '@/i18n.js';
import type {ProfileDetails} from '@/root/profile/common/types.js';
import SomeoneProfileDetails from '@/root/profile/common/SomeoneProfileDetails.js';
import {useBackgroundColor} from '@/utils/Utils.js';

/**
 * This component handle the creation of a "Someone" and its main profile.
 * @param props
 * @param props.leuteModel
 * @param props.onDone
 */
export default function CreateSomeone(props: {leuteModel: LeuteModel}): ReactElement {
    const navigate = useNavigate();
    const appBarContext = useContext(AppBarContext);
    const {originalPicture} = useEditPictureContext();
    const navigateBack = useNavigateBack({failRoute: '/contacts'});

    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const [profileDetails, setProfileDetails] = useState<ProfileDetails>({
        avatar: undefined,
        personEmail: '',
        name: ''
    });

    /**
     * Used to create the "Someone" and its main profile containing the information gathered from
     * the user.
     */
    const createSomeone = useCallback(async () => {
        try {
            const someoneIdHAsh = await props.leuteModel.createSomeoneWithShallowIdentity();
            const someoneModel = await SomeoneModel.constructFromLatestVersion(someoneIdHAsh);
            const profile = await someoneModel.mainProfile();
            await updateProfilePersonDescription(profile, profileDetails);
            await updateProfileCommunicationEndpoints(profile, profileDetails);
            await profile.saveAndLoad();
            navigateBack();
        } catch (e) {
            setNotificationMessage(i18n.t('errors.someone.create'));
            setNotificationType(NOTIFICATION.Error);
            navigate('/', {replace: true});
        }
    }, [
        navigate,
        navigateBack,
        profileDetails,
        props.leuteModel,
        setNotificationMessage,
        setNotificationType
    ]);

    useEditMode(navigateBack, createSomeone, 'leute.someone.title');
    useBackgroundColor(DARK_ORANGE);

    // each time when the user change the profile information, update the callbacks of the appbar
    useEffect(() => {
        if (appBarContext.contextValue.mode === APP_BAR_MODE.EDIT) {
            appBarContext.setContextValue({
                ...appBarContext.contextValue,
                rightBtnCallback: createSomeone,
                leftBtnCallback: navigateBack
            });
        }
    }, [profileDetails, originalPicture]);

    return (
        <SomeoneProfileDetails
            profileDetails={profileDetails}
            updateProfileDetails={setProfileDetails}
            title={i18n.t('leute.someone.title')}
        />
    );
}
