import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import {isSameDay, isSameMonth} from 'date-fns';

import {JournalList} from '@refinio/one.ui/lib/ui/views/journal/Journal.js';
import type {JournalEntry} from '@refinio/one.models/lib/models/JournalModel.js';
import type JournalModel from '@refinio/one.models/lib/models/JournalModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';

import {useEventTypes} from '@/hooks/journal/hooks.js';
import Calendar from '@/components/calendar/Calendar.js';

import './EventCalendar.css';

export default function EventCalendar(props: {
    journalModel: JournalModel;
    questionnaireModel: QuestionnaireModel;
    leuteModel: LeuteModel;
}): ReactElement {
    const [selectedDay, setSelectedDay] = useState<Date>(new Date());
    const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
    const [daysWithEventsForMonth, setDaysWithEventsForMonth] = useState<Set<number>>(new Set());
    const [dayEvents, setDayEvents] = useState<JournalEntry[]>([]);
    const [allEvents, setAllEvents] = useState<JournalEntry[]>([]);
    const eventTypes = useEventTypes(props.questionnaireModel);

    useEffect(() => {
        async function getAllEvents() {
            setAllEvents(await props.journalModel.retrieveAllEvents());
        }
        getAllEvents().catch(console.error);
        // mount only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get days for the selected month for which we have events
    useEffect(() => {
        const newDaysWithEventsForMonth: Set<number> = new Set();
        let found = false;

        for (
            let i = 0;
            i < allEvents.length &&
            (!found || isSameMonth(allEvents[i].data.creationTime, selectedMonth));
            i++
        ) {
            if (isSameMonth(allEvents[i].data.creationTime, selectedMonth)) {
                newDaysWithEventsForMonth.add(allEvents[i].data.creationTime.getDate());
                found = true;
            }
        }

        setDaysWithEventsForMonth(newDaysWithEventsForMonth);
    }, [allEvents, selectedMonth]);

    // get events for the selected month
    useEffect(() => {
        async function getDayEvents() {
            const newDayEvents: JournalEntry[] = [];
            let found = false;

            for (
                let i = 0;
                i < allEvents.length &&
                (!found || isSameDay(allEvents[i].data.creationTime, selectedDay));
                i++
            ) {
                if (isSameDay(allEvents[i].data.creationTime, selectedDay)) {
                    newDayEvents.push(allEvents[i]);
                    found = true;
                }
            }

            setDayEvents(newDayEvents);
        }
        if (allEvents.length > 0) {
            getDayEvents().catch(console.error);
        }
    }, [selectedDay, allEvents]);

    return (
        <div className="event-calendar-container">
            <Calendar
                daySelectionChange={setSelectedDay}
                monthSelectionChange={setSelectedMonth}
                dayIndicator={[...daysWithEventsForMonth]}
            />
            <div className="event-calendar-content">
                <JournalList
                    events={dayEvents}
                    eventTypes={eventTypes}
                    leuteModel={props.leuteModel}
                />
            </div>
        </div>
    );
}
