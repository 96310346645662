import type {ReactElement} from 'react';

import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type Notifications from '@refinio/one.models/lib/models/Notifications.js';
import ListElement from '@refinio/one.ui/lib/ui/components/list/ListElement.js';

import AvatarPreview from '@/components/avatarPreview/AvatarPreview.js';
import {GREEN_CHECK_MARK_BUTTON} from '@/components/Constants.js';
import StartChat from '@/components/startChat/StartChat.js';
import {useIsProfileSigned} from '@/hooks/contact/profileHooks.js';
import type {SomeonePreview} from './LeuteView.js';

import './SomeoneListEntry.css';

export default function SomeoneListEntry(props: {
    someone: SomeonePreview;
    onSelect: (someone: SomeonePreview) => void;
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    hideStartChat?: boolean;
    notifications?: Notifications;
    rightAddons?: ReactElement | ReactElement[];
    disableTrustIndicator?: boolean;
}): ReactElement {
    const isSigned = useIsProfileSigned(props.someone.mainProfile, props.leuteModel.trust);

    return (
        <ListElement
            onClick={() => props.onSelect(props.someone)}
            extra={
                <>
                    <AvatarPreview src={props.someone.avatar} />
                    <div className="someone-list-entry-details">
                        <span className="name">{props.someone.name}</span>
                        <span className="email">{props.someone.email}</span>
                    </div>
                    {!props.disableTrustIndicator && (
                        <div className="trusted">{isSigned && GREEN_CHECK_MARK_BUTTON}</div>
                    )}
                    <div className="right-alignment" onClick={e => e.stopPropagation()}>
                        {props.rightAddons}
                        {!props.hideStartChat && (
                            <StartChat
                                leuteModel={props.leuteModel}
                                topicModel={props.topicModel}
                                receiverProfile={props.someone.mainProfile}
                                notifications={props.notifications}
                            />
                        )}
                    </div>
                </>
            }
        />
    );
}
