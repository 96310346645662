import type {ReactElement} from 'react';

import type {OneCertificateInterfaces} from '@OneObjectInterfaces';
import type {
    BLOB,
    HashTypes,
    OneIdObjectTypes,
    OneObjectTypeNames,
    OneObjectTypes,
    Person
} from '@refinio/one.core/lib/recipes.js';
import type {UnversionedObjectResult} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {CertificateData} from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';
import type {OneCertificateTypeNames} from '@refinio/one.models/lib/recipes/Certificates/CertificateRecipes.js';
import type {License} from '@refinio/one.models/lib/recipes/Certificates/License.js';
import type {Signature} from '@refinio/one.models/lib/recipes/SignatureRecipes.js';

export type ObjectType = {
    type: 'Object';
    hash: SHA256Hash;
    obj: OneObjectTypes;
};

export type IdObjectType = {
    type: 'IdObject';
    hash: SHA256IdHash;
    obj: OneIdObjectTypes;
};

export type BLOBType = {
    type: 'BLOB';
    hash: SHA256Hash<BLOB>;
    blob: string;
    length: number;
};

export type UnknownType = {
    type: 'unknown';
    hash: SHA256Hash<HashTypes> | SHA256IdHash;
    err?: any;
};

export type TrustObject = ObjectType | IdObjectType | BLOBType | UnknownType;
export type TrustObjectType =
    | ObjectType['type']
    | IdObjectType['type']
    | BLOBType['type']
    | UnknownType['type'];
export const TrustObjectCategories = {
    Object: 'Object',
    IdObject: 'IdObject',
    BLOB: 'BLOB',
    ALL: 'ALL'
} as const;
export type TrustObjectCategory =
    (typeof TrustObjectCategories)[keyof typeof TrustObjectCategories];

export const AvaliableIssuers = ['TBD'] as const;
export type Issuer = (typeof AvaliableIssuers)[number];

export type CertificateSettingsCreateReturn =
    | void
    | UnversionedObjectResult<Signature>
    | {
          license: UnversionedObjectResult<License>;
          certificate: UnversionedObjectResult<OneCertificateInterfaces[OneCertificateTypeNames]>;
          signature: UnversionedObjectResult<Signature>;
      };

export type CertificateSettingCreate<T extends TrustObject> = (
    targetObject: T,
    issuer: SHA256IdHash<Person> | undefined
) => Promise<CertificateSettingsCreateReturn> | CertificateSettingsCreateReturn;

export type IsCreatable<T extends TrustObject> = (trustObject: T) => Promise<boolean> | boolean;

export type SettingIconType = ReactElement | string | undefined;
export type SettingIcon<T extends TrustObject> =
    | SettingIconType
    | ((trustObject: T, cert: CertificateData) => SettingIconType | Promise<SettingIconType>);

type CertificateSettingData<T extends TrustObject> = {
    certificateRecipeName: OneCertificateTypeNames;
    settingUniqueId: string;
    create: CertificateSettingCreate<T>;
    oneType?: OneObjectTypeNames | '*';
    icon?: SettingIcon<T>;
    isCreatable?: IsCreatable<T>;
    type?: T['type'] | undefined;
};

export type CertificateSettingAllType = {
    oneType: '*';
    type?: undefined;
} & CertificateSettingData<TrustObject>;

export type CertificateSettingBLOBType = {
    type: 'BLOB';
} & CertificateSettingData<BLOBType>;

export type CertificateSettingIdObjectType = {
    type: 'IdObject';
} & CertificateSettingData<IdObjectType>;

export type CertificateSettingObjectType = {
    type: 'Object';
} & CertificateSettingData<ObjectType>;

export type CertificateSettingUnknownType = {
    type: 'unknown';
} & CertificateSettingData<TrustObject>;

export type CertificateSetting =
    | CertificateSettingAllType
    | CertificateSettingBLOBType
    | CertificateSettingObjectType
    | CertificateSettingIdObjectType
    | CertificateSettingUnknownType;

export type ExpandedCertificateData = CertificateData & {
    trustObject: TrustObject;
    setting: CertificateSetting;
};
