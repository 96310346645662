import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import type {ReactZoomPanPinchRef} from 'react-zoom-pan-pinch';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';

import Button from '@mui/material/Button/Button.js';

import i18n from '@/i18n.js';
import {getURL} from '@/utils/Utils.js';

import '@/components/onboarding/Onboarding.css';

const CANVAS_SIDE = 900;

const EDITOR_WIDTH = 300;
const EDITOR_HEIGHT = 300;
const CANVAS_BACKGROUND_COLOR = 'white';

/**
 * Used to edit the picture uploaded by the user in onboarding process.
 * @param props
 * @param props.image - the image that is loaded in the editor.
 * @param props.setEditMode - callback for setting the edit mode.
 * @param props.setEditedImage - callback for setting the edited image.
 * @param props.imageNaturalWidth - initial width of the image.
 * @param props.imageNaturalHeight - initial height of the image.
 */
export default function PictureEdit(props: {
    image: ArrayBuffer;
    setEditMode: (value: boolean) => void;
    setEditedImage: (value: ArrayBuffer | undefined) => void;
    imageNaturalWidth: number;
    imageNaturalHeight: number;
}): ReactElement {
    // minimum scale calculated based on the image initial size:
    // -> if the image is larger than the editor, it will be scaled down to fit the editor height or width
    //    (if the height is smaller than width than the image will fit the editor vertically otherwise horizontally)
    const [minimumScale, setMinimumScale] = useState<number | undefined>();

    // the offsets of the edited picture -> are modified when the user drag the picture
    const [xOffset, setXOffset] = useState(0);
    const [yOffset, setYOffset] = useState(0);

    // the image scale -> changed while zooming, pinching
    const [scale, setScale] = useState(1);

    const [canvasSide, setCanvasSide] = useState(CANVAS_SIDE);

    /**
     * After the image is loaded in our editor we need to get the minimum scale allowed
     * and also the offsets of the picture.
     */
    useEffect(() => {
        /**
         * Used to calculate the minimum scale for the uploaded picture.
         */
        function calculateMinimumScale(): void {
            if (
                props.imageNaturalHeight <= EDITOR_HEIGHT ||
                props.imageNaturalWidth <= EDITOR_WIDTH
            ) {
                setMinimumScale(1);
                return;
            }

            if (props.imageNaturalWidth <= props.imageNaturalHeight) {
                setMinimumScale(1 / (props.imageNaturalWidth / EDITOR_WIDTH));
            } else {
                setMinimumScale(1 / (props.imageNaturalHeight / EDITOR_HEIGHT));
            }
        }

        // calculate the minimum scale for the uploaded picture
        calculateMinimumScale();
        const minScale = minimumScale !== undefined ? minimumScale : 1;
        setXOffset((props.imageNaturalWidth * minScale - EDITOR_WIDTH) / 2);
        setYOffset((props.imageNaturalHeight * minScale - EDITOR_HEIGHT) / 2);
    }, [props.imageNaturalWidth, props.imageNaturalHeight, minimumScale]);

    /**
     * Update the scale with the minimum scale after the minimum scale was calculated.
     */
    useEffect(() => {
        if (minimumScale !== undefined) {
            setScale(minimumScale);
        }
    }, [minimumScale]);

    /**
     * Whenever the user interact with the image editor we need to update the image states.
     * @param editorRef - editor reference.
     */
    function updateImageStates(editorRef: ReactZoomPanPinchRef): void {
        const newScale = editorRef.state.scale;
        const minScale = minimumScale !== undefined ? minimumScale : 1;

        setScale(newScale > minScale ? newScale : minScale);

        setXOffset(Math.abs(editorRef.state.positionX));
        setYOffset(Math.abs(editorRef.state.positionY));
    }

    /**
     * Used to draw the edited picture.
     */
    function drawPicture(): void {
        const canvas = document.getElementById('drawing-area') as HTMLCanvasElement;
        if (canvas !== null) {
            const ctx = canvas.getContext('2d');

            if (ctx !== null) {
                const url = getURL(props.image);
                const img = new Image();
                img.src = url;

                img.onload = function () {
                    if (
                        props.imageNaturalHeight * scale >= EDITOR_HEIGHT &&
                        props.imageNaturalWidth * scale >= EDITOR_WIDTH
                    ) {
                        setCanvasSide(CANVAS_SIDE);
                        ctx.scale(scale, scale);
                        const newWidth = scale >= 1 ? EDITOR_WIDTH : EDITOR_WIDTH / scale;
                        const newHeight = scale >= 1 ? EDITOR_HEIGHT : EDITOR_HEIGHT / scale;
                        const newCanvasSide = scale >= 1 ? CANVAS_SIDE : CANVAS_SIDE / scale;
                        ctx.drawImage(
                            img,
                            Math.abs(xOffset / scale),
                            Math.abs(yOffset / scale),
                            newWidth,
                            newHeight,
                            0,
                            0,
                            newCanvasSide,
                            newCanvasSide
                        );
                    } else {
                        setCanvasSide(EDITOR_HEIGHT);
                        ctx.fillStyle = CANVAS_BACKGROUND_COLOR;
                        ctx.fillRect(0, 0, EDITOR_WIDTH, EDITOR_HEIGHT);
                        const newWidth = props.imageNaturalWidth * scale;
                        const newHeight = props.imageNaturalHeight * scale;
                        ctx.drawImage(
                            img,
                            0,
                            0,
                            props.imageNaturalWidth,
                            props.imageNaturalHeight,
                            (EDITOR_WIDTH - newWidth) / 2,
                            (EDITOR_HEIGHT - newHeight) / 2,
                            newWidth,
                            newHeight
                        );
                    }
                    ctx.save();

                    canvas.toBlob(
                        async blob => {
                            const arB = await blob?.arrayBuffer();
                            props.setEditedImage(arB);
                            props.setEditMode(false);
                        },
                        'image/jpeg',
                        1
                    );

                    URL.revokeObjectURL(url);
                };
            }
        }
    }

    return (
        <div className="information-prompt-container edit-picture-container">
            {minimumScale !== undefined && (
                <>
                    <div className="image-editor">
                        <TransformWrapper
                            centerOnInit={true}
                            onPinchingStop={ref => updateImageStates(ref)}
                            onPanningStop={ref => updateImageStates(ref)}
                            onZoomStop={ref => updateImageStates(ref)}
                            alignmentAnimation={{
                                disabled: true,
                                sizeX: 0,
                                sizeY: 0,
                                animationType: 'linear'
                            }}
                            velocityAnimation={{
                                animationTime: 0,
                                animationType: 'linear',
                                sensitivity: 0
                            }}
                            panning={{velocityDisabled: true}}
                            minScale={minimumScale}
                            initialScale={minimumScale}
                            zoomAnimation={{size: minimumScale, animationTime: 0}}
                            centerZoomedOut={true}
                        >
                            <TransformComponent>
                                <img src={getURL(props.image)} alt="edit" />
                            </TransformComponent>
                        </TransformWrapper>
                        <div className="edit-image-info body2">
                            {i18n.t('onboarding.editPicturePrompt.info')}
                        </div>
                    </div>
                    <canvas id="drawing-area" width={canvasSide} height={canvasSide} />
                    <div className="edit-picture-handler">
                        <div className="edit-picture-buttons">
                            <Button
                                color="primary"
                                variant="contained"
                                className="btn-cancel body"
                                onClick={() => props.setEditMode(false)}
                            >
                                {i18n.t('buttons.common.cancel')}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                className="body btn-ok"
                                onClick={drawPicture}
                            >
                                {i18n.t('buttons.common.ok')}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
