import type {ReactElement} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';

import PageNotFound from '@/components/PageNotFound.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import SomeoneView from './someoneId/SomeoneView.js';
import CreateSomeone from './create/CreateSomeone.js';

export default function SomeoneRouter(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    getTrustView?: (hashes: (SHA256Hash | SHA256IdHash)[]) => ReactElement;
}): ReactElement {
    const {leuteModel, topicModel} = props;
    const i18n = useTranslation();
    const goBack = useNavigateBack();

    return (
        <Routes>
            <Route
                path={'create'}
                element={
                    <>
                        <AppBarSettings mode={APP_BAR_MODE.EDIT} title={i18n.t('menu.contacts')} />
                        <CreateSomeone leuteModel={leuteModel} />
                    </>
                }
            />
            <Route
                path=":someoneId"
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.CHEVRON_VIEW}
                            title={i18n.t('leute.someone.title')}
                            leftBtnCallback={goBack}
                        />
                        <SomeoneView
                            topicModel={topicModel}
                            leuteModel={leuteModel}
                            getInformationAddon={props.getTrustView}
                        />
                    </>
                }
            />
            <Route element={<PageNotFound />} />
        </Routes>
    );
}
