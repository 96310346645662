import {useState, type ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';
import Card from '@mui/material/Card/Card.js';
import CardActions from '@mui/material/CardActions/CardActions.js';
import CardContent from '@mui/material/CardContent/CardContent.js';
import FormControl from '@mui/material/FormControl/FormControl.js';
import InputLabel from '@mui/material/InputLabel/InputLabel.js';
import MenuItem from '@mui/material/MenuItem/MenuItem.js';
import Select from '@mui/material/Select/Select.js';
import Typography from '@mui/material/Typography/Typography.js';

import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type TrustedKeysManager from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';
import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';

import type {CertificateSetting} from './common/types.js';
import {AvaliableIssuers, type Issuer, type TrustObject} from './common/types.js';
import CertificateDescription from './CertificateDescription.js';
import {
    createCertificate,
    getAppliableCertificateUniqueIds,
    getCertificateSetting,
    getDefaultCertificateSettings
} from './common/utils.js';
import {useTrustObjects} from './common/hooks.js';

import './TrustView.css';

export default function CertificateCreate(props: {
    hashes: (SHA256Hash | SHA256IdHash)[] | SHA256Hash | SHA256IdHash | undefined;
    trustedKeysManager: TrustedKeysManager;
    onError?: (error: Error) => void;
    overwriteCertificateSettings?: CertificateSetting[];
}): ReactElement {
    const trustObjects = useTrustObjects(
        props.hashes ? (typeof props.hashes === 'object' ? props.hashes : [props.hashes]) : [],
        props.onError
    );
    const i18n = useTranslation();
    const certificateSettings = props.overwriteCertificateSettings
        ? props.overwriteCertificateSettings
        : getDefaultCertificateSettings(props.trustedKeysManager);

    return (
        <div className="certificate-create-container">
            <CollapsibleComponent
                headline={<>{i18n.t('trust.createCertificate')}</>}
                content={
                    <>
                        <CertificateCreation
                            trustObjects={trustObjects}
                            certificateSettings={certificateSettings}
                        />
                    </>
                }
            />
        </div>
    );
}

function CertificateCreation(props: {
    trustObjects: TrustObject[];
    certificateSettings: CertificateSetting[];
}): ReactElement {
    const i18n = useTranslation();
    const [trustObjectIndex, setTrustObjectIndex] = useState<number | ''>(
        props.trustObjects.length === 1 ? 0 : ''
    );
    const [issuer, setIssuer] = useState<Issuer | ''>('');
    const [certificateSettingUniqueId, setCertificateSettingUniqueId] = useState<
        string | undefined
    >(undefined);

    useEffect(() => {
        setTrustObjectIndex(props.trustObjects.length === 1 ? 0 : '');
    }, [props.trustObjects]);

    const certificateSetting = certificateSettingUniqueId
        ? getCertificateSetting(props.certificateSettings, {
              settingUniqueId: certificateSettingUniqueId
          })
        : undefined;

    const [appliableCertificateUniqueIds, setAppliableCertificateUniqueIds] = useState<string[]>(
        []
    );

    useEffect(() => {
        async function loadAppliableCertificateUniqueIds(): Promise<void> {
            if (trustObjectIndex === '') {
                setAppliableCertificateUniqueIds([]);
                return;
            }
            setAppliableCertificateUniqueIds(
                await getAppliableCertificateUniqueIds(
                    props.trustObjects[trustObjectIndex],
                    props.certificateSettings
                )
            );
        }
        loadAppliableCertificateUniqueIds().catch(console.error);
    }, [trustObjectIndex, props.trustObjects, props.certificateSettings]);

    return (
        <Card className="certificate-creation-card">
            <CardContent>
                {props.trustObjects.length > 1 && (
                    <div className="section">
                        <Typography className="header">{i18n.t('trust.object')}</Typography>
                        <InputLabel
                            variant="standard"
                            shrink={false}
                            disableAnimation={true}
                            className="absolute-label"
                        >
                            {trustObjectIndex === '' ? `<${i18n.t('trust.object')}>` : ''}
                        </InputLabel>
                        <Select
                            value={String(trustObjectIndex)}
                            onChange={event => {
                                setTrustObjectIndex(parseInt(event.target.value, 10));
                                setCertificateSettingUniqueId(undefined);
                            }}
                            variant="standard"
                            disableUnderline={true}
                        >
                            {props.trustObjects.map((trustObject, index: number) => (
                                <MenuItem key={trustObject.hash} value={String(index)}>
                                    {trustObject.type === 'IdObject' ||
                                    trustObject.type === 'Object'
                                        ? `${trustObject.obj.$type$} (${String(
                                              trustObject.hash
                                          ).substring(0, 7)})`
                                        : trustObject.hash}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                )}
                <div className="section">
                    <Typography className="header">{i18n.t('trust.issuer')}</Typography>
                    <InputLabel
                        variant="standard"
                        shrink={false}
                        disableAnimation={true}
                        className="absolute-label"
                    >
                        {issuer === '' ? `<${i18n.t('trust.issuer')}>` : ''}
                    </InputLabel>
                    <Select
                        value={issuer}
                        onChange={event => {
                            setIssuer(event.target.value as Issuer);
                        }}
                        variant="standard"
                        disableUnderline={true}
                    >
                        {AvaliableIssuers.map((aIssuer: Issuer, index: number) => (
                            <MenuItem key={`${aIssuer}-${index}`} value={aIssuer}>
                                {i18n.t(`trust.issuers.${aIssuer}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="section">
                    <Typography className="header">{i18n.t('trust.certificateType')}</Typography>
                    <InputLabel
                        variant="standard"
                        shrink={false}
                        disableAnimation={true}
                        className="absolute-label"
                    >
                        {certificateSettingUniqueId === undefined
                            ? `<${i18n.t('trust.certificateType')}>`
                            : ''}
                    </InputLabel>
                    <Select
                        value={
                            certificateSettingUniqueId === undefined ||
                            appliableCertificateUniqueIds.length < 1
                                ? ''
                                : certificateSettingUniqueId
                        }
                        onChange={event => {
                            setCertificateSettingUniqueId(event.target.value);
                        }}
                        variant="standard"
                        disableUnderline={true}
                    >
                        {trustObjectIndex !== '' &&
                            appliableCertificateUniqueIds.map(appliableCertificateUniqueId => (
                                <MenuItem
                                    key={`${appliableCertificateUniqueId}`}
                                    value={appliableCertificateUniqueId}
                                >
                                    {i18n.t(
                                        `trust.certificates.${appliableCertificateUniqueId}`,
                                        appliableCertificateUniqueId
                                    )}
                                </MenuItem>
                            ))}
                    </Select>
                </div>
                <div className="section">
                    <Typography className="header">{i18n.t('trust.licenseText')}</Typography>
                    {certificateSetting && (
                        <CertificateDescription
                            certificateType={certificateSetting.certificateRecipeName}
                        />
                    )}
                </div>
            </CardContent>
            <CardActions>
                <Button
                    className="full"
                    disabled={
                        certificateSetting === undefined || issuer === '' || trustObjectIndex === ''
                    }
                    onClick={() =>
                        certificateSetting &&
                        issuer !== '' &&
                        trustObjectIndex !== '' &&
                        createCertificate(
                            certificateSetting,
                            props.trustObjects[trustObjectIndex],
                            issuer
                        )
                    }
                >
                    {i18n.t('trust.create')}
                </Button>
            </CardActions>
        </Card>
    );
}
