import React, {useCallback, useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import MenuBookIcon from '@mui/icons-material/MenuBook';
import IconButton from '@mui/material/IconButton/IconButton.js';

import type {DiaryModel} from '@refinio/one.models/lib/models';
import type {SHA256Hash} from '@refinio/one.core/lib/util/type-checks';

import DefaultAttachmentDetails from '@/root/chat/attachmentViews/defaultView/DefaultAttachmentDetails.js';
import ViewDiaryEntry from '@/root/diary/view/ViewDiaryEntry';

/**
 * @param props.getCertificatePopupView callback to get certificate popup view
 * @param props.onError Optional. Default console.error
 * @returns
 */
export default function DiaryEntryView(props: {
    hash: SHA256Hash;
    diaryModel: DiaryModel;
    getCertificatePopupView: (onClose: () => void) => ReactElement;
    onError?: (e: Error) => void;
}): ReactElement {
    const i18n = useTranslation();
    const [dataObjectId, setDataObjectId] = useState<string | undefined>(undefined);
    const [isObjectViewOpened, setIsObjectViewOpened] = useState<boolean>(false);

    const toggleObjectView = useCallback(() => {
        setIsObjectViewOpened(oldValue => !oldValue);
    }, []);

    useEffect(() => {
        async function loadDataObjectId(): Promise<void> {
            for await (const dataObject of props.diaryModel.entriesIterator()) {
                if (dataObject.dataHash === props.hash) {
                    setDataObjectId(dataObject.id);
                    return;
                }
            }
        }

        if (dataObjectId !== undefined) {
            return;
        }

        loadDataObjectId().catch(props.onError ? props.onError : console.error);
        return props.diaryModel.onUpdated(loadDataObjectId);
    }, [props.diaryModel]);

    if (!dataObjectId) {
        return <></>;
    }

    return (
        <div className="attachment-box hash-attachment">
            <IconButton
                className="attachment-button"
                sx={{'&:hover': {backgroundColor: 'transparent'}, padding: 0}}
                onClick={toggleObjectView}
            >
                <MenuBookIcon className="icon-avatar-wrapper attachment-icon" />
                <div className="text">{i18n.t('journal.eventTypes.diaryTitle')}</div>
            </IconButton>
            <DefaultAttachmentDetails
                getCertificatePopupView={props.getCertificatePopupView}
                onViewObjectRequest={toggleObjectView}
            />
            {isObjectViewOpened && (
                <ViewDiaryEntry
                    diaryModel={props.diaryModel}
                    id={dataObjectId}
                    onClose={toggleObjectView}
                />
            )}
        </div>
    );
}
