import type {ReactElement, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import {shortenHash} from '@refinio/one.ui/lib/ui/views/utils/Utils.js';

import TextFieldEntry from '@/components/textFieldEntry/TextFieldEntry.js';

import './SomeoneProfileDetails.css';

export default function InstanceInformation(props: {
    personEmail: string;
    owner: SHA256IdHash<Person> | undefined;
    ownerName?: string | undefined;
    disableUnderlines?: boolean;
    children?: ReactNode;
}): ReactElement {
    const i18n = useTranslation();
    const disableUnderlines =
        props.disableUnderlines === undefined ? false : props.disableUnderlines;

    return (
        <>
            <div className="trust-section">
                <TextFieldEntry
                    label={i18n.t('common.email')}
                    value={props.personEmail}
                    disableUnderline={disableUnderlines}
                    onDone={_ => {}}
                />
                {props.children ? props.children : null}
            </div>
            {props.owner && (
                <TextFieldEntry
                    label={i18n.t('common.owner')}
                    value={
                        props.ownerName
                            ? `${props.ownerName} (${shortenHash(props.owner)})`
                            : props.owner
                    }
                    disableUnderline={disableUnderlines}
                    onDone={_ => {}}
                />
            )}
        </>
    );
}
