import {useCallback, useEffect, useState} from 'react';
import type {NavigateOptions} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

type FailOptions = {
    failRoute?: string;
    failOptions?: NavigateOptions;
};

export function useNavigateBack(failOptions?: FailOptions): () => void {
    const navigate = useNavigate();
    const [options, setOptions] = useState<FailOptions | undefined>(undefined);

    useEffect(() => {
        if (
            failOptions &&
            failOptions.failRoute &&
            (!options || options.failRoute !== failOptions.failRoute)
        ) {
            setOptions(failOptions);
        } else if (
            failOptions &&
            failOptions.failOptions &&
            (!options ||
                JSON.stringify(options.failOptions) !== JSON.stringify(failOptions.failOptions))
        ) {
            setOptions(failOptions);
        }
    }, [failOptions, options]);

    const goBack = useCallback(() => {
        // window.history.state.idx counts the back pages
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            const route = options && options.failRoute ? options.failRoute : '/';
            const navOptions = options && options.failOptions ? options.failOptions : undefined;
            navigate(route, navOptions);
        }
    }, [navigate, options]);

    return goBack;
}
