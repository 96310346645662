import type {ReactElement} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type ConnectionsModel from '@refinio/one.models/lib/models/ConnectionsModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type {Someone} from '@refinio/one.models/lib/recipes/Leute/Someone.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import SomeoneModel from '@refinio/one.models/lib/models/Leute/SomeoneModel.js';
import {getInstancesOfPerson} from '@refinio/one.models/lib/misc/instance.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import StartChat from '@/components/startChat/StartChat.js';
import {useProfileDetails} from '@/hooks/contact/profileHooks.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import {useMoreMenu} from '@/hooks/menu/menu.js';
import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import type {InstanceListItem} from '@/root/settings/InstanceListEntry.js';
import SomeoneProfileDetails from '@/root/profile/common/SomeoneProfileDetails.js';
import CollapsibleProfilesList from '@/root/collapsibles/CollapsibleProfilesList.js';
import CollapsibleInstancesList from '@/root/collapsibles/CollapsibleInstancesList.js';
import CollapsibleCouponList from '@/components/one.euro/collapsibleCouponList/CollapsibleCouponList.js';

export function useInstancesList(
    leuteModel: LeuteModel,
    viewedPersonId: SHA256IdHash<Person>
): InstanceListItem[] {
    const [instances, setInstances] = useState<InstanceListItem[]>([]);

    useEffect(() => {
        async function fetchInstances(): Promise<InstanceListItem[] | void> {
            const mainProfile = await leuteModel.getMainProfile(viewedPersonId);
            const newInstances = await getInstancesOfPerson(viewedPersonId);
            setInstances(
                newInstances.map(instance => ({
                    instanceId: instance.instanceId,
                    mainProfile: mainProfile
                }))
            );
        }

        fetchInstances().catch(console.error);
    }, [viewedPersonId, leuteModel]);

    return instances;
}

const menuItems = [MENU_ENTRY.VIEW_OWN_SIGN_KEYS];

export default function IdentityView(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    connectionsModel: ConnectionsModel;
    getInformationAddon?: (hashes: (SHA256Hash | SHA256IdHash)[]) => ReactElement;
}): ReactElement {
    const params = useParams<{someoneId: SHA256IdHash<Someone>; identity: SHA256IdHash<Person>}>();
    const viewedPersonId = params.identity as SHA256IdHash<Person>;
    const [profiles, setProfiles] = useState<ProfileModel[]>([]);
    const [mainProfile, setMainProfile] = useState<ProfileModel | undefined>();
    const [profileDetails] = useProfileDetails(mainProfile, props.leuteModel);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const goBack = useNavigateBack();
    const navigate = useNavigate();
    const i18n = useTranslation();

    const onMenuSelected = useCallback(
        (selected: string) => {
            switch (selected) {
                case MENU_ENTRY.VIEW_OWN_SIGN_KEYS:
                    navigate('/signkeys/own');
                    break;
            }
        },
        [navigate]
    );

    useMoreMenu(menuItems, onMenuSelected);

    const instancesList = useInstancesList(props.leuteModel, viewedPersonId);

    const errorMessage = i18n.t('errors.someone.profile');

    useEffect(() => {
        async function getProfileInformation(): Promise<void> {
            const someoneModel = await SomeoneModel.constructFromLatestVersion(
                params.someoneId as SHA256IdHash<Someone>
            );
            const newProfiles = await someoneModel.profiles();
            setProfiles(newProfiles.filter(profile => profile.personId === viewedPersonId));
            const newMainProfile = await someoneModel.mainProfile();
            setMainProfile(newMainProfile);
        }
        getProfileInformation().catch(_ => {
            setNotificationMessage(errorMessage);
            setNotificationType(NOTIFICATION.Error);
            goBack();
        });
    }, [
        params.someoneId,
        viewedPersonId,
        setNotificationMessage,
        setNotificationType,
        errorMessage,
        goBack
    ]);

    function selectProfile(profileModel: ProfileModel): void {
        navigate(`/profile/${profileModel.idHash}`);
    }

    const addon =
        props.getInformationAddon !== undefined && mainProfile
            ? props.getInformationAddon(
                  mainProfile.loadedVersion
                      ? [mainProfile.loadedVersion, mainProfile.personId]
                      : [mainProfile.personId]
              )
            : undefined;

    return (
        <>
            {mainProfile && (
                <SomeoneProfileDetails
                    profileDetails={profileDetails}
                    title={i18n.t('leute.identity.title')}
                    instanceInformationAddon={addon}
                    sendMessageElement={
                        <StartChat
                            leuteModel={props.leuteModel}
                            receiverProfile={mainProfile}
                            topicModel={props.topicModel}
                        />
                    }
                    hideStatus={true}
                    hideCommunicationEndpoints={true}
                />
            )}
            <CollapsibleProfilesList
                leuteModel={props.leuteModel}
                profiles={profiles}
                mainProfileHash={mainProfile?.idHash}
                topicModel={props.topicModel}
                onSelectProfile={selectProfile}
                hideIdentityInfo={true}
            />
            <CollapsibleInstancesList
                leuteModel={props.leuteModel}
                instancesList={instancesList}
                showHashOnly={true}
                title={i18n.t('leute.lists.instances')}
            />
            <CollapsibleCouponList leuteModel={props.leuteModel} topicModel={props.topicModel} />
        </>
    );
}
