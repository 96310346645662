import {useEffect, useState} from 'react';

import {useForceRender} from '@/hooks/useForceRender.js';
import BlobDescriptorCache from '@/root/chat/cache/BlobDescriptorCache.js';

/**
 * @param onError Optional. Default console.error
 * @returns
 */
export default function useBlobDescriptorCache(
    onError: (e: Error) => void = console.error
): BlobDescriptorCache {
    const [blobDescriptorCache] = useState<BlobDescriptorCache>(new BlobDescriptorCache());
    const forceRender = useForceRender();

    useEffect(() => {
        const shutdownFunctions = [() => {}];

        shutdownFunctions.push(blobDescriptorCache.shutdown.bind(blobDescriptorCache));
        shutdownFunctions.push(blobDescriptorCache.onError(onError));
        shutdownFunctions.push(blobDescriptorCache.onUpdate(forceRender));

        return () => {
            for (const shutdownFunction of shutdownFunctions) {
                shutdownFunction();
            }
        };
    }, []);

    return blobDescriptorCache;
}
