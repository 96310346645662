import type {RefObject, UIEvent} from 'react';
import {useLayoutEffect, useState} from 'react';

/**
 * This handles automatic scrolling of lists.
 *
 * @param listRef - refernce to the list to scroll.
 * @param scrollToSameDistanceFromBottomTriggers - when one of those variable changes it ensures, that the scroll
 *                                                 position stays the same.
 * @param scrollToBottomTriggers - when one of those variable changes it ensures, that it is scrolled to the end.
 * @param onScrollToTop - callback is emitted when user scrolls to the top.
 */
export default function useListScroller(
    listRef: RefObject<HTMLUListElement>,
    scrollToSameDistanceFromBottomTriggers: unknown[],
    scrollToBottomTriggers: unknown[],
    onScrollToTop: () => void
): (event: UIEvent<HTMLUListElement>) => void {
    const [scrollProperties] = useState({
        scrollHeight: 0,
        scrollDistanceFromBottom: 0
    });

    /**
     * Scroll to the same distance from the bottom as before the change.
     *
     * Triggers
     * - When a new message arrived anywhere (messages)
     * - When a message content (listChildResizedToggle)
     */
    useLayoutEffect(() => {
        if (listRef.current !== null) {
            const el = listRef.current;
            el.scrollTop =
                el.scrollHeight - el.clientHeight - scrollProperties.scrollDistanceFromBottom;
        }
    }, [...scrollToSameDistanceFromBottomTriggers, scrollProperties, listRef]);

    /**
     * Scroll to bottom.
     *
     * Triggers
     * - When a new message arrived at the end. (newMessageArrivedAtEndToggle)
     *
     * Note that this useEffect will be called after the above useEffect, so a new message at the end will scroll to the
     * bottom.
     */
    useLayoutEffect(() => {
        if (listRef.current !== null) {
            const el = listRef.current;
            el.scrollTop = el.scrollHeight - el.clientHeight;
            scrollProperties.scrollDistanceFromBottom = 0;
        }
    }, [...scrollToBottomTriggers, scrollProperties, listRef]);

    /**
     * Loads more messages when the scroll reaches the top and recaluclates the scrolling distance relative to the
     * bottom.
     *
     * @param event
     */
    function scrollHandler(event: UIEvent<HTMLUListElement>) {
        const el = event.target as HTMLUListElement;

        if (scrollProperties.scrollHeight === el.scrollHeight) {
            // If the scrollHeight (vertical size of list content) stayed the same we need to update the
            // scrollDistanceFromBottom which will be used to adjust the scrolling position when content of the list
            // changed.
            // The scrollHeight stays the same when the user scrolls
            scrollProperties.scrollDistanceFromBottom =
                el.scrollHeight - el.clientHeight - el.scrollTop;
        } else {
            // If the scrollHeight changed, then we need to keep the distance fixed, because the list will scroll to
            // this value. We remember the new value so that on the next scroll event we can again detect if the
            // height changed.
            // The scrollHeight changes when the content changed.
            scrollProperties.scrollHeight = el.scrollHeight;
        }

        // If the user scrolled to the top we load the next batch of old messages.
        if (el.scrollTop === 0) {
            onScrollToTop();
        }
    }

    return scrollHandler;
}
