import type {ReactElement} from 'react';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';

import type {SHA256Hash} from '@refinio/one.core/lib/util/type-checks.js';
import BlobViewer from '@refinio/one.ui/lib/ui/components/blobViewer/BlobViewer.js';

import {useBlobDescriptor} from '@/root/chat/attachmentViews/blobDescriptor/hooks.js';
import getBase64 from '@/root/chat/utils/base64.js';

/**
 * Extends BlobViewer.
 * Loads blobDescriptor from hash.
 *
 * @param props.imageHash
 * @param props.onError
 * @returns
 */
export default function HashBlobViewer(props: {
    hash: SHA256Hash;
    onError: (error: Error) => void;
}): ReactElement {
    const blobDescriptor = useBlobDescriptor(props.hash, props.onError);

    if (!blobDescriptor) {
        return <CircularProgress />;
    }
    return <BlobViewer base64={getBase64(blobDescriptor.data)} mimeType={blobDescriptor.type} />;
}
