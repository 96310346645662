import React from 'react';
import type {ReactElement} from 'react';

import type {BlobDescriptor} from '@refinio/one.models/lib/models/BlobCollectionModel.js';

import {useBlobDescriptorUrl} from '@/root/chat/attachmentViews/blobDescriptor/hooks.js';
import BlobAttachmentDetails from '@/root/chat/attachmentViews/blobDescriptor/BlobAttachmentDetails.js';

/**
 * @param props.blobDescriptor
 * @param props.getCertificatePopupView callback to get certificate popup view
 * @returns
 */
export default function AudioAttachmentView(props: {
    blobDescriptor: BlobDescriptor;
    getCertificatePopupView: (onClose: () => void) => ReactElement;
}): ReactElement {
    const objUrl = useBlobDescriptorUrl(props.blobDescriptor);

    return (
        <div className="attachment-box audio-attachment">
            <audio controls>
                <source src={objUrl} type={props.blobDescriptor.type} />
            </audio>
            <BlobAttachmentDetails
                blobDescriptor={props.blobDescriptor}
                getCertificatePopupView={props.getCertificatePopupView}
            />
        </div>
    );
}
