import type {ReactElement} from 'react';
import {useContext, useEffect, useState, Fragment} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Divider from '@mui/material/Divider/Divider.js';

import type SomeoneModel from '@refinio/one.models/lib/models/Leute/SomeoneModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import type {InstanceListItem} from './InstanceListEntry.js';
import InstanceListEntry from './InstanceListEntry.js';

import './InstancesSettingsView.css';

export function useInstancesList(leuteModel: LeuteModel): [InstanceListItem[], InstanceListItem[]] {
    const [myInstancesSettingsList, setMyInstancesSettingsList] = useState<InstanceListItem[]>([]);

    const [othersInstancesSettingsList, setOthersInstancesSettingsList] = useState<
        InstanceListItem[]
    >([]);

    useEffect(() => {
        async function fetchInstances(): Promise<InstanceListItem[] | void> {
            const me = await leuteModel.me();
            const othersInstances = await leuteModel.others();

            const myInstanceSettingsList = [
                {
                    mainProfile: await me.mainProfile()
                }
            ];

            const othersInstanceSettingsList = await Promise.all(
                othersInstances.map(async (someone: SomeoneModel) => {
                    const mainProfile = await someone.mainProfile();
                    return {
                        mainProfile: mainProfile
                    };
                })
            );

            setMyInstancesSettingsList(myInstanceSettingsList);
            setOthersInstancesSettingsList(othersInstanceSettingsList);
        }

        fetchInstances().catch(console.error);
    }, [leuteModel]);

    return [myInstancesSettingsList, othersInstancesSettingsList];
}

/**
 * Settings UI view. Shows your local instances.
 */
export default function InstancesSettingsView(props: {leuteModel: LeuteModel}): ReactElement {
    const navigate = useNavigate();
    const {i18n} = useTranslation();

    const [myInstancesSettingsList, othersInstancesSettingsList] = useInstancesList(
        props.leuteModel
    );

    const appBarContext = useContext(AppBarContext);

    /**
     * Handler for pressing an instance settings
     * @param instanceSettings
     */
    function onInstanceSettingsPressed(instanceSettings: InstanceListItem) {
        appBarContext.setContextValue({
            ...appBarContext.contextValue,
            title: props.leuteModel.getPersonName(instanceSettings.mainProfile.personId)
        });
        navigate(`/settings/${instanceSettings.mainProfile.personId}`);
    }

    return (
        <div className="instance-settings-list-container">
            <div className="cnt-title">{i18n.t('settings.instances')}</div>
            {myInstancesSettingsList.map((instanceSettings, index) => (
                <Fragment key={index}>
                    <InstanceListEntry
                        leuteModel={props.leuteModel}
                        instance={instanceSettings}
                        onSelect={onInstanceSettingsPressed}
                    />
                    {index !== myInstancesSettingsList.length - 1 && <Divider variant={'middle'} />}
                </Fragment>
            ))}
            <div className="cnt-title">{i18n.t('settings.others')}</div>
            {othersInstancesSettingsList.map((instanceSettings, index) => (
                <Fragment key={index}>
                    <InstanceListEntry
                        leuteModel={props.leuteModel}
                        instance={instanceSettings}
                        onSelect={onInstanceSettingsPressed}
                    />
                    {index !== othersInstancesSettingsList.length - 1 && (
                        <Divider variant={'middle'} />
                    )}
                </Fragment>
            ))}
        </div>
    );
}
