import React, {useCallback, useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import type {OnClickActions} from '@paypal/paypal-js/types/components/buttons';

import type {LeuteModel, TopicModel} from '@refinio/one.models/lib/models';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks';
import type {Person} from '@refinio/one.core/lib/recipes';
import {shortenHash} from '@refinio/one.ui/lib/ui/views/utils/Utils';
import GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import CustomDialog from '@/components/customDialog/CustomDialog.js';
import PaypalButton from '@/components/one.euro/paypalButton/PaypalButton.js';
import './AddCouponsPopup.css';

/**
 * @param props.active Optional, recommended control from outside. default true. Should the popup show.
 */
export default function AddCouponsPopup(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    onClose?: () => void;
    active?: boolean;
}): ReactElement {
    const i18n = useTranslation();
    const [personId, setPersonId] = useState<SHA256IdHash<Person> | undefined>();
    const [quantity, setQuantity] = useState<number>();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    async function sendMessage(): Promise<void> {
        await props.leuteModel.trust.refreshCaches();
        const mainIdentity = await props.leuteModel.myMainIdentity();
        const leuteGroup =
            await GroupModel.constructFromLatestProfileVersionByGroupName('leuteReplicant');
        if (leuteGroup.persons.length !== 1) {
            throw Error('Unable to get replicant identity');
        }
        const replicantIdentity = leuteGroup.persons[0];
        const topicChannelId = [mainIdentity, replicantIdentity].sort().join('<->');
        await props.topicModel.createOneToOneTopic(mainIdentity, replicantIdentity);
        const topicRoom = await props.topicModel.enterTopicRoom(topicChannelId);
        await topicRoom.sendMessage(`Pressed PayPal button. Value ${quantity} €`);
    }

    async function onPayPalClick(
        _data: Record<string, unknown>,
        actions: OnClickActions
    ): Promise<void> {
        // validation
        if (personId === undefined) {
            setNotificationMessage(i18n.t('oneeuro.paypal.dialog.errors.noIdentity'));
            setNotificationType(NOTIFICATION.Error);
            return await actions.reject();
        }
        if (quantity === undefined || quantity <= 0) {
            setNotificationMessage(i18n.t('oneeuro.paypal.dialog.errors.noQuantity'));
            setNotificationType(NOTIFICATION.Error);
            return await actions.reject();
        }

        await sendMessage();
        return await actions.resolve();
    }

    if (props.active === false) {
        return <></>;
    }

    return (
        <CustomDialog
            className="add-coupons-popup"
            onClose={props.onClose}
            title={i18n.t('oneeuro.paypal.dialog.title')}
            content={
                <>
                    <IdentitySelector leuteModel={props.leuteModel} onSelect={setPersonId} />
                    <QuantityField onChange={setQuantity} />
                </>
            }
            action={
                <>
                    <PaypalButton
                        onClick={onPayPalClick}
                        dependencies={[quantity, personId, props.leuteModel, props.topicModel]}
                    />
                    <Button
                        className="paypal-btn-cancel"
                        variant="outlined"
                        onClick={props.onClose}
                    >
                        {i18n.t('buttons.common.cancel')}
                    </Button>
                </>
            }
        />
    );
}

/**
 * @param textFieldValue
 * @param decimalLengh
 * @returns
 */
function getTrimedDotValue(textFieldValue: string, decimalLengh: number): string {
    const dotValue = textFieldValue.replaceAll(',', '.');
    const dotIndex = dotValue.indexOf('.');

    if (decimalLengh <= 0) {
        return dotIndex > -1 ? dotValue.substring(0, dotIndex) : dotValue;
    }

    if (dotIndex > -1) {
        if (dotIndex < dotValue.length - (1 + decimalLengh)) {
            return dotValue.substring(0, dotIndex + (1 + decimalLengh));
        }
    }

    return dotValue;
}

/**
 *
 * @param props.onChange
 * @param props.decimalLengh Optional; Default 2
 * @param props.unit Optional; Default €
 * @returns
 */
function QuantityField(props: {
    onChange: (value: number) => void;
    decimalLengh?: number;
    unit?: string;
}): ReactElement {
    const unit = props.unit ? props.unit : '€';
    const decimalLengh = props.decimalLengh ? props.decimalLengh : 2;
    const i18n = useTranslation();
    const [value, setValue] = useState<string>('');

    function filter(textFieldValue: string): void {
        const trimedDotValue = getTrimedDotValue(textFieldValue, decimalLengh);
        const numberValue = Number(trimedDotValue);
        if (!isNaN(numberValue)) {
            // return comma if present
            if (textFieldValue.indexOf(',') > -1) {
                setValue(trimedDotValue.replaceAll('.', ','));
            } else {
                setValue(trimedDotValue);
            }
            props.onChange(numberValue);
        }
    }

    return (
        <FormControl variant="standard" sx={{minWidth: '100%'}}>
            <TextField
                variant="standard"
                value={value}
                multiline={false}
                label={i18n.t('oneeuro.paypal.dialog.quantity')}
                onChange={e => filter(e.target.value)}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{unit}</InputAdornment>
                }}
            />
        </FormControl>
    );
}

/**
 * @param props.leuteModel
 * @param props.default Optiona. Default 'main'.
 * @returns
 */
function IdentitySelector(props: {
    leuteModel: LeuteModel;
    onSelect: (identity: SHA256IdHash<Person>) => void;
    default?: 'main' | 'none' | SHA256IdHash<Person>;
}): ReactElement {
    const i18n = useTranslation();
    const [personIds, setPersonIds] = useState<SHA256IdHash<Person>[]>([]);
    const [personId, setPersonId] = useState<SHA256IdHash<Person> | undefined>(
        props.default !== 'main' && props.default !== 'none' ? props.default : undefined
    );

    useEffect(() => {
        async function getMyIdentities(): Promise<void> {
            const someone = await props.leuteModel.me();

            setPersonIds(someone.identities());
            if (props.default === undefined || props.default === 'main') {
                const main = await someone.mainIdentity();
                setPersonId(main);
                props.onSelect(main);
            }
        }

        getMyIdentities().catch(console.error);
        return props.leuteModel.onProfileUpdate(getMyIdentities);
    }, [props.leuteModel]);

    return (
        <FormControl variant="standard" sx={{minWidth: '100%'}}>
            <InputLabel className="select-standard-label">
                {i18n.t('oneeuro.paypal.dialog.personId')}
            </InputLabel>
            <Select
                value={personId === undefined ? '' : personId}
                onChange={e => {
                    const identity = e.target.value as SHA256IdHash<Person>;
                    setPersonId(identity);
                    props.onSelect(identity);
                }}
                label={i18n.t('oneeuro.paypal.dialog.personId')}
            >
                {personIds.map(personIdOption => (
                    <MenuItem key={personIdOption} value={personIdOption}>
                        {`${props.leuteModel.getPersonName(personIdOption)} (${shortenHash(
                            personIdOption
                        )})`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
