import {useTheme} from '@mui/material/styles';
import {useState, type ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {TextField} from '@mui/material';

import type DiaryModel from '@refinio/one.models/lib/models/DiaryModel.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {useNavigateBack} from '@/hooks/navigation.js';
import '../Diary.css';

export default function ViewDiaryEntry(props: {
    diaryModel: DiaryModel;
    id?: string;
    onClose?: () => void | Promise<void>;
}): ReactElement {
    const i18n = useTranslation();
    const [entry, setEntry] = useState<string>('');
    const goBack = useNavigateBack();
    const {id} = useParams<{id: string}>();
    const theme = useTheme();

    useEffect(() => {
        async function getEntry(): Promise<void> {
            if (!id && !props.id) {
                return;
            }

            const tempId = props.id ? props.id : id ? id : 'error';
            const dataObject = await props.diaryModel.getEntryById(tempId);
            setEntry(dataObject.data);
        }

        getEntry().catch(console.error);
        return props.diaryModel.onUpdated(getEntry);
    }, [id, props.diaryModel]);

    return (
        <AppBarFullScreenPopup
            mode={APP_BAR_MODE.CHEVRON}
            title={i18n.t('diary.entry')}
            onClose={props.onClose ? props.onClose : goBack}
            className="diary-container"
            background={theme.palette.background.default}
        >
            <TextField
                value={entry}
                className="diary-entry-view"
                multiline={true}
                disabled={true}
            />
        </AppBarFullScreenPopup>
    );
}
