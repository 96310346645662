import type TrustedKeysManager from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';

import type {
    Issuer,
    TrustObject,
    CertificateSetting,
    CertificateSettingCreate,
    SettingIconType
} from './types.js';
import {GREEN_CHECK_MARK_BUTTON, WAVING_HAND} from '@/components/Constants.js';
import type {CertificateData} from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';
import type {ReactElement} from 'react';

/**
 *
 * @param trustObject
 * @param certificateSettings
 * @returns
 */
export async function getAppliableCertificateUniqueIds(
    trustObject: TrustObject,
    certificateSettings: CertificateSetting[]
): Promise<string[]> {
    const canApply: string[] = [];

    for (const certificateSetting of certificateSettings) {
        if (certificateSetting.type === trustObject.type) {
            if (
                certificateSetting.oneType === undefined ||
                ((trustObject.type === 'Object' || trustObject.type === 'IdObject') &&
                    certificateSetting.oneType === trustObject.obj.$type$)
            ) {
                if (
                    certificateSetting.isCreatable === undefined ||
                    (await certificateSetting.isCreatable(trustObject as never))
                ) {
                    canApply.push(certificateSetting.settingUniqueId);
                    continue;
                }
            }
        }
        if (certificateSetting.oneType === '*') {
            if (
                certificateSetting.isCreatable === undefined ||
                (await certificateSetting.isCreatable(trustObject as never))
            ) {
                canApply.push(certificateSetting.settingUniqueId);
                continue;
            }
        }
    }

    return canApply;
}

/**
 * @param issuerType
 * @returns undefined meaning current instance owner or idHash of person
 */
function getIssuer(issuerType: Issuer): SHA256IdHash<Person> | undefined {
    // for future
    switch (issuerType) {
        default:
            return undefined;
    }
}

/**
 *
 * @param setting
 * @param trustObject
 * @param certificateData
 * @returns
 */
export async function getIcon(
    setting: CertificateSetting,
    trustObject: TrustObject,
    certificateData: CertificateData
): Promise<SettingIconType> {
    if (typeof setting.icon === 'string') {
        return setting.icon;
    }
    if (typeof setting.icon === 'function') {
        if (trustObject) {
            return await setting.icon(trustObject as never, certificateData);
        } else {
            return undefined;
        }
    }
    return setting.icon;
}

/**
 *
 * @param certificateSettings
 * @param trustObject  Optional. Default undefined which means it does not check for it
 * @param search.settingUniqueId Optional. Default undefined which means it does not check for it
 * @returns
 */
export function getCertificateSetting(
    certificateSettings: CertificateSetting[],
    search: {
        appliableToTrustObject?: TrustObject;
        settingUniqueId?: string;
    }
): CertificateSetting | undefined {
    return certificateSettings.find(c => {
        if (search.settingUniqueId) {
            return c.settingUniqueId === search.settingUniqueId;
        } else if (search.appliableToTrustObject !== undefined) {
            if (c.oneType !== '*') {
                return (
                    c.type === search.appliableToTrustObject.type &&
                    (c.oneType === undefined ||
                        ((search.appliableToTrustObject.type === 'Object' ||
                            search.appliableToTrustObject.type === 'IdObject') &&
                            search.appliableToTrustObject.obj.$type$ === c.oneType))
                );
            }
        }

        if (c.oneType === '*') {
            return true;
        }

        return false;
    });
}

/**
 *
 * @param certificateSettings
 * @param trustObject
 * @param settingUniqueId
 * @returns
 */
export function getCertificateType(
    certificateSettings: CertificateSetting[],
    settingUniqueId: string | undefined = undefined
): CertificateSetting | undefined {
    return certificateSettings.find(c => c.settingUniqueId === settingUniqueId);
}

/**
 * @param certificateSetting
 * @param trustObject can be obtained by getTrustObject(hash / idhash)
 * @param issuerType
 */
export async function createCertificate(
    certificateSetting: CertificateSetting,
    trustObject: TrustObject,
    issuerType: Issuer
) {
    const issuer = getIssuer(issuerType);
    if (certificateSetting) {
        await (certificateSetting.create as CertificateSettingCreate<TrustObject>)(
            trustObject,
            issuer
        );
    }
}

/**
 *
 * @param trustedKeysManager
 * @returns
 */
export function getAffirmationCertificateSettings(
    trustedKeysManager: TrustedKeysManager
): CertificateSetting {
    return {
        oneType: '*',
        settingUniqueId: 'AffirmationCertificate',
        certificateRecipeName: 'AffirmationCertificate',
        create: async (trustObject: TrustObject, issuer?: SHA256IdHash<Person>) =>
            await trustedKeysManager.affirm(trustObject.hash as SHA256Hash, issuer),
        icon: GREEN_CHECK_MARK_BUTTON
    };
}

/**
 *
 * @param trustedKeysManager
 * @returns
 */
export function getTrustKeysCertificateSettings(
    trustedKeysManager: TrustedKeysManager
): CertificateSetting {
    return {
        oneType: 'Profile',
        type: 'Object',
        settingUniqueId: 'TrustKeysCertificate',
        certificateRecipeName: 'TrustKeysCertificate',
        create: async (trustObject, issuer) =>
            await trustedKeysManager.certify(
                'TrustKeysCertificate',
                {
                    profile: trustObject.hash as unknown as SHA256Hash<Profile>
                },
                issuer
            )
    };
}

/**
 *
 * @param trustedKeysManager
 * @returns
 */
export function getRightToDeclareTrustedKeysForEverybodyCertificateSettings(
    trustedKeysManager: TrustedKeysManager
): CertificateSetting {
    return {
        oneType: 'Person',
        type: 'IdObject',
        settingUniqueId: 'RightToDeclareTrustedKeysForEverybodyCertificate',
        certificateRecipeName: 'RightToDeclareTrustedKeysForEverybodyCertificate',
        create: async (trustObject, issuer) =>
            await trustedKeysManager.certify(
                'RightToDeclareTrustedKeysForEverybodyCertificate',
                {
                    beneficiary: trustObject.hash as unknown as SHA256IdHash<Person>
                },
                issuer
            )
    };
}

/**
 *
 * @param trustedKeysManager
 * @returns
 */
export function getRightToDeclareTrustedKeysForSelfCertificateSettings(
    trustedKeysManager: TrustedKeysManager
): CertificateSetting {
    return {
        oneType: 'Person',
        type: 'IdObject',
        settingUniqueId: 'RightToDeclareTrustedKeysForSelfCertificate',
        certificateRecipeName: 'RightToDeclareTrustedKeysForSelfCertificate',
        create: async (trustObject, issuer) =>
            await trustedKeysManager.certify(
                'RightToDeclareTrustedKeysForSelfCertificate',
                {
                    beneficiary: trustObject.hash as unknown as SHA256IdHash<Person>
                },
                issuer
            )
    };
}

/**
 *
 * @param trustedKeysManager
 * @returns
 */
export function getSignupCertificateSettings(
    trustedKeysManager: TrustedKeysManager
): CertificateSetting {
    return {
        oneType: 'Person',
        type: 'IdObject',
        certificateRecipeName: 'SignupCertificate',
        settingUniqueId: 'SignupCertificate',
        create: async (trustObject, issuer) =>
            await trustedKeysManager.certify(
                'SignupCertificate',
                {
                    person: trustObject.hash as unknown as SHA256IdHash<Person>
                },
                issuer
            ),
        icon: GREEN_CHECK_MARK_BUTTON
    };
}

/**
 *
 * @param trustedKeysManager
 * @returns
 */
export function getDefaultCertificateSettings(
    trustedKeysManager: TrustedKeysManager
): CertificateSetting[] {
    return [
        getAffirmationCertificateSettings(trustedKeysManager),
        getTrustKeysCertificateSettings(trustedKeysManager),
        getRightToDeclareTrustedKeysForEverybodyCertificateSettings(trustedKeysManager),
        getRightToDeclareTrustedKeysForSelfCertificateSettings(trustedKeysManager),
        getSignupCertificateSettings(trustedKeysManager)
    ];
}

export const NotSignedIcon = WAVING_HAND;
