import type {ReactElement} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import ReactMarkdown from 'react-markdown';
import MarkdownLinkTemplate from './MarkdownLinkTemplate';

/**
 * @param props.url
 * @returns
 */
export default function Markdown(props: {
    url: string;
    className?: string;
    onError?: (error: Error) => void;
}): ReactElement {
    const [mdContent, setMdContent] = useState<string | undefined>(undefined);
    const onError = props.onError ? props.onError : console.error;

    useEffect(() => {
        fetch(props.url)
            .then(res => res.text())
            .then(text => setMdContent(text))
            .catch(onError);
    }, [props.url]);

    if (!mdContent) {
        return <></>;
    }

    return (
        <ReactMarkdown
            components={{
                a: MarkdownLinkTemplate
            }}
            className={`content-container${props.className ? ` ${props.className}` : ''}`}
        >
            {mdContent}
        </ReactMarkdown>
    );
}
