import type {ReactElement, SyntheticEvent} from 'react';

import ListElement from '@refinio/one.ui/lib/ui/components/list/ListElement.js';

import Checkbox from '@mui/material/Checkbox/Checkbox.js';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel.js';

import AvatarPreview from '@/components/avatarPreview/AvatarPreview.js';
import {GREEN_CHECK_MARK_BUTTON} from '@/components/Constants.js';

import './SelectableChatListElement.css';

export default function SelectableChatListElement(props: {
    imgURL: string | undefined;
    name: string;
    onChange: (event: SyntheticEvent<Element, Event>, isChecked: boolean) => void;
    isChecked?: boolean;
    isSigned?: boolean;
}): ReactElement {
    return (
        <ListElement
            extra={
                <>
                    <AvatarPreview src={props.imgURL} />
                    <FormControlLabel
                        className="selectable-member"
                        control={<Checkbox color="default" checked={!!props.isChecked} />}
                        label={
                            <>
                                {props.name}
                                <div className="trusted">
                                    {props.isSigned && GREEN_CHECK_MARK_BUTTON}
                                </div>
                            </>
                        }
                        labelPlacement="start"
                        onChange={props.onChange}
                        color="primary"
                    />
                </>
            }
        />
    );
}
