import {useTranslation} from 'react-i18next';

import Modal from '@mui/material/Modal/Modal.js';
import Typography from '@mui/material/Typography/Typography.js';
import Button from '@mui/material/Button/Button.js';
import Box from '@mui/material/Box/Box.js';

import './DeleteInstanceModal.css';

/**
 * Delete instance pop-up.
 * @param props
 * @constructor
 */
export default function DeleteInstanceModal(props: {
    open: boolean;
    handleClose: () => void;
    handleDelete: () => Promise<void>;
}) {
    const {i18n} = useTranslation();

    return (
        <Modal
            className={'modal-container'}
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={'box-container'}>
                <Typography
                    className={'modal-title'}
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                >
                    {i18n.t('settings.deleteModal.title')}
                </Typography>
                <Typography id="modal-modal-description" sx={{mt: 2}}>
                    {i18n.t('settings.deleteModal.description')}
                </Typography>
                <div className={'modal-actions-container'}>
                    <Button
                        onClick={props.handleClose}
                        className={'instance-button'}
                        variant="outlined"
                        size="small"
                    >
                        {' '}
                        {i18n.t('buttons.common.cancel')}
                    </Button>
                    <div style={{width: '20px'}}> </div>
                    <Button
                        onClick={props.handleDelete}
                        color="error"
                        className={'instance-button'}
                        variant="outlined"
                        size="small"
                    >
                        {' '}
                        {i18n.t('buttons.common.delete')}
                    </Button>
                </div>
            </Box>
        </Modal>
    );
}
