import {useLocation, useNavigate} from 'react-router-dom';

let locationStack: string[] = [];

/**
 * This interface represents a stack of locations that are remembered, so that you can later return.
 *
 * @see useLocationStackNavigator for further information.
 */
export interface LocationStackNavigator {
    /**
     * Navigates to a new location and remembers the current one, so that you can return to it.
     *
     * The current location is pushed to an internal (and global) location stack.
     *
     * @param to - The location to navigate to
     */
    pushCurrentLocationAndNavigate(to: string): void;

    /**
     * Return to the location that was pushed last.
     *
     * If no location is on the stack, this will do nothing.
     */
    popLocationAndNavigate(): void;

    /**
     * Pops the topmost location from the location stack without navigating.
     */
    popLocation(): string | undefined;

    /**
     * Clears all locations from the stack.
     */
    clearLocations(): void;

    /**
     * Returns true if stack is empty
     */
    empty: boolean;

    /**
     * Returns the number of locations that are currently on the stack
     */
    size: number;
}

/**
 * Allows you to navigate to multiple locations and remember where you came from, so that you can return later.
 *
 * It is a hook, because it needs to access useNavigate and useLocation.
 *
 * Example - consider you are currently at the page "/page1" of your app, and you want to temporarily navigate away to
 * "/page2" that also has navigation links.
 * If you then want to return to page1 later by clicking on a 'back' button that is visible on all pages, then you can
 * do this:
 *
 * In "/page1" instead of using
 *   const navigate = useNavigate();
 *   navigate('/page2');
 * you would use
 *   const stackNavigate = useLocationStackNavigator();
 *   stackNavigate.pushCurrentLocationAndNavigate('/page2');
 * Your back button can then simply call
 *   stackNavigate.popLocationAndNavigate and you are again on '/page1'.
 */
export function useLocationStackNavigator(): LocationStackNavigator {
    const navigate = useNavigate();
    const location = useLocation();

    return {
        pushCurrentLocationAndNavigate(to: string) {
            locationStack.push(location.pathname);
            navigate(to);
        },

        popLocationAndNavigate() {
            const memorizedLocation = locationStack.pop();

            if (memorizedLocation !== undefined) {
                navigate(memorizedLocation);
            }
        },

        popLocation(): string | undefined {
            return locationStack.pop();
        },

        clearLocations(): void {
            locationStack = [];
        },

        get empty() {
            return locationStack.length === 0;
        },

        get size() {
            return locationStack.length;
        }
    };
}
