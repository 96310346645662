import type {ReactElement} from 'react';
import {Route, Routes} from 'react-router-dom';

import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';

import EQ5D3LQuestionnaire from '@/components/EQ5D3LQuestionnaire/EQ5D3LQuestionnaire.js';
import QuestionnaireDisplay from './QuestionnaireDisplay.js';
import QuestionnaireDisplayOwnerAdapter from './QuestionnaireDisplayOwnerAdapter.js';

export function QuestionnaireRouter(props: {questionnaireModel: QuestionnaireModel}): ReactElement {
    return (
        <Routes>
            <Route
                path="EQ5D3LQuestionnaire/:action"
                element={<EQ5D3LQuestionnaire questionnaireModel={props.questionnaireModel} />}
            />
            <Route
                path="EQ5D3LQuestionnaire/:owner/:action"
                element={<EQ5D3LQuestionnaire questionnaireModel={props.questionnaireModel} />}
            />
            <Route
                path=":owner/:action"
                element={
                    <QuestionnaireDisplayOwnerAdapter
                        questionnaireModel={props.questionnaireModel}
                    />
                }
            />
            <Route
                path=":action"
                element={<QuestionnaireDisplay questionnaireModel={props.questionnaireModel} />}
            />
        </Routes>
    );
}
