import {useContext, useEffect} from 'react';

import type {AppBarData} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {APP_BAR_MODE, AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

/**
 * Used to switch the app bar mode to edit mode.
 */
export function useEditMode(
    onClose: () => void,
    onDone: () => Promise<void>,
    title: string = 'common.edit'
): void {
    const appBarContext = useContext(AppBarContext);

    // at the first render update the app bar to edit mode
    // and register the onDone and onClose callbacks
    useEffect(() => {
        const old = appBarContext.contextValue;
        appBarContext.setContextValue({
            ...appBarContext.contextValue,
            hide: false,
            mode: APP_BAR_MODE.EDIT,
            title: title,
            leftBtnCallback: onClose,
            rightBtnCallback: onDone
        });
        return () => {
            appBarContext.setContextValue({...old, hide: false});
        };
        // mount only
    }, []);
}

export function useForceHidenAppBar(unmountValue?: Partial<AppBarData>): void {
    const {
        setContextValue,
        contextValue: {hide}
    } = useContext(AppBarContext);

    useEffect(() => {
        const oldValue = hide;
        return () => {
            if (unmountValue === undefined) {
                setContextValue(contextValue => ({...contextValue, hide: oldValue}));
            } else {
                setContextValue(contextValue => ({
                    ...contextValue,
                    hide: oldValue,
                    ...unmountValue
                }));
            }
        };
        // mount only
    }, []);

    useEffect(() => {
        if (!hide) {
            setContextValue(contextValue => ({...contextValue, hide: true}));
        }
    }, [hide, setContextValue]);
}

export function useChevronAppBar(onClose: () => void, title?: string): void {
    const appBarContext = useContext(AppBarContext);

    // at the first render update the app bar to chevron mode
    // and register the onClose callbacks
    useEffect(() => {
        const old = appBarContext.contextValue;
        appBarContext.setContextValue({
            ...appBarContext.contextValue,
            hide: false,
            mode: APP_BAR_MODE.CHEVRON,
            title: title ? title : appBarContext.contextValue.title,
            leftBtnCallback: onClose
        });
        return () => {
            appBarContext.setContextValue({...old, hide: false});
        };
        // mount only
    }, []);
}
