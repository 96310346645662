export default function getBase64(data: ArrayBuffer) {
    // raw binary segments in string format
    let binary = '';
    // ArrayBuffer to typed array represents an array of 8-bit unsigned integers
    const bytes = new Uint8Array(data);

    for (let i = 0; i < bytes.byteLength; i++) {
        // Convert the raw binary segments to the appropriate ASCII encoding
        binary += String.fromCharCode(bytes[i]);
    }
    // window.btoa encodes raw binary segments in string format to base-64.
    return window.btoa(binary);
}
