import type {ReactElement} from 'react';
import {useCallback} from 'react';

import ContactCard from '@/components/card/ContactCard.js';
import {useUpdateOnUploadedImage} from '@/hooks/files/upload.js';
import type {StatusInformation} from '@/root/profile/profileId/status/create/SomeoneProfileStatus.js';
import {STATUS_INFORMATION} from '@/root/profile/profileId/status/create/SomeoneProfileStatus.js';
import InstanceInformation from './InstanceInformation.js';
import type {ProfileDetails, CommunicationEndpoints} from './types.js';
import StatusSection from './StatusSection.js';
import CommunicationEndpointsView from './CommunicationEndpointsView.js';
import SendMessage from './SendMessage.js';
import PersonName from './PersonName.js';
import ProfileAvatar from './ProfileAvatar.js';
import OrganisationName from './OrganisationName.js';
import './SomeoneProfileDetails.css';

/**
 * Represents the card which displays the details of a profile.
 * @param props - the properties of the view.
 * @param props.profileDetails - all the information of a profile.
 * @param props.updateProfileDetails - callback which updates the profile information.
 *        - Is optional because when the component is used in view mode the callback is not needed - the user is nto able to edit anything.
 * @param props.title - used to display the title of the view. Optional because when we want to display more profiles then
 *        - the title should be provided just for the first card within the view.
 * @param props.onAddStatus callback on adding status to the profile
 * @param props.instanceInformationAddon
 * @param props.sendMessageElement
 * @param props.hideStatus flag to hide profile status in view mode
 * @param props.hideCommunicationEndpoints flag to hide communication endpoints lists
 * @param props.bellowNameAddon Element placed right bellow name field
 */
export default function SomeoneProfileDetails(props: {
    profileDetails: ProfileDetails;
    updateProfileDetails?: (profileDetails: ProfileDetails) => void;
    title?: string;
    onAddStatus?: (statusData: StatusInformation[]) => void;
    instanceInformationAddon?: ReactElement;
    sendMessageElement?: ReactElement;
    hideStatus?: boolean;
    bellowNameAddon?: ReactElement;
    hideCommunicationEndpoints?: boolean;
    showOrganisationName?: boolean;
}): ReactElement {
    const {profileDetails, updateProfileDetails} = props;
    const isViewMode = updateProfileDetails === undefined;

    const onUploaded = useCallback(
        (image: ArrayBuffer) => {
            if (updateProfileDetails && profileDetails.avatar !== image) {
                updateProfileDetails({
                    ...profileDetails,
                    avatar: image
                });
            }
        },
        [profileDetails, updateProfileDetails]
    );
    useUpdateOnUploadedImage(onUploaded);

    /**
     * Adds empty endpoint
     */
    function addEndpoint(type: (typeof CommunicationEndpoints)[number]): void {
        if (isViewMode || type === 'oneInstanceEndpoint') {
            return;
        }

        let newValues = [''];
        const oldValues = profileDetails[type];
        if (oldValues !== undefined) {
            newValues = [...oldValues];
            newValues.push('');
        }

        updateProfileDetails({
            ...profileDetails,
            [type]: newValues
        });
    }

    /**
     * Used to compose an array which represents what kind of data should be displayed in the add status view.
     * @param selectedStatus - selected status.
     */
    function addStatusInformation(selectedStatus: StatusInformation): void {
        if (props.onAddStatus === undefined) {
            return;
        }
        const statusInformation: StatusInformation[] = [
            STATUS_INFORMATION.LOCATION,
            selectedStatus
        ];
        props.onAddStatus(statusInformation);
    }

    function setEndpoint(
        name: (typeof CommunicationEndpoints)[number],
        index: number,
        value: string
    ) {
        if (!isViewMode) {
            const detail = profileDetails[name];
            if (detail) {
                const newValue = [...detail];
                newValue[index] = value;
                updateProfileDetails({
                    ...profileDetails,
                    [name]: newValue
                });
            }
        }
    }

    return (
        <div className={`cnt-someone-details ${!isViewMode ? 'position-absolute' : ''}`}>
            {props.title && <div className="cnt-title">{props.title}</div>}
            <ContactCard
                content={
                    <div className={` ${isViewMode ? 'disabled' : ''}`}>
                        <ProfileAvatar viewMode={isViewMode} avatar={profileDetails.avatar} />
                        <PersonName
                            name={profileDetails.name}
                            onChange={name => {
                                if (!isViewMode) {
                                    updateProfileDetails({
                                        ...profileDetails,
                                        name: name
                                    });
                                }
                            }}
                            disableUnderline={isViewMode}
                        />

                        {props.bellowNameAddon}

                        {props.profileDetails.organisationName !== undefined && (
                            <OrganisationName
                                name={props.profileDetails.organisationName}
                                onChange={
                                    isViewMode
                                        ? undefined
                                        : (organisationName: string) => {
                                              if (!isViewMode) {
                                                  updateProfileDetails({
                                                      ...profileDetails,
                                                      organisationName: organisationName
                                                  });
                                              }
                                          }
                                }
                                disableUnderline={isViewMode}
                            />
                        )}

                        {isViewMode && (
                            <InstanceInformation
                                personEmail={profileDetails.personEmail}
                                owner={profileDetails.owner}
                                ownerName={profileDetails.ownerName}
                                disableUnderlines={isViewMode}
                            >
                                {props.instanceInformationAddon}
                            </InstanceInformation>
                        )}

                        {isViewMode && props.sendMessageElement && (
                            <SendMessage>{props.sendMessageElement}</SendMessage>
                        )}
                    </div>
                }
            />
            {props.hideCommunicationEndpoints ? (
                ''
            ) : (
                <CommunicationEndpointsView
                    endpoints={profileDetails}
                    set={setEndpoint}
                    add={addEndpoint}
                    isViewMode={isViewMode}
                />
            )}
            {isViewMode && !props.hideStatus && (
                <StatusSection
                    status={profileDetails.status}
                    addStatusInformation={addStatusInformation}
                />
            )}
        </div>
    );
}
