import {fetchFile} from '@refinio/one.core/lib/system/fetch-file.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Group} from '@refinio/one.core/lib/recipes.js';
import {calculateIdHashOfObj} from '@refinio/one.core/lib/util/object.js';
import {exists} from '@refinio/one.core/lib/system/storage-base.js';
import {isObject, isString} from '@refinio/one.core/lib/util/type-checks-basic.js';
import {storeBase64StringAsBlob} from '@refinio/one.core/lib/storage-blob.js';
import type {Identity} from '@refinio/one.models/lib/misc/IdentityExchange.js';
import {readIdentityFile} from '@refinio/one.models/lib/misc/identityExchange-browser.js';
import type {PersonDescriptionTypes} from '@refinio/one.models/lib/recipes/Leute/PersonDescriptions.js';
import {convertIdentityToProfile} from '@refinio/one.models/lib/misc/IdentityExchange.js';
import GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import oneReplicantImageBase64 from './resources/base64Images/oneReplicant.js';

export type InitialIopPeerInfo = {
    identityFile: string;
    identity: Identity; // Will be set automatically on loading
    initialName?: string;
    group?: string;
};

type Config = {
    app: string;
    commServerUrl: string;
    initialIopPeers: InitialIopPeerInfo[];
    enableLogging: boolean;
    logTypes: ('error' | 'alert' | 'log' | 'debug')[];
};

/**
 * Check that the passed object conforms to the config type.
 *
 * @param arg
 */
export function isConfig(arg: unknown): arg is Config {
    const validLogTypes = ['error', 'alert', 'log', 'debug'];

    if (!isObject(arg)) {
        console.error('isConfig: arg is not an object');
        return false;
    }

    if (typeof arg.app !== 'string') {
        console.error('isConfig: app is not a string');
        return false;
    }

    if (typeof arg.commServerUrl !== 'string') {
        console.error('isConfig: commServerUrl is not a string');
        return false;
    }

    if (!Array.isArray(arg.initialIopPeers)) {
        console.error('isConfig: initialIopPeers is not an array');
        return false;
    }

    for (const iopPeer of arg.initialIopPeers) {
        if (!isObject(iopPeer)) {
            console.error('isConfig: Array element of initialIopPeers is not an object');
            return false;
        }

        if (!isString(iopPeer.identityFile)) {
            console.error('isConfig: initialIopPeers.identityFile is not a string');
            return false;
        }

        if (iopPeer.initialName !== undefined && typeof iopPeer.initialName !== 'string') {
            console.error('isConfig: initialIopPeers.initialName is defined but is not a string');
            return false;
        }

        if (iopPeer.group !== undefined && typeof iopPeer.group !== 'string') {
            console.error('isConfig: initialIopPeers.group is defined but is not a string');
            return false;
        }
    }

    if (typeof arg.enableLogging !== 'boolean') {
        console.error('isConfig: enableLogging is not a boolean');
        return false;
    }

    if (!Array.isArray(arg.logTypes)) {
        console.error('isConfig: logTypes is not an array');
        return false;
    }

    if (!arg.logTypes.every(logLevel => isString(logLevel) && validLogTypes.includes(logLevel))) {
        console.error('isConfig: logTypes contains unexpected value');
        return false;
    }

    return true;
}

/**
 * Load a config file.
 *
 * @param url - A url to a remote location. If relative, it is relative to the loaded app.
 */
export async function loadConfig(url: string): Promise<Config> {
    const config: unknown = JSON.parse(await fetchFile(url));

    if (!isConfig(config)) {
        throw new Error('Format of config file is wrong.');
    }

    // Read identity files for initial IoP peers
    for (const iopPeer of config.initialIopPeers) {
        const identity = await readIdentityFile(iopPeer.identityFile);
        iopPeer.identity = identity;

        if (identity.url && identity.url.endsWith('.dev.refinio.one')) {
            console.warn('You are using an initial iop peer that uses a development url');
        }
    }

    // Print developer warning.
    // It assumes, that the dev config is commited which has the dev commserver in it.
    if (config.commServerUrl.endsWith('.dev.refinio.one')) {
        console.warn('You are running a build with the development com server!');
    }

    return config;
}

/**
 * Adds an IoP peer to the instance by creating a matching profile.
 *
 * @param leute
 * @param iopPeer
 */
export async function addIopPeerToLeute(
    leute: LeuteModel,
    iopPeer: InitialIopPeerInfo
): Promise<void> {
    const blobImage = await storeBase64StringAsBlob(oneReplicantImageBase64);

    const personDescriptions: PersonDescriptionTypes[] = [
        {
            $type$: 'ProfileImage',
            image: blobImage.hash
        }
    ];

    if (iopPeer.initialName) {
        personDescriptions.push({
            $type$: 'PersonName',
            name: iopPeer.initialName
        });
    }

    const profile = await convertIdentityToProfile(
        iopPeer.identity,
        'default',
        await leute.myMainIdentity(),
        [],
        personDescriptions
    );

    if (profile.loadedVersion === undefined) {
        throw new Error('Should not happen: saved profile has no hash');
    }

    await leute.trust.certify('TrustKeysCertificate', {
        profile: profile.loadedVersion
    });

    if (iopPeer.group) {
        const replicantGroup = await GroupModel.constructWithNewGroup(iopPeer.group);
        if (!replicantGroup.persons.includes(profile.personId)) {
            replicantGroup.persons.push(profile.personId);
            await replicantGroup.saveAndLoad();
        }
    }

    await leute.addProfile(profile.idHash);
}

export async function getGroupHash(groupName: string): Promise<SHA256IdHash<Group> | undefined> {
    const groupHash = await calculateIdHashOfObj({$type$: 'Group', name: groupName});
    if (await exists(groupHash)) {
        return groupHash;
    }
}
