import {createContext, useContext} from 'react';

import type {PopoverOrigin} from '@mui/material/Popover/Popover.js';

import type {PopupMenuEntry} from '@refinio/one.ui/lib/ui/components/popupMenu/PopupMenu.js';

export type MenuContent = {
    setMenuEntries: (entries: PopupMenuEntry[]) => void;
    selectMenuEntry: (behaviour: (selectedEntry: string) => void) => void;
    setMenuReference: (reference: any) => void;
    isOpen: (open: boolean) => void;
    setMenuClassName: (name: string) => void;
    setMenuAnchorOrigin: (anchorOrigin: PopoverOrigin | undefined) => void;
};

export const MenuContext = createContext<MenuContent>({
    setMenuEntries: () => {},
    selectMenuEntry: () => {},
    setMenuReference: () => {},
    isOpen: () => {},
    setMenuClassName: () => {},
    setMenuAnchorOrigin: () => {}
});

/**
 * Forward the popup menu context.
 */
export function useMenuContext(): MenuContent {
    return useContext(MenuContext);
}
