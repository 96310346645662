import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const ResClueQuestionnaire: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/resclue.one',
    name: 'resclue',
    title: 'resclue.one',
    status: 'active',
    item: [
        {
            type: 'group',
            linkId: '1',
            prefix: '',
            required: false,
            text: '',
            disabledDisplay: 'protected',
            item: [
                {
                    type: 'string',
                    linkId: '2',
                    prefix: 'surname',
                    required: false,
                    text: 'Name',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: '3',
                    prefix: 'name',
                    required: false,
                    text: 'Vorname(n)',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'date',
                    linkId: '4',
                    prefix: 'birthdate',
                    required: false,
                    text: 'Geburtsdatum',
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            type: 'group',
            linkId: '5',
            prefix: '',
            required: false,
            text: '',
            disabledDisplay: 'protected',
            item: [
                {
                    type: 'string',
                    linkId: '6',
                    prefix: 'street_patient',
                    required: false,
                    text: 'Straße',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: '7',
                    prefix: 'housenumber_patient',
                    required: false,
                    text: 'Hausnummer',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: '8',
                    prefix: 'site_patient',
                    required: false,
                    text: 'PLZ/Ort',
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            type: 'group',
            linkId: '9',
            prefix: '',
            required: false,
            text: '',
            disabledDisplay: 'protected',
            item: [
                {
                    type: 'string',
                    linkId: '10',
                    prefix: 'nationality',
                    required: false,
                    text: 'Nationalität',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'choice',
                    linkId: '11',
                    prefix: 'gender',
                    required: false,
                    text: 'Geschlecht',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Männlich',
                                system: 'http://refinio.one/resclue/valueCoding/gender'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Weiblich',
                                system: 'http://refinio.one/resclue/valueCoding/gender'
                            }
                        }
                    ],
                    disabledDisplay: 'protected'
                },
                {
                    type: 'integer',
                    linkId: '12',
                    prefix: 'weight',
                    required: false,
                    text: 'Gewicht',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'integer',
                    linkId: '13',
                    prefix: 'size',
                    required: false,
                    text: 'Größe',
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            type: 'group',
            linkId: '14',
            prefix: '',
            required: false,
            text: 'Versicherung',
            disabledDisplay: 'protected',
            item: [
                {
                    type: 'string',
                    linkId: '15',
                    prefix: 'costunit',
                    required: false,
                    text: 'Kostenträger',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: '16',
                    prefix: 'insurancenumber',
                    required: false,
                    text: 'Versicherungsnummer',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: '17',
                    prefix: 'resclueid',
                    required: false,
                    text: 'Resclue-ID',
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            type: 'string',
            linkId: '18',
            prefix: 'preconditions',
            required: false,
            text: 'Vorerkrankungen',
            disabledDisplay: 'protected'
        },
        {
            type: 'string',
            linkId: '19',
            prefix: 'medication',
            required: false,
            text: 'Medikation',
            disabledDisplay: 'protected'
        },
        {
            type: 'string',
            linkId: '20',
            prefix: 'allergies',
            required: false,
            text: 'Allergien',
            disabledDisplay: 'protected'
        },
        {
            type: 'string',
            linkId: '21',
            prefix: 'bloodtype',
            required: false,
            text: 'Blutgruppe',
            disabledDisplay: 'protected'
        },
        {
            type: 'group',
            linkId: '22',
            prefix: '',
            required: false,
            text: 'Gesetzliche Betreuung',
            disabledDisplay: 'protected',
            item: [
                {
                    type: 'string',
                    linkId: '23',
                    prefix: 'surname_guardianship_1',
                    required: false,
                    text: 'Name',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: '24',
                    prefix: 'name_guardianship_1',
                    required: false,
                    text: 'Vorname(n)',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: '25',
                    prefix: 'phonenumber_guardianship_1',
                    required: false,
                    text: 'Telefonnummer',
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            type: 'choice',
            linkId: '26',
            prefix: 'patientsdirective',
            required: false,
            text: 'Patientenverfügung/VV',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ja',
                        system: 'http://refinio.one/resclue/valueCoding/YesNo'
                    }
                },
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Nein',
                        system: 'http://refinio.one/resclue/valueCoding/YesNo'
                    }
                }
            ],
            disabledDisplay: 'protected'
        },
        {
            type: 'choice',
            linkId: '27',
            prefix: 'organdonorcard',
            required: false,
            text: 'Organspendeausweis',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ja',
                        system: 'http://refinio.one/resclue/valueCoding/YesNo'
                    }
                },
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Nein',
                        system: 'http://refinio.one/resclue/valueCoding/YesNo'
                    }
                }
            ],
            disabledDisplay: 'protected'
        },
        {
            type: 'choice',
            linkId: '28',
            prefix: 'digitalvacccard',
            required: false,
            text: 'Digitaler Impfpass',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ja',
                        system: 'http://refinio.one/resclue/valueCoding/YesNo'
                    }
                },
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Nein',
                        system: 'http://refinio.one/resclue/valueCoding/YesNo'
                    }
                }
            ],
            disabledDisplay: 'protected'
        },
        {
            type: 'string',
            linkId: '29',
            prefix: 'previousClinics',
            required: false,
            text: 'Vorbehandelnde Klinik',
            disabledDisplay: 'protected'
        },
        {
            type: 'group',
            linkId: '30',
            prefix: '',
            required: false,
            text: 'Hausarzt',
            disabledDisplay: 'protected',
            item: [
                {
                    type: 'string',
                    linkId: '31',
                    prefix: 'name_doc',
                    required: false,
                    text: 'Name',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: '32',
                    prefix: 'street_doc',
                    required: false,
                    text: 'Straße',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'integer',
                    linkId: '33',
                    prefix: 'housenumber_doc',
                    required: false,
                    text: 'Hausnummer',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: '34',
                    prefix: 'site_doc',
                    required: false,
                    text: 'PLZ/Ort',
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            type: 'group',
            linkId: '35',
            prefix: '',
            required: false,
            text: 'Notfallkontakt',
            disabledDisplay: 'protected',
            item: [
                {
                    type: 'string',
                    linkId: '36',
                    prefix: 'surname_emergency_1',
                    required: false,
                    text: 'Name',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: '37',
                    prefix: 'name_emergency_1',
                    required: false,
                    text: 'Vorname(n)',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: '38',
                    prefix: 'phonenumber_emergency_1',
                    required: false,
                    text: 'Telefonnummer',
                    disabledDisplay: 'protected'
                }
            ]
        }
    ]
};
