import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const GeneralShortQuestionnaire: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://refinio.one/questionaire/generalQuestionnaireShort',
    name: 'General Questionnaire (short)',
    title: 'General Questionnaire (short)',
    status: 'active',
    item: [
        {
            linkId: 'personalData',
            prefix: '1',
            text: 'Personal Data',
            type: 'display',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'birthdate',
                    prefix: '1_1',
                    type: 'date',
                    text: 'Birthday',
                    required: false,
                    disabledDisplay: 'protected'
                },
                {
                    linkId: 'street',
                    prefix: '1_2',
                    type: 'string',
                    text: 'Current address: Street',
                    required: false,
                    disabledDisplay: 'protected'
                },
                {
                    linkId: 'town',
                    prefix: '1_3',
                    type: 'string',
                    text: 'Current address: City / Town',
                    required: false,
                    disabledDisplay: 'protected'
                },
                {
                    linkId: 'postalCode',
                    prefix: '1_4',
                    type: 'string',
                    text: 'Postal code',
                    required: false,
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            linkId: 'pregnancyData',
            prefix: '2',
            text: 'Pregnancy Data',
            type: 'display',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'numPreg',
                    prefix: '2_1',
                    text: 'Number of pregnancies',
                    type: 'integer',
                    required: false,
                    disabledDisplay: 'protected',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 20
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 1
                        }
                    ]
                },
                {
                    linkId: 'numLivingChild',
                    prefix: '2_2',
                    text: 'Number of living children',
                    type: 'integer',
                    required: false,
                    disabledDisplay: 'protected',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 20
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 1
                        }
                    ]
                },
                {
                    linkId: 'numStillborn',
                    prefix: '2_3',
                    text: 'Number stillborns',
                    type: 'integer',
                    required: false,
                    disabledDisplay: 'protected',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 20
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 1
                        }
                    ]
                },
                {
                    linkId: 'numAbort',
                    prefix: '2_4',
                    text: 'Number of abortions',
                    type: 'integer',
                    required: false,
                    disabledDisplay: 'protected',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 20
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 1
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'mensHistory',
            prefix: '3',
            text: 'Menstrual History',
            type: 'display',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'firstMens',
                    prefix: '3_1',
                    type: 'date',
                    text: 'Date of first menstruation (menarche)',
                    required: false,
                    disabledDisplay: 'protected'
                },
                {
                    linkId: 'lastMens',
                    prefix: '3_2',
                    type: 'date',
                    text: 'Date of last lifetime menstruation (menopause)',
                    required: false,
                    disabledDisplay: 'protected'
                },
                {
                    linkId: 'mensCycle',
                    prefix: '3_3',
                    type: 'choice',
                    text: 'Menstruation Cycle',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Regular menstruation cycle (28 +- 4 d)',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/MensCycle_en/reg'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Irregular menstruation cycle (28 < / > 4 d)',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/MensCycle_en/ireg'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'contraceptionMeth',
            prefix: '4',
            text: 'Method of Contraception',
            type: 'display',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'contraceptionHis',
                    prefix: '4_1',
                    type: 'open-choice',
                    text: 'History',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Hormonal contraception (pill)',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/ContraceptionMethod_en/pill'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Barrier methods (IUD and others)',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/ContraceptionMethod_en/other'
                            }
                        }
                    ]
                },
                {
                    linkId: 'contraceptionActual',
                    prefix: '4_2',
                    type: 'open-choice',
                    text: 'Actual',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Hormonal contraception (pill)',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/ContraceptionActualMethod_en/pill'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Barrier methods (IUD and others)',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/ContraceptionActualMethod_en/other'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherContraception',
                    prefix: '4_3',
                    type: 'string',
                    text: 'Use of other contraception (condom etc)',
                    required: false,
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            linkId: 'medHistoryDisp',
            prefix: '5',
            text: 'Medical History',
            type: 'display',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'medHistory',
                    prefix: '5_1',
                    type: 'open-choice',
                    text: 'Medical History',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Infectious diseases',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/medHistory_en/infect'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Organ diseases',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/medHistory_en/organ'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Metabolic diseases',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/medHistory_en/metabolic'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Inherited diseases',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/medHistory_en/inherit'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: 'Noncommunicable diseases',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/medHistory_en/noncommunicable'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: 'Palliative care',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/medHistory_en/palliative'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Psychiatric diseases',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/medHistory_en/psychiatric'
                            }
                        }
                    ]
                }
            ]
        },

        {
            linkId: 'actualDisComp',
            prefix: '6',
            text: 'Actual diseases and complaints',
            type: 'display',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'actualComp',
                    prefix: '6_1',
                    type: 'open-choice',
                    text: 'Actual complaints',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Fever',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/fever'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Pain',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/pain'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'High blood pressure',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/highBlood'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'GI-Tract symptoms (diarrhea obstipation, vomiting etc.)',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/giTract'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: 'Lung',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/lung'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: 'Bleeding',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/bleeding'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Head (headache etc.)',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/head'
                            }
                        },
                        {
                            valueCoding: {
                                code: '8',
                                version: '1.0',
                                display: 'Kidney and bladder',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/kidney'
                            }
                        },
                        {
                            valueCoding: {
                                code: '9',
                                version: '1.0',
                                display: 'Muscle (weakness; pain)',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/muscle'
                            }
                        },
                        {
                            valueCoding: {
                                code: '10',
                                version: '1.0',
                                display: 'Orthopedic symptoms and complains',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/orthopedic'
                            }
                        },
                        {
                            valueCoding: {
                                code: '11',
                                version: '1.0',
                                display: 'Metabolic complainsr',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/metabolic'
                            }
                        },
                        {
                            valueCoding: {
                                code: '12',
                                version: '1.0',
                                display: 'Psychological symptoms',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/psychological'
                            }
                        },
                        {
                            valueCoding: {
                                code: '13',
                                version: '1.0',
                                display: 'Palliative care',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/palliative'
                            }
                        },
                        {
                            valueCoding: {
                                code: '14',
                                version: '1.0',
                                display: 'Starving, no food',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/starving'
                            }
                        },
                        {
                            valueCoding: {
                                code: '15',
                                version: '1.0',
                                display: 'No or bad water supply',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/water'
                            }
                        },
                        {
                            valueCoding: {
                                code: '16',
                                version: '1.0',
                                display: 'Other organs',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/complaints_en/other'
                            }
                        }
                    ]
                },
                {
                    linkId: 'actPregnancy',
                    prefix: '6_2',
                    type: 'choice',
                    text: 'Regular Pregnancy?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Yes',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/YesNo_en/yes'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'No',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/YesNo_en/no'
                            }
                        }
                    ]
                },
                {
                    linkId: 'actPregnancyComplications',
                    prefix: '6_3',
                    type: 'open-choice',
                    text: 'Actual pregnancy complications?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Preeclampsia symptoms (headache etc)',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Complications_en/preeclampsia'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'preterm labor',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Complications_en/preterm'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'bleeding symptoms',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Complications_en/bleeding'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherEvents',
                    prefix: '6_4',
                    type: 'string',
                    text: 'Other Events? (e.g. Accidents)',
                    required: false,
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            linkId: 'participationPrevProgram',
            prefix: '7',
            text: 'Participation prevention program',
            type: 'display',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'cancerPrev',
                    prefix: '7_1',
                    type: 'open-choice',
                    text: 'Cancer Prevention',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Cervial Cancer',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/CancerPrev_en/Cervial'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Breast Cancer',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/CancerPrev_en/breast'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Prostate Cancer',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/CancerPrev_en/prostate'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Colorectal Cancer',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/CancerPrev_en/colorectal'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: 'Skin Cancer',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/CancerPrev_en/skin'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherPrev',
                    prefix: '7_2',
                    type: 'open-choice',
                    text: 'Other prevention programs',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Diabetes',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/OtherPrev_en/diabetes'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Blood pressure',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/OtherPrev_en/blood'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Thrombo-embolic',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/OtherPrev_en/thrombo'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'others',
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/OtherPrev_en/others'
                            }
                        }
                    ]
                }
            ]
        }
    ]
};
