import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {BLOB, HashTypes} from '@refinio/one.core/lib/recipes.js';
import {getObject} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import {getIdObject} from '@refinio/one.core/lib/storage-versioned-objects.js';
import {readBlobAsArrayBuffer} from '@refinio/one.core/lib/storage-blob.js';
import {uint8arrayToHexString} from '@refinio/one.core/lib/util/arraybuffer-to-and-from-hex-string.js';

import {TrustObjectCategories, type TrustObject, type TrustObjectCategory} from './types.js';

/**
 * This is a function that calls getObject / getIdObject or readBlob based on the type the object has.
 *
 * @param trustHash - The hash of object
 * @param category - If 'ALL' all objects will be loaded and returned.
 *                   If 'Object' only Objects (unversioned and specific versions) will be loaded
 *                   If 'IdObject' only IdObjects will be loaded
 *                   If 'BLOB' only BLOBs will be loaded
 * @returns The object. Objects that weren't loaded have UnknownType (depends on categroy parameter) an complete
 *          failures on loading will have UnkownType but with the err field set.
 */
export async function getTrustObject(
    trustHash: SHA256Hash<HashTypes> | SHA256IdHash,
    category: TrustObjectCategory = TrustObjectCategories.ALL
): Promise<TrustObject> {
    // Object or IdObject
    if (category !== 'IdObject') {
        try {
            const hash = trustHash as SHA256Hash;
            const obj = await getObject(hash);

            if (category === 'BLOB') {
                return {type: 'unknown', hash: trustHash};
            }

            return {type: 'Object', hash, obj};
        } catch (_e) {
            // Here it is either a IDObject or BLOB -> next test for IdObject
        }
    }

    if (category !== 'Object') {
        try {
            const hash = trustHash as unknown as SHA256IdHash;
            const obj = await getIdObject(hash);

            if (category === 'BLOB') {
                return {type: 'unknown', hash: trustHash};
            }

            return {type: 'IdObject', hash, obj};
        } catch (_e) {
            // Here it is either a IDObject or BLOB -> next test for IdObject
        }
    }

    if (category === 'BLOB' || category === 'ALL') {
        try {
            const hash = trustHash as SHA256Hash<BLOB>;
            const rawBlob = new Uint8Array(await readBlobAsArrayBuffer(hash));
            const blob = uint8arrayToHexString(rawBlob.slice(0, 32));
            return {type: 'BLOB', hash, blob, length: rawBlob.length};
        } catch (e) {
            return {type: 'unknown', hash: trustHash, err: e};
        }
    }

    // This happens if the object isn't of requested category (Object / IdObject)
    return {type: 'unknown', hash: trustHash};
}
