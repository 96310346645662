import type {ReactElement} from 'react';
import {Route, Routes} from 'react-router-dom';

import type DiaryModel from '@refinio/one.models/lib/models/DiaryModel.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';

import CreateDiaryEntryOwnerAdapter from './create/CreateDiaryEntryOwnerAdapter.js';
import CreateDiaryEntry from './create/CreateDiaryEntry.js';
import ViewDiaryEntry from './view/ViewDiaryEntry.js';

export default function DiaryRouter(props: {
    diaryModel: DiaryModel;
    channelManager: ChannelManager;
}): ReactElement {
    return (
        <Routes>
            <Route path="create" element={<CreateDiaryEntry diaryModel={props.diaryModel} />} />
            <Route
                path=":owner/create"
                element={
                    <CreateDiaryEntryOwnerAdapter
                        diaryModel={props.diaryModel}
                        channelManager={props.channelManager}
                    />
                }
            />
            <Route path=":id" element={<ViewDiaryEntry diaryModel={props.diaryModel} />} />
            <Route element={<CreateDiaryEntry diaryModel={props.diaryModel} />} />
        </Routes>
    );
}
