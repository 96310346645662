import {useState, type ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {List} from '@mui/material';

import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';
import type {LeuteModel, TopicModel} from '@refinio/one.models/lib/models';

import AddListItem from '@/components/addListItem/AddListItem.js';
import AddCouponsPopup from '../addCouponsPopup/AddCouponsPopup';

export default function CollapsibleCouponList(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
}): ReactElement {
    const i18n = useTranslation();
    const [isAddingCoupons, setIsAddingCoupons] = useState<boolean>(false);

    return (
        <>
            <CollapsibleComponent
                headline={
                    <div className="collapsible-headline">
                        {i18n.t('oneeuro.coupons.title')}
                        <div>(0)</div>
                    </div>
                }
                content={
                    <List>
                        <AddListItem
                            name={i18n.t('oneeuro.coupons.add')}
                            onClick={() => setIsAddingCoupons(true)}
                            className="list-element"
                        />
                    </List>
                }
            />
            {isAddingCoupons && (
                <AddCouponsPopup
                    leuteModel={props.leuteModel}
                    topicModel={props.topicModel}
                    onClose={() => setIsAddingCoupons(false)}
                />
            )}
        </>
    );
}
