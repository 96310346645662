import {useEffect, useState} from 'react';

import type {BlobDescriptor} from '@refinio/one.models/lib/models/BlobCollectionModel.js';
import {getObject} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import BlobCollectionModel from '@refinio/one.models/lib/models/BlobCollectionModel.js';
import type {BlobDescriptor as OneBlobDescriptor} from '@refinio/one.models/lib/recipes/BlobRecipes.js';
import type {SHA256Hash} from '@refinio/one.core/lib/util/type-checks.js';

import getBase64 from '../../utils/base64.js';

/**
 * Transform an ArrayBuffer to src url in base64 format with type, for dysplaying the blob
 * @param blobDescriptor
 * @returns
 */
export function useBlobDescriptorUrl(blobDescriptor: BlobDescriptor): string {
    const [objUrl, setObjUrl] = useState<string>('');
    useEffect(() => {
        setObjUrl(`data:${blobDescriptor.type};base64,${getBase64(blobDescriptor.data)}`);
    }, [blobDescriptor.data, blobDescriptor.type]);
    return objUrl;
}

/**
 * Transform an ArrayBuffer to src url in base64 format with type, for dysplaying the blob
 * @param blobDescriptor
 * @returns
 */
export function useBlobBase64(blobDescriptor: BlobDescriptor): string {
    const [base64, setBase64] = useState<string>('');
    useEffect(() => {
        setBase64(getBase64(blobDescriptor.data));
    }, [blobDescriptor.data, blobDescriptor.type]);
    return base64;
}

/**
 * @param hash
 * @param onError
 * @returns
 */
export function useBlobDescriptor(
    hash: SHA256Hash,
    onError: (error: Error) => void
): BlobDescriptor | undefined {
    const [blobDescriptor, setBlobDescriptor] = useState<BlobDescriptor | undefined>();

    useEffect(() => {
        async function getImage() {
            setBlobDescriptor(
                await BlobCollectionModel.resolveBlobDescriptor(
                    await getObject<OneBlobDescriptor>(hash as SHA256Hash<OneBlobDescriptor>)
                )
            );
        }

        getImage().catch(onError);
    });

    return blobDescriptor;
}
