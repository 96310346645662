import type {ReactElement} from 'react';

import Avatar from '@mui/material/Avatar/Avatar.js';

import './AvatarPreview.css';

/**
 * Does not work with svgs
 * @param props
 * @returns
 */
export default function AvatarPreview(props: {src: string | undefined}): ReactElement {
    return <Avatar src={props.src} className="avatar-preview" />;
}
