import type {ReactElement} from 'react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

import Divider from '@mui/material/Divider/Divider.js';
import List from '@mui/material/List/List.js';
import ListItem from '@mui/material/ListItem/ListItem.js';

import ListElement from '@refinio/one.ui/lib/ui/components/list/ListElement.js';
import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';

import TextFieldEntry from '@/components/textFieldEntry/TextFieldEntry.js';
import AddListItem from '@/components/addListItem/AddListItem.js';
import type {CommunicationEndpointsTypes, OneInstanceCommunicationEndpoint} from './types.js';
import {CommunicationEndpoints as CommunicationEndpointsArr} from './types.js';
import './SomeoneProfileDetails.css';

/**
 * @param props.endpoints Dictionary of existing communication endpoints
 * @param props.set Optional Callback. In isViewMode = false - change endpoint
 * @param props.add Optional Callback. In isViewMode = false - add endpoint
 * @param props.isViewMode Default false
 * @returns
 */
export default function CommunicationEndpointsView(props: {
    endpoints: CommunicationEndpointsTypes;
    set?: (name: (typeof CommunicationEndpointsArr)[number], index: number, value: string) => void;
    add?: (type: (typeof CommunicationEndpointsArr)[number]) => void;
    isViewMode?: boolean;
}): ReactElement {
    const i18n = useTranslation();

    function getEndpointDataArray(
        type: (typeof CommunicationEndpointsArr)[number]
    ): Required<CommunicationEndpointsTypes>[keyof CommunicationEndpointsTypes] {
        const endpoints = props.endpoints[type];

        if (endpoints && Array.isArray(endpoints)) {
            return endpoints;
        }

        return [];
    }

    return (
        <>
            {CommunicationEndpointsArr.map((endpointName, index) => {
                const endpointDataArray = getEndpointDataArray(endpointName);
                return (
                    <CollapsibleComponent
                        key={`collapsible-${index}-enpoints`}
                        headline={
                            <div className="collapsible-headline">
                                {i18n
                                    .t(`leute.profile.communicationEndpoints.${endpointName}.title`)
                                    .toUpperCase()}
                                <div>({endpointDataArray.length})</div>
                            </div>
                        }
                        content={
                            <EndpointList
                                endpointName={endpointName}
                                endpointDataArray={endpointDataArray}
                                isViewMode={
                                    props.isViewMode || endpointName === 'oneInstanceEndpoint'
                                }
                                set={props.set}
                                add={props.add}
                            />
                        }
                    />
                );
            })}
        </>
    );
}

/**
 * Helper component
 *
 * @params props @see CommunicationEndpointsView
 */
function EndpointList(props: {
    endpointName: (typeof CommunicationEndpointsArr)[number];
    endpointDataArray: Required<CommunicationEndpointsTypes>[keyof CommunicationEndpointsTypes];
    isViewMode?: boolean;
    set?: (name: (typeof CommunicationEndpointsArr)[number], index: number, value: string) => void;
    add?: (type: (typeof CommunicationEndpointsArr)[number]) => void;
}): ReactElement {
    const i18n = useTranslation();

    return (
        <List className="additional-information">
            {props.endpointDataArray.map((data, index2) => (
                <Fragment key={`endpoint-${index2}`}>
                    {index2 !== 0 && <Divider variant={'middle'} />}
                    {props.isViewMode ? (
                        <EndpointValue endpointName={props.endpointName} value={data} />
                    ) : (
                        <EndpointField
                            endpointName={props.endpointName}
                            value={data}
                            onChange={(value: string) => {
                                props.set && props.set(props.endpointName, index2, value);
                            }}
                        />
                    )}
                </Fragment>
            ))}

            {!props.isViewMode && props.add && (
                <>
                    {props.endpointDataArray.length > 0 && <Divider variant={'middle'} />}
                    <AddListItem
                        name={i18n.t(
                            `leute.profile.communicationEndpoints.${props.endpointName}.title`
                        )}
                        onClick={() => props.add && props.add(props.endpointName)}
                    />
                </>
            )}
        </List>
    );
}

/**
 * Helper component
 *
 * @params props @see CommunicationEndpointsView
 */
function EndpointValue(props: {
    endpointName: (typeof CommunicationEndpointsArr)[number];
    value: Required<CommunicationEndpointsTypes>[keyof CommunicationEndpointsTypes][0];
}): ReactElement {
    switch (props.endpointName) {
        case 'oneInstanceEndpoint': {
            const oneInstanceEndpoint = props.value as OneInstanceCommunicationEndpoint;
            return (
                <ListElement
                    extra={
                        <div className="oneInstanceEndpoint">
                            <div className="text">{oneInstanceEndpoint.hash}</div>
                            <div className="info">{oneInstanceEndpoint.personId}</div>
                            <div className="info">{oneInstanceEndpoint.instanceId}</div>
                        </div>
                    }
                />
            );
        }
        default:
            return <ListItem>{String(props.value)}</ListItem>;
    }
}

/**
 * Helper component
 *
 * @params props @see CommunicationEndpointsView
 */
function EndpointField(props: {
    endpointName: (typeof CommunicationEndpointsArr)[number];
    value: Required<CommunicationEndpointsTypes>[keyof CommunicationEndpointsTypes][0];
    onChange: (value: string) => void;
}): ReactElement {
    const i18n = useTranslation();

    return (
        <ListItem>
            <TextFieldEntry
                inputMode={props.endpointName === 'phoneNumber' ? 'tel' : undefined}
                label={
                    props.value
                        ? ''
                        : i18n.t(`leute.profile.communicationEndpoints.${props.endpointName}.title`)
                }
                onDone={props.onChange}
                value={typeof props.value === 'string' ? props.value : ''}
                disableUnderline={true}
            />
        </ListItem>
    );
}
