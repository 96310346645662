import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Someone} from '@refinio/one.models/lib/recipes/Leute/Someone.js';
import SomeoneModel from '@refinio/one.models/lib/models/Leute/SomeoneModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import type {SomeonePreview} from '@/root/contacts/LeuteView.js';
import {buildSomeonePreview, getProfileAvatar} from './utils.js';
import {useUpdateLeuteData} from './commonHooks.js';

const replicantNames = ['Refinio LEUTE.ONE Replicant', 'Refinio GLUE.ONE Replicant'];

export function useSomeonesPreview(
    leuteModel: LeuteModel,
    exclude?: {me?: boolean; replicants?: boolean; personIds?: SHA256IdHash<Person>[]}
): {
    someones: SomeonePreview[];
} {
    const [someones, setSomeones] = useState<SomeonePreview[]>([]);

    async function loadData(): Promise<void> {
        const someoneModels = [...(await leuteModel.others())];

        let meAndOthers = [];

        if (!exclude || !exclude.me) {
            const me = await leuteModel.me();
            const meNP = await buildSomeonePreview(leuteModel, me);
            meAndOthers.push(meNP);
        }

        let others = await Promise.all(
            someoneModels.map(async someoneModel => {
                return buildSomeonePreview(leuteModel, someoneModel);
            })
        );

        if (exclude && exclude.replicants) {
            others = others.filter(someone => replicantNames.indexOf(someone.name));
        }

        if (exclude && exclude.personIds) {
            others = others.filter(
                someone => !exclude.personIds?.includes(someone.mainProfile.personId)
            );
        }

        others.sort((s1, s2) => {
            return s1.name.localeCompare(s2.name);
        });

        meAndOthers = meAndOthers.concat(others);
        setSomeones(meAndOthers);
    }

    useEffect(() => {
        function registerOnUpdateEventsForMainProfiles() {
            const disconnects: (() => void)[] = [];

            someones.forEach((mainProfileForSomeone, index) => {
                disconnects.push(
                    mainProfileForSomeone.mainProfile.onUpdate(async () => {
                        await mainProfileForSomeone.mainProfile.loadLatestVersion();
                        await updateSomeone(mainProfileForSomeone, index);
                    })
                );
            });

            return disconnects;
        }

        async function updateSomeone(
            mainProfileForSomeone: SomeonePreview,
            index: number
        ): Promise<void> {
            someones[index].name = leuteModel.getPersonName(
                mainProfileForSomeone.mainProfile.personId
            );
            someones[index].model = mainProfileForSomeone.model;
            someones[index].mainProfile = mainProfileForSomeone.mainProfile;

            someones[index].avatar = await getProfileAvatar(mainProfileForSomeone.mainProfile);
        }

        const disconnects = registerOnUpdateEventsForMainProfiles();
        return () => {
            for (const disconnect of disconnects) {
                disconnect();
            }
        };
    }, [someones]);

    useUpdateLeuteData(leuteModel, loadData);

    return {someones: someones};
}

export function useMePreview(leuteModel: LeuteModel): SomeonePreview | undefined {
    const [mePreview, setMePreview] = useState<SomeonePreview | undefined>(undefined);

    async function loadData(): Promise<void> {
        const me = await leuteModel.me();
        setMePreview(await buildSomeonePreview(leuteModel, me));
    }

    useEffect(() => {
        if (!mePreview) {
            return;
        }

        return mePreview.mainProfile.onUpdate(loadData);
    }, [mePreview]);

    useUpdateLeuteData(leuteModel, loadData);

    return mePreview;
}

export function useSomeonePreviewFromSomeoneIdParam(
    leuteModel: LeuteModel
): SomeonePreview | undefined {
    const [someone, setSomeone] = useState<SomeonePreview | undefined>(undefined);
    const params = useParams<{someoneId: SHA256IdHash<Someone>}>();

    async function loadData(): Promise<void> {
        if (!params.someoneId) {
            return;
        }

        const someoneModel = await SomeoneModel.constructFromLatestVersion(params.someoneId);
        setSomeone(await buildSomeonePreview(leuteModel, someoneModel));
    }

    useUpdateLeuteData(leuteModel, loadData);

    return someone;
}
