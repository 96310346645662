import type {ReactElement, SyntheticEvent} from 'react';

import Checkbox from '@mui/material/Checkbox/Checkbox.js';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel.js';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import ListElement from '@refinio/one.ui/lib/ui/components/list/ListElement.js';

import AvatarPreview from '@/components/avatarPreview/AvatarPreview.js';
import {GREEN_CHECK_MARK_BUTTON} from '@/components/Constants.js';
import {useIsProfileSigned} from '@/hooks/contact/profileHooks.js';
import type {GroupPickMember} from './GroupMembersPicker.js';

export default function SelectableGroupMemberListEntry(props: {
    groupMember: GroupPickMember;
    leuteModel: LeuteModel;
    onChange: (event: SyntheticEvent<Element, Event>, isChecked: boolean) => void;
    isChecked: boolean;
}): ReactElement {
    const isSigned = useIsProfileSigned(props.groupMember.profileModel, props.leuteModel.trust);

    return (
        <ListElement
            extra={
                <>
                    <AvatarPreview src={props.groupMember.imgURL} />
                    <FormControlLabel
                        className="group-member"
                        control={<Checkbox color="default" checked={props.isChecked} />}
                        label={
                            <>
                                <div className="group-list-entry-middle">
                                    <span className="name">{props.groupMember.name}</span>
                                    <span className="info">{props.groupMember.email}</span>
                                </div>
                                <div className="trusted">{isSigned && GREEN_CHECK_MARK_BUTTON}</div>
                            </>
                        }
                        labelPlacement="start"
                        onChange={props.onChange}
                        color="primary"
                    />
                </>
            }
        />
    );
}
