import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {UnversionedObjectResult} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import {getObject} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import type {Signature} from '@refinio/one.models/lib/recipes/SignatureRecipes.js';
import {objectEvents} from '@refinio/one.models/lib/misc/ObjectEventDispatcher.js';

/**
 * Copied from models until we have a solution in models again
 * @param person
 * @param cb
 * @param cbDescription
 */
export function onNewSignupCert(
    person: SHA256IdHash<Person>,
    cb: (issuer: SHA256IdHash<Person>) => void,
    cbDescription: string
): () => void {
    async function handleNewObjectAsync(result: UnversionedObjectResult<Signature>): Promise<void> {
        const cert = await getObject(result.obj.data);

        if (cert.$type$ !== 'SignupCertificate') {
            return;
        }

        if (cert.person === person) {
            cb(result.obj.issuer);
        }
    }

    return objectEvents.onUnversionedObject(handleNewObjectAsync, cbDescription, 'Signature');
}

/**
 * Copied from models until we have a solution in models again
 * @param data
 * @param cb
 * @param cbDescription
 */
export function onNewAffirmation(
    data: SHA256Hash,
    cb: (issuer: SHA256IdHash<Person>) => void,
    cbDescription: string
): () => void {
    async function handleNewObjectAsync(result: UnversionedObjectResult<Signature>): Promise<void> {
        const cert = await getObject(result.obj.data);

        if (cert.$type$ !== 'AffirmationCertificate') {
            return;
        }

        if (cert.data === data) {
            cb(result.obj.issuer);
        }
    }

    return objectEvents.onUnversionedObject(handleNewObjectAsync, cbDescription, 'Signature');
}

/**
 * Copied from models until we have a solution in models again
 * @param data
 * @param cb
 * @param cbDescription
 */
export function onNewAffirmationOrSignupCert(
    data: SHA256Hash | SHA256IdHash,
    cb: (issuer: SHA256IdHash<Person>) => void,
    cbDescription: string
): () => void {
    async function handleNewObjectAsync(result: UnversionedObjectResult<Signature>): Promise<void> {
        const cert = await getObject(result.obj.data);

        if (cert.$type$ === 'AffirmationCertificate') {
            if (cert.data === data) {
                cb(result.obj.issuer);
            }
        } else if (cert.$type$ === 'SignupCertificate') {
            if (cert.person === data) {
                cb(result.obj.issuer);
            }
        }
    }

    return objectEvents.onUnversionedObject(handleNewObjectAsync, cbDescription, 'Signature');
}
