import type {ReactElement} from 'react';
import {useState, useEffect, useContext, useCallback} from 'react';
import type {To} from 'react-router-dom';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Radio from '@mui/material/Radio/Radio.js';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Someone} from '@refinio/one.models/lib/recipes/Leute/Someone.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile.js';
import SomeoneModel from '@refinio/one.models/lib/models/Leute/SomeoneModel.js';
import {AppBarContext, APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import CollapsibleProfilesList from '@/root/collapsibles/CollapsibleProfilesList.js';

/**
 * @param props.leuteModel
 * @param props.topicModel
 * @param props.doneRoute react-router-dom v6 route
 * @param props.cancelRoute react-router-dom v6 route
 * @returns
 */
export default function ProfileChange(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    doneRoute: To;
    cancelRoute: To | number;
}): ReactElement {
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const appBarContext = useContext(AppBarContext);
    const [profiles, setProfiles] = useState<ProfileModel[]>([]);
    const [mainProfile, setMainProfile] = useState<ProfileModel | undefined>();
    const [selected, setSelected] = useState<SHA256IdHash<Profile>>();
    const params = useParams<{someoneId: SHA256IdHash<Someone>}>();
    const navigate = useNavigate();
    const i18n = useTranslation();

    const cancelChange = useCallback(() => {
        if (!isNaN(Number(props.cancelRoute))) {
            navigate(Number(props.cancelRoute));
        } else {
            navigate(props.cancelRoute as To);
        }
    }, [navigate, props.cancelRoute]);

    const finishChange = useCallback(async () => {
        if (selected === undefined) {
            return;
        }
        if (params.someoneId === undefined) {
            return;
        }
        const someoneModel = await SomeoneModel.constructFromLatestVersion(params.someoneId);
        await someoneModel.setMainProfile(selected);
        navigate(props.doneRoute.toString().replace(':someoneId', params.someoneId), {
            replace: true
        });
    }, [selected, params.someoneId, props.doneRoute, navigate]);

    // keep up to date the cancel and done buttons on the menu
    useEffect(() => {
        appBarContext.setContextValue({
            ...appBarContext.contextValue,
            mode: APP_BAR_MODE.EDIT,
            title: i18n.t('contacts:edit'),
            hide: false,
            rightBtnCallback: finishChange,
            leftBtnCallback: cancelChange
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finishChange, cancelChange]);

    useEffect(() => {
        async function getSomeoneModel(): Promise<void> {
            if (!params.someoneId) {
                return;
            }
            const someoneModel = await SomeoneModel.constructFromLatestVersion(params.someoneId);
            const profiles = await someoneModel.profiles();
            setProfiles(profiles);
            const mainProfile = await someoneModel.mainProfile();
            setMainProfile(mainProfile);
            setSelected(mainProfile.idHash);
        }

        getSomeoneModel().catch(_ => {
            setNotificationMessage(i18n.t('errors:someone.profile'));
            setNotificationType(NOTIFICATION.Error);
            navigate('/', {replace: true});
        });
    }, [params.someoneId]);

    function selectProfile(profile: ProfileModel) {
        if (profile.idHash !== selected) {
            setSelected(profile.idHash);
        }
    }

    function selector(profile: ProfileModel): ReactElement {
        return (
            <div className="right-alignment" onClick={e => e.stopPropagation()}>
                <Radio
                    onChange={event => {
                        if (event.target.checked) {
                            selectProfile(profile);
                        }
                    }}
                    checked={profile.idHash === selected}
                />
            </div>
        );
    }

    return (
        <CollapsibleProfilesList
            leuteModel={props.leuteModel}
            profiles={profiles}
            mainProfileHash={mainProfile?.idHash}
            onSelectProfile={selectProfile}
            topicModel={props.topicModel}
            title={i18n.t('leute.profile.setMainProfileTitle').toUpperCase()}
            entryAddon={selector}
            hideListLength={true}
        />
    );
}
