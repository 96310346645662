import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const SchmerzerfassungQuestionnaireVerbalAssessmentDE: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/Schmerzerfassung_VerbalAssessment',
    name: 'Schmerzerfassung_VerbalAssessment',
    title: 'Schmerzerfassung Verbal Assessment',
    status: 'active',
    item: [
        {
            linkId: 'verbalAssessment',
            prefix: '3',
            type: 'group',
            text: 'Verbale Rating Skala (VRS)',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'verbalResting',
                    prefix: '3_1',
                    type: 'choice',
                    text: 'Im Ruhezustand',
                    required: true,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'kein Schmerz',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'mäßiger Schmerz',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'mittelstarker Schmerz',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'starker Schmerz',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'stärkster vorstellbarer Schmerz',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'verbalLoad',
                    prefix: '3_2',
                    type: 'choice',
                    text: 'Bei Belastung',
                    required: true,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'kein Schmerz',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'mäßiger Schmerz',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'mittelstarker Schmerz',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'starker Schmerz',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'stärkster vorstellbarer Schmerz',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'verbalComment',
                    prefix: '3_3',
                    type: 'string',
                    text: 'Bemerkung:',
                    required: false,
                    disabledDisplay: 'protected'
                }
            ]
        }
    ]
};
