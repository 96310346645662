import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const WhpQuestionnaire: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://refinio.one/questionaire/whpQuestionnaire',
    name: 'WHP Follow Up Visit From 1',
    title: 'WHP Follow Up Visit Form 1',
    status: 'active',
    item: [
        {
            linkId: 'dateOfVisit',
            prefix: '1',
            type: 'date',
            text: 'Date',
            disabledDisplay: 'protected'
        },
        {
            linkId: 'facility',
            prefix: '2',
            type: 'group',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'facilityName',
                    prefix: '2_1',
                    type: 'string',
                    text: 'Facility name',
                    disabledDisplay: 'protected'
                },
                {
                    linkId: 'facilityMobile',
                    prefix: '2_2',
                    type: 'choice',
                    text: 'Was it a mobile clinic?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Yes',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'No',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'name',
            prefix: '3',
            text: 'Name',
            type: 'string',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'whipId',
            prefix: '4',
            text: 'WHIP ID#',
            type: 'string',
            required: false,
            disabledDisplay: 'protected',
            extension: [
                {
                    url: 'http://hl7.org/fhir/StructureDefinition/regex',
                    valueString: '^([0-1][0-9]|20)-[0-2]-\\d{4}-\\d{2}$'
                },
                {
                    url: 'http://hl7.org/fhir/StructureDefinition/entryFormat',
                    valueString: '(00-20)-(0-2)-XXXX-XX'
                }
            ]
        },
        {
            linkId: 'age',
            prefix: '5',
            text: 'Age (years)',
            type: 'integer',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'lmp',
            prefix: '6',
            text: 'LMP',
            type: 'date',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'adress',
            prefix: '7',
            type: 'group',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'addressChange',
                    prefix: '7_1',
                    type: 'choice',
                    text: 'Has your address, phone number, or other contact information changed since last visit?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Yes',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'No',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'adressNew',
                    prefix: '7_2',
                    type: 'group',
                    disabledDisplay: 'protected',
                    enableWhen: [
                        {
                            question: 'addressChange',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ],
                    item: [
                        {
                            linkId: 'adressTown',
                            prefix: '7_2_1',
                            text: 'Town',
                            type: 'string',
                            required: false,
                            disabledDisplay: 'protected'
                        },
                        {
                            linkId: 'adressVillage',
                            prefix: '7_2_2',
                            text: 'Village',
                            type: 'string',
                            required: false,
                            disabledDisplay: 'protected'
                        },
                        {
                            linkId: 'addressQuater',
                            prefix: '7_2_3',
                            text: 'Quater',
                            type: 'string',
                            required: false,
                            disabledDisplay: 'protected'
                        }
                    ]
                },
                {
                    linkId: 'newPhone',
                    prefix: '7_3',
                    type: 'group',
                    disabledDisplay: 'protected',
                    enableWhen: [
                        {
                            question: 'addressChange',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ],
                    item: [
                        {
                            linkId: 'phoneNumber',
                            prefix: '7_3_1',
                            text: 'Phone number',
                            type: 'string',
                            required: false,
                            disabledDisplay: 'protected',
                            extension: [
                                {
                                    url: 'http://hl7.org/fhir/StructureDefinition/regex',
                                    valueString: '^\\d{9}$'
                                },
                                {
                                    url: 'http://hl7.org/fhir/StructureDefinition/entryFormat',
                                    valueString: 'XXXXXXXXX (9 Digits)'
                                }
                            ]
                        }
                    ]
                },
                {
                    linkId: 'otherContact',
                    prefix: '7_4',
                    type: 'group',
                    disabledDisplay: 'protected',
                    enableWhen: [
                        {
                            question: 'addressChange',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ],
                    item: [
                        {
                            linkId: 'contactName',
                            prefix: '7_4_1',
                            text: 'Contact/Name',
                            type: 'string',
                            required: false,
                            disabledDisplay: 'protected'
                        },
                        {
                            linkId: 'contactPhone',
                            prefix: '7_4_2',
                            text: 'Phone number',
                            type: 'string',
                            required: false,
                            disabledDisplay: 'protected',
                            extension: [
                                {
                                    url: 'http://hl7.org/fhir/StructureDefinition/regex',
                                    valueString: '^\\d{9}$'
                                },
                                {
                                    url: 'http://hl7.org/fhir/StructureDefinition/entryFormat',
                                    valueString: 'XXXXXXXXX (9 Digits)'
                                }
                            ]
                        },
                        {
                            linkId: 'contactRelationship',
                            prefix: '7_4_3',
                            text: 'Relationship',
                            type: 'string',
                            required: false,
                            disabledDisplay: 'protected'
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'dateLastVisit',
            prefix: '8',
            text: 'Date of last visit',
            type: 'date',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'facilityLastVisit',
            prefix: '9',
            text: 'Facility of last visit',
            type: 'string',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'funcLim',
            prefix: '10',
            text: 'Functional Limitations',
            type: 'group',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'funcLimDescription',
                    prefix: '10_0',
                    text: '(Read out the introduction to start this section. For each question, tick the response that applies)\nINTRODUCTION: The next questions are about the difficulties you may have doing certain activities because of a HEALTH PROBLEM.',
                    type: 'display',
                    disabledDisplay: 'protected'
                },
                {
                    linkId: 'funcLimGlasses',
                    prefix: '10_1',
                    type: 'choice',
                    text: 'Do you have difficulty seeing, even if wearing glasses?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'No - no difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'some difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'a lot of difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Can not do at all',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'funcLimHearing',
                    prefix: '10_2',
                    type: 'choice',
                    text: 'Do you have difficulty hearing, even if using a hearing aid?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'No - no difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'some difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'a lot of difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Can not do at all',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'funcLimWalking',
                    prefix: '10_3',
                    type: 'choice',
                    text: 'Do you have difficulty walking or climbing stairs?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'No - no difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'some difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'a lot of difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Can not do at all',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'funcLimRemembering',
                    prefix: '10_4',
                    type: 'choice',
                    text: 'Do you have difficulty remembering or concentrating?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'No - no difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'some difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'a lot of difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Can not do at all',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'funcLimWashing',
                    prefix: '10_5',
                    type: 'choice',
                    text: 'Do you have difficulty (with self-care such as) washing all over or dressing?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'No - no difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'some difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'a lot of difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Can not do at all',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'funcLimEnglish',
                    prefix: '10_6',
                    type: 'choice',
                    text: 'Using English Language, do you have difficulty communicating, e.g. understanding or being understood?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'No - no difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'some difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'a lot of difficulty',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Can not do at all',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Difficulty_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'funcLimAssistive',
                    prefix: '10_7',
                    type: 'choice',
                    text: 'Do you use an assisitive device? ',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Yes',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'No',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'funcLimAssistiveWhich',
                    prefix: '10_8',
                    text: 'Please list',
                    type: 'string',
                    required: false,
                    disabledDisplay: 'protected',
                    enableWhen: [
                        {
                            question: 'funcLimAssistive',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'clinicReturn',
            prefix: '11',
            type: 'group',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'clinicReturnCoice',
                    prefix: '11_1',
                    type: 'choice',
                    text: 'What are you returining to the clinic for?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Rescreen',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/ReturnClinic_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Treatment of pre-cancer of the cervix',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/ReturnClinic_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Follow up after treatment of pre-cancer of the cervix',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/ReturnClinic_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Followup after treatment of cancer',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/ReturnClinic_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: 'New symptom or problem since last visit',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/ReturnClinic_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'clinicReturnOther',
                    prefix: '11_2',
                    text: 'Other',
                    type: 'string',
                    required: false,
                    disabledDisplay: 'protected'
                },
                {
                    linkId: 'treatments',
                    prefix: '11_3',
                    type: 'group',
                    enableBehavior: 'any',
                    disabledDisplay: 'protected',
                    enableWhen: [
                        {
                            question: 'clinicReturnCoice',
                            operator: '=',
                            answerCoding: {
                                code: '2'
                            }
                        },
                        {
                            question: 'clinicReturnCoice',
                            operator: '=',
                            answerCoding: {
                                code: '3'
                            }
                        }
                    ],
                    item: [
                        {
                            linkId: 'treatmentsChoice',
                            prefix: '11_3_1',
                            type: 'choice',
                            text: 'Which of the following treatments were you asked to return for?',
                            required: false,
                            disabledDisplay: 'protected',
                            answerOption: [
                                {
                                    valueCoding: {
                                        code: '1',
                                        version: '1.0',
                                        display: 'Cryotherapy',
                                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/ReturnTreatments_en'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: '2',
                                        version: '1.0',
                                        display: 'Treatment of pre-cancer of the cervix',
                                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/ReturnClinic_en'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: '3',
                                        version: '1.0',
                                        display: 'LEEP',
                                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/ReturnClinic_en'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: '4',
                                        version: '1.0',
                                        display: 'Biopsy',
                                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/ReturnClinic_en'
                                    }
                                }
                            ]
                        },
                        {
                            linkId: 'treatmentsOther',
                            prefix: '11_3_2',
                            text: 'Other',
                            type: 'string',
                            required: false,
                            disabledDisplay: 'protected'
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'diagnoisis ',
            prefix: '12',
            type: 'group',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'diagnosisChoice',
                    prefix: '12_1',
                    type: 'choice',
                    text: 'Presumtive Diagnosis and/or DC-VIA results previous visits.',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Normal',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Uncertain',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Cervicitis',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Vaginitis',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: 'Positive HPV test',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: 'CIN meeting ablation criteria',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'CIN meeting LEEP criteria',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '8',
                                version: '1.0',
                                display: 'Supspicius for cervicial CA',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '9',
                                version: '1.0',
                                display: 'Warts',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '10',
                                version: '1.0',
                                display: 'VIN (vulvar)',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '11',
                                version: '1.0',
                                display: 'VAIN (vagina)',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '12',
                                version: '1.0',
                                display: 'Breast mass',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '13',
                                version: '1.0',
                                display: 'Other breast abnomrality',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Diagnosis_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'diagnosisOther',
                    prefix: '12_2',
                    text: 'Other',
                    type: 'string',
                    required: false,
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            linkId: 'intervention',
            prefix: '13',
            type: 'group',
            disabledDisplay: 'protected',
            enableWhen: [
                {
                    question: 'diagnosisChoice',
                    operator: '!=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'interventionChoice',
                    prefix: '13_1',
                    type: 'choice',
                    text: 'Intervention last visit',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'None',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Invervention_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Treatment of infection',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Invervention_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Biopsy',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Invervention_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Cyro',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Invervention_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: 'Thermal ablation',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Invervention_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: 'Leep',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Invervention_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'interventionOther',
                    prefix: '13_2',
                    text: 'Other',
                    type: 'string',
                    required: false,
                    disabledDisplay: 'protected'
                },
                {
                    linkId: 'biopsy',
                    prefix: '13_3',
                    type: 'group',
                    disabledDisplay: 'protected',
                    enableWhen: [
                        {
                            question: 'interventionChoice',
                            operator: '=',
                            answerCoding: {
                                code: '3'
                            }
                        }
                    ],
                    item: [
                        {
                            linkId: 'biopsyChoice',
                            prefix: '13_3_1',
                            type: 'choice',
                            text: 'Biopsy',
                            required: false,
                            disabledDisplay: 'protected',
                            answerOption: [
                                {
                                    valueCoding: {
                                        code: '1',
                                        version: '1.0',
                                        display: 'cervical',
                                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Biopsy_en'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: '2',
                                        version: '1.0',
                                        display: 'vulvar',
                                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Biopsy_en'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: '3',
                                        version: '1.0',
                                        display: 'endometrial',
                                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Biopsy_en'
                                    }
                                }
                            ]
                        },
                        {
                            linkId: 'biopsyOther',
                            prefix: '13_3_2',
                            text: 'Other',
                            type: 'string',
                            required: false,
                            disabledDisplay: 'protected'
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'pathReport',
            prefix: '14',
            type: 'choice',
            text: 'Is pathology report available from previous visits?',
            required: false,
            disabledDisplay: 'protected',
            enableWhen: [
                {
                    question: 'diagnosisChoice',
                    operator: '!=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Yes',
                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'No',
                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                    }
                }
            ]
        },
        {
            linkId: 'lesion',
            prefix: '15',
            type: 'group',
            disabledDisplay: 'protected',
            enableWhen: [
                {
                    question: 'pathReport',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'lesion_choice',
                    prefix: '15_1',
                    type: 'choice',
                    text: 'Results from path report (highest grade lesion)',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Negative for CIN & other neoplasia',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Lesion_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'CIN1',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Lesion_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'CIN2',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Lesion_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'CIN3/CA-in-situ',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Lesion_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: 'Microinvasive squamous cancer',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Lesion_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: 'Squamous cell carcinoma',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Lesion_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Cervical adenomarcinoma-in-situ',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Lesion_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '8',
                                version: '1.0',
                                display: 'Cervical adenomarcinoma, invasive',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Lesion_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '9',
                                version: '1.0',
                                display: 'Endomatrial hyperplasia',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Lesion_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '10',
                                version: '1.0',
                                display: 'Endometrial cancer',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Lesion_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '11',
                                version: '1.0',
                                display: 'Breast cancer',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/Lesion_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'lesionOther',
                    prefix: '15_2',
                    text: 'List other Path Diagnosis if applicable',
                    type: 'string',
                    required: false,
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            linkId: 'previousHPV',
            prefix: '16',
            type: 'choice',
            text: 'Previous HPV test result',
            required: false,
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Negative',
                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/NegPos_en'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Positive',
                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/NegPos_en'
                    }
                }
            ]
        },
        {
            linkId: 'todayHPV',
            prefix: '17',
            type: 'group',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'todayHPVTest',
                    prefix: '17_1',
                    type: 'choice',
                    text: 'HPV test today?',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Yes',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'No',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'todayHPVResult',
                    prefix: '17_2',
                    type: 'choice',
                    text: 'Result',
                    required: false,
                    disabledDisplay: 'protected',
                    enableWhen: [
                        {
                            question: 'todayHPVTest',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Negative',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/NegPos_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Positive',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/NegPos_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'HPVtype',
                    prefix: '17_3',
                    type: 'group',
                    disabledDisplay: 'protected',
                    enableWhen: [
                        {
                            question: 'todayHPVResult',
                            operator: '=',
                            answerCoding: {
                                code: '2'
                            }
                        }
                    ],
                    item: [
                        {
                            linkId: 'HPVtypeChoice',
                            prefix: '17_3_1',
                            text: 'HPV type(s)',
                            type: 'open-choice',
                            required: false,
                            disabledDisplay: 'protected',
                            answerOption: [
                                {
                                    valueCoding: {
                                        code: '1',
                                        version: '1.0',
                                        display: 'HPV 16',
                                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/HPVType_en'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: '2',
                                        version: '1.0',
                                        display: 'HPV 18',
                                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/HPVType_en'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: '3',
                                        version: '1.0',
                                        display: 'One or more of the other 13 HR types',
                                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/HPVType_en'
                                    }
                                }
                            ]
                        },
                        {
                            linkId: 'HPtypeChoice',
                            prefix: '17_3_2',
                            text: 'List HR types',
                            type: 'string',
                            required: false,
                            disabledDisplay: 'protected'
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'HIVresults',
            prefix: '18',
            type: 'group',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'HIVresult',
                    prefix: '18_1',
                    text: "What was client's most recent HIV test result?",
                    type: 'choice',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Positive',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/NegPos_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Negative',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/NegPos_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Unknown',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/NegPos_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'HIVtreatment',
                    prefix: '18_2',
                    text: 'Is client currently receiving care in an HIV Care and Treatment clinic?',
                    type: 'choice',
                    required: false,
                    disabledDisplay: 'protected',
                    enableWhen: [
                        {
                            question: 'HIVresult',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Yes',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'No',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'HIVtestToday',
                    prefix: '18_3',
                    text: 'Was HIV test today?',
                    type: 'choice',
                    required: false,
                    disabledDisplay: 'protected',
                    enableBehavior: 'any',
                    enableWhen: [
                        {
                            question: 'HIVresult',
                            operator: '=',
                            answerCoding: {
                                code: '2'
                            }
                        },
                        {
                            question: 'HIVresult',
                            operator: '=',
                            answerCoding: {
                                code: '3'
                            }
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Yes',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'No',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'HIVtestResult',
                    prefix: '18_4',
                    text: 'What was the result?',
                    type: 'choice',
                    required: false,
                    disabledDisplay: 'protected',
                    enableBehavior: 'any',
                    enableWhen: [
                        {
                            question: 'HIVresult',
                            operator: '=',
                            answerCoding: {
                                code: '2'
                            }
                        },
                        {
                            question: 'HIVresult',
                            operator: '=',
                            answerCoding: {
                                code: '3'
                            }
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Negative',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/NegPos_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Positive',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/NegPos_en'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'CBEResult',
            prefix: '19',
            type: 'group',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'CBEChoice',
                    prefix: '19_1',
                    text: 'CBE result',
                    type: 'open-choice',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Normal',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/CBE_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Nipple retraction',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/CBE_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Discoloration',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/CBE_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Mastitis',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/CBE_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: 'Dimple',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/CBE_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: 'Masses',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/CBE_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Axillary lymphadenopathy',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/CBE_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '8',
                                version: '1.0',
                                display: 'Nipple discharge',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/CBE_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '9',
                                version: '1.0',
                                display: 'Tenderness',
                                system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/CBE_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'CBEOther',
                    prefix: '19_2',
                    text: 'Other',
                    type: 'string',
                    required: false,
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            linkId: 'abonrmalitiesChoice',
            prefix: '20',
            text: 'Evaluation of breast abnormalities',
            type: 'open-choice',
            required: false,
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Not done',
                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/BreastAbnormalities_en'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Breast ultrasound',
                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/BreastAbnormalities_en'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Mammography',
                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/BreastAbnormalities_en'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'FNA (fine needle aspiration) performed',
                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/BreastAbnormalities_en'
                    }
                },
                {
                    valueCoding: {
                        code: '5',
                        version: '1.0',
                        display: 'TruCut Bioscopy',
                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/BreastAbnormalities_en'
                    }
                }
            ]
        },
        {
            linkId: 'informationChoice',
            prefix: '21',
            text: 'Would you like information on methods of family planning/child spacing?',
            type: 'choice',
            required: false,
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Yes',
                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'No',
                        system: 'http://refinio.one/questionaire/whpQuestionnaire/valueCoding/YesNo_en'
                    }
                }
            ]
        }
    ]
};
