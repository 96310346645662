import {Fragment, useState, type ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import Card from '@mui/material/Card/Card.js';
import CardContent from '@mui/material/CardContent/CardContent.js';
import Divider from '@mui/material/Divider/Divider.js';
import Typography from '@mui/material/Typography/Typography.js';

import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';

import type {ExpandedCertificateData} from './common/types.js';
import type {CertificateSetting} from './common/types.js';
import {useCertificateIcon, useExpandedCertificates, useTrustObjects} from './common/hooks.js';
import {NotSignedIcon} from './common/utils.js';

import './TrustView.css';

export default function CertificatesView(props: {
    hashes: (SHA256Hash | SHA256IdHash)[] | SHA256Hash | SHA256IdHash | undefined;
    leuteModel: LeuteModel;
    certificateSettings: CertificateSetting[];
    onError?: (error: Error) => void;
}): ReactElement {
    const i18n = useTranslation();
    const trustObjects = useTrustObjects(
        props.hashes ? (typeof props.hashes === 'object' ? props.hashes : [props.hashes]) : [],
        props.onError
    );
    const certificates = useExpandedCertificates(
        trustObjects,
        props.leuteModel.trust,
        props.certificateSettings,
        props.onError
    );

    return (
        <div className="certificate-view">
            <CollapsibleComponent
                headline={
                    <>
                        {i18n.t('trust.certificatesTitle')} ({certificates.length})
                    </>
                }
                content={
                    <div>
                        {certificates.map((certificate: ExpandedCertificateData, index: number) => (
                            <Fragment key={certificate.certificateHash}>
                                <Card>
                                    <CardContent>
                                        <Certificate
                                            certificate={certificate}
                                            leuteModel={props.leuteModel}
                                            showObjectData={trustObjects.length > 1}
                                        />
                                    </CardContent>
                                </Card>
                                {index !== certificates.length - 1 && (
                                    <Divider variant={'middle'} />
                                )}
                            </Fragment>
                        ))}
                    </div>
                }
            />
        </div>
    );
}

function Certificate(props: {
    certificate: ExpandedCertificateData;
    leuteModel: LeuteModel;
    showObjectData?: boolean;
}): ReactElement {
    const i18n = useTranslation();
    const [name, setName] = useState<string | undefined>(undefined);
    const icon = useCertificateIcon(
        props.certificate.setting,
        props.certificate.trustObject,
        props.certificate
    );

    useEffect(() => {
        function getName(): void {
            setName(props.leuteModel.getPersonName(props.certificate.signature.issuer));
        }
        getName();
        return props.leuteModel.onUpdated(getName);
    }, [props.leuteModel, props.certificate.signature.issuer]);

    return (
        <div className="certificate-container">
            <div className="left">
                <Typography className="type">
                    {i18n.t(
                        `trust.certificates.${props.certificate.setting.settingUniqueId}`,
                        props.certificate.setting.settingUniqueId
                    )}
                </Typography>
                <Typography className="issuer">
                    {i18n.t('trust.issuer')}:{' '}
                    {name
                        ? `${name} (${String(props.certificate.signature.issuer).substring(0, 7)})`
                        : props.certificate.signature.issuer}
                </Typography>
                {props.showObjectData && (
                    <Typography className="object">
                        {i18n.t('trust.object')}:{' '}
                        {props.certificate.trustObject.type === 'IdObject' ||
                        props.certificate.trustObject.type === 'Object'
                            ? `${props.certificate.trustObject.obj.$type$} (${String(
                                  props.certificate.trustObject.hash
                              ).substring(0, 7)})`
                            : props.certificate.trustObject.hash}
                    </Typography>
                )}
            </div>
            <div className="right">
                <div className="icon">{icon ? icon : NotSignedIcon}</div>
            </div>
        </div>
    );
}
