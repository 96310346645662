import type {ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';

import BaseAddItem from './BaseAddItem.js';

export default function DiaryItem(props: {dataOwner?: SHA256IdHash<Person>}): ReactElement {
    const navigate = useNavigate();
    const i18n = useTranslation();

    return (
        <BaseAddItem
            type={'diary'}
            onClick={() => navigate(`/diary/${props.dataOwner ? `${props.dataOwner}/` : ''}create`)}
            title={i18n.t('home.widget.diary')}
        />
    );
}
