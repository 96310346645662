import React, {useRef, type ReactElement} from 'react';

import BaseAddItem from './BaseAddItem.js';

/**
 * DocumentItem component for uploading documents.
 *
 * @param props - The component props.
 * @param props.onDocumentSubmit - Callback function to handle document submission.
 * @returns ReactElement
 */
export default function DocumentItem(props: {
    onDocumentSubmit: (file: File) => void | Promise<void>;
}): ReactElement {
    const fileRef = useRef<HTMLInputElement>(null);

    async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        if (!event.target.files) {
            return;
        }

        try {
            await Promise.all(
                Array.from(event.target.files).map(file => props.onDocumentSubmit(file))
            );
        } catch (e) {
            console.error(e);
        }
    }

    function handleClick(): void {
        if (!fileRef.current) {
            console.error('Unable to handle onClick, due to no fileRef detected');
            return;
        }
        fileRef.current.click();
    }

    return (
        <>
            <input
                ref={fileRef}
                type="file"
                style={{display: 'none'}}
                onChange={handleFileChange}
                multiple
            />
            <div>
                <BaseAddItem type={'document'} onClick={handleClick} />
            </div>
        </>
    );
}
