import {useEffect, useRef, useState} from 'react';

export function useWindowDimensions(): {width: number; height: number} {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWidthAndHeight);
        return () => window.removeEventListener('resize', updateWidthAndHeight);
    });

    return {
        width,
        height
    };
}

export function useWindowHeight(): number {
    const [height, setHeight] = useState(window.innerHeight);

    const updateHeight = () => {
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    });

    return height;
}

export function useVHPhoneFixRef<T extends HTMLElement>(addHeight: number = 0) {
    // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
    const windowHeight = useWindowHeight();
    const fixRef = useRef<T>(null);
    useEffect(() => {
        if (fixRef.current !== null) {
            fixRef.current.style.height = `${windowHeight + addHeight}px`;
        }
    }, [windowHeight, fixRef, addHeight]);

    return fixRef;
}

export function useVHPhoneFixById(elementId: string, addHeight: number = 0) {
    // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
    const windowHeight = useWindowHeight();
    useEffect(() => {
        const element = document.getElementById(elementId);
        if (element) {
            element.style.height = `${windowHeight + addHeight}px`;
        }
    }, [windowHeight, elementId, addHeight]);
}
