import type {ReactElement} from 'react';

import ListElement from '@refinio/one.ui/lib/ui/components/list/ListElement.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import AvatarPreview from '@/components/avatarPreview/AvatarPreview.js';
import {GREEN_CHECK_MARK_BUTTON} from '@/components/Constants.js';
import {useIsProfileSigned} from '@/hooks/contact/profileHooks.js';
import type {GroupMember} from './GroupMembersList.js';

export default function GroupListEntry(props: {
    groupMember: GroupMember;
    leuteModel: LeuteModel;
}): ReactElement {
    const isSigned = useIsProfileSigned(props.groupMember.profile, props.leuteModel.trust);

    return (
        <ListElement
            className="group-list-entry"
            extra={
                <>
                    <AvatarPreview src={props.groupMember.img} />
                    <div className="group-list-entry-middle">
                        <span className="name">{props.groupMember.name}</span>
                        <span className="info">{props.groupMember.email}</span>
                    </div>
                    <div className="trusted">{isSigned && GREEN_CHECK_MARK_BUTTON}</div>
                </>
            }
        />
    );
}
