import type {ReactElement} from 'react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';

import ToggleDetails from '@/root/chat/attachmentViews/utils/ToggleDetails.js';
import CertificateDetails from '@/root/chat/attachmentViews/utils/CertificateDetails.js';

/**
 * @param props.onViewObjectRequest
 * @param props.getCertificatePopupView callback to get certificate popup view
 * @returns
 */
export default function QuestionnaireResponsesAttachmentDetails(props: {
    onViewObjectRequest: () => void;
    getCertificatePopupView: (onClose: () => void) => ReactElement;
}): ReactElement {
    const i18n = useTranslation();

    return (
        <ToggleDetails>
            <>
                <div className="attachment-details">
                    <Button
                        sx={{width: 1}}
                        variant="outlined"
                        className="mui-outlined-button"
                        onClick={props.onViewObjectRequest}
                    >
                        {i18n.t('buttons.common.view')}
                    </Button>
                </div>
                <CertificateDetails certificateView={props.getCertificatePopupView} />
            </>
        </ToggleDetails>
    );
}
