import type {Instance, Person} from '@refinio/one.core/lib/recipes.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {CommunicationEndpointTypes} from '@refinio/one.models/lib/recipes/Leute/CommunicationEndpoints.js';
import type {
    PersonStatus,
    PersonImage
} from '@refinio/one.models/lib/recipes/Leute/PersonDescriptions.js';

export const COMMUNICATION_ENDPOINT_NAMES = {
    ONE_INSTANCE_ENDPOINTS: 'oneInstanceEndpoint',
    EMAIL: 'email',
    PHONE_NUMBER: 'phoneNumber'
} as const;

export type OneInstanceCommunicationEndpoint = {
    hash: SHA256Hash<CommunicationEndpointTypes>;
    personId: SHA256IdHash<Person>;
    instanceId: SHA256IdHash<Instance>;
};

export type ProfileDetails = {
    avatar: ArrayBuffer | undefined;
    name: string;
    personEmail: string;
    status?: PersonStatus | PersonImage;
    personId?: SHA256IdHash<Person>;
    owner?: SHA256IdHash<Person>;
    ownerName?: string | undefined;
    [COMMUNICATION_ENDPOINT_NAMES.ONE_INSTANCE_ENDPOINTS]?: OneInstanceCommunicationEndpoint[];
    [COMMUNICATION_ENDPOINT_NAMES.EMAIL]?: string[];
    [COMMUNICATION_ENDPOINT_NAMES.PHONE_NUMBER]?: string[];
    organisationName?: string;
};

export const CommunicationEndpoints = [
    COMMUNICATION_ENDPOINT_NAMES.ONE_INSTANCE_ENDPOINTS,
    COMMUNICATION_ENDPOINT_NAMES.EMAIL,
    COMMUNICATION_ENDPOINT_NAMES.PHONE_NUMBER
];

export type CommunicationEndpointsTypes = {
    oneInstanceEndpoint?: OneInstanceCommunicationEndpoint[];
    email?: string[];
    phoneNumber?: string[];
};
