import type {ReactElement} from 'react';
import {Fragment, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import Divider from '@mui/material/Divider/Divider.js';
import List from '@mui/material/List/List.js';

import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import type {InstanceListItem} from '@/root/settings/InstanceListEntry.js';
import InstanceListEntry from '@/root/settings/InstanceListEntry.js';

export default function CollapsibleInstancesList(props: {
    leuteModel: LeuteModel;
    instancesList: InstanceListItem[];
    title?: string;
    showHashOnly?: boolean;
    disableSelect?: boolean;
}): ReactElement {
    const i18n = useTranslation();
    const navigate = useNavigate();
    const appBarContext = useContext(AppBarContext);

    /**
     * Handler for pressing an instance settings
     * @param instanceSettings
     */
    function onInstancePressed(instanceSettings: InstanceListItem) {
        appBarContext.setContextValue({
            ...appBarContext.contextValue,
            title: props.leuteModel.getPersonName(instanceSettings.mainProfile.personId)
        });
        navigate(`/settings/${instanceSettings.mainProfile.personId}`);
    }

    return (
        <CollapsibleComponent
            headline={
                <div className="collapsible-headline">
                    {props.title ? props.title : i18n.t('settings.instances')}
                    <div>({props.instancesList.length})</div>
                </div>
            }
            content={
                <List>
                    {props.instancesList &&
                        props.instancesList.map((instance, index) => (
                            <Fragment key={index}>
                                <InstanceListEntry
                                    leuteModel={props.leuteModel}
                                    instance={instance}
                                    onSelect={props.disableSelect ? () => {} : onInstancePressed}
                                    showHashOnly={props.showHashOnly}
                                />
                                {index < props.instancesList.length - 1 ? (
                                    <Divider variant={'middle'} />
                                ) : null}
                            </Fragment>
                        ))}
                </List>
            }
        />
    );
}
