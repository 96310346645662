import type {ReactElement} from 'react';
import {useState} from 'react';

import type {JournalEntry} from '@refinio/one.models/lib/models/JournalModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import {JournalLists} from '@refinio/one.ui/lib/ui/views/journal/Journal.js';
import JournalDialog from '@refinio/one.ui/lib/ui/views/journal/JournalDialog.js';

import {ShareJournalEntryPopUp} from '@/components/shareJournalEntryPopup/ShareJournalEntryPopUp.js';
import {useShareJournalEntryDefaults} from '@/components/shareJournalEntryPopup/useShareJournalEntryDefaults.js';
import {useQuestionnaireEventType} from '@/hooks/journal/hooks.js';

/**
 * @param props.leuteModel
 * @param props.topicModel
 * @param props.eventList
 * @param props.questionnaireModel
 * @returns
 */
export default function QuestionnaireListWithSharing(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    eventList: JournalEntry[];
    questionnaireModel: QuestionnaireModel;
    onError?: (e: Error) => void;
}): ReactElement {
    const {sharedEntry, isSharePopupOpen, addonRenderFn, close} = useShareJournalEntryDefaults();
    const questionnaireEventType = useQuestionnaireEventType(
        props.questionnaireModel,
        addonRenderFn
    );
    const [clickedEvent, setClickedEvent] = useState<JournalEntry | undefined>(undefined);

    return (
        <div className="questionnaire-list">
            <JournalLists
                events={props.eventList}
                eventTypes={[questionnaireEventType]}
                onEntryClicked={(event: JournalEntry) => setClickedEvent(event)}
                leuteModel={props.leuteModel}
            />
            {clickedEvent && (
                <JournalDialog
                    dialogState={true}
                    setDialogState={() => setClickedEvent(undefined)}
                    event={clickedEvent}
                    type={questionnaireEventType.type}
                />
            )}
            <ShareJournalEntryPopUp
                leuteModel={props.leuteModel}
                topicModel={props.topicModel}
                sharedEntry={sharedEntry}
                isOpen={isSharePopupOpen}
                onClose={close}
            />
        </div>
    );
}
