import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const Onboarding: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://refinio.one/questionaire/Onboarding',
    name: 'onboarding_patient',
    title: 'Onboarding',
    status: 'active',
    item: [
        {
            linkId: 'name',
            prefix: '1',
            text: 'Name',
            type: 'string',
            required: true,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'birthdate',
            prefix: '2',
            text: 'Birthdate',
            type: 'date',
            required: true,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'personImage',
            prefix: '3',
            type: 'attachment',
            text: 'Please upload an image of the patient:',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'email',
            prefix: '4',
            text: 'E-Mail',
            type: 'string',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'whp1',
            prefix: '5',
            text: 'What is your decision about having photographs taken of your cervix?',
            type: 'choice',
            required: true,
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'I agree to have my cervical photos used for the purposes stated',
                        system: 'http://refinio.net/edda/valueCoding/AgreeCervixExamination_en'
                    }
                },
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'I do not agree to have my cervix photographed',
                        system: 'http://refinio.net/edda/valueCoding/AgreeCervixExamination_en'
                    }
                }
            ]
        },
        {
            linkId: 'whp2',
            prefix: '6',
            type: 'choice',
            text: 'If you agree to having photographs of your cervix, what is your decision about allowing us to use your cervical photos (without using your name or other personal information) for training, program reports, and/or for future research on cervical cancer?',
            required: true,
            disabledDisplay: 'hidden',
            enableBehavior: 'any',
            enableWhen: [
                {
                    operator: '=',
                    question: 'whp1',
                    answerCoding: {code: '1'}
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'I agree to have my cervical photos used for the purposes stated',
                        system: 'http://refinio.net/edda/valueCoding/AgreeCervixPhotosUsage_en'
                    }
                },
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display:
                            'I do not agree to have my cervical photos used for the purposes stated',
                        system: 'http://refinio.net/edda/valueCoding/AgreeCervixPhotosUsage_en'
                    }
                }
            ]
        },
        {
            linkId: 'whp3',
            prefix: '7',
            type: 'choice',
            text: 'What is your decision about having the treatments/intervention described above during your exam today?',
            required: true,
            disabledDisplay: 'hidden',
            enableBehavior: 'any',
            enableWhen: [
                {
                    operator: '=',
                    question: 'whp1',
                    answerCoding: {code: '1'}
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display:
                            'I agree to have any of these treatments/interventions done during my exam, if the provider recommends it.',
                        system: 'http://refinio.net/edda/valueCoding/TreatmentsToday_en'
                    }
                },
                {
                    valueCoding: {
                        code: '999',
                        version: '1.0',
                        display:
                            ' I will decide about the treatment/intervention during my exam, based on the findings.',
                        system: 'http://refinio.net/edda/valueCoding/TreatmentsToday_en'
                    }
                }
            ]
        },
        {
            linkId: 'attachment',
            prefix: '8',
            type: 'attachment',
            text: 'If you have any old reports or images please upload them here:',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'certificate',
            prefix: '9',
            type: 'open-choice',
            text: 'Sign Up Certificate',
            required: false,
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display:
                            'Midwife affirms that patient has read and agreed to be signed up with edda.one by Midwife and store her data in the patients name.',
                        system: 'http://refinio.net/edda/valueCoding/SignUpCertificate_en'
                    }
                }
            ]
        }
    ]
};
