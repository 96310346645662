import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Group} from '@refinio/one.core/lib/recipes.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {useForceRender} from '@/hooks/useForceRender.js';
import i18n from '@/i18n.js';
import {useUpdateLeuteData} from './commonHooks.js';
import type BlacklistModel from '@/model/BlacklistModel.js';

export function useGroupsPreview(leuteModel: LeuteModel): {
    groups: {name: string; model: GroupModel}[];
} {
    const [groups, setGroups] = useState<{name: string; model: GroupModel}[]>([]);
    const forceRender = useForceRender();

    async function loadData(): Promise<void> {
        const groupModels = [...(await leuteModel.groups())];

        const others = groupModels.map(groupModel => {
            return {
                name: groupModel.name,
                model: groupModel
            };
        });

        others.sort((s1, s2) => {
            return s1.name.localeCompare(s2.name);
        });

        setGroups(others);
    }

    useEffect(() => {
        function registerOnUpdateEventsForGroups() {
            const disconnects: (() => void)[] = [];
            groups.forEach((group, index) => {
                disconnects.push(
                    group.model.onUpdated(async () => {
                        await group.model.loadLatestVersion();
                        groups[index].name = group.model.name;
                        forceRender();
                    })
                );
            });

            return disconnects;
        }

        const disconnects = registerOnUpdateEventsForGroups();
        return () => {
            for (const disconnect of disconnects) {
                disconnect();
            }
        };
    }, [groups]);

    useUpdateLeuteData(leuteModel, loadData);

    return {groups: groups};
}

export function useGroup(
    groupId?: SHA256IdHash<Group>,
    blacklistModel?: BlacklistModel
): GroupModel | undefined {
    const [group, setGroup] = useState<GroupModel>();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const navigate = useNavigate();
    const appBarContext = useContext(AppBarContext);

    useEffect(() => {
        let ignored = false;
        async function getGroup(groupId: SHA256IdHash<Group>): Promise<void> {
            if (blacklistModel && blacklistModel.blacklistGroupModel.groupIdHash === groupId) {
                return setGroup(blacklistModel.blacklistGroupModel);
            }
            const groupModel = await GroupModel.constructFromLatestProfileVersion(groupId);
            if (!ignored) {
                return setGroup(groupModel);
            }
        }
        if (groupId) {
            getGroup(groupId).catch(_ => {
                appBarContext.setContextValue({
                    ...appBarContext.contextValue,
                    mode: APP_BAR_MODE.VIEW,
                    title: ''
                });
                setNotificationMessage(i18n.t('errors.group.retrieve'));
                setNotificationType(NOTIFICATION.Error);
                navigate('/', {replace: true});
            });
        }

        return () => {
            ignored = true;
        };
    }, [groupId]);

    return group;
}
