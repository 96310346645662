import type {ChangeEvent, ReactElement} from 'react';
import {useEffect, useState} from 'react';

import Button from '@mui/material/Button/Button.js';

import StyledTextField from '@refinio/one.ui/lib/ui/components/fields/StyledTextField.js';

import i18n from '@/i18n.js';
import {ONBOARDING_PROGRESS} from '@/components/onboarding/Onboarding.js';

import '@/components/onboarding/Onboarding.css';

/**
 * Represents the information prompt which is displayed in the onboarding process.
 * Used to collect data from the user.
 * @param props
 * @param props.onDone - callback which provide the input from the user to the parent component.
 * @param props.question - the question displayed for the user.
 * @param props.label - the label information for the text field.
 * @param props.currentStep - the current step displayed in progress.
 * @param props.defaultValue - the default value of the text field.
 * @param props.isDefaultDisabled - specify if the "ok" button should be disabled until the user enter the data.
 * @param props.leftButtons Optional. Default undefined. extra butons on the left side of the progress bar
 * @param props.logoSrc
 */
export default function InformationPrompt(props: {
    onDone: (value: string) => void;
    question: string;
    label: string;
    currentStep: number;
    defaultValue?: string;
    isDefaultDisabled?: boolean;
    leftButtons?: ReactElement[] | ReactElement | undefined;
    logoSrc: string;
}): ReactElement {
    // the state used to keep the data from the text field
    const [value, setValue] = useState<string>('');

    // used to initialize the "value" state when the defaultValue is provided
    useEffect(() => {
        if (props.defaultValue === undefined) {
            setValue('');
        } else {
            setValue(props.defaultValue);
        }
    }, [props.defaultValue]);

    /**
     * Used to update the "value" state whenever the text field value is changed.
     * @param identifier
     * @param event - the event triggered from the text field.
     */
    function updateValue(
        _identifier: string,
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        setValue(event.target.value);
    }

    /**
     * Set the "onDone" callback with the collected information.
     */
    function setOnDone(): void {
        props.onDone(value);
    }

    return (
        <div className="information-prompt-container">
            <div className="information-prompt">
                <img className="logo" alt="app-logo" src={props.logoSrc} />
                <div className="question h1">{props.question}</div>
            </div>
            <div className="information-handler">
                <StyledTextField
                    identifier={'information'}
                    label={props.label}
                    value={value}
                    autoFocus={true}
                    changeCallback={updateValue}
                    enterPressCallback={setOnDone}
                />
                <div className="set-information-container">
                    <div className="left-buttons">{props.leftButtons}</div>
                    <div className="progress">
                        {props.currentStep + '/' + (Object.keys(ONBOARDING_PROGRESS).length - 2)}
                    </div>
                    <div className="right-buttons">
                        <Button
                            color="primary"
                            variant="contained"
                            className="body ok-btn"
                            disabled={props.isDefaultDisabled && value === ''}
                            onClick={setOnDone}
                        >
                            {i18n.t('buttons.common.ok')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
