import * as blazeface from '@tensorflow-models/blazeface';
import '@tensorflow/tfjs-backend-cpu';
import type {SimpleBox} from './ImageUtils.js';

export const AI_ALGORITHMS = {
    FACE_DETECTOR: 'face-detector'
};
export type AiAlgorithm = (typeof AI_ALGORITHMS)[keyof typeof AI_ALGORITHMS];
export type AiResult = {
    algorithm: AiAlgorithm;
    files?: Array<File>;
    text?: string;
};

export async function detectFaces(image: HTMLImageElement): Promise<SimpleBox[]> {
    const model = await blazeface.load();
    const returnTensors = false;
    const flipHorizontal = false;
    const annotateBoxes = true;
    const predictions = await model.estimateFaces(
        image,
        returnTensors,
        flipHorizontal,
        annotateBoxes
    );

    return predictions.map(p => {
        return {
            topLeft: p.topLeft as [number, number],
            bottomRight: p.bottomRight as [number, number]
        };
    });
}
