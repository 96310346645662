import {useTheme} from '@mui/material/styles';
import {useState, type ReactElement, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {TextField} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import type DiaryModel from '@refinio/one.models/lib/models/DiaryModel.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {useNavigateBack} from '@/hooks/navigation.js';
import '../Diary.css';

export default function CreateDiaryEntry(props: {
    diaryModel: DiaryModel;
    overwritePost?: (diaryEntry: string) => Promise<void>;
}): ReactElement {
    const i18n = useTranslation();
    const [entry, setEntry] = useState<string>('');
    const goBack = useNavigateBack();
    const navigate = useNavigate();
    const theme = useTheme();

    const onFinish = useCallback(async () => {
        if (entry !== '') {
            if (props.overwritePost) {
                await props.overwritePost(entry);
                return;
            }
            await props.diaryModel.addEntry(entry);
            navigate('/journal');
        }
    }, [entry, props.diaryModel]);

    return (
        <AppBarFullScreenPopup
            background={theme.palette.background.default}
            mode={APP_BAR_MODE.EDIT}
            title={i18n.t('diary.entry')}
            onClose={goBack}
            onFinish={onFinish}
            className="diary-container"
        >
            <TextField
                onChange={e => setEntry(e.target.value)}
                className="diary-entry-edit"
                multiline={true}
                placeholder={i18n.t('diary.placeholder')}
            />
        </AppBarFullScreenPopup>
    );
}
