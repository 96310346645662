import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type {QuestionnaireResponse} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

/**
 * Get map of titles by Url
 * @param questionnaireModel
 * @returns
 */
export function useQuestionnaireTitlesByUrlMap(
    questionnaireModel: QuestionnaireModel
): Map<Questionnaire['url'], QuestionnaireResponse['questionnaire']> | undefined {
    const [questionnaireTitlesByUrlMap, setQuestionnaireTitlesByUrlMap] = useState<
        Map<Questionnaire['url'], QuestionnaireResponse['questionnaire']> | undefined
    >(undefined);

    /**
     * Get a map for questionnaire url => title
     */
    useEffect(() => {
        async function getTitles() {
            const questionnaires = await questionnaireModel.questionnaires();
            const newQuestionnaireTitlesByUrlMap = new Map();
            for (let i = 0; i < questionnaires.length; i++) {
                if (questionnaires[i].url) {
                    newQuestionnaireTitlesByUrlMap.set(
                        questionnaires[i].url,
                        questionnaires[i].title
                    );
                }
            }
            setQuestionnaireTitlesByUrlMap(newQuestionnaireTitlesByUrlMap);
        }
        getTitles().catch(console.error);
    }, [questionnaireModel]);

    return questionnaireTitlesByUrlMap;
}

export function useQuestionnaireResponse(
    questionnaireModel: QuestionnaireModel
): QuestionnaireResponse | undefined | null {
    const location = useLocation();
    const [response, setResponse] = useState<QuestionnaireResponse | undefined | null>(null);

    useEffect(() => {
        async function getResponse(responseId: string): Promise<void> {
            const responseObj = await questionnaireModel.responsesById(responseId);
            if (responseObj && responseObj.data.response.length > 0) {
                setResponse(responseObj.data.response[0]);
            }
        }
        const queryParams = new URLSearchParams(location.search);
        const responseId = queryParams.get('response');
        if (responseId) {
            getResponse(responseId).catch(console.error);
        } else {
            setResponse(undefined);
        }
    }, [location.search, questionnaireModel]);

    return response;
}
