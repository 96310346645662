import {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type TopicRoom from '@refinio/one.models/lib/models/Chat/TopicRoom.js';
import type {BlobDescriptor} from '@refinio/one.models/lib/models/BlobCollectionModel.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';

export type WrappedChatMessage = {
    name: string;
    message: string;
    date: Date;
    isMe: boolean;
    senderIdHash: SHA256IdHash<Person>;
    attachments?: BlobDescriptor[];
};

/**
 * Hook function to find the desired topic room by channel groupId hash
 * @param topicModel
 * @param topicChannelId
 */
export function useTopicRoomByChannelId(
    topicModel: TopicModel,
    topicChannelId: string | undefined
): TopicRoom | undefined {
    let ignore = false;
    const [topic, setTopic] = useState<TopicRoom>();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const {contextValue, setContextValue} = useContext(AppBarContext);

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchTopic(): Promise<void> {
            if (ignore || !topicChannelId) {
                return;
            }
            const topicRoom = await topicModel.enterTopicRoom(topicChannelId);
            setTopic(topicRoom);
        }
        fetchTopic().catch(_ => {
            setContextValue({...contextValue, hide: false});
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('errors.chat.canNotOpenTopic');
            navigate('/contacts', {replace: true});
        });

        return () => {
            ignore = true;
        };
    }, [topicChannelId]);

    return topic;
}

/**
 * Due to how hooks work, myPersonId and hisPersonId can be undefined (at first),
 * but this hook will return undefined
 * @param topicModel
 * @param myPersonId not optional
 * @param hisPersonId not optional
 * @returns
 */
export function useTopicId(
    topicModel: TopicModel,
    myPersonId?: SHA256IdHash<Person>,
    hisPersonId?: SHA256IdHash<Person>
): string | undefined {
    const [topicId, setTopicId] = useState<string | undefined>(undefined);

    useEffect(() => {
        async function updateTopicId() {
            if (myPersonId === undefined || hisPersonId === undefined) {
                return;
            }
            const topic = await topicModel.createOneToOneTopic(myPersonId, hisPersonId);
            setTopicId(topic.id);
        }
        updateTopicId().catch(err => console.error(err));
    }, [hisPersonId, myPersonId, topicModel]);

    return topicId;
}
