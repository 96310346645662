import type {ReactElement, RefAttributes} from 'react';
import {useCallback, useState} from 'react';
import {differenceInDays, differenceInHours, isSameDay, isSameMonth} from 'date-fns';

import type {DateCalendarProps, PickersDayProps} from '@mui/x-date-pickers';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns/AdapterDateFns.js';
import {PickersDay} from '@mui/x-date-pickers/PickersDay/PickersDay.js';
import Button from '@mui/material/Button/Button.js';
import {DateCalendar} from '@mui/x-date-pickers';

import i18n from '@/i18n.js';

import './Calendar.css';

const today = new Date();

/**
 * Wrapper for CalendarPicker common functionality
 *
 * @param props.className
 * @param props.daySelectionChange callback
 * @param props.monthSelectionChange callback
 * @param props.initialDate Default today
 * @param props.dayIndicator days for which to show indicator
 * @returns
 */
export default function Calendar(
    props: {
        className?: string;
        daySelectionChange?: (day: Date) => void;
        monthSelectionChange?: (month: Date) => void;
        initialDate?: Date;
        dayIndicator?: number[];
    } & Partial<DateCalendarProps<Date>> &
        Partial<RefAttributes<HTMLDivElement>>
): ReactElement {
    const [selectedDay, setSelectedDay] = useState<Date>(
        props.initialDate ? props.initialDate : today
    );
    // filter props for calendarPicker (exclude this component props)
    const {
        className,
        daySelectionChange,
        monthSelectionChange,
        initialDate,
        dayIndicator,
        ...calendarPickerProps
    } = props;

    const changeDay = useCallback(
        (date: Date) => {
            setSelectedDay(date);
            if (!isSameMonth(selectedDay, date) && props.monthSelectionChange) {
                props.monthSelectionChange(date);
            }
            if (props.daySelectionChange) {
                props.daySelectionChange(date);
            }
        },
        [selectedDay, props]
    );

    const changeMonth = useCallback(
        (date: Date) => {
            if (props.monthSelectionChange) {
                props.monthSelectionChange(date);
            }
            if (!isSameMonth(selectedDay, date) && props.daySelectionChange) {
                setSelectedDay(date);
                props.daySelectionChange(date);
            }
        },
        [selectedDay, props]
    );

    function DataCheckerDayRender(
        pickerData: PickersDayProps<Date> & {highlightedDays?: number[]}
    ) {
        const {day, outsideCurrentMonth, ...other} = pickerData;
        if (outsideCurrentMonth) {
            return <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />;
        }

        const isToday = differenceInDays(day, today) === 0 && differenceInHours(day, today) <= 0;
        const isSelected =
            differenceInDays(day, selectedDay) === 0 && differenceInHours(day, selectedDay) <= 0;
        return (
            <PickersDay
                {...other}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
                className={`day${isToday && !isSelected ? ' today' : ''}${
                    isSelected ? ' selected' : ''
                }${!isSelected && props.dayIndicator?.includes(day.getDate()) ? ' data' : ''}`}
            />
        );
    }

    return (
        <div className={`calendar${props.className ? ` ${props.className}` : ''}`}>
            <div className="additional-buttons">
                <Button
                    disabled={isSameDay(selectedDay, today)}
                    className="today-button"
                    variant="outlined"
                    onClick={() => changeDay(today)}
                >
                    {i18n.t('calendar.todayButton')}
                </Button>
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.getLocale()}>
                <DateCalendar
                    {...calendarPickerProps}
                    value={selectedDay}
                    onChange={date => {
                        if (date) {
                            changeDay(date);
                        }
                    }}
                    onMonthChange={changeMonth}
                    onYearChange={changeMonth}
                    dayOfWeekFormatter={(dayOfWeek: string) => dayOfWeek}
                    slots={{
                        day: DataCheckerDayRender
                    }}
                />
            </LocalizationProvider>
        </div>
    );
}
