import {useCallback, type ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import type DocumentModel from '@refinio/one.models/lib/models/DocumentModel.js';

import type {QuestionnaireMenu} from '@/components/widgetMenu/items/QuestionnaireItem.js';
import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import WidgetList from '@/components/widgetMenu/WidgetList.js';
import DocumentItem from '@/components/widgetMenu/items/DocumentItem.js';
import ImageItem from '@/components/widgetMenu/items/ImageItem.js';
import DiaryItem from '@/components/widgetMenu/items/DiaryItem.js';
import QuestionnaireItem from '@/components/widgetMenu/items/QuestionnaireItem.js';
import LaboratoryItem from '@/components/widgetMenu/items/LaboratoryItem.js';

const addQuestionnaireMenu: QuestionnaireMenu[] = [
    {entry: MENU_ENTRY.ExampleQuestionnaire, name: 'Example Questionnaire'},
    {entry: MENU_ENTRY.OnboardingPatientQuestionnaire, name: 'onboarding_patient'},
    {entry: MENU_ENTRY.GeneralQuestionnaireShort, name: 'General Questionnaire (short)'},
    {entry: MENU_ENTRY.ResclueQuestionnaire, name: 'resclue'},
    {
        entry: MENU_ENTRY.GeneralFeedbackQuestionnaire,
        name: 'general_feedback_edda'
    },
    {
        entry: MENU_ENTRY.SpecificFeedbackQuestionnaire,
        name: 'specific_feedback_edda'
    }
];

const oncologyQuestionnaireMenu: QuestionnaireMenu[] = [
    {entry: MENU_ENTRY.WhpQuestionnaire, name: 'WHP Follow Up Visit From 1'},
    {
        entry: MENU_ENTRY.CervicalCancerScreeningAndTreatmentRegisterQuestionnaire,
        name: 'cervical_cancer_screeninc_and_treatment_register'
    },
    {
        entry: MENU_ENTRY.IFCPC_colposcopic_terminology_cervix,
        name: '2011_IFCPC_colposcopic_terminology_cervix'
    },
    {
        entry: MENU_ENTRY.ColposcopyExaminationRecord,
        name: 'colposcopy_examination_record'
    }
];

export default function HomeWidget(props: {documentModel: DocumentModel}): ReactElement {
    const i18n = useTranslation();

    const onDocumentSubmit = useCallback(
        async (file: File) =>
            props.documentModel.addDocument(await file.arrayBuffer(), file.type, file.name),
        []
    );

    return (
        <WidgetList title={i18n.t('home.widgetTitle')}>
            <QuestionnaireItem menu={addQuestionnaireMenu} />
            <QuestionnaireItem
                menu={oncologyQuestionnaireMenu}
                title={i18n.t('home.widget.oncology')}
            />
            <DocumentItem onDocumentSubmit={onDocumentSubmit} />
            <ImageItem onDocumentSubmit={onDocumentSubmit} />
            <LaboratoryItem />
            <DiaryItem />
        </WidgetList>
    );
}
