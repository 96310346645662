import {fetchFile} from '@refinio/one.core/lib/system/fetch-file';
import {isObject} from '@refinio/one.core/lib/util/type-checks-basic';

type Manifest = {
    name?: string;
    short_name?: string;
    scope?: string;
    start_url?: string;
    display?: string;
    theme_color?: string;
    background_color?: string;
    icons?: Array<{
        src: string;
        sizes: string;
        type: string;
    }>;
};

const defaultManifest: Manifest = {
    name: 'one.leute',
    short_name: 'one.leute',
    scope: window.location.origin,
    start_url:
        window.location.origin +
        window.location.pathname +
        window.location.search +
        window.location.hash,
    display: 'standalone',
    theme_color: '#000000',
    background_color: '#ffffff',
    icons: [
        {
            src: 'favicon.ico',
            sizes: '64x64 32x32 24x24 16x16',
            type: 'image/x-icon'
        },
        {
            src: 'one.svg',
            type: 'image/png',
            sizes: '192x192'
        },
        {
            src: 'one.svg',
            type: 'image/png',
            sizes: '512x512'
        }
    ]
};

/**
 * Check that the passed object conforms to the manifest type.
 *
 * @param arg
 */
export function isManifest(arg: unknown): arg is Manifest {
    if (!isObject(arg)) {
        console.error('isManifest: arg is not an object');
        return false;
    }

    if (arg.name !== undefined && typeof arg.name !== 'string') {
        console.error('isManifest: name is not a string');
        return false;
    }

    if (arg.short_name !== undefined && typeof arg.short_name !== 'string') {
        console.error('isManifest: short_name is not a string');
        return false;
    }

    if (arg.scope !== undefined && typeof arg.scope !== 'string') {
        console.error('isManifest: scope is not a string');
        return false;
    }

    if (arg.start_url !== undefined && typeof arg.start_url !== 'string') {
        console.error('isManifest: start_url is not a string');
        return false;
    }

    if (arg.display !== undefined && typeof arg.display !== 'string') {
        console.error('isManifest: display is not a string');
        return false;
    }

    if (arg.theme_color !== undefined && typeof arg.theme_color !== 'string') {
        console.error('isManifest: theme_color is not a string');
        return false;
    }

    if (arg.background_color !== undefined && typeof arg.background_color !== 'string') {
        console.error('isManifest: background_color is not a string');
        return false;
    }

    if (arg.icons !== undefined) {
        if (typeof arg.icons !== 'object' || !Array.isArray(arg.icons)) {
            console.error('isManifest: icons is not a array');
            return false;
        }

        for (const icon of arg.icons as Array<{
            src: string;
            sizes: string;
            type: string;
        }>) {
            if (typeof icon.src !== 'string') {
                console.error('isManifest: icon contains src that is not a string');
                return false;
            }
            if (typeof icon.sizes !== 'string') {
                console.error('isManifest: icon contains sizes that is not a string');
                return false;
            }
            if (typeof icon.type !== 'string') {
                console.error('isManifest: icon contains type that is not a string');
                return false;
            }
        }
    }

    return true;
}

async function loadManifest(manifestAbsolutePath: string): Promise<Manifest> {
    const manifest: unknown = JSON.parse(await fetchFile(manifestAbsolutePath));

    if (!isManifest(manifest)) {
        throw new Error('Format of manifest file is wrong.');
    }

    const startUrl =
        manifest.start_url !== undefined
            ? window.location.origin + manifest.start_url
            : defaultManifest.start_url;

    return {
        ...defaultManifest,
        ...manifest,
        start_url: startUrl
    };
}

/**
 * Creates the dynamic manifest, setting the start_url at current window location. (Used for STH)
 *
 * @returns {void}
 */
export async function generateDynamicManifest(
    manifestAbsolutePath: string | undefined
): Promise<void> {
    const dynamicManifest: Manifest =
        manifestAbsolutePath === undefined
            ? defaultManifest
            : await loadManifest(manifestAbsolutePath);

    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    const htmlTag = document.querySelector('#dynamic-manifest');

    if (htmlTag) {
        htmlTag.setAttribute('href', manifestURL);
    }
}
