import {useState, type ReactElement, useEffect} from 'react';

import type {OneObjectTypes} from '@refinio/one.core/lib/recipes.js';
import type {SHA256Hash} from '@refinio/one.core/lib/util/type-checks.js';
import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import {
    type QuestionnaireResponses,
    questionnaireResponsesTypes
} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type BodyTemperatureModel from '@refinio/one.models/lib/models/BodyTemperatureModel.js';
import type DiaryModel from '@refinio/one.models/lib/models/DiaryModel.js';
import type WbcDiffModel from '@refinio/one.models/lib/models/WbcDiffModel.js';
import type {BlobDescriptor as OneBlobDescriptor} from '@refinio/one.models/lib/recipes/BlobRecipes.js';
import type {DocumentInfo} from '@refinio/one.models/lib/models/DocumentModel.js';
import {readBlobAsArrayBuffer} from '@refinio/one.core/lib/storage-blob.js';

import BlobDescriptorAttachmentView from '@/root/chat/attachmentViews/blobDescriptor/BlobDescriptorAttachmentView.js';
import WBCView from '@/root/chat/attachmentViews/wbc/WBCView.js';
import DiaryEntryView from '@/root/chat/attachmentViews/diaryEntry/DiaryEntryView.js';
import BodyTemperatureView from '@/root/chat/attachmentViews/bodyTemperature/BodyTemperatureView';
import QuestionnaireResponsesAttachmentView from '@/root/chat/attachmentViews/questionnaireReponses/QuestionnaireResponsesAttachmentView.js';
import type {
    AttachmentViewFactoryAdditionalData,
    AttachmentViewFactoryEntry
} from '@/root/chat/attachmentViews/types.js';
import type {BlobDescriptor} from '@refinio/one.models/lib/models/BlobCollectionModel';

export const defaultAttachmentViewFactoryEntries: AttachmentViewFactoryEntry[] = [
    [
        'BlobDescriptor',
        (
            hash: SHA256Hash,
            oneBlobDescriptor: OneObjectTypes,
            getCertificatePopupView: (onClose: () => void) => ReactElement,
            onError?: (error: Error) => void,
            additionalData?: AttachmentViewFactoryAdditionalData
        ) => {
            // transform OneBlobDescriptor to BlobDescriptor by using cache
            const blobDescriptor = additionalData?.blobDescriptorCache
                ? additionalData?.blobDescriptorCache.query(
                      hash as SHA256Hash<OneBlobDescriptor>,
                      oneBlobDescriptor as OneBlobDescriptor
                  )
                : undefined;
            if (blobDescriptor === undefined) {
                return <></>; // blob not yet loaded, will force refresh later
            }
            return (
                <BlobDescriptorAttachmentView
                    blobDescriptor={blobDescriptor}
                    getCertificatePopupView={getCertificatePopupView}
                    onError={onError}
                    additionalData={additionalData}
                />
            );
        }
    ]
];

/**
 * Adapter from DocumentInfo to BlobDescriptor renderer
 *
 * @param hash
 * @param oneBlobDescriptor
 * @param getCertificatePopupView
 * @param onError
 * @param additionalData
 * @returns
 */
function DocumentInfoToBlobDescriptorAdapter(props: {
    hash: SHA256Hash;
    oneObject: OneObjectTypes;
    getCertificatePopupView: (onClose: () => void) => ReactElement;
    onError?: (e: Error) => void;
    additionalData?: AttachmentViewFactoryAdditionalData;
}): ReactElement {
    const document = props.oneObject as DocumentInfo;
    const [blobDescriptor, setBlobDescriptor] = useState<BlobDescriptor>({
        data: new ArrayBuffer(0),
        lastModified: 1,
        name: document.documentName,
        size: 0,
        type: document.mimeType
    });

    useEffect(() => {
        async function getBlob(): Promise<void> {
            const blob = await readBlobAsArrayBuffer(document.document);
            setBlobDescriptor(b => ({...b, data: blob}));
        }
        getBlob().catch(props.onError ? props.onError : console.error);
    }, []);

    if (blobDescriptor.data.byteLength === 0) {
        return <></>; // blob not yet loaded, will force refresh later
    }

    return (
        <BlobDescriptorAttachmentView
            blobDescriptor={blobDescriptor}
            getCertificatePopupView={props.getCertificatePopupView}
            onError={props.onError}
            additionalData={props.additionalData}
        />
    );
}

/**
 * Optional attachment renderers.
 * Must be supplied to the ChatRouter to take effect.
 * - used to avoid propagation of models.
 * - used to inject attachment view renderers without modifiing chat component.
 *
 * @param questionnaireModel
 * @param bodyTemperatureModel
 * @param wbcDiffModel
 * @param diaryModel
 * @returns
 */
export function getAdditionalAttachmentViews(
    questionnaireModel: QuestionnaireModel,
    bodyTemperatureModel: BodyTemperatureModel,
    wbcDiffModel: WbcDiffModel,
    diaryModel: DiaryModel
): AttachmentViewFactoryEntry[] {
    const renderers: AttachmentViewFactoryEntry[] = [];

    // Document to BlobDescriptor adapter
    function documentInfoAdapterRenderer(
        hash: SHA256Hash,
        oneObject: OneObjectTypes,
        getCertificatePopupView: (onClose: () => void) => ReactElement,
        onError?: (e: Error) => void,
        additionalData?: AttachmentViewFactoryAdditionalData
    ): ReactElement {
        return (
            <DocumentInfoToBlobDescriptorAdapter
                hash={hash}
                oneObject={oneObject}
                getCertificatePopupView={getCertificatePopupView}
                onError={onError}
                additionalData={additionalData}
            />
        );
    }
    renderers.push(['DocumentInfo_1_1_0', documentInfoAdapterRenderer]);

    // register questionnaire renderers
    function questionnaireResponsesRenderer(
        _hash: SHA256Hash,
        oneObject: OneObjectTypes,
        getCertificatePopupView: (onClose: () => void) => ReactElement,
        onError?: (e: Error) => void
    ): ReactElement {
        return (
            <QuestionnaireResponsesAttachmentView
                questionnaireResponses={oneObject as QuestionnaireResponses}
                questionnaireModel={questionnaireModel}
                getCertificatePopupView={getCertificatePopupView}
                onError={onError}
            />
        );
    }

    for (const questionnaireResponsesType of questionnaireResponsesTypes) {
        renderers.push([questionnaireResponsesType, questionnaireResponsesRenderer]);
    }

    // body temperature renderer
    function bodyTemperatureRenderer(
        hash: SHA256Hash,
        _oneObject: OneObjectTypes,
        getCertificatePopupView: (onClose: () => void) => ReactElement,
        onError?: (e: Error) => void
    ): ReactElement {
        return (
            <BodyTemperatureView
                getCertificatePopupView={getCertificatePopupView}
                onError={onError}
                bodyTemperatureModel={bodyTemperatureModel}
                hash={hash}
            />
        );
    }
    renderers.push(['BodyTemperature', bodyTemperatureRenderer]);

    // wbc renderer
    function wbcRenderer(
        hash: SHA256Hash,
        _oneObject: OneObjectTypes,
        getCertificatePopupView: (onClose: () => void) => ReactElement,
        onError?: (e: Error) => void
    ): ReactElement {
        return (
            <WBCView
                getCertificatePopupView={getCertificatePopupView}
                onError={onError}
                wbcDiffModel={wbcDiffModel}
                hash={hash}
            />
        );
    }
    renderers.push(['WbcObservation', wbcRenderer]);

    // diaryEntry renderer
    function diaryEntryRenderer(
        hash: SHA256Hash,
        _oneObject: OneObjectTypes,
        getCertificatePopupView: (onClose: () => void) => ReactElement,
        onError?: (e: Error) => void
    ): ReactElement {
        return (
            <DiaryEntryView
                getCertificatePopupView={getCertificatePopupView}
                onError={onError}
                diaryModel={diaryModel}
                hash={hash}
            />
        );
    }
    renderers.push(['DiaryEntry', diaryEntryRenderer]);

    return renderers;
}
