import React, {Fragment, useCallback} from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import type {ReactElement, ChangeEvent, KeyboardEvent} from 'react';
import {useParams} from 'react-router-dom';

import ChatIcon from 'mdi-material-ui/Send.js';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';
import IconButton from '@mui/material/IconButton/IconButton.js';
import List from '@mui/material/List/List.js';
import TextField from '@mui/material/TextField/TextField.js';
import MoreIcon from '@mui/icons-material/MoreVert.js';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp.js';
import ReplayIcon from '@mui/icons-material/Replay';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import type Notifications from '@refinio/one.models/lib/models/Notifications.js';

import DefaultAttachmentView from '../attachmentViews/defaultView/DefaultAttachmentView.js';
import type {CachedChatMessage} from '@/root/chat/hooks/useChatMessages.js';
import useChatMessages from '@/root/chat/hooks/useChatMessages.js';
import useResizeObserverOnChildren from '@/root/chat/hooks/useResizeObserverOnChildren.js';
import useListScroller from '@/root/chat/hooks/useListScroller.js';
import useChatNavigation from '@/root/chat/hooks/useChatNavigation.js';
import {useTopicRoomByChannelId} from '@/hooks/chat/topicHooks.js';
import {useReferenceMenuPosition} from '@/hooks/menu/menu.js';
import {useLocationStackNavigator} from '@/utils/LocationStackNavigator.js';
import {MenuContext} from '@/context/MenuContext.js';
import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import type {AiResult} from '@/utils/AIUtils.js';
import useBlobDescriptorCache from '@/root/chat/hooks/useBlobDescriptorCache.js';
import useChatHeader from '@/root/chat/hooks/useChatHeader.js';
import CertificatePopup from '@/components/trust/CertificatePopup.js';
import ChatFileSelector from './ChatFileSelector.js';
import ChatAiSelector from './ChatAiSelector.js';
import ChatBubble from './ChatBubble.js';
import {generateThumbnails} from '@/root/chat/utils/thumbnail.js';
import type {
    AttachmentViewFactoryAdditionalData,
    AttachmentViewFactoryEntry
} from '../attachmentViews/types.js';
import {defaultAttachmentViewFactoryEntries} from '../attachmentViews/attachmentViewRenderers.js';

import './Chat.css';

/**
 * UI Element that visualizes chats with other persons.
 *
 * TODOs:
 * - Currently there is a mixup between topicId and channelId, because the rendering of chat messages couldn't be done
 *   using the TopicModel (interface is not suitable and fixing it is too hard) -> fix this, otherwise only channels
 *   without an owner will work.
 * - Everything in the 'Message sending & input field' is still a mess.
 *
 * @param props
 * @param props.topicModel - The topic model use for storing messages. This is a mess right now.
 * @param props.leuteModel - Leute model for getting the person names in the chat and my own id.
 * @param props.questionnaireModel - Questionnaire Model
 * @param props.channelManager - Channelmanager because we currently read messages directly from the channels.
 * @param props.notifications
 * @param props.attachmentViewFactoryAdditions
 * @constructor
 */
export default function Chat(props: {
    topicModel: TopicModel;
    leuteModel: LeuteModel;
    channelManager: ChannelManager;
    notifications: Notifications;
    attachmentViewAdditions?: AttachmentViewFactoryEntry[];
}): ReactElement {
    const {topicID} = useParams<{topicID: string}>();
    const inputRef = useRef<HTMLInputElement>(null);
    const locationStackNavigator = useLocationStackNavigator();
    const onError = console.error;

    // ######## AttachmentViews ########

    const blobDescriptorCache = useBlobDescriptorCache(onError);

    const attachmentViews = new Map(
        props.attachmentViewAdditions
            ? [...defaultAttachmentViewFactoryEntries, ...props.attachmentViewAdditions]
            : defaultAttachmentViewFactoryEntries
    );

    /**
     * create attachment views for attachments
     * @returns
     * @param msg
     */
    function createAttachmentsViews(msg: CachedChatMessage): ReactElement[] {
        return msg.attachments.map((attachmentInfo, index) => {
            if (attachmentInfo.cachedOneObject === undefined) {
                // cache for attachments is still loading
                return (
                    <Fragment key={index}>
                        <CircularProgress />
                    </Fragment>
                );
            }

            if (attachmentViews.has(attachmentInfo.cachedOneObject.$type$)) {
                const viewCreator = attachmentViews.get(attachmentInfo.cachedOneObject.$type$);
                if (viewCreator) {
                    let additionalData: AttachmentViewFactoryAdditionalData | undefined = undefined;
                    if (attachmentInfo.originalHash) {
                        additionalData = {originalHash: attachmentInfo.originalHash};
                    }
                    if (
                        attachmentInfo.cachedOneObject.$type$ === 'DocumentInfo_1_1_0' ||
                        attachmentInfo.cachedOneObject.$type$ === 'BlobDescriptor'
                    ) {
                        if (additionalData) {
                            additionalData.blobDescriptorCache = blobDescriptorCache;
                        } else {
                            additionalData = {blobDescriptorCache};
                        }
                    }
                    return (
                        <Fragment key={index}>
                            {viewCreator(
                                attachmentInfo.hash,
                                attachmentInfo.cachedOneObject,
                                getCertificatesView(msg),
                                onError,
                                additionalData
                            )}
                        </Fragment>
                    );
                }
            }

            return (
                <DefaultAttachmentView
                    key={index}
                    text={attachmentInfo.cachedOneObject.$type$}
                    getCertificatePopupView={getCertificatesView(msg)}
                    onError={onError}
                />
            );
        });
    }

    // ######## Navigation ########

    const goBack = useChatNavigation();

    // ######## Chat messages ########
    // eslint-disable-next-line no-unused-vars
    const [
        messages,
        loadNextBatchOfMessages,
        newMessageArrivedAtEndToggle,
        forceReloadMessages,
        channelOwner
    ] = useChatMessages(props.channelManager, props.leuteModel, topicID, onError);

    // reset notification count
    useEffect(() => {
        if (topicID) {
            props.notifications.resetNotificatioinCountForTopic(topicID);
        }
    }, [topicID, messages, props.notifications]);

    // ######## Scrolling ########

    const listElement = useRef<HTMLUListElement>(null);
    const listChildResizedToggle = useResizeObserverOnChildren(listElement, [messages]);
    const handleScrollEvent = useListScroller(
        listElement,
        [messages, listChildResizedToggle],
        [newMessageArrivedAtEndToggle],
        loadNextBatchOfMessages
    );

    // ######## Message sending & input field ########

    const [inputMessage, setInputMessage] = useState<string | undefined>(undefined);
    const topicRoom = useTopicRoomByChannelId(props.topicModel, topicID);

    /**
     * Updates the input message
     * @param event
     */
    function updateMessage(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setInputMessage(event.target.value /* .replaceAll('\n', '')*/);
    }

    /**
     * Send message, sets the input message on undefined
     */
    function sendMessage(): void {
        inputRef.current?.focus();
        if (inputMessage === undefined || inputMessage === '') {
            return;
        }

        if (topicRoom === undefined) {
            return;
        }

        // The reason why channelOwner has to be null (not undefined)
        // is that ChannelManager postToChannel
        // interprets that as no owner, while undefined
        // means 'use my main identity'
        topicRoom
            .sendMessage(inputMessage, undefined, channelOwner ?? null)
            .then(
                () => {
                    console.log('FINISHED!');
                    setInputMessage(undefined);
                },
                err => {
                    console.log('FINISHED - ERR!');
                    throw err;
                }
            )
            .catch(onError);
    }

    /**
     * Send Message on enter press
     * @param event
     */
    function sendMessageOnEnterPress(event: KeyboardEvent<HTMLDivElement>): void {
        if (event.key === 'Enter' && !event.shiftKey) {
            sendMessage();
        }
    }

    async function sendFiles(files: Array<File>, image?: boolean) {
        if (files === undefined || files.length <= 0) {
            return;
        }

        if (topicRoom === undefined) {
            return;
        }

        const message = inputMessage === undefined ? '' : inputMessage;
        if (image) {
            const thumbnails = await generateThumbnails(files, [300, 300]);
            const imagesData = files.map((originalImage, index) => ({
                original: originalImage,
                thumbnail: thumbnails[index]
            }));
            // The reason why channelOwner has to be null (not undefined)
            // is that ChannelManager postToChannel
            // interprets that as no owner, while undefined
            // means 'use my main identity'
            await topicRoom.sendMessageWithThumbnailImageAttachmentAsFile(
                message,
                imagesData,
                undefined,
                channelOwner ?? null
            );
            setInputMessage(undefined);
        } else {
            // The reason why channelOwner has to be null (not undefined)
            // is that ChannelManager postToChannel
            // interprets that as no owner, while undefined
            // means 'use my main identity'
            await topicRoom.sendMessageWithAttachmentAsFile(
                message,
                files,
                undefined,
                channelOwner ?? null
            );
            setInputMessage(undefined);
        }
    }

    function sendAiResult(result: AiResult) {
        if (topicRoom === undefined) {
            return;
        }
        let message = result.text === undefined ? '' : result.text;
        message += ` (generated by ${result.algorithm})`;
        const files = result.files === undefined ? [] : result.files;
        // The reason why channelOwner has to be null (not undefined)
        // is that ChannelManager postToChannel
        // interprets that as no owner, while undefined
        // means 'use my main identity'
        topicRoom
            .sendMessageWithAttachmentAsFile(message, files, undefined, channelOwner ?? null)
            .then(_ => {
                setInputMessage(undefined);
            })
            .catch(onError);
    }

    // ######## Layout support functions ########

    /**
     * @param index
     * @returns
     */
    function isFirstMessageInSequence(index: number): boolean {
        return index > 0 ? messages[index].authorIdHash !== messages[index - 1].authorIdHash : true;
    }

    // ######## Topic menu ########

    const {setMenuEntries, selectMenuEntry, isOpen, setMenuReference, setMenuClassName} =
        useContext(MenuContext);
    const topicMenuRef = useRef<HTMLDivElement>(null);
    useReferenceMenuPosition();

    function openTopicMenu(): void {
        setMenuReference(topicMenuRef.current);
        setMenuEntries([MENU_ENTRY.TOPIC_INFORMATION]);
        selectMenuEntry(() => topicMenuEntrySelected);
        setMenuClassName('menu-chat-information');
        isOpen(true);
    }

    async function topicMenuEntrySelected(entry: string): Promise<void> {
        isOpen(false);
        switch (entry) {
            case MENU_ENTRY.TOPIC_INFORMATION: {
                if (topicID === undefined) {
                    return;
                }
                const topicHash = await props.topicModel.topics.queryHashById(topicID);
                if (topicHash === undefined) {
                    return;
                }
                locationStackNavigator.pushCurrentLocationAndNavigate(
                    `/debug/object/unversioned/${topicHash}`
                );
                break;
            }
        }
    }

    // ######### Utils ##########

    function getCertificatesView(msg: CachedChatMessage): (onClose: () => void) => ReactElement {
        return (onClose: () => void) => (
            <CertificatePopup
                leuteModel={props.leuteModel}
                hashes={[msg.messageHash, msg.channelEntryHash, msg.creationTimeHash]}
                createOnlyHashes={[msg.messageHash]}
                onClose={onClose}
                isOpened={true}
            />
        );
    }

    // ########### Header #############
    const chatHeader = useChatHeader(topicRoom, props.leuteModel);

    return (
        <div className="page-container chat-container">
            <div className="chat-header">
                <div className="chat-header-name">{chatHeader}</div>
                <div className="chat-header-menu" ref={topicMenuRef}>
                    <IconButton
                        className="topic-menu-button"
                        color="inherit"
                        size="large"
                        onClick={forceReloadMessages}
                    >
                        <ReplayIcon />
                    </IconButton>
                    <IconButton
                        className="topic-menu-button"
                        edge="end"
                        color="inherit"
                        size="large"
                        onClick={openTopicMenu}
                    >
                        <MoreIcon />
                    </IconButton>
                </div>
            </div>
            <List
                ref={listElement}
                onScroll={handleScrollEvent}
                className="chat-messages-container"
            >
                {messages.map((msg, index) => {
                    return (
                        <div key={msg.channelEntryHash}>
                            <ChatBubble
                                className={`${msg.isMe ? 'chat-bubble-right' : 'chat-bubble-left'}`}
                                sender={
                                    msg.isMe === false && isFirstMessageInSequence(index)
                                        ? msg.author
                                        : undefined
                                }
                                message={msg.message}
                                date={msg.date}
                                attachmentsViews={createAttachmentsViews(msg)}
                                onMessageDetailsClicked={() =>
                                    locationStackNavigator.pushCurrentLocationAndNavigate(
                                        `/message/${topicID}/${msg.channelEntryHash}`
                                    )
                                }
                            />
                        </div>
                    );
                })}
            </List>
            <div className="chat-send-message">
                <div className="cnt-back-button" onClick={goBack}>
                    <KeyboardArrowUpIcon />
                </div>
                <TextField
                    className="chat-send-message-input-filed"
                    value={inputMessage === undefined ? '' : inputMessage}
                    id="write-message"
                    variant="standard"
                    onChange={updateMessage}
                    onKeyDown={sendMessageOnEnterPress}
                    multiline
                    maxRows={6}
                    InputProps={{inputRef: inputRef}}
                />
                <ChatFileSelector loading={false} onFileChange={sendFiles} />
                <ChatAiSelector loading={false} onResultChange={sendAiResult} />
                <IconButton onClick={sendMessage} aria-label="send message">
                    <ChatIcon className="icon-avatar-wrapper" />
                </IconButton>
            </div>
        </div>
    );
}
