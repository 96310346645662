import {useEffect} from 'react';

import type {IndexedDBEvent} from '@refinio/one.core/lib/system/storage-base.js';
import {onIndexedDB} from '@refinio/one.core/lib/system/storage-base.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';

export function useIndexedDBNotificationOrError() {
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    useEffect(() => {
        function notificationOnError(event: IndexedDBEvent) {
            if (event.code !== 'CLOSE') {
                return;
            }
            console.error('IndexedDB event', event.code, event.message);
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage(event.message);
        }

        return onIndexedDB.addListener(notificationOnError);
    }, []);
}
