import {useTheme} from '@mui/material/styles';
import {useState, type ReactElement, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '@mui/material';

import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import Markdown from '@/components/markdown/Markdown.js';
import './PatientCreate.css';

export function ConsentView(props: {
    giveConsent: () => void;
    cancelConsent: () => void;
    consentMDFileUrl: string;
}): ReactElement {
    const i18n = useTranslation();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
    const [opened, setOpened] = useState<boolean>(false);
    const toggleOpened = useCallback(() => setOpened(o => !o), [setOpened]);
    const toggleCheckbox = useCallback(() => setCheckboxValue(c => !c), [setCheckboxValue]);
    const next = useCallback(() => {
        if (checkboxValue) {
            props.giveConsent();
        } else {
            setNotificationMessage(i18n.t('malawi_demo.consent.checkboxError'));
            setNotificationType(NOTIFICATION.Error);
        }
    }, [checkboxValue, props.giveConsent, setNotificationMessage, setNotificationType]);
    const theme = useTheme();

    if (opened) {
        return (
            <AppBarFullScreenPopup
                background={theme.palette.background.default}
                key="consent-text"
                mode={APP_BAR_MODE.CHEVRON}
                title={i18n.t('malawi_demo.consent.title')}
                onClose={toggleOpened}
            >
                <Markdown className="consent-container" url={props.consentMDFileUrl} />
            </AppBarFullScreenPopup>
        );
    }

    return (
        <AppBarFullScreenPopup
            background={theme.palette.background.default}
            key="consent-main"
            mode={APP_BAR_MODE.EDIT}
            title={i18n.t('malawi_demo.consent.title')}
            onClose={props.cancelConsent}
            onFinish={next}
        >
            <div className="consent-container">
                <div className="consent-header">{i18n.t('malawi_demo.consent.header')}</div>
                <div className="consent-description">
                    {i18n.t('malawi_demo.consent.description')}
                </div>
                <div className="consent-link" onClick={toggleOpened}>
                    {i18n.t('malawi_demo.consent.link')}
                </div>
                <div className="consent-checkbox">
                    <Checkbox
                        id="edda_consent"
                        onChange={toggleCheckbox}
                        checked={checkboxValue}
                    />
                    <div className="text" onClick={toggleCheckbox}>
                        {i18n.t('malawi_demo.consent.checkboxText')}
                    </div>
                </div>
            </div>
        </AppBarFullScreenPopup>
    );
}
