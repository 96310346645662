import {OneObjectTypes, Person} from '@refinio/one.core/lib/recipes.js';
import {getObject} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import {objectEvents} from '@refinio/one.models/lib/misc/ObjectEventDispatcher.js';
import ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import {RawChannelEntry} from '@refinio/one.models/src/models/ChannelManager.js';
import {ChannelInfo} from '@refinio/one.models/src/recipes/ChannelRecipes.js';

let disconnectFns: Array<() => void> = [];

export function initLeuteLog(channelManager: ChannelManager) {
    disconnectFns.push(
        objectEvents.onNewVersion(
            result => {
                console.log(
                    `New ${result.obj.$type$} - id: ${
                        result.obj.profileId
                    }, personId: ${result.obj.personId.slice(
                        0,
                        8
                    )}, owner: ${result.obj.owner.slice(0, 8)}, dc: ${
                        result.obj.personDescription.length
                    }, ec: ${result.obj.communicationEndpoint.length}`
                );
            },
            'initLeuteLog: New profile version',
            'Profile'
        )
    );
    disconnectFns.push(
        objectEvents.onUnversionedObject(
            result => {
                console.log(
                    `New ${result.obj.$type$} - sender: ${result.obj.sender.slice(0, 8)}, text: ${
                        result.obj.text
                    }, ac: ${result.obj.attachments?.length || 0} - d: ${result.hash.slice(0, 8)}`
                );
            },
            'initLeuteLog: New chat message',
            'ChatMessage'
        )
    );
    channelManager.onUpdated(
        async (
            channelInfoIdHash: SHA256IdHash<ChannelInfo>,
            channelId: string,
            channelOwner: SHA256IdHash<Person> | null,
            timeOfEarliestChange: Date,
            data: Array<RawChannelEntry & {isNew: boolean}>
        ) => {
            try {
                console.log(
                    `Channel Manager Event with ${
                        data.length
                    } changes - ci: ${channelInfoIdHash.slice(
                        0,
                        8
                    )}, cid: ${channelId}, channelOwner: ${
                        channelOwner ? channelOwner.slice(0, 8) : 'null'
                    }, tec: ${timeOfEarliestChange}`
                );
                const dataAndObj: Array<RawChannelEntry & {isNew: boolean; obj: OneObjectTypes}> =
                    await Promise.all(
                        data.map(async d => {
                            const obj = await getObject(d.dataHash);
                            return {
                                ...d,
                                obj
                            };
                        })
                    );

                for (const [i, result] of dataAndObj.entries()) {
                    if (result.obj.$type$ === 'ChatMessage') {
                        console.log(
                            `${i}) ${result.isNew ? 'New' : 'Old'} ${result.obj.$type$} - sender:` +
                                ` ${result.obj.sender.slice(0, 8)}, text: ${result.obj.text}, ac: ${
                                    result.obj.attachments?.length || 0
                                } - ci: ${result.channelInfoIdHash.slice(
                                    0,
                                    8
                                )}, ce: ${result.channelEntryHash.slice(
                                    0,
                                    8
                                )}, ct: ${result.creationTimeHash.slice(
                                    0,
                                    8
                                )}, d: ${result.dataHash.slice(0, 8)}`
                        );
                    }
                }
            } catch (e) {
                console.error('Failed to load channel entry');
            }
        }
    );
}

export function shutdownLeuteLog() {
    for (const disconnectFn of disconnectFns) {
        disconnectFn();
    }
    disconnectFns = [];
}
