import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox, InputAdornment} from '@mui/material';

import type {WbcMeasurement} from '@refinio/one.models/lib/recipes/WbcDiffRecipes.js';
import type {WbcObservation} from '@refinio/one.models/lib/recipes/WbcDiffRecipes.js';
import {TextField} from '@mui/material';

const measurements = [
    'Leukocytes',
    'Neutrophils',
    'Lymphocytes',
    'Monocytes',
    'Eosinophils',
    'Basophils'
] as (keyof WbcObservation)[];

/**
 *
 * @param props.value measurements
 * @param props.onChange Optional. Default undefined. When undefined - component is in view more
 * @returns
 */
export default function WBCMeasurements(props: {
    value: Partial<WbcObservation>;
    onChange?: (key: keyof WbcObservation, value: WbcMeasurement) => void;
}): ReactElement {
    const isViewMode = props.onChange === undefined;

    function getMeasurement(key: keyof WbcObservation): WbcMeasurement {
        const measurement = props.value[key];
        return !measurement || typeof measurement === 'string'
            ? {value: '', unit: ''}
            : measurement;
    }

    function onMeasurementChange(key: keyof WbcObservation): (v: WbcMeasurement) => void {
        return (v: WbcMeasurement) => {
            if (props.onChange) {
                props.onChange(key, v);
            }
        };
    }

    return (
        <div className="wbc-measurements-container">
            {measurements.map(key => (
                <WBCMeasurement
                    key={key}
                    name={key}
                    measurement={getMeasurement(key)}
                    onChange={isViewMode ? undefined : onMeasurementChange(key)}
                />
            ))}
        </div>
    );
}

/**
 *
 * @param props.name measurement name
 * @param props.measurement
 * @param props.onChange Optional. Default undefined. When undefined - component is in view more
 * @returns
 */
function WBCMeasurement(props: {
    name: string;
    measurement: WbcMeasurement;
    onChange?: ((value: WbcMeasurement) => void) | undefined;
}): ReactElement {
    const isViewMode = props.onChange === undefined;
    const i18n = useTranslation();

    function onMeasurementValueChange(value: string) {
        if (props.onChange) {
            props.onChange({...props.measurement, value});
        }
    }

    function onMeasurementUnsafeChange(value: boolean) {
        if (props.onChange) {
            props.onChange({...props.measurement, unsafe: value});
        }
    }

    return (
        <div className="wbc-measurement-container">
            <TextField
                variant={'standard'}
                label={i18n.t(`wbc.measurementName.${props.name}`)}
                value={props.measurement.value}
                disabled={isViewMode}
                InputProps={{
                    endAdornment: <InputAdornment position="end">10^9/L</InputAdornment>
                }}
                onChange={isViewMode ? undefined : e => onMeasurementValueChange(e.target.value)}
            />
            <Checkbox
                onChange={isViewMode ? undefined : e => onMeasurementUnsafeChange(e.target.checked)}
                disabled={isViewMode}
            />
            {i18n.t('wbc.valueUnsafe')}
        </div>
    );
}
