import type {ReactElement} from 'react';

import TextField from '@mui/material/TextField/TextField.js';

import './TextFieldEntry.css';

export default function TextFieldEntry(props: {
    label: string;
    onDone: (value: string) => void;
    value: string;
    disableUnderline: boolean;
    inputMode?:
        | 'none'
        | 'text'
        | 'tel'
        | 'url'
        | 'email'
        | 'numeric'
        | 'decimal'
        | 'search'
        | undefined;
}): ReactElement {
    function filterValue(value: string) {
        if (props.inputMode === 'tel') {
            const allowedChars = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
            const allowedStart = '+';
            const hasStart = value.startsWith(allowedStart);
            return `${hasStart ? allowedStart : ''}${value
                .split('')
                .splice(hasStart ? allowedStart.length : 0, value.length)
                .filter(c => allowedChars.includes(c))
                .join('')}`;
        }

        return value;
    }

    return (
        <TextField
            inputMode={props.inputMode}
            multiline
            className="text-field-entry"
            value={props.value}
            label={props.label}
            variant="standard"
            fullWidth={true}
            onChange={event => props.onDone(filterValue(event.target.value))}
            InputProps={{disableUnderline: props.disableUnderline}}
        />
    );
}
