import {useCallback, useContext, useRef, type ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';

import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import {MenuContext} from '@/context/MenuContext.js';
import BaseAddItem from './BaseAddItem.js';

export default function LaboratoryItem(props: {dataOwner?: SHA256IdHash<Person>}): ReactElement {
    const navigate = useNavigate();
    const i18n = useTranslation();
    const itemRef = useRef<HTMLDivElement>(null);
    const {
        setMenuEntries,
        selectMenuEntry,
        isOpen,
        setMenuReference,
        setMenuClassName,
        setMenuAnchorOrigin
    } = useContext(MenuContext);

    const openMenu = useCallback(() => {
        setMenuReference(itemRef.current);
        setMenuAnchorOrigin({horizontal: 'center', vertical: 'center'});
        setMenuClassName('app-bar-cnt-add-menu');
        setMenuEntries([MENU_ENTRY.WBC, MENU_ENTRY.BodyTemperature]);
        selectMenuEntry(() => (entrySelected: string) => {
            setMenuClassName('app-bar-cnt-menu');
            isOpen(false);
            switch (entrySelected) {
                case MENU_ENTRY.WBC:
                    navigate(`/wbc/${props.dataOwner ? `${props.dataOwner}/` : ''}create`);
                    break;
                case MENU_ENTRY.BodyTemperature:
                    navigate(
                        `/bodyTemperature/${props.dataOwner ? `${props.dataOwner}/` : ''}create`
                    );
                    break;
            }
        });
        isOpen(true);
    }, []);

    return (
        <div ref={itemRef}>
            <BaseAddItem
                type="labratory"
                onClick={openMenu}
                title={i18n.t('home.widget.labratory')}
            />
        </div>
    );
}
