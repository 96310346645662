import type {RefObject} from 'react';
import {useEffect, useState} from 'react';

/**
 * This hook registers a resize observer on all children of the passed html list.
 *
 * Whenever an element changed size it will toggle the returned boolean value, so that you can use it as dependency of a
 * useEffect or useLayoutEffect e.g. to change the scrolling position.
 *
 * @param listRef - The list whose children is observed
 * @param reobserveTriggers - Whenever one of those values changes the observers will be reregistered on the listRef
 *                            children. This is used as dependency on the useEffect that registers / unregisters the
 *                            observer.
 */
export default function useResizeObserverOnChildren(
    listRef: RefObject<HTMLUListElement>,
    reobserveTriggers: unknown[]
): boolean {
    const [changedTrigger, setChangedTrigger] = useState(false);

    const [resizeObserver] = useState(
        new ResizeObserver(() => {
            setChangedTrigger(v => !v);
        })
    );

    useEffect(() => {
        if (listRef.current !== null) {
            const el = listRef.current;
            for (const child of el.children) {
                resizeObserver.observe(child);
            }
            return () => {
                for (const child of el.children) {
                    resizeObserver.unobserve(child);
                }
            };
        }
    }, [...reobserveTriggers, resizeObserver, listRef]);

    return changedTrigger;
}
