import type {ReactElement} from 'react';
import {useCallback, useContext, useRef, useState} from 'react';

import IconButton from '@mui/material/IconButton/IconButton.js';
import MoreIcon from '@mui/icons-material/MoreVert.js';

import type {SHA256Hash} from '@refinio/one.core/lib/util/type-checks.js';
import type {JournalEntry} from '@refinio/one.models/lib/models/JournalModel.js';

import {MenuContext} from '@/context/MenuContext.js';
import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';

/**
 *
 * @param filterEntryTypes Optional. Default undefined meaning apply to all
 * @returns
 */
export function useShareJournalEntryDefaults(
    filterEntryTypes: readonly string[] | string[] | undefined = undefined
): {
    sharedEntry: SHA256Hash | undefined;
    isSharePopupOpen: boolean;
    addonRenderFn: (entry: JournalEntry) => ReactElement | void;
    close: () => void;
} {
    const {setMenuEntries, selectMenuEntry, isOpen, setMenuClassName} = useContext(MenuContext);
    const [sharedEntry, setSharedEntry] = useState<SHA256Hash | undefined>(undefined);
    const [isSharePopupOpen, setIsSharePopupOpen] = useState<boolean>(false);

    const showMenuFor = useCallback(
        (hash: SHA256Hash) => {
            return () => {
                setMenuEntries([MENU_ENTRY.OBJECT_ACCESS]);
                selectMenuEntry(() => (selectedMenuEntry: string) => {
                    isOpen(false);
                    switch (selectedMenuEntry) {
                        case MENU_ENTRY.OBJECT_ACCESS:
                            setSharedEntry(hash);
                            setIsSharePopupOpen(true);
                            break;
                    }
                });
                setMenuClassName('menu-journal');
                isOpen(true);
            };
        },
        [isOpen, selectMenuEntry, setMenuEntries, setMenuClassName]
    );

    const addonRenderFn = useCallback(
        (entry: JournalEntry) => {
            if (!filterEntryTypes || filterEntryTypes.includes(entry.type)) {
                return <EntryMenu onClick={showMenuFor(entry.data.dataHash as SHA256Hash)} />;
            }
        },
        [filterEntryTypes, showMenuFor]
    );

    const close = useCallback(() => setIsSharePopupOpen(false), []);

    return {sharedEntry, isSharePopupOpen, addonRenderFn, close};
}

/**
 * @param props.showMenu callback
 * @returns
 */
function EntryMenu(props: {onClick: () => void}): ReactElement {
    const menuRef = useRef<HTMLDivElement>(null);
    const {setMenuReference} = useContext(MenuContext);

    function showMenu() {
        // needed to move the menu context to the right position in the UI
        setMenuReference(menuRef.current);
        props.onClick();
    }

    return (
        <div ref={menuRef} style={{alignSelf: 'center'}}>
            <IconButton
                sx={{'&:hover': {backgroundColor: 'transparent'}, padding: 0}}
                onClick={() => showMenu()}
            >
                <MoreIcon />
            </IconButton>
        </div>
    );
}
