import type {FormEvent, ReactElement} from 'react';
import {useContext, useState, useRef} from 'react';

import Button from '@mui/material/Button/Button.js';
import IconButton from '@mui/material/IconButton/IconButton.js';
import AddIcon from '@mui/icons-material/Add.js';

import ImageCapturePopup from '@refinio/one.ui/lib/ui/views/imageCapture/ImageCapturePopup.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import {FILE_FORMATS} from '@/components/Constants.js';
import {useEditPictureContext} from '@/context/EditPictureContext.js';
import {MenuContext} from '@/context/MenuContext.js';
import {getUploadImageError, getInputAcceptedTypes, getURL} from '@/utils/Utils.js';

import './PictureEditor.css';

/**
 * Represents the picture prompt which is displayed in the onboarding process.
 * @param props
 */
export default function UploadPicture(props: {image: ArrayBuffer | undefined}): ReactElement {
    // the state used to keep the data from the text field
    const buttonRef = useRef<HTMLLabelElement | null>(null);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const [showCamera, setShowCamera] = useState<boolean>(false);
    // popup menu control
    const {setMenuEntries, selectMenuEntry, isOpen, setMenuReference, setMenuClassName} =
        useContext(MenuContext);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const {
        originalPicture,
        setOriginalPicture,
        setOriginalPictureDimensions,
        originalPictureDimensions
    } = useEditPictureContext();

    /**
     * Upload the file from the device and set the image that will be edited.
     * @param event - event triggered by uploading a file.
     */
    function uploadFile(event: FormEvent<EventTarget>): void {
        let uploadedFile: File | undefined;

        if (event.target instanceof HTMLInputElement) {
            if (event.target.files !== null) {
                uploadedFile = event.target.files[0];
            }
        }

        if (!uploadedFile) {
            return;
        }

        const error = getUploadImageError(uploadedFile);

        if (error !== '') {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage(error);
            return;
        }

        const reader = new FileReader();

        reader.onload = function (e) {
            if (e.target !== null && uploadedFile !== undefined) {
                setOriginalPicture(undefined);
                setOriginalPictureDimensions({naturalWidth: 0, naturalHeight: 0});
                setOriginalPicture(e.target.result as ArrayBuffer);
            }
        };

        reader.readAsArrayBuffer(uploadedFile);
    }

    /**
     * Used to click the hidden button which allows the user to browse files.
     */
    function browseFile(): void {
        if (buttonRef.current !== null) {
            buttonRef.current.click();
        }
    }

    /**
     * Used to get the image initial height and width.
     * @param event - event triggered after the image is loaded.
     */
    function getImageInitialSize(event: HTMLImageElement): void {
        setOriginalPictureDimensions({
            naturalWidth: event.naturalWidth,
            naturalHeight: event.naturalHeight
        });
    }

    function openMenu() {
        setMenuReference(containerRef.current);
        setMenuClassName('upload-menu');
        setMenuEntries([MENU_ENTRY.SELECT_IMAGES, MENU_ENTRY.TAKE_IMAGE]);
        selectMenuEntry(() => (entrySelected: string) => {
            setMenuClassName('app-bar-cnt-menu');
            isOpen(false);
            switch (entrySelected) {
                case MENU_ENTRY.SELECT_IMAGES:
                    browseFile();
                    break;
                case MENU_ENTRY.TAKE_IMAGE:
                    setShowCamera(true);
                    break;
            }
        });
        isOpen(true);
    }

    return (
        <>
            <>
                <div className="upload-menu-anchor" ref={containerRef} />
                {showCamera && (
                    <ImageCapturePopup
                        active={showCamera}
                        onFileChange={async file => {
                            setShowCamera(false);
                            setOriginalPicture(undefined);
                            setOriginalPictureDimensions({naturalWidth: 0, naturalHeight: 0});
                            setOriginalPicture(await file.arrayBuffer());
                        }}
                        onClose={() => {
                            setShowCamera(false);
                        }}
                    />
                )}
                {props.image !== undefined ? (
                    <img
                        className="preview"
                        src={getURL(props.image)}
                        onClick={openMenu}
                        alt="avatar-preview"
                    />
                ) : (
                    <IconButton className="upload-image" onClick={openMenu}>
                        <AddIcon />
                    </IconButton>
                )}
                <Button
                    ref={buttonRef}
                    variant="contained"
                    component="label"
                    className="btn-file-input"
                >
                    <input
                        type="file"
                        hidden
                        accept={getInputAcceptedTypes(FILE_FORMATS)}
                        onClick={event => {
                            (event.target as HTMLInputElement).value = '';
                        }}
                        onChange={uploadFile}
                    />
                </Button>
            </>
            {originalPicture !== undefined &&
                originalPictureDimensions.naturalWidth === 0 &&
                originalPictureDimensions.naturalHeight === 0 && (
                    <img
                        src={getURL(originalPicture)}
                        onLoad={event => getImageInitialSize(event.target as HTMLImageElement)}
                        onError={() => {
                            setOriginalPicture(undefined);
                            setNotificationType(NOTIFICATION.Error);
                            setNotificationMessage('common.errors.fileUpload.corruptedFile');
                        }}
                        alt="edit"
                        id="drawing-canvas"
                    />
                )}
        </>
    );
}
