import React from 'react';
import type {ReactElement} from 'react';

import {PayPalScriptProvider, PayPalButtons} from '@paypal/react-paypal-js';
import type {ReactPayPalScriptOptions} from '@paypal/react-paypal-js';
import type {
    OnApproveData,
    OnApproveActions,
    CreateOrderData,
    CreateOrderActions,
    OnClickActions
} from '@paypal/paypal-js/types/components/buttons';
import './PaypalButton.css';

// TODO: talk about extracting this value to manifest or other config type file
const PAYPAL_CLIENT_ID = 'test';

const initialOptions: ReactPayPalScriptOptions = {
    clientId: PAYPAL_CLIENT_ID,
    currency: 'EUR',
    enableFunding: '',
    disableFunding: 'paylater,venmo,card',
    dataSdkIntegrationSource: 'integrationbuilder_sc'
};

/**
 * @param props.createOrder
 * @param props.onApprove
 * @param props.onClick
 * @param props.dependencies forceReRender
 * @returns
 */
export default function PaypalButton(props: {
    createOrder?: (data: CreateOrderData, actions: CreateOrderActions) => Promise<string>;
    onApprove?: (data: OnApproveData, actions: OnApproveActions) => Promise<void>;
    onClick?: (data: Record<string, unknown>, actions: OnClickActions) => Promise<void> | void;
    dependencies?: any[];
}): ReactElement {
    return (
        <div className="paypal-button">
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    forceReRender={props.dependencies}
                    className="paypal-button-container"
                    style={{
                        layout: 'vertical'
                    }}
                    createOrder={props.createOrder}
                    onApprove={props.onApprove}
                    onClick={props.onClick}
                />
            </PayPalScriptProvider>
        </div>
    );
}
