import {useEffect, useState} from 'react';

import {getInstanceOwnerIdHash} from '@refinio/one.core/lib/instance.js';
import type TrustedKeysManager from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';

import type {SomeonePreview} from '@/root/contacts/LeuteView.js';

export function useSignedUpSomeonesPreview(
    trust: TrustedKeysManager,
    someones: SomeonePreview[],
    onError: (error: Error) => void = console.error
): SomeonePreview[] {
    const [onlySignedUp, setOnlySignedUp] = useState<SomeonePreview[]>([]);

    useEffect(() => {
        async function filterSignedUpOnly(): Promise<void> {
            const me = getInstanceOwnerIdHash();
            if (!me) {
                return;
            }

            const signedUpOnly: SomeonePreview[] = [];

            for (const someone of someones) {
                if (
                    await trust.isCertifiedBy(
                        await someone.model.mainIdentity(),
                        'SignupCertificate',
                        me
                    )
                ) {
                    signedUpOnly.push(someone);
                }
            }
            setOnlySignedUp(signedUpOnly);
        }
        filterSignedUpOnly().catch(onError);
    }, [onError, someones, trust]);

    return onlySignedUp;
}
