import type {ReactElement} from 'react';
import {Routes, Route} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type IoMRequestManager from '@refinio/one.models/lib/models/IoM/IoMRequestManager.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import PageNotFound from '@/components/PageNotFound.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import IoMCreate from './IoMCreate.js';
import IoMView from './IoMView.js';

export default function IoMRouter(props: {
    leuteModel: LeuteModel;
    iomRequestManager: IoMRequestManager;
}): ReactElement {
    const i18n = useTranslation();

    return (
        <Routes>
            <Route
                path="/create"
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.iom_create')}
                        />
                        <IoMCreate
                            leuteModel={props.leuteModel}
                            iomRequestManager={props.iomRequestManager}
                        />
                    </>
                }
            />
            <Route
                path="/"
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.iom_view')}
                        />
                        <IoMView
                            leuteModel={props.leuteModel}
                            iomRequestManager={props.iomRequestManager}
                        />
                    </>
                }
            />
            <Route element={<PageNotFound />} />
        </Routes>
    );
}
