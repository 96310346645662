import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import ListElement from '@refinio/one.ui/lib/ui/components/list/ListElement.js';
import {shortenHash} from '@refinio/one.ui/lib/ui/views/utils/Utils.js';

import {getProfileAvatar} from '@/hooks/contact/utils.js';
import AvatarPreview from '@/components/avatarPreview/AvatarPreview.js';
import {getIdObject} from '@refinio/one.core/lib/storage-versioned-objects';

interface ProfileInformation {
    name: string;
    owner: string;
    email: string;
    identity: string;
    personId: SHA256IdHash<Person>;
    avatar?: string | undefined;
    tag?: string;
}

export default function ProfileListEntry(props: {
    leuteModel: LeuteModel;
    profileModel: ProfileModel;
    onSelect: () => void;
    topicModel: TopicModel;
    isMainProfile: boolean;
    hideDetailedInformation?: boolean;
    hideIdentityInfo?: boolean;
    showDefaultLabel?: boolean;
    entryAddon?: (profileModel: ProfileModel) => ReactElement | undefined;
    onError?: (error: Error) => void;
}): ReactElement {
    const onError = props.onError ? props.onError : console.error;
    const [profileInformation, setProfileInformation] = useState<ProfileInformation | undefined>();
    const i18n = useTranslation();

    useEffect(() => {
        async function getInfo() {
            const person = await getIdObject(props.profileModel.personId);
            const partialInformation = {
                name: props.profileModel.descriptionsOfType('PersonName')[0]
                    ? props.profileModel.descriptionsOfType('PersonName')[0].name
                    : 'unknown',
                personId: props.profileModel.personId,
                tag: props.profileModel.profileId,
                owner: `${await props.leuteModel.getMainProfileDisplayName(
                    props.profileModel.owner
                )} (${shortenHash(props.profileModel.owner)})`,
                identity: `${await props.leuteModel.getMainProfileDisplayName(
                    props.profileModel.personId
                )} (${shortenHash(props.profileModel.personId)})`,
                email: person.email.indexOf('@') > -1 ? person.email : shortenHash(person.email, 7)
            };

            getProfileAvatar(props.profileModel)
                .then(avatar => setProfileInformation({...partialInformation, avatar: avatar}))
                .catch(() => setProfileInformation({...partialInformation}));
        }

        getInfo().catch(onError);
    }, [props.leuteModel, props.profileModel]);

    return (
        <>
            {profileInformation !== undefined && (
                <ListElement
                    onClick={props.onSelect}
                    extra={
                        <>
                            <AvatarPreview src={profileInformation.avatar} />
                            <div>
                                {profileInformation.name}
                                {props.hideDetailedInformation === undefined ||
                                !props.hideDetailedInformation ? (
                                    <div className="profile-person-id">
                                        <div className="pid">
                                            {`${
                                                props.hideIdentityInfo
                                                    ? ''
                                                    : `${i18n.t('common.identity')}: ${
                                                          profileInformation.email
                                                      } /`
                                            } ${i18n.t('common.owner')}: ${
                                                profileInformation.owner
                                            }`}
                                            {props.isMainProfile && ' / ' + i18n.t('common.main')}
                                            {profileInformation.tag === 'default' &&
                                                ' / ' + i18n.t('common.default')}
                                        </div>
                                    </div>
                                ) : (
                                    props.isMainProfile && (
                                        <div className="additional-gray-information">
                                            {i18n.t('common.default')}
                                        </div>
                                    )
                                )}
                            </div>
                            {props.entryAddon && props.entryAddon(props.profileModel)}
                        </>
                    }
                />
            )}
        </>
    );
}
