import { useEffect, useState, type ReactElement } from 'react';

import type { SHA256Hash, SHA256IdHash } from '@refinio/one.core/lib/util/type-checks.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import CertificateCreate from './CertificateCreate.js';
import CertificatesView from './CertificatesView.js';
import type { CertificateSetting } from './common/types.js';

import ChatBubble from '@/root/chat/chatId/ChatBubble.js';
import Paper from '@mui/material/Paper/Paper.js';
import { Person } from '@refinio/one.core/lib/recipes.js';
import { getObject } from '@refinio/one.core/lib/storage-unversioned-objects.js';
import { getObjectByIdHash } from '@refinio/one.core/lib/storage-versioned-objects.js';
import { ChannelManager } from '@refinio/one.models/lib/models/index.js';
import { ChannelInfo, LinkedListEntry } from '@refinio/one.models/lib/recipes/ChannelRecipes.js';
import { ChatMessage } from '@refinio/one.models/lib/recipes/ChatRecipes.js';
import { CreationTime } from '@refinio/one.models/lib/recipes/MetaRecipes.js';
import { QRCodeSVG } from 'qrcode.react';
import { useLocation, useParams } from 'react-router-dom';
import './TrustView.css';
import { getDefaultCertificateSettings } from './common/utils.js';



type MessageDetails = {
    creationTime: CreationTime;
    author: Person;
    message: ChatMessage;
};


export default function ChatMessageDetails(props: {
    leuteModel: LeuteModel;
    channelManager: ChannelManager;
    overwriteCertificateSettings?: CertificateSetting[];
}): ReactElement {
    const [hashes, setHashes] = useState<(SHA256Hash | SHA256IdHash)[]>([]);
    const [messsage, setMessageDetails] = useState<MessageDetails | undefined>(undefined);
    const [channelDetails, setChannelDetails] = useState<ChannelInfo[] | undefined>(undefined);

    const params = useParams();

    const channelEntryID = params.channelEntryID as SHA256Hash<LinkedListEntry>;
    const channelId = params.chatID;

    const location = useLocation();


    // TODO: Error Handling
    useEffect(() => {
        const fetchMessageDetails = async () => {
            const channelEntryObj = await getObject(channelEntryID);
            const creationTimeObj = await getObject(channelEntryObj.data) as CreationTime;
            const chatMessageObj = await getObject(creationTimeObj.data) as ChatMessage;
            const author = (await getObjectByIdHash(chatMessageObj.sender)).obj;
            setHashes([channelEntryID, channelEntryObj.data, creationTimeObj.data]);
            setMessageDetails({author, message: chatMessageObj, creationTime: creationTimeObj})
        };
        const fetchChannelDetails = async () => {
            const channelDetails = await props.channelManager.getMatchingChannelInfos({
                channelId: channelId,
            });
            console.log(channelDetails);
            setChannelDetails(channelDetails);
        }

        fetchMessageDetails()
        fetchChannelDetails();
    }, [channelEntryID, channelId]);

    const certificateSettings = props.overwriteCertificateSettings
        ? props.overwriteCertificateSettings
        : getDefaultCertificateSettings(props.leuteModel.trust);

    return (
        <div className="page-container">
            {channelDetails &&
                JSON.stringify(channelDetails)
            }
            {messsage && (
                <ChatBubble
                    date={new Date(messsage.creationTime.timestamp)}
                    className="chat-bubble-right"
                    sender={messsage.author.name}
                    message={messsage.message.text}
                />
            )}
            <CertificateCreate
                trustedKeysManager={props.leuteModel.trust}
                // We pass only the message hash to the CertificateCreate component
                hashes={[hashes[2]]}
                overwriteCertificateSettings={certificateSettings}
            />
            <CertificatesView
                hashes={hashes}
                leuteModel={props.leuteModel}
                certificateSettings={certificateSettings}
            />

            Copy this page:
            <Paper square elevation={3} className="page-content-box page-content-box-qr-code">
                <QRCodeSVG value={location.pathname} size={200} />
            </Paper>

        </div>
    );
}
