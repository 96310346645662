import {useCallback, useState} from 'react';
import type {ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import type {QuestionnaireResponse} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Someone} from '@refinio/one.models/lib/recipes/Leute/Someone.js';
import {ConsentType, type Consent} from '@refinio/one.models/lib/models/ConsentModel.js';
import {storeFileWithBlobDescriptor} from '@refinio/one.models/lib/misc/storeFileWithBlobDescriptor.js';
import {storeUnversionedObject} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import {doesPersonExistByEmail} from '@refinio/one.models/lib/misc/person.js';
import BodyTemperatureModel from '@refinio/one.models/lib/models/BodyTemperatureModel.js';
import DiaryModel from '@refinio/one.models/lib/models/DiaryModel.js';
import WbcDiffModel from '@refinio/one.models/lib/models/WbcDiffModel.js';
import {createAccess} from '@refinio/one.core/lib/access.js';
import {SET_ACCESS_MODE} from '@refinio/one.core/lib/storage-base-common.js';
import DocumentModel from '@refinio/one.models/lib/models/DocumentModel.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {usePatientOnboarding} from '@/hooks/questionnaire/patientOnboarding.js';
import {usePersonId} from '@/hooks/contact/commonHooks.js';
import QuestionnaireDisplay from '@/root/questionnaires/QuestionnaireDisplay.js';
import {createSomeoneWithDescription, getBLOBHashFromBase64} from '@/utils/Utils.js';
import {getTrustObject} from '@/components/trust/common/getTrustObject.js';
import {ConsentView} from './ConsentView.js';
import patienConsentMDFileUrl from '@/locales/leute.one/en/MDFiles/Patient_consent.md';
import './PatientCreate.css';

const questionnaireName = 'onboarding_patient';
const questionnaireLanguage = 'en';

async function postConsent(
    channelManager: ChannelManager,
    owner: SHA256IdHash<Person>
): Promise<void> {
    // ensure channel exists
    await channelManager.createChannel('consentFile', owner);

    const consentLocalFileResponse = await fetch(patienConsentMDFileUrl);
    const consentContent = await consentLocalFileResponse.blob();

    const blobDescriptor = await storeFileWithBlobDescriptor(
        new File([consentContent], 'consent.md')
    );

    const consent = {
        $type$: ConsentType,
        fileReference: blobDescriptor.hash,
        isoStringDate: new Date().toISOString(),
        status: 'given',
        text: 'Consent to take part at Digital Cervicography (DC), Cryotherapy, Thermal Ablation, and Biopsy'
    } as Consent;

    const consentResult = await storeUnversionedObject(consent);

    await channelManager.postToChannel('consentFile', consentResult.obj, owner);
}

export default function PatientCreate(props: {
    questionnaireModel: QuestionnaireModel;
    leuteModel: LeuteModel;
    channelManager: ChannelManager;
    topicModel: TopicModel;
    listRoute: string;
    patientRoute: string;
}): ReactElement {
    const i18n = useTranslation();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const [stage, setStage] = useState<'consent' | 'questionnaire'>('consent');
    const navigate = useNavigate();
    const goToPatientsList = useCallback(
        () => navigate(props.listRoute),
        [navigate, props.listRoute]
    );
    const goToPatientsView = useCallback(
        (someoneId: SHA256IdHash<Someone>) =>
            navigate(props.patientRoute.replace(':someoneId', someoneId)),
        [navigate, props.patientRoute]
    );
    const giveConsent = useCallback(() => setStage('questionnaire'), [setStage]);
    const patientOnboarding = usePatientOnboarding();
    const me = usePersonId(props.leuteModel);

    async function createPatientChannels(
        myPersonId: SHA256IdHash<Person>,
        patientPersonId: SHA256IdHash<Person>
    ): Promise<void> {
        await props.topicModel.createOneToOneTopic(myPersonId, patientPersonId);
        await createAccess([
            {
                id: await props.channelManager.createChannel(
                    QuestionnaireModel.channelId,
                    patientPersonId
                ),
                person: [patientPersonId],
                group: [],
                mode: SET_ACCESS_MODE.ADD
            }
        ]);
        await createAccess([
            {
                id: await props.channelManager.createChannel(
                    DocumentModel.channelId,
                    patientPersonId
                ),
                person: [patientPersonId],
                group: [],
                mode: SET_ACCESS_MODE.ADD
            }
        ]);
        await createAccess([
            {
                id: await props.channelManager.createChannel(
                    BodyTemperatureModel.channelId,
                    patientPersonId
                ),
                person: [patientPersonId],
                group: [],
                mode: SET_ACCESS_MODE.ADD
            }
        ]);
        await createAccess([
            {
                id: await props.channelManager.createChannel(
                    WbcDiffModel.channelId,
                    patientPersonId
                ),
                person: [patientPersonId],
                group: [],
                mode: SET_ACCESS_MODE.ADD
            }
        ]);
        await createAccess([
            {
                id: await props.channelManager.createChannel(DiaryModel.channelId, patientPersonId),
                person: [patientPersonId],
                group: [],
                mode: SET_ACCESS_MODE.ADD
            }
        ]);
    }

    async function createPatient(response: QuestionnaireResponse) {
        const name = patientOnboarding.extract.name(response);
        const email = patientOnboarding.extract.email(response);
        const image = patientOnboarding.extract.image(response);
        const certificateChecked = patientOnboarding.extract.certificateChecked(response);

        if (name && me) {
            if (email !== undefined && (await doesPersonExistByEmail(email))) {
                setNotificationMessage(i18n.t('edda_errors.patient.emailIsRegistered'));
                setNotificationType(NOTIFICATION.Error);
                return;
            }
            const personId = await createSomeoneWithDescription(
                props.leuteModel,
                name,
                email,
                image ? await getBLOBHashFromBase64(image.base64) : undefined
            );
            await createPatientChannels(me, personId);
            await props.questionnaireModel.postResponse(response, undefined, undefined, personId);
            await postConsent(props.channelManager, personId);

            if (certificateChecked) {
                const trustObject = await getTrustObject(personId);
                await props.leuteModel.trust.certify('SignupCertificate', {
                    person: trustObject.hash as unknown as SHA256IdHash<Person>
                });

                const someone = await props.leuteModel.getSomeone(personId);
                if (someone) {
                    return goToPatientsView(someone.idHash);
                }
            }

            return goToPatientsList();
        }
    }

    if (stage === 'consent') {
        return (
            <ConsentView
                cancelConsent={goToPatientsList}
                giveConsent={giveConsent}
                consentMDFileUrl={patienConsentMDFileUrl}
            />
        );
    }

    return (
        <QuestionnaireDisplay
            questionnaireModel={props.questionnaireModel}
            questionnaireName={questionnaireName}
            questionnaireLanguage={questionnaireLanguage}
            saveResponseOverwrite={createPatient}
        />
    );
}
