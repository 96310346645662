import type {ReactElement} from 'react';
import {useCallback} from 'react';
import {useParams} from 'react-router-dom';

import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {QuestionnaireResponse} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

import QuestionnaireDisplay from './QuestionnaireDisplay.js';
import {useNavigateBack} from '@/hooks/navigation.js';

export default function QuestionnaireDisplayOwnerAdapter(props: {
    questionnaireModel: QuestionnaireModel;
}): ReactElement {
    const params = useParams<{owner: SHA256IdHash<Person>}>();
    const navigateBack = useNavigateBack();
    const completeQuestionnaire = useCallback(
        async (response: QuestionnaireResponse) => {
            await props.questionnaireModel.postResponse(
                response,
                undefined,
                undefined,
                params.owner
            );
            navigateBack();
        },
        [props.questionnaireModel]
    );

    return (
        <QuestionnaireDisplay
            questionnaireModel={props.questionnaireModel}
            saveResponseOverwrite={completeQuestionnaire}
        />
    );
}
