import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const SchmerzerfassungQuestionnaireNumAssessmentDE: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/Schmerzerfassung_NumAssessment',
    name: 'Schmerzerfassung_NumAssessment',
    title: 'Schmerzerfassung Numerical Assessment',
    status: 'active',
    item: [
        {
            linkId: 'numAssessment',
            prefix: '1',
            type: 'group',
            text: 'Numerische Einschätzung',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'numResting',
                    prefix: '1_1',
                    type: 'choice',
                    text: 'Im Ruhezustand',
                    required: true,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: '0',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: '1',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '2',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '3',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '4',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '5',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '6',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: '7',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '8',
                                version: '1.0',
                                display: '8',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '9',
                                version: '1.0',
                                display: '9',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '10',
                                version: '1.0',
                                display: '10',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'numLoad',
                    prefix: '1_2',
                    type: 'choice',
                    text: 'Bei Belastung',
                    required: true,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: '0',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: '1',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '2',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '3',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '4',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '5',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '6',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: '7',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '8',
                                version: '1.0',
                                display: '8',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '9',
                                version: '1.0',
                                display: '9',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        },
                        {
                            valueCoding: {
                                code: '10',
                                version: '1.0',
                                display: '10',
                                system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/Pain_en'
                            }
                        }
                    ]
                },
                {
                    linkId: 'numComment',
                    prefix: '1_3',
                    type: 'string',
                    text: 'Bemerkung:',
                    required: false,
                    disabledDisplay: 'protected'
                }
            ]
        }
    ]
};
