import React, {useContext, useEffect} from 'react';
import type {ReactElement} from 'react';

import type {AppBarData} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {APP_BAR_MODE, AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {useLocation, useNavigate} from 'react-router-dom';

import {useNavigateBack} from '../../hooks/navigation.js';

/**
 * Helper component for configurating AppBar
 *
 * @param props @see AppBarData
 * @param props.navigateBackOnClose Should try to go back. Can pass a string for navigation route. only on CHEVRON or EDIT
 * @param props.force Forces the props on the state
 * @returns
 */
export default function AppBarSettings(
    props: Partial<AppBarData> & {
        navigateBackOnClose?: true | string;
        force?: boolean;
        onUnmount?: Partial<AppBarData>;
    }
): ReactElement {
    const {setContextValue, contextValue} = useContext(AppBarContext);
    const location = useLocation();
    const navigate = useNavigate();
    const navigateBack = useNavigateBack();

    useEffect(() => {
        if (props.force === undefined || !props.force) {
            setContextValue(setNewValuesCallback(props, navigate, navigateBack));
        }
        return () => {
            if (props.onUnmount) {
                setContextValue(oldContext => ({...oldContext, ...props.onUnmount}));
            }
        };
        // mount / unmount only
    }, []);

    // on location change refresh
    useEffect(() => {
        setContextValue(setNewValuesCallback(props, navigate, navigateBack));
    }, [location, navigate, navigateBack]);

    useEffect(() => {
        if (hasDifference(props, contextValue) && (props.force !== undefined || props.force)) {
            setContextValue(setNewValuesCallback(props, navigate, navigateBack));
        }
    }, [props, setContextValue, contextValue, navigate, navigateBack]);

    return <></>;
}

function hasDifference(
    newValues: Partial<AppBarData> & {navigateBackOnClose?: string | boolean | undefined},
    contextValue: AppBarData
): boolean {
    const newValuesKeys = Object.keys(newValues) as (keyof AppBarData)[];

    for (const name of newValuesKeys) {
        if (contextValue[name] !== newValues[name]) {
            return true;
        }
    }

    return false;
}

function setNewValuesCallback(
    props: Partial<AppBarData> & {navigateBackOnClose?: string | boolean | undefined},
    navigate: (route: string) => void,
    navigateBack: () => void
): (oldContext: AppBarData) => AppBarData {
    return (oldContext: AppBarData) => {
        if (
            props.navigateBackOnClose !== undefined &&
            (props.mode === APP_BAR_MODE.CHEVRON ||
                props.mode === APP_BAR_MODE.EDIT ||
                (props.mode === undefined &&
                    (oldContext.mode === APP_BAR_MODE.CHEVRON ||
                        oldContext.mode === APP_BAR_MODE.EDIT)))
        ) {
            return {
                ...oldContext,
                ...props,
                leftBtnCallback: () => {
                    if (typeof props.navigateBackOnClose === 'string') {
                        navigate(props.navigateBackOnClose);
                    } else {
                        navigateBack();
                    }
                }
            };
        }
        return {...oldContext, ...props};
    };
}
