import type {ReactElement} from 'react';

import i18n from '@/i18n.js';

import '@/components/PageNotFound.css';

export default function PageNotFound(): ReactElement {
    return (
        <div className="not-found-container">
            <h2>
                <span role="img" aria-label="emoji-hand">
                    👋
                </span>{' '}
                {i18n.t('errors.NotFound.title')}
            </h2>
            <h4>
                <div className="not-found-text-desc">{i18n.t('errors.NotFound.desc')}</div>
            </h4>
        </div>
    );
}
