import type {ReactElement} from 'react';
import {Routes, Route} from 'react-router-dom';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';

import PageNotFound from '@/components/PageNotFound.js';
import {CreateGroup} from './create/CreateGroup.js';
import GroupView from './groupId/GroupView.js';
import EditGroupDetails from './groupId/edit/general/EditGroupDetails.js';
import EditGroupMembers from './groupId/edit/members/EditGroupMembers.js';
import type BlacklistModel from '@/model/BlacklistModel.js';

export default function GroupRouter(props: {
    topicModel: TopicModel;
    leuteModel: LeuteModel;
    blacklistModel: BlacklistModel;
}): ReactElement {
    const {leuteModel} = props;

    return (
        <Routes>
            <Route path={'create'} element={<CreateGroup leuteModel={leuteModel} />} />
            <Route path={':groupId'} element={<GroupView leuteModel={leuteModel} />} />
            <Route path={':groupId/edit/general'} element={<EditGroupDetails />} />
            <Route
                path={':groupId/edit/members'}
                element={
                    <EditGroupMembers
                        leuteModel={leuteModel}
                        blacklistModel={props.blacklistModel}
                    />
                }
            />
            <Route element={<PageNotFound />} />
        </Routes>
    );
}
