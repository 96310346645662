import type {ReactElement} from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import type {ReactZoomPanPinchRef} from 'react-zoom-pan-pinch';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';

import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import i18n from '@/i18n.js';
import {getURL} from '@/utils/Utils.js';
import {useEditPictureContext} from '@/context/EditPictureContext.js';
import {useEditMode} from '@/hooks/appBar/common.js';

import './PictureEditor.css';

const CANVAS_SIDE = 900;
const CANVAS_BACKGROUND_COLOR = 'white';
const EDITOR_WIDTH = 300;
const EDITOR_HEIGHT = 300;

/**
 * Used to edit the picture uploaded by the user while creating the someone.
 * @param props
 * @param props.originalImage - the image that is loaded in the editor.
 * @param props.onDone - callback for exiting the edit mode.
 * @param props.setEditedImage - callback for setting the edited image.
 * @param props.imageSize - initial width and height of the uploaded image.
 */
export default function EditPicture(props: {
    originalImage: ArrayBuffer;
    onDone: () => void;
}): ReactElement {
    const {originalImage, onDone} = props;
    const appBarContext = useContext(AppBarContext);
    const {setEditedPicture, originalPictureDimensions} = useEditPictureContext();

    function closeEditing(): void {
        onDone();
    }

    // the offsets of the edited picture -> are modified when the user drag the picture
    const [xOffset, setXOffset] = useState(0);
    const [yOffset, setYOffset] = useState(0);

    // the image scale -> changed while zooming, pinching
    const [scale, setScale] = useState(calculateMinimumScale());

    const [canvasSide, setCanvasSide] = useState(CANVAS_SIDE);

    // minimum scale calculated based on the image initial size:
    // -> if the image is larger than the editor, it will be scaled down to fit the editor height or width
    //    (if the height is smaller than width then the image will fit the editor vertically otherwise horizontally)
    const minimumScale = calculateMinimumScale();

    const drawPicture = useCallback(async () => {
        const canvas = document.getElementById('drawing-canvas') as HTMLCanvasElement;
        if (canvas !== null) {
            const ctx = canvas.getContext('2d');

            if (ctx !== null) {
                const url = getURL(originalImage);
                const img = new Image();
                img.src = url;

                img.onload = function () {
                    if (
                        originalPictureDimensions.naturalHeight * scale >= EDITOR_HEIGHT &&
                        originalPictureDimensions.naturalWidth * scale >= EDITOR_WIDTH
                    ) {
                        setCanvasSide(CANVAS_SIDE);
                        ctx.scale(scale, scale);
                        const newWidth = scale >= 1 ? EDITOR_WIDTH : EDITOR_WIDTH / scale;
                        const newHeight = scale >= 1 ? EDITOR_HEIGHT : EDITOR_HEIGHT / scale;
                        const newCanvasSide = scale >= 1 ? CANVAS_SIDE : CANVAS_SIDE / scale;
                        ctx.drawImage(
                            img,
                            Math.abs(xOffset / scale),
                            Math.abs(yOffset / scale),
                            newWidth,
                            newHeight,
                            0,
                            0,
                            newCanvasSide,
                            newCanvasSide
                        );
                    } else {
                        setCanvasSide(EDITOR_HEIGHT);
                        ctx.fillStyle = CANVAS_BACKGROUND_COLOR;
                        ctx.fillRect(0, 0, EDITOR_WIDTH, EDITOR_HEIGHT);
                        const newWidth = originalPictureDimensions.naturalWidth * scale;
                        const newHeight = originalPictureDimensions.naturalHeight * scale;
                        ctx.drawImage(
                            img,
                            0,
                            0,
                            originalPictureDimensions.naturalWidth,
                            originalPictureDimensions.naturalHeight,
                            (EDITOR_WIDTH - newWidth) / 2,
                            (EDITOR_HEIGHT - newHeight) / 2,
                            newWidth,
                            newHeight
                        );
                    }
                    ctx.save();

                    canvas.toBlob(
                        async blob => {
                            const arB = await blob?.arrayBuffer();
                            setEditedPicture(arB);
                            onDone();
                        },
                        'image/jpeg',
                        1
                    );

                    URL.revokeObjectURL(url);
                };
            }
        }
    }, [
        scale,
        xOffset,
        yOffset,
        originalImage,
        originalPictureDimensions.naturalWidth,
        originalPictureDimensions.naturalHeight
    ]);

    useEditMode(closeEditing, drawPicture);

    // each time when the users change the group members, update the onDoneCallback
    useEffect(() => {
        if (appBarContext.contextValue.mode === APP_BAR_MODE.EDIT) {
            appBarContext.setContextValue({
                ...appBarContext.contextValue,
                rightBtnCallback: drawPicture,
                leftBtnCallback: closeEditing
            });
        }
    }, [drawPicture]);

    /**
     * Used to calculate the minimum scale for the uploaded picture.
     */
    function calculateMinimumScale(): number {
        if (
            originalPictureDimensions.naturalHeight <= EDITOR_HEIGHT ||
            originalPictureDimensions.naturalWidth <= EDITOR_WIDTH
        ) {
            return 1;
        }

        if (originalPictureDimensions.naturalWidth <= originalPictureDimensions.naturalHeight) {
            return 1 / (originalPictureDimensions.naturalWidth / EDITOR_WIDTH);
        }

        return 1 / (originalPictureDimensions.naturalHeight / EDITOR_HEIGHT);
    }

    useEffect(() => {
        setXOffset((originalPictureDimensions.naturalWidth * minimumScale - EDITOR_WIDTH) / 2);
        setYOffset((originalPictureDimensions.naturalHeight * minimumScale - EDITOR_HEIGHT) / 2);
    }, [
        originalPictureDimensions.naturalWidth,
        originalPictureDimensions.naturalHeight,
        minimumScale
    ]);

    /**
     * Whenever the user interact with the image editor we need to update the image states.
     * @param editorRef - editor reference.
     */
    function updateImageStates(editorRef: ReactZoomPanPinchRef): void {
        const newScale = editorRef.state.scale;
        const minScale = minimumScale !== undefined ? minimumScale : 1;

        setScale(newScale > minScale ? newScale : minScale);

        setXOffset(Math.abs(editorRef.state.positionX));
        setYOffset(Math.abs(editorRef.state.positionY));
    }

    return (
        <div className="editor">
            <TransformWrapper
                centerOnInit={true}
                onPinchingStop={ref => updateImageStates(ref)}
                onPanningStop={ref => updateImageStates(ref)}
                onZoomStop={ref => updateImageStates(ref)}
                alignmentAnimation={{
                    disabled: true,
                    sizeX: 0,
                    sizeY: 0,
                    animationType: 'linear'
                }}
                velocityAnimation={{
                    animationTime: 0,
                    animationType: 'linear',
                    sensitivity: 0
                }}
                panning={{velocityDisabled: true}}
                minScale={minimumScale}
                initialScale={minimumScale}
                zoomAnimation={{size: minimumScale, animationTime: 0}}
                centerZoomedOut={true}
            >
                <TransformComponent>
                    <img src={getURL(originalImage)} alt="edit" />
                </TransformComponent>
            </TransformWrapper>
            <div className="edit-information body2">
                {i18n.t('onboarding.editPicturePrompt.info')}
            </div>
            <canvas id="drawing-canvas" width={canvasSide} height={canvasSide} />
        </div>
    );
}
