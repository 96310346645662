import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const IFCPCcolposcopicterminologyofthecervix: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/2011IFCPCcolposcopicterminologyofthecervix',
    name: '2011_IFCPC_colposcopic_terminology_cervix',
    title: '2011 IFCPC colposcopic terminology of the cervix',
    status: 'active',
    item: [
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'general',
            prefix: '1',
            required: false,
            text: 'General Assesment',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'cervix',
                    prefix: '1_1',
                    required: false,
                    text: 'Cervix adequate/inadequate',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Adequate',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/adequate_inadequate'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Inadequate',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/adequate_inadequate'
                            }
                        }
                    ]
                },
                {
                    type: 'string',
                    disabledDisplay: 'hidden',
                    linkId: 'reason',
                    prefix: '1_2',
                    required: false,
                    text: 'Reason (i.e.: cervixobscured by inflammation, bleeding, scar)',
                    enableWhen: [
                        {
                            question: 'cervix',
                            operator: '=',
                            answerCoding: {
                                code: '0'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'visibility',
                    prefix: '1_3',
                    required: false,
                    text: 'Squamo-columnar Junction visibility',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'complete visible',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/visibility'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'partially visible',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/visibility'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'not visible',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/visibility'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'transformation',
                    prefix: '1_4',
                    required: false,
                    text: 'Transformation zone type',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: '1',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/transformation'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '2',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/transformation'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '3',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/transformation'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'normal_findings',
            prefix: '2',
            required: false,
            text: 'Normal colposcopic findings',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'squamous_epithelium',
                    prefix: '2_1',
                    required: false,
                    text: 'Original squamous epithelium:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Mature',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/mature_atrophic'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Atrophic',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/mature_atrophic'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'colmunar_epithelium',
                    prefix: '2_2',
                    required: false,
                    text: 'Colmunar epithelium',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ectopy',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/Ectopy'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'metaplastic_epithelium',
                    prefix: '2_3',
                    required: false,
                    text: 'Metaplastic squamous epithelium:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Nabothian cyst',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/squamous_epithelium'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Crypt (gland) openings',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/squamous_epithelium'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'deciduousis_pregnancy',
                    prefix: '2_4',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Deciduosis in pregnancy',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/Deciduosis_pregnancy'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'abnormal_findings',
            prefix: '3',
            required: false,
            text: 'Abnormal colposcopic findings',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'location_lesion',
                    prefix: '3_1',
                    required: false,
                    text: 'Location of the lesion: Inside or outside the T-zone',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Inside',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/location_lesion'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Outside',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/location_lesion'
                            }
                        }
                    ]
                },
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'location_lesion_clock',
                    prefix: '3_2',
                    required: false,
                    text: 'Location of the lesion: Location of the lesion by clock position'
                },
                {
                    type: 'integer',
                    disabledDisplay: 'protected',
                    linkId: 'size_lesion_quadrants',
                    prefix: '3_3',
                    required: false,
                    text: 'Number of cervical quadrants the lesion covers',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 0
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 4
                        }
                    ]
                },
                {
                    type: 'integer',
                    disabledDisplay: 'protected',
                    linkId: 'size_lesion_percentage',
                    prefix: '3_4',
                    required: false,
                    text: 'Size of the lesion in percentage of cervix',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 0
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 100
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'grade_1',
                    prefix: '3_5',
                    required: false,
                    text: 'Grade 1 (minor)',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Fine mosaic; fine punctation',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/grade1'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Thin acetowhite epithelium',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/grade1'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Irregular, geographical border',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/grade1'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'grade_2',
                    prefix: '3_6',
                    required: false,
                    text: 'Grade 2 (major)',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Sharp border; inner border sign; ridge sign',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/grade2'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Dense acetowhite epithelium',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/grade2'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Coarse mosaic; coarse punctation',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/grade2'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Rapid appearance of acetowhitening',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/grade2'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Cuffed crypt (gland) openings',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/grade2'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'non_specific',
                    prefix: '3_7',
                    required: false,
                    text: 'Non-specific',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Leukoplakia (keratosis, hyperkeratosis); erosion',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/non_specific'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Lugol’s staining (Schiller test): stained or nonstained',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/non_specific'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'sus_for_invasion',
            prefix: '4',
            required: false,
            text: 'Suspicious for invasion',
            item: [
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'vessels',
                    prefix: '4_1',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Atypical vessels',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/Atypical_vessels'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'additional_signs',
                    prefix: '4_2',
                    required: false,
                    text: 'Additional signs',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Fragile vessels',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/Additional_signs'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Irregular surface',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/Additional_signs'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Exophytic lesion',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/Additional_signs'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Necrosis, Ulceration (necrotic)',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/Additional_signs'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'tumor/gross neoplasm',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/Additional_signs'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'open-choice',
            disabledDisplay: 'protected',
            linkId: 'miscell_finding',
            prefix: '5',
            required: false,
            text: 'Miscellaneous finding',
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Congenital transformation zone',
                        system: 'http://uk-erlangen.de/dhde/valueCoding/Miscellaneous_finding'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Condyloma',
                        system: 'http://uk-erlangen.de/dhde/valueCoding/Miscellaneous_finding'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Polyp (ectocervical or endocervical)',
                        system: 'http://uk-erlangen.de/dhde/valueCoding/Miscellaneous_finding'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Inflammation',
                        system: 'http://uk-erlangen.de/dhde/valueCoding/Miscellaneous_finding'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Stenosis',
                        system: 'http://uk-erlangen.de/dhde/valueCoding/Miscellaneous_finding'
                    }
                },
                {
                    valueCoding: {
                        code: '5',
                        version: '1.0',
                        display: 'Congenital anomaly',
                        system: 'http://uk-erlangen.de/dhde/valueCoding/Miscellaneous_finding'
                    }
                },
                {
                    valueCoding: {
                        code: '6',
                        version: '1.0',
                        display: 'Post-treatment consequence',
                        system: 'http://uk-erlangen.de/dhde/valueCoding/Miscellaneous_finding'
                    }
                },
                {
                    valueCoding: {
                        code: '7',
                        version: '1.0',
                        display: 'Endometriosis',
                        system: 'http://uk-erlangen.de/dhde/valueCoding/Miscellaneous_finding'
                    }
                }
            ]
        }
    ]
};
