import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';
import List from '@mui/material/List/List.js';

import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';
import ListElement from '@refinio/one.ui/lib/ui/components/list/ListElement.js';

export default function Downloads(): ReactElement {
    const i18n = useTranslation();

    return (
        <CollapsibleComponent
            headline={<div className="collapsible-headline">{i18n.t('downloads.listHeader')}</div>}
            content={
                <List>
                    <ListElement
                        text={i18n.t('downloads.oneFilerInstaller')}
                        extra={
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    window.location.replace('/downloads/OneFilerInstaller_x64.exe')
                                }
                            >
                                {i18n.t('buttons.common.download')}
                            </Button>
                        }
                    />
                </List>
            }
        />
    );
}
