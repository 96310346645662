import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const GeneralFeedback: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/GeneralFeedback',
    name: 'general_feedback_edda',
    title: 'General Feedback (Edda)',
    status: 'active',
    item: [
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'num_enrolled',
            prefix: '1',
            required: false,
            text: 'Number of enrolled cases (per center)',
            item: [
                {
                    type: 'integer',
                    disabledDisplay: 'protected',
                    linkId: 'num_edda',
                    prefix: '1_1',
                    required: false,
                    text: 'EDDA.One Software?',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 0
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 999
                        }
                    ]
                },
                {
                    type: 'integer',
                    disabledDisplay: 'protected',
                    linkId: 'num_lft',
                    prefix: '1_2',
                    required: false,
                    text: 'LFT?',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 0
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 999
                        }
                    ]
                },
                {
                    type: 'integer',
                    disabledDisplay: 'protected',
                    linkId: 'num_col',
                    prefix: '1_3',
                    required: false,
                    text: 'Colposcopy?',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 0
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 999
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'conv_general',
            prefix: '2',
            required: false,
            text: 'Convenience and general impression?',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'conv_edda',
                    prefix: '2_1',
                    required: false,
                    text: 'EDDA.One Software',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'conv_lft',
                    prefix: '2_2',
                    required: false,
                    text: 'LFT',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'conv_col',
                    prefix: '2_3',
                    required: false,
                    text: 'Colposcopy',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'userfriendly',
            prefix: '3',
            required: false,
            text: 'User friendly handling?',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'userfriendly_edda',
                    prefix: '3_1',
                    required: false,
                    text: 'EDDA.One Software',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'userfriendly_lft',
                    prefix: '3_2',
                    required: false,
                    text: 'LFT',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'userfriendly_col',
                    prefix: '3_3',
                    required: false,
                    text: 'Colposcopy',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'textbooks',
            prefix: '4',
            required: false,
            text: 'The provided textbooks describing EDDA.One; LFT; Colposcopy?',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'textbooks_read',
                    prefix: '4_1',
                    required: false,
                    text: 'Easy to read (letter size and design)',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'textbooks_understand',
                    prefix: '4_2',
                    required: false,
                    text: 'Easy to understand',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'comp_proposed_established',
            prefix: '5',
            required: false,
            text: 'Comparison between proposed system vs your established screening',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'comp_conv',
                    prefix: '5_1',
                    required: false,
                    text: 'Convenience',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'comp_time',
                    prefix: '5_2',
                    required: false,
                    text: 'Time consumption in form of saving',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'comp_effective',
                    prefix: '5_3',
                    required: false,
                    text: 'More effective (due to your understanding)',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'comp_improve',
                    prefix: '5_4',
                    required: false,
                    text: 'Improvements (consider also complains like more sophisticated, connectivity, learning curve etc)',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                }
            ]
        }
    ]
};
