import type { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import PageNotFound from '@/components/PageNotFound.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import ChatMessageDetails from '@/components/trust/ChatMessageDetails.js';
import { ChannelManager } from '@refinio/one.models/lib/models';

export default function ChatMessageDetailsRouter(props: {
    leuteModel: LeuteModel;
    channelManager: ChannelManager;
}): ReactElement {
    return (
        <Routes>
            <Route
                path={':chatID/:channelEntryID'}
                element={
                    <>
                        <AppBarSettings hide={true} />
                        <ChatMessageDetails
                            leuteModel={props.leuteModel}
                            channelManager={props.channelManager}
                        />
                    </>
                }
            />
            <Route element={<PageNotFound />} />
        </Routes>
    );
}
