import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {InputAdornment, TextField} from '@mui/material';

import './BodyTemperature.css';

/**
 *
 * @param props.value temperature
 * @param props.onChange Optional. Default undefined. When undefined - component is in view more
 * @returns
 */
export default function BodyTemperature(props: {
    value: string;
    onChange?: ((value: string) => void) | undefined;
}): ReactElement {
    const i18n = useTranslation();
    return (
        <div className="body-temperature-container">
            <TextField
                type="text"
                className="temperature"
                variant="standard"
                value={props.value}
                InputProps={{
                    endAdornment: <InputAdornment position="end">°C</InputAdornment>
                }}
                label={i18n.t('bodyTemperature.title')}
                onChange={e => {
                    if (props.onChange) {
                        const val = e.target.value.replaceAll(',', '.');
                        const number = Number();
                        if (!isNaN(number)) {
                            props.onChange(val);
                        }
                    }
                }}
                disabled={props.onChange === undefined}
            />
        </div>
    );
}
