import {Fragment, useEffect, useState} from 'react';

import Divider from '@mui/material/Divider/Divider.js';

import type GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';

import {getPersonEmail, loadProfilePersonDescription} from '@/hooks/contact/utils.js';
import i18n from '@/i18n.js';
import {getDefaultProfile, getURL} from '@/utils/Utils.js';
import GroupListEntry from './GroupListEntry.js';

import '../groupId/Group.css';
import {usePersonId} from '@/hooks/contact/commonHooks.js';

export type GroupMember = {
    name: string;
    img: string | undefined;
    idHash: SHA256IdHash<Profile>;
    profile: ProfileModel;
    email: string;
};
/**
 * Group Members List Component
 * @param props
 * @constructor
 */
export default function GroupMembersList(props: {group: GroupModel; leuteModel: LeuteModel}) {
    const [profiles, setProfiles] = useState<GroupMember[]>([]);
    const mePersonId = usePersonId(props.leuteModel);

    /**
     * Each time a new profile is added, update the list
     */
    useEffect(() => {
        let cancel = false;

        async function fetchProfiles(): Promise<void> {
            if (cancel || !mePersonId) {
                return;
            }

            const newProfiles: GroupMember[] = [];

            for (const personId of props.group.persons) {
                const defaultProfile = await getDefaultProfile(props.leuteModel, personId);
                if (!defaultProfile) {
                    console.error('Could not extract default profile from identity', personId);
                    continue;
                }
                const name = props.leuteModel.getPersonName(personId);
                const profileDetails = await loadProfilePersonDescription(defaultProfile);
                newProfiles.push({
                    name,
                    img:
                        profileDetails.avatar !== undefined
                            ? getURL(profileDetails.avatar)
                            : undefined,
                    idHash: defaultProfile.idHash,
                    profile: defaultProfile,
                    email: await getPersonEmail(personId)
                });
            }

            setProfiles(newProfiles);
        }

        fetchProfiles().catch(console.error);
        return () => {
            cancel = true;
        };
    }, [props.group, mePersonId]);

    return (
        <div className="cnt-group-members">
            <div className="cnt-title">
                {i18n.t('leute.lists.groupMembers')}
                {` (${profiles.length})`}
            </div>
            {profiles.map((profile, index) => (
                <Fragment key={profile.idHash}>
                    <GroupListEntry groupMember={profile} leuteModel={props.leuteModel} />
                    {index !== profiles.length - 1 && <Divider variant={'middle'} />}
                </Fragment>
            ))}
        </div>
    );
}
