import type {ReactElement} from 'react';
import {Route, Routes} from 'react-router-dom';

import type BodyTemperatureModel from '@refinio/one.models/lib/models/BodyTemperatureModel.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';

import CreateBodyTemperature from './create/CreateBodyTemperature.js';
import CreateBodyTemperatureOwnerAdapter from './create/CreateBodyTemperatureOwnerAdapter.js';
import ViewBodyTemperaturePopup from './view/ViewBodyTemperature.js';

export default function BodyTemperatureRouter(props: {
    bodyTemperatureModel: BodyTemperatureModel;
    channelManager: ChannelManager;
}): ReactElement {
    return (
        <Routes>
            <Route
                path="create"
                element={
                    <CreateBodyTemperature bodyTemperatureModel={props.bodyTemperatureModel} />
                }
            />
            <Route
                path=":owner/create"
                element={
                    <CreateBodyTemperatureOwnerAdapter
                        bodyTemperatureModel={props.bodyTemperatureModel}
                        channelManager={props.channelManager}
                    />
                }
            />
            <Route
                path=":id"
                element={
                    <ViewBodyTemperaturePopup bodyTemperatureModel={props.bodyTemperatureModel} />
                }
            />
            <Route
                element={
                    <CreateBodyTemperature bodyTemperatureModel={props.bodyTemperatureModel} />
                }
            />
        </Routes>
    );
}
