import {useCallback, useContext, useRef, type ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';

import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import {MenuContext} from '@/context/MenuContext.js';
import BaseAddItem from './BaseAddItem.js';

type MENU_ENTRY_VALUES = (typeof MENU_ENTRY)[keyof typeof MENU_ENTRY];
export type QuestionnaireMenu = {entry: MENU_ENTRY_VALUES; name: string};

export default function QuestionnaireItem(props: {
    menu: QuestionnaireMenu[];
    dataOwner?: SHA256IdHash<Person>;
    title?: string;
}): ReactElement {
    const itemRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const {
        setMenuEntries,
        selectMenuEntry,
        isOpen,
        setMenuReference,
        setMenuClassName,
        setMenuAnchorOrigin
    } = useContext(MenuContext);

    function questionnaireRoute(name: string): void {
        if (name === 'EQ5D3LQuestionnaire') {
            if (props.dataOwner === undefined) {
                navigate(`/questionnaire/${name}/new`);
            } else {
                navigate(`/questionnaire/${props.dataOwner}/${name}/new`);
            }
            return;
        }

        if (props.dataOwner === undefined) {
            navigate(`/questionnaire/new?questionnaires=${name}`);
        } else {
            navigate(`/questionnaire/${props.dataOwner}/new?questionnaires=${name}`);
        }
    }

    // dynamic popup menu event with html element reference control and menu selection control
    const openMenu = useCallback(() => {
        setMenuReference(itemRef.current);
        setMenuAnchorOrigin({horizontal: 'center', vertical: 'center'});
        setMenuClassName('app-bar-cnt-add-menu');
        setMenuEntries(props.menu.map(qm => qm.entry));
        selectMenuEntry(() => (entrySelected: string) => {
            setMenuClassName('app-bar-cnt-menu');
            isOpen(false);
            if (entrySelected === MENU_ENTRY.QuestionnaireEQ5D3L) {
                questionnaireRoute('EQ5D3LQuestionnaire');
            } else {
                const questionaireName = props.menu.find(qm => qm.entry === entrySelected);
                if (questionaireName) {
                    questionnaireRoute(questionaireName.name);
                } else {
                    console.error(`no questionnaire name found for entrySelected ${entrySelected}`);
                }
            }
        });
        isOpen(true);
    }, []);

    return (
        <div ref={itemRef}>
            <BaseAddItem type={'questionnaire'} onClick={openMenu} title={props.title} />
        </div>
    );
}
