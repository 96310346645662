import {useTranslation} from 'react-i18next';

import {DARK_ORANGE} from '@/components/Constants.js';
import ContactCard from '@/components/card/ContactCard.js';
import UploadPicture from '@/components/pictureEditor/UploadPicture.js';
import TextFieldEntry from '@/components/textFieldEntry/TextFieldEntry.js';
import type {DetailedGroup} from '@/root/group/create/CreateGroup.js';
import {useBackgroundColor} from '@/utils/Utils.js';

import '../groupId/Group.css';

/**
 * Group Details Form Component
 * @param props.groupDetails - the group details.
 * @param props.onDone - called when the user finished the actions within this view.
 * @constructor
 */
export default function GroupDetailsForm(props: {
    groupDetails: DetailedGroup;
    onDone: (name: string) => void;
}) {
    const i18n = useTranslation();
    useBackgroundColor(DARK_ORANGE);

    return (
        <div className="cnt-someone-details">
            <div className="cnt-title">{i18n.t('leute.group.title')}</div>
            <ContactCard
                content={
                    <>
                        <UploadPicture image={props.groupDetails.picture} />
                        <div className="trust-section">
                            <TextFieldEntry
                                label={i18n.t('leute.group.groupFields.name')}
                                onDone={props.onDone}
                                value={
                                    i18n.t(`leute.group.names.${props.groupDetails.name}`) ===
                                    `leute.group.names.${props.groupDetails.name}`
                                        ? props.groupDetails.name
                                        : i18n.t(`leute.group.names.${props.groupDetails.name}`)
                                }
                                disableUnderline={false}
                            />
                        </div>
                    </>
                }
            />
        </div>
    );
}
