import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import TextFieldEntry from '@/components/textFieldEntry/TextFieldEntry.js';

import './SomeoneProfileDetails.css';

export default function OrganisationName(props: {
    name: string;
    onChange?: (name: string) => void;
    disableUnderline?: boolean;
}): ReactElement {
    const i18n = useTranslation();

    return (
        <div className="trust-section">
            <TextFieldEntry
                label={i18n.t('leute.profile.personDescription.organisationName')}
                onDone={name => {
                    if (props.onChange) {
                        props.onChange(name);
                    }
                }}
                value={props.name}
                disableUnderline={
                    props.disableUnderline === undefined ? false : props.disableUnderline
                }
            />
        </div>
    );
}
