import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const CervicalCancerScreeningAndTreatmentRegister: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://refinio.one/questionaire/CervicalCancerScreeningAndTreatmentRegister',
    name: 'cervical_cancer_screeninc_and_treatment_register',
    title: 'Cervical cancer screening and treatment register',
    status: 'active',
    item: [
        {
            type: 'group',
            linkId: 'intake',
            prefix: '1',
            text: 'Intake',
            disabledDisplay: 'protected',
            item: [
                {
                    type: 'date',
                    linkId: 'vd',
                    prefix: '1_1',
                    text: 'Visit Date',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: 'c',
                    prefix: '1_2',

                    text: 'Client #',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: 'fs',
                    prefix: '1_3',

                    text: 'First name & Surname',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'string',
                    linkId: 'apn',
                    prefix: '1_4',

                    text: 'Physical Adress & Phone Number',
                    disabledDisplay: 'protected'
                },
                {
                    type: 'integer',
                    linkId: 'ca',
                    prefix: '1_5',
                    text: 'Client Age',
                    disabledDisplay: 'protected',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 100
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 0
                        }
                    ]
                },
                {
                    type: 'choice',
                    linkId: 'age',
                    prefix: '1_6',

                    text: 'Client Age',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '<25y',
                                version: '1.0',
                                display: 'Below 25 years',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/age'
                            }
                        },
                        {
                            valueCoding: {
                                code: '25-29y',
                                version: '1.0',
                                display: '25 - 29 years',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/age'
                            }
                        },
                        {
                            valueCoding: {
                                code: '30-44y',
                                version: '1.0',
                                display: '30-44 years',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/age'
                            }
                        },
                        {
                            valueCoding: {
                                code: '45-49y',
                                version: '1.0',
                                display: '45-49 years',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/age'
                            }
                        },
                        {
                            valueCoding: {
                                code: '>49y',
                                version: '1.0',
                                display: 'Above 49 years',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/age'
                            }
                        }
                    ],
                    disabledDisplay: 'protected'
                },
                {
                    type: 'choice',
                    linkId: 'hiv',
                    prefix: '1_7',

                    text: 'HIV Status',
                    answerOption: [
                        {
                            valueCoding: {
                                code: 'pa',
                                version: '1.0',
                                display: 'Positive on ART',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/HIV_status'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'pna',
                                version: '1.0',
                                display: 'Positive NOT on ART',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/HIV_status'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'n>1y',
                                version: '1.0',
                                display: 'Negative Tested < 1 Year',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/HIV_status'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'unk',
                                version: '1.0',
                                display:
                                    'Unkown (Negative tested > 1 Year, Inconclusive, Prefers not to Disclose, Never tested)',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/HIV_status'
                            }
                        }
                    ],
                    disabledDisplay: 'protected'
                },
                {
                    type: 'choice',
                    linkId: 'rv',
                    prefix: '1_8',

                    text: 'Reason for Visit',
                    answerOption: [
                        {
                            valueCoding: {
                                code: 'is',
                                version: '1.0',
                                display: 'Initial Screening',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/visit_reason'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'pt',
                                version: '1.0',
                                display: 'Postponed Treatment',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/visit_reason'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1ysa',
                                version: '1.0',
                                display: '1 year Subsequent Check-Up after Treatment',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/visit_reason'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'ss',
                                version: '1.0',
                                display: 'Subsequent Screening',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/visit_reason'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'ref',
                                version: '1.0',
                                display: 'Referral',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/visit_reason'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'pvat',
                                version: '1.0',
                                display: 'Problem Visit after Treatment',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/visit_reason'
                            }
                        }
                    ],
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            type: 'group',
            linkId: 'screening',
            prefix: '2',
            text: 'Screening',
            disabledDisplay: 'protected',
            item: [
                {
                    type: 'choice',
                    linkId: 'screening_method',
                    prefix: '2_1',

                    text: 'Screening Method',
                    answerOption: [
                        {
                            valueCoding: {
                                code: 'via',
                                version: '1.0',
                                display: 'VIA',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/screening_method'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'ps',
                                version: '1.0',
                                display: 'PAP Smear',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/screening_method'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'hd',
                                version: '1.0',
                                display: 'HPV DNA',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/screening_method'
                            }
                        }
                    ],
                    disabledDisplay: 'protected'
                },
                {
                    type: 'group',
                    linkId: 'screening_result',
                    prefix: '2_2',
                    text: 'Screening Result',
                    disabledDisplay: 'protected',
                    item: [
                        {
                            type: 'choice',
                            linkId: 'HIV+',
                            prefix: '2_2_1',
                            text: 'HIV+',
                            answerOption: [
                                {
                                    valueCoding: {
                                        code: 'nvp',
                                        version: '1.0',
                                        display: 'VIA Negative',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'pvp',
                                        version: '1.0',
                                        display: 'VIA Positive',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'scp',
                                        version: '1.0',
                                        display: 'Suspect Cancer',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'psnp',
                                        version: '1.0',
                                        display: 'PAP Smear Normal',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'pap',
                                        version: '1.0',
                                        display: 'PAP Smear Abnormal',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'h-p',
                                        version: '1.0',
                                        display: 'HPV-',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'h+p',
                                        version: '1.0',
                                        display: 'HPV+',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'snl+',
                                        version: '1.0',
                                        display: 'No Visible Lesion',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'ogp',
                                        version: '1.0',
                                        display: 'Other Gynae',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                }
                            ],
                            disabledDisplay: 'protected'
                        },
                        {
                            type: 'choice',
                            linkId: 'HIV- / Unknown',
                            prefix: '2_2_2',
                            text: 'HIV+',
                            answerOption: [
                                {
                                    valueCoding: {
                                        code: 'nvp',
                                        version: '1.0',
                                        display: 'VIA Negative',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'pvp',
                                        version: '1.0',
                                        display: 'VIA Positive',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'scp',
                                        version: '1.0',
                                        display: 'Suspect Cancer',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'psnp',
                                        version: '1.0',
                                        display: 'PAP Smear Normal',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'pap',
                                        version: '1.0',
                                        display: 'PAP Smear Abnormal',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'h-p',
                                        version: '1.0',
                                        display: 'HPV-',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'h+p',
                                        version: '1.0',
                                        display: 'HPV+',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'snl+',
                                        version: '1.0',
                                        display: 'No Visible Lesion',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'ogp',
                                        version: '1.0',
                                        display: 'Other Gynae',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/screening_result'
                                    }
                                }
                            ],
                            disabledDisplay: 'protected'
                        },
                        {
                            type: 'choice',
                            linkId: 'ccs',
                            prefix: '2_2_3',
                            text: 'Cervical Cancer Suspect Disaggregated by Age',
                            answerOption: [
                                {
                                    valueCoding: {
                                        code: 'CS<25y',
                                        version: '1.0',
                                        display: 'Below 25 years',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/CCS_by_age'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'CS 25-29y',
                                        version: '1.0',
                                        display: '25 - 29 years',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/CCS_by_age'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'CS 30-44y',
                                        version: '1.0',
                                        display: '30-44 years',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/CCS_by_age'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'CS 45-49y',
                                        version: '1.0',
                                        display: '45-49 years',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/CCS_by_age'
                                    }
                                },
                                {
                                    valueCoding: {
                                        code: 'CS>49y',
                                        version: '1.0',
                                        display: 'Above 49 years',
                                        system: 'http://uk-erlangen.de/dhde/valueCoding/CCS_by_age'
                                    }
                                }
                            ],
                            disabledDisplay: 'protected'
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            linkId: 'treatment',
            prefix: '3',
            text: 'Treatment',
            disabledDisplay: 'protected',
            item: [
                {
                    type: 'choice',
                    linkId: 'treatment_status',
                    prefix: '3_1',
                    text: 'Treatment Status',
                    answerOption: [
                        {
                            valueCoding: {
                                code: 'sdt',
                                version: '1.0',
                                display: 'Same day Treatment',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/treatment_status'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'ptt',
                                version: '1.0',
                                display: 'Postponed Treatment',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/treatment_status'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'ptp',
                                version: '1.0',
                                display: 'Postponed Treatment Performed',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/treatment_status'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'ref',
                                version: '1.0',
                                display: 'Referral',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/treatment_status'
                            }
                        }
                    ],
                    disabledDisplay: 'protected'
                },
                {
                    type: 'choice',
                    linkId: 'treatment_performed',
                    prefix: '3_2',
                    text: 'Treatment Performed',
                    answerOption: [
                        {
                            valueCoding: {
                                code: 'cryo',
                                version: '1.0',
                                display: 'Cryotherapy',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/treatment_performed'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'therm',
                                version: '1.0',
                                display: 'Thermal Coagulation',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/treatment_performed'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'lp',
                                version: '1.0',
                                display: 'Leep',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/treatment_performed'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'oth',
                                version: '1.0',
                                display: 'Other',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/treatment_performed'
                            }
                        }
                    ],
                    disabledDisplay: 'protected'
                },
                {
                    type: 'choice',
                    linkId: 'referral_reasion',
                    prefix: '3_3',
                    text: 'Referral Reason',
                    answerOption: [
                        {
                            valueCoding: {
                                code: 'llr',
                                version: '1.0',
                                display: 'Large Lesion (>75%)',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/referral_reason'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'flr',
                                version: '1.0',
                                display: 'Further Investigation & Management',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/referral_reason'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'scr',
                                version: '1.0',
                                display: 'Suspected Cancer',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/referral_reason'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'ntr',
                                version: '1.0',
                                display: 'No Treatmen',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/referral_reason'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'ogr',
                                version: '1.0',
                                display: 'Other Gynae',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/referral_reason'
                            }
                        }
                    ],
                    disabledDisplay: 'protected'
                },
                {
                    type: 'open-choice',
                    linkId: 'referral_feedback',
                    prefix: '3_4',
                    text: 'Referral Feedback',
                    answerOption: [
                        {
                            valueCoding: {
                                code: 'rfr',
                                version: '1.0',
                                display: 'Referral Feedback Received',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/referral_feedback_received'
                            }
                        }
                    ],
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            type: 'group',
            linkId: 'other_services',
            prefix: '4',
            text: 'Other Services',
            disabledDisplay: 'protected',
            item: [
                {
                    type: 'choice',
                    linkId: 'family_planning_services',
                    prefix: '4_1',
                    text: 'Offered Family Planning Services? (Yes/No/NA)',
                    answerOption: [
                        {
                            valueCoding: {
                                code: 'yes',
                                version: '1.0',
                                display: 'yes',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/yes_no_na'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'no',
                                version: '1.0',
                                display: 'no',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/yes_no_na'
                            }
                        },
                        {
                            valueCoding: {
                                code: 'na',
                                version: '1.0',
                                display: 'na',
                                system: 'http://uk-erlangen.de/dhde/valueCoding/yes_no_na'
                            }
                        }
                    ],
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            type: 'string',
            linkId: 'orr',
            prefix: '5',
            text: 'Other Remarks',
            disabledDisplay: 'protected'
        },
        {
            type: 'string',
            linkId: 'spn',
            prefix: '6',
            text: 'Service Provider Name',
            disabledDisplay: 'protected'
        }
    ]
};
