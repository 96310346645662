import {useCallback, useContext, useEffect} from 'react';

import type {PopupMenuEntry} from '@refinio/one.ui/lib/ui/components/popupMenu/PopupMenu.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {MenuContext} from '@/context/MenuContext.js';

/**
 * More menu facade pattern by React hook implementation
 * @param menuEntities
 * @param onEntrySelected
 */
export function useMoreMenu(
    menuEntities: PopupMenuEntry[],
    onEntrySelected: (selectedMenuEntry: string) => void
) {
    const {setMenuEntries, selectMenuEntry} = useContext(MenuContext);
    const appBarContext = useContext(AppBarContext);

    // on unmount, clean after itself
    useEffect(() => {
        return () => {
            setMenuEntries([]);
            selectMenuEntry(() => {});
        };
        // mount only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setMenuEntries(menuEntities);
        // selectMenuEntry callback is called with undefined if click is outside of menu
        selectMenuEntry(() => (selectedMenuEntry: string) => {
            // prevent calling callback on no selection (outside of menu click)
            if (selectedMenuEntry) {
                onEntrySelected(selectedMenuEntry);
            }
        });
        // should refresh only on appBarContext.contextValue.isRightBtnClicked
        // or change to menuEntities
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        appBarContext.contextValue.isRightBtnClicked,
        menuEntities,
        onEntrySelected,
        selectMenuEntry,
        setMenuEntries
    ]);
}

export function useReferenceMenuPosition(): void {
    useMenuClassName('');
}

export function useMenuClassName(className?: string): void {
    const {setMenuClassName} = useContext(MenuContext);

    useEffect(() => {
        // clear css position
        setMenuClassName(className ? className : '');
        return () => {
            // set default css position
            setMenuClassName('app-bar-cnt-menu');
        };
        // mount/unmount only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

export function usePopupMenu(
    menuEntities: PopupMenuEntry[],
    onEntrySelect: (menuEntry: string) => void,
    className: string = 'no-class-menu'
): (menuRef?: HTMLElement) => void {
    const {
        setMenuReference,
        setMenuClassName,
        setMenuAnchorOrigin,
        isOpen,
        setMenuEntries,
        selectMenuEntry
    } = useContext(MenuContext);

    const openMenu = useCallback(
        (menuRef?: HTMLElement) => {
            setMenuReference(menuRef);
            isOpen(true);
            setMenuClassName(className);
        },
        [className, isOpen, setMenuClassName, setMenuReference]
    );

    useEffect(() => {
        setMenuAnchorOrigin({horizontal: 'center', vertical: 'center'});
        setMenuEntries(menuEntities);
        selectMenuEntry(() => (menuEntry: string) => {
            onEntrySelect(menuEntry);
            isOpen(false);
        });
        return () => {
            setMenuAnchorOrigin(undefined);
            setMenuClassName('app-bar-cnt-menu');
        };
    }, [
        isOpen,
        setMenuAnchorOrigin,
        selectMenuEntry,
        setMenuClassName,
        setMenuEntries,
        menuEntities,
        onEntrySelect
    ]);

    return openMenu;
}
