import type {ReactElement} from 'react';
import './Questionnaire.css';
import i18n from '@/i18n';

/**
 * @returns {ReactElement} - ninth page of the EQ-5D-3L.
 */
export default function HealthInfoPage(): ReactElement {
    return (
        <div className="observation-list">
            <div className="text-survey-welcome">{i18n.t('eq5.healthInfoPage.observation1')}</div>
            <div className="text-survey-welcome">{i18n.t('eq5.healthInfoPage.observation2')}</div>
            <div className="text-survey-welcome">
                {i18n.t('eq5.healthInfoPage.observation3')}
                <br />
                {i18n.t('eq5.healthInfoPage.observation4')}
            </div>
        </div>
    );
}
