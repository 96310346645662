import type {ReactElement} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';

import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type {Questionnaire as QuestionnaireType} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type {QuestionnaireResponse} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

import QuestionnaireIssueResolver from './QuestionnaireIssueResolver.js';
import QuestionnaireScreen from './QuestionnaireScreen.js';

import './QuestionnaireDisplay.css';

/**
 * Wrapper for questionnaire view.
 * Handles cases were current selected language does not have
 * a questionnaire, but the questionnaire is avaliable in another
 * language. Gives the user a chance to switch the language only
 * for this wrapper, without changing it for the application.
 *
 * @param props.questionnaireModel
 * @param props.saveResponseOverwrite Optional.
 * @param props.questionnaireName Optional. Default: checks location get params for `questionnaires` (new) or `response` (view)
 * @param props.questionnaireLanguage Optional. Default: checks location get params for `language`
 * @param props.onCancel Optional. Default: undefined. Callback on cancel. Overwrites navigate back.
 * @returns
 */
export default function QuestionnaireDisplay(props: {
    questionnaireModel: QuestionnaireModel;
    saveResponseOverwrite?: (response: QuestionnaireResponse) => Promise<void>;
    questionnaireName?: string;
    questionnaireLanguage?: string;
    onCancel?: () => void;
}): ReactElement {
    const i18n = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [questionnaire, setQuestionnaire] = useState<QuestionnaireType | undefined | null>(null);

    useEffect(() => {
        async function getQuestionnaire(): Promise<void> {
            const queryParams = new URLSearchParams(location.search);
            const paramQuestionnaire = queryParams.get('questionnaires');
            const response = queryParams.get('response');
            if (props.questionnaireName) {
                setQuestionnaire(
                    await props.questionnaireModel.questionnaireByName(
                        props.questionnaireName,
                        props.questionnaireLanguage
                    )
                );
                return;
            } else if (paramQuestionnaire) {
                setQuestionnaire(
                    await props.questionnaireModel.questionnaireByName(
                        paramQuestionnaire,
                        props.questionnaireLanguage
                    )
                );
                return;
            } else if (response) {
                const responsesObject = await props.questionnaireModel.responsesById(response);
                const url = responsesObject.data.response[0].questionnaire;
                if (!url) {
                    setQuestionnaire(undefined);
                    return;
                }
                setQuestionnaire(await props.questionnaireModel.questionnaireByUrl(url));
                return;
            }
        }
        getQuestionnaire().catch(console.error);
        props.questionnaireModel.onUpdated(getQuestionnaire);
    }, [props.questionnaireModel, location, props.questionnaireName, props.questionnaireLanguage]);

    const actionAfterSubmit = useCallback(
        async (response: QuestionnaireResponse) => {
            if (props.saveResponseOverwrite) {
                await props.saveResponseOverwrite(response);
            } else {
                await props.questionnaireModel.postResponse(response);
                navigate('/journal');
            }
        },
        [navigate, props]
    );

    if (questionnaire === null) {
        return (
            <div className="circular-progress-container empty-questionnaire">
                <CircularProgress className="circular-progress" size={35} />
            </div>
        );
    } else if (questionnaire === undefined) {
        return <>{i18n.t('errors.questionnaire.missing')}</>;
    }

    return (
        <div className="questionnaire-container">
            <QuestionnaireIssueResolver
                questionnaire={questionnaire}
                questionnaireModel={props.questionnaireModel}
                questionnaireLanguage={props.questionnaireLanguage}
            >
                <QuestionnaireScreen
                    questionnaireModel={props.questionnaireModel}
                    questionnaire={questionnaire}
                    onFinish={actionAfterSubmit}
                    onCancel={props.onCancel}
                />
            </QuestionnaireIssueResolver>
        </div>
    );
}
