import type {ReactElement} from 'react';

import UploadPicture from '@/components/pictureEditor/UploadPicture.js';
import {getURL} from '@/utils/Utils.js';

import './SomeoneProfileDetails.css';

export default function ProfileAvatar(props: {
    viewMode?: boolean;
    avatar: ArrayBuffer | undefined;
}): ReactElement {
    return props.viewMode === undefined || !props.viewMode ? (
        <UploadPicture image={props.avatar} />
    ) : (
        <>
            {props.avatar !== undefined ? (
                <img className="preview" src={getURL(props.avatar)} alt="avatar-preview" />
            ) : (
                <div className="cnt-empty-image" />
            )}
        </>
    );
}
