import VersionsView from '@refinio/one.ui/lib/ui/views/debug/versions/VersionsView.js';
import {useCallback, type ReactElement} from 'react';
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Button} from '@mui/material';

import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {ChannelInfo} from '@refinio/one.models/lib/recipes/ChannelRecipes.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type IoMManager from '@refinio/one.models/lib/models/IoM/IoMManager.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import type ConnectionsModel from '@refinio/one.models/lib/models/ConnectionsModel.js';
import ChannelsView from '@refinio/one.ui/lib/ui/views/debug/channels/ChannelsView.js';
import ChannelDetailedView from '@refinio/one.ui/lib/ui/views/debug/channels/ChannelDetailedView.js';
import DebugKeysView from '@refinio/one.ui/lib/ui/views/debug/keys/DebugKeysView.js';
import {ObjectTreeScreen} from '@refinio/one.ui/lib/ui/views/debug/oneBrowser/ObjectTreeScreen.js';
import {ObjectsTableScreen} from '@refinio/one.ui/lib/ui/views/debug/oneBrowser/ObjectsTableScreen.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import ObjectEventsView from '@refinio/one.ui/lib/ui/views/debug/objectEvents/ObjectEventsView.js';
import ProfileKeyView from '@refinio/one.ui/lib/ui/views/debug/profileKey/ProfileKeyView.js';
import IOStatisticsView from '@refinio/one.ui/lib/ui/views/debug/IOStats/IOStatisticsView.js';
import LoggerSettingsView from '@refinio/one.ui/lib/ui/views/debug/log/LoggerSettingsView.js';
import AccessView from '@refinio/one.ui/lib/ui/views/debug/access/AccessView.js';
import ObjectDumpView from '@refinio/one.ui/lib/ui/views/debug/objectDump/ObjectDumpView.js';
import ConnectionsDebugViewWithInvitation from '@refinio/one.ui/lib/ui/views/debug/connections/ConnectionsDebugViewWithInvitation.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import PageNotFound from '@/components/PageNotFound.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import {useLocationStackNavigator} from '@/utils/LocationStackNavigator.js';
import {getCreateCertificateCallback, getCertificateMenu, getIssuerMenu} from './utils.js';
import {useParing} from '@/utils/pairing.js';

import './RegisterDebugRoutes.css';

/**
 * This component registers the debug routes
 * @param props
 * @constructor
 */
export default function DebugRouter(props: {
    channelManager: ChannelManager;
    connectionsModel: ConnectionsModel;
    iomManager: IoMManager;
    leuteModel: LeuteModel;
}): ReactElement {
    const i18n = useTranslation();
    const {channelManager, leuteModel} = props;
    const location = useLocation();
    const navigate = useNavigate();
    const locationStackNavigator = useLocationStackNavigator();
    const isHashTrusted = props.leuteModel.trust.isAffirmedByAnyone.bind(props.leuteModel.trust);

    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    /**
     * Showing error to the user
     * @param error translated string
     */
    const setError = useCallback(
        (error: string) => {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage(error);
        },
        [setNotificationType, setNotificationMessage]
    );

    const tryPairing = useParing(props.connectionsModel, props.iomManager.requestManager, () =>
        setError(i18n.t('errors.connection.invitationNotValid'))
    );

    function onOneBrowserObjectSelect(objectHash: SHA256IdHash): void {
        navigate(`object/versioned/${objectHash}`, {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state: location?.state
        });
    }

    function onChannelSelect(version: string, hash: SHA256Hash<ChannelInfo>): void {
        navigate(`channelsView/${version}/${hash}`);
    }

    return (
        <Routes>
            <Route
                path="oneBrowser"
                element={
                    <div className="debug-container">
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.debug.oneBrowser')}
                        />
                        <ObjectsTableScreen onObjectSelected={onOneBrowserObjectSelect} />
                    </div>
                }
            />
            <Route
                path="versionViewer"
                element={
                    <div className="debug-container">
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.debug.oneBrowser')}
                        />
                        <VersionsView leuteModel={props.leuteModel} />
                    </div>
                }
            />
            <Route
                path="object/*"
                element={
                    <div className="debug-container">
                        <AppBarSettings
                            mode={
                                locationStackNavigator.empty
                                    ? APP_BAR_MODE.BROWSE
                                    : APP_BAR_MODE.CHEVRON
                            }
                            leftBtnCallback={locationStackNavigator.popLocationAndNavigate}
                        />
                        <ObjectTreeScreen
                            onUnversionedObjectDelete={obj => {
                                console.log('Delete button pressed', obj);
                            }}
                            isHashTrusted={isHashTrusted}
                        />
                    </div>
                }
            />
            <Route
                path="connections"
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.connections')}
                        />
                        <ConnectionsDebugViewWithInvitation
                            connectionsModel={props.connectionsModel}
                            leuteModel={leuteModel}
                            iomManager={props.iomManager}
                            redirectToOneObjectBrowser={onOneBrowserObjectSelect}
                            pairing={tryPairing}
                            onError={setError}
                        >
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                    navigate('/qr-scanner');
                                }}
                                className="qr-scanner"
                            >
                                {i18n.t('buttons.camera.qrScanner')}
                            </Button>
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() => navigate('/invitePartner')}
                            >
                                {i18n.t('invitations.inviteSomeone')}
                            </Button>
                        </ConnectionsDebugViewWithInvitation>
                    </>
                }
            />
            <Route
                path="channelsView"
                element={
                    <div className="debug-container">
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.debug.channels')}
                        />
                        <ChannelsView onChannelSelected={onChannelSelect} />
                    </div>
                }
            />
            <Route
                path="channelsView/:channelRegistryHash/:channelHash"
                element={
                    <div className="debug-container">
                        <AppBarSettings mode={APP_BAR_MODE.BROWSE} />
                        <ChannelDetailedView
                            channelManager={channelManager}
                            isHashTrusted={isHashTrusted}
                        />
                    </div>
                }
            />
            <Route
                path="keys"
                element={
                    <div className="debug-container">
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.debug.keys')}
                        />
                        <DebugKeysView leuteModel={leuteModel} />
                    </div>
                }
            />
            <Route
                path="access"
                element={
                    <div className="debug-container">
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.debug.access')}
                        />
                        <AccessView leuteModel={leuteModel} />
                    </div>
                }
            />
            <Route
                path="objDump"
                element={
                    <div className="debug-container">
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.debug.objDump')}
                        />
                        <ObjectDumpView />
                    </div>
                }
            />
            <Route
                path="loggerSettings"
                element={
                    <div className="debug-container">
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.debug.loggerSettings')}
                        />
                        <LoggerSettingsView />
                    </div>
                }
            />
            <Route
                path="profileKeyView"
                element={
                    <div className="debug-container">
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.debug.profileKeyView')}
                        />
                        <ProfileKeyView
                            leuteModel={props.leuteModel}
                            createCertificate={getCreateCertificateCallback(props.leuteModel.trust)}
                            getCertificateMenuItem={getCertificateMenu}
                            getIssuerMenuItem={getIssuerMenu}
                        />
                    </div>
                }
            />
            <Route
                path="objectEvents"
                element={
                    <div className="debug-container">
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.debug.objectEvents')}
                        />
                        <ObjectEventsView />
                    </div>
                }
            />
            <Route
                path="ioStatistics"
                element={
                    <div className="debug-container">
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.debug.ioStatistics')}
                        />
                        <IOStatisticsView />
                    </div>
                }
            />
            <Route element={<PageNotFound />} />
        </Routes>
    );
}
