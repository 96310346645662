import {createContext, useContext} from 'react';

export type EditPictureContent = {
    originalPicture: ArrayBuffer | undefined;
    setOriginalPicture: (originalPicture: ArrayBuffer | undefined) => void;
    setEditedPicture: (editedPicture: ArrayBuffer | undefined) => void;
    editedPicture: ArrayBuffer | undefined;
    setOriginalPictureDimensions: (pictureDimensions: {
        naturalWidth: number;
        naturalHeight: number;
    }) => void;
    originalPictureDimensions: {naturalWidth: number; naturalHeight: number};
};

export const EditPictureContext = createContext<EditPictureContent>({
    setOriginalPicture: () => {},
    originalPicture: undefined,
    setEditedPicture: () => {},
    editedPicture: undefined,
    setOriginalPictureDimensions: () => {},
    originalPictureDimensions: {naturalWidth: 0, naturalHeight: 0}
});

/**
 * Forward the picture editor context.
 */
export function useEditPictureContext(): EditPictureContent {
    return useContext(EditPictureContext);
}
