import {Fragment, useCallback, type ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import Button from '@mui/material/Button/Button.js';
import Divider from '@mui/material/Divider/Divider.js';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type Notifications from '@refinio/one.models/lib/models/Notifications.js';
import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';

import {useSomeonesPreview} from '@/hooks/contact/someoneHooks.js';
import SomeoneListEntry from '@/root/contacts/SomeoneListEntry.js';
import type {SomeonePreview} from '@/root/contacts/LeuteView.js';
import {useSignedUpSomeonesPreview} from '@/root/malawi_demo/hooks/someone/signUp.js';

import './PatientList.css';

export default function PatientList(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    notifications: Notifications;
    viewRoute: string;
    createRoute: string;
    getInformationAddon?: (hashes: (SHA256Hash | SHA256IdHash)[]) => ReactElement;
}): ReactElement {
    const i18n = useTranslation();
    const navigate = useNavigate();
    const exclude = {me: true, replicants: true};
    const {someones} = useSomeonesPreview(props.leuteModel, exclude);
    const singedUpSomeones = useSignedUpSomeonesPreview(props.leuteModel.trust, someones);

    const viewPatient = useCallback(
        (patient: SomeonePreview) => {
            navigate(props.viewRoute.replace(':someoneId', patient.model.idHash));
        },
        [navigate, props.viewRoute]
    );

    return (
        <div className="patients-view-container">
            <Button className="create-new-patient" onClick={() => navigate(props.createRoute)}>
                {`+ ${i18n.t('malawi_demo.buttons.createPatient')}`}
            </Button>
            <CollapsibleComponent
                headline={
                    <div className="collapsible-headline">
                        {i18n.t('malawi_demo.patients').toUpperCase()}
                        <div>({singedUpSomeones.length})</div>
                    </div>
                }
                content={
                    <>
                        {singedUpSomeones.map((patient, index) => {
                            const addon =
                                props.getInformationAddon !== undefined && patient.mainProfile
                                    ? props.getInformationAddon(
                                          patient.mainProfile.loadedVersion
                                              ? [
                                                    patient.mainProfile.loadedVersion,
                                                    patient.mainProfile.personId
                                                ]
                                              : [patient.mainProfile.personId]
                                      )
                                    : undefined;
                            return (
                                <Fragment key={index}>
                                    <SomeoneListEntry
                                        leuteModel={props.leuteModel}
                                        someone={patient}
                                        onSelect={viewPatient}
                                        topicModel={props.topicModel}
                                        notifications={props.notifications}
                                        disableTrustIndicator={true}
                                        rightAddons={addon}
                                    />
                                    {index !== someones.length - 1 && (
                                        <Divider variant={'middle'} />
                                    )}
                                </Fragment>
                            );
                        })}
                    </>
                }
            />
        </div>
    );
}
