import {useTheme} from '@mui/material/styles';
import {useState, type ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useNavigateBack} from '@/hooks/navigation';

import type BodyTemperatureModel from '@refinio/one.models/lib/models/BodyTemperatureModel.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import BodyTemperature from '../BodyTemperature.js';
import '../BodyTemperature.css';

export default function ViewBodyTemperature(props: {
    bodyTemperatureModel: BodyTemperatureModel;
    id?: string;
    onClose?: () => void | Promise<void>;
}): ReactElement {
    const i18n = useTranslation();
    const [temperature, setTemperature] = useState<number | ''>('');
    const goBack = useNavigateBack();
    const {id} = useParams<{id: string}>();
    const theme = useTheme();

    useEffect(() => {
        async function getEntry(): Promise<void> {
            if (!id && !props.id) {
                return;
            }

            const tempId = props.id ? props.id : id ? id : 'error';
            const dataObject = await props.bodyTemperatureModel.bodyTemperatureById(tempId);
            setTemperature(dataObject.data.temperature);
        }

        getEntry().catch(console.error);
        return props.bodyTemperatureModel.onUpdated(getEntry);
    }, [id, props.bodyTemperatureModel]);

    return (
        <AppBarFullScreenPopup
            mode={APP_BAR_MODE.CHEVRON}
            title={i18n.t('bodyTemperature.title')}
            onClose={props.onClose ? props.onClose : goBack}
            className="body-temperature-container"
            background={theme.palette.background.default}
        >
            <BodyTemperature value={String(temperature)} />
        </AppBarFullScreenPopup>
    );
}
