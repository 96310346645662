import type {ReactElement, ChangeEvent} from 'react';
import {useRef, useState, useContext} from 'react';
import {isMobile} from 'react-device-detect';

import PaperclipIcon from 'mdi-material-ui/Paperclip.js';
import IconButton from '@mui/material/IconButton/IconButton.js';

import ImageCapturePopup from '@refinio/one.ui/lib/ui/views/imageCapture/ImageCapturePopup.js';

import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import {MenuContext} from '@/context/MenuContext.js';

import './ChatFileSelector.css';

interface CallbackFunction {
    (files: Array<File>, image?: boolean): void | Promise<void>;
}

export default function ChatFileSelector(props: {
    loading: boolean;
    onFileChange: CallbackFunction;
}): ReactElement {
    const [showCamera, setShowCamera] = useState<boolean>(false);

    function toggleCamera() {
        setShowCamera((s: boolean) => !s);
    }

    const {setMenuEntries, selectMenuEntry, isOpen, setMenuReference, setMenuClassName} =
        useContext(MenuContext);

    const imageRef = useRef<HTMLInputElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    function toggleFileMenu() {
        // menu reference is the anchor for the menu popup
        setMenuEntries([MENU_ENTRY.SELECT_IMAGES, MENU_ENTRY.SELECT_FILES, MENU_ENTRY.TAKE_IMAGE]);
        selectMenuEntry(() => entrySelected);
        setMenuReference(menuRef.current);
        setMenuClassName('menu-chat-files');
        isOpen(true);
    }

    function entrySelected(value: string) {
        switch (value) {
            case MENU_ENTRY.SELECT_IMAGES:
                if (null !== imageRef.current) {
                    imageRef.current.click();
                }
                break;
            case MENU_ENTRY.TAKE_IMAGE:
                toggleCamera();
                break;
            case MENU_ENTRY.SELECT_FILES:
                if (null !== fileRef.current) {
                    fileRef.current.click();
                }
                break;
        }
        isOpen(false);
    }

    function getHandleInputChange(type: 'image' | 'document') {
        return async (event: ChangeEvent<HTMLInputElement>) => {
            if (event === null || event.target === null || event.target.files === null) {
                return;
            }
            if (event.target.files && event.target.files.length > 0) {
                await props.onFileChange([...event.target.files], type === 'image');
            }
        };
    }

    return (
        <div ref={menuRef}>
            {showCamera && (
                <ImageCapturePopup
                    onFileChange={(file: File) => props.onFileChange([file])}
                    active={showCamera}
                    onClose={toggleCamera}
                />
            )}
            <input
                ref={imageRef}
                type="file"
                style={{display: 'none'}}
                accept="image/*"
                onChange={getHandleInputChange('image')}
                multiple
            />
            <input
                ref={fileRef}
                type="file"
                style={{display: 'none'}}
                onChange={getHandleInputChange('document')}
                multiple
            />
            <IconButton
                disabled={props.loading}
                onClick={
                    isMobile ? () => imageRef.current && imageRef.current.click() : toggleFileMenu
                }
                aria-label="send file"
            >
                <PaperclipIcon className="icon-avatar-wrapper" />
            </IconButton>
        </div>
    );
}
