import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';

import type {SHA256Hash} from '@refinio/one.core/lib/util/type-checks.js';
import type IoMRequestManager from '@refinio/one.models/lib/models/IoM/IoMRequestManager.js';
import type {IoMRequest} from '@refinio/one.models/lib/recipes/IoM/IoMRequest.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import Button from '@mui/material/Button/Button.js';
import Paper from '@mui/material/Paper/Paper.js';
import Table from '@mui/material/Table/Table.js';
import TableBody from '@mui/material/TableBody/TableBody.js';
import TableCell from '@mui/material/TableCell/TableCell.js';
import TableContainer from '@mui/material/TableContainer/TableContainer.js';
import TableHead from '@mui/material/TableHead/TableHead.js';
import TableRow from '@mui/material/TableRow/TableRow.js';

function useIoMRequests(
    leuteModel: LeuteModel,
    iomRequestManager: IoMRequestManager,
    onError: (msg: any) => void
) {
    const [requests, setRequests] = useState<
        {
            active: boolean;
            requestHash: SHA256Hash<IoMRequest>;
            request: IoMRequest;
            timestamp: number;
            signable: boolean;
            initiator: ProfileModel;
            mainId: {
                profile: ProfileModel;
                signed: boolean;
            };
            alternateId: {
                profile: ProfileModel;
                signed: boolean;
            };
        }[]
    >([]);

    useEffect(() => {
        async function updateRequests() {
            const me = await leuteModel.me();
            const myId = await me.mainIdentity();

            const allRequests = await iomRequestManager.allRequests();
            const mappedRequests = await Promise.all(
                allRequests.map(async request => {
                    return {
                        active: request.active,
                        requestHash: request.requestHash,
                        request: request.request,
                        timestamp: request.request.timestamp,
                        signable: !(await leuteModel.trust.isAffirmedBy(request.requestHash, myId)),
                        initiator: await leuteModel.getMainProfile(request.request.initiator),
                        mainId: {
                            profile: await leuteModel.getMainProfile(request.request.mainId),
                            signed: await leuteModel.trust.isAffirmedBy(
                                request.requestHash,
                                request.request.mainId
                            )
                        },
                        alternateId: {
                            profile: await leuteModel.getMainProfile(request.request.alternateId),
                            signed: await leuteModel.trust.isAffirmedBy(
                                request.requestHash,
                                request.request.alternateId
                            )
                        }
                    };
                })
            );
            setRequests(mappedRequests);
        }

        updateRequests().catch(onError);

        const d1 = iomRequestManager.onNewRequest(() => {
            updateRequests().catch(onError);
        });
        const d2 = iomRequestManager.onRequestUpdate(() => {
            updateRequests().catch(onError);
        });

        return () => {
            d1();
            d2();
        };
    }, [leuteModel, iomRequestManager, setRequests, onError]);

    return requests;
}

export default function IoMView(props: {
    leuteModel: LeuteModel;
    iomRequestManager: IoMRequestManager;
}): ReactElement {
    const requests = useIoMRequests(props.leuteModel, props.iomRequestManager, console.error);
    const navigate = useNavigate();

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 700}} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell>RequestId</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Mode</TableCell>
                            <TableCell>PersonId</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Affirmed By Other</TableCell>
                            <TableCell>Affirm</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requests.map(request => {
                            const participant =
                                request.initiator.personId === request.mainId.profile.personId
                                    ? request.alternateId
                                    : request.mainId;
                            return (
                                <TableRow key={participant.profile.personId + '_row'}>
                                    <TableCell>
                                        {request.requestHash.slice(0, 10) + '...'}
                                    </TableCell>
                                    <TableCell>
                                        {!request.active ? 'PENDING' : 'FULFILLED'}
                                    </TableCell>
                                    <TableCell>{request.request.mode}</TableCell>
                                    <TableCell>
                                        {participant.profile.personId.slice(0, 10)}...
                                    </TableCell>
                                    <TableCell>
                                        {props.leuteModel.getPersonName(
                                            participant.profile.personId
                                        )}
                                    </TableCell>
                                    <TableCell>{participant.signed ? 'YES' : 'NO'}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => {
                                                props.iomRequestManager
                                                    .affirmRequest(request.requestHash)
                                                    .catch(console.error);
                                            }}
                                            disabled={!request.signable}
                                        >
                                            SIGN
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={() => navigate('create')}>Create new IoM Request</Button>
        </>
    );
}
