import {Fragment, useContext, useEffect, type ReactElement, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Divider from '@mui/material/Divider/Divider.js';

import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type SomeoneModel from '@refinio/one.models/lib/models/Leute/SomeoneModel.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel.js';
import type Notifications from '@refinio/one.models/lib/models/Notifications.js';
import type BlacklistModel from '@/model/BlacklistModel.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';
import ListElement from '@refinio/one.ui/lib/ui/components/list/ListElement.js';

import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import {MenuContext} from '@/context/MenuContext.js';
import AvatarPreview from '@/components/avatarPreview/AvatarPreview.js';
import {getURL} from '@/utils/Utils.js';
import {useSomeonesPreview} from '@/hooks/contact/someoneHooks.js';
import {useGroupsPreview} from '@/hooks/contact/groupHooks.js';
import SomeoneListEntry from './SomeoneListEntry.js';
import './LeuteView.css';

export const VIEWS = {
    LEUTE: 'leute',
    CREATE_GROUP: 'create-group',
    GROUP_DETAILS: 'group-details',
    CREATE_SOMEONE: 'create-someone',
    SOMEONE_DETAILS: 'someone-details'
} as const;

export type View = (typeof VIEWS)[keyof typeof VIEWS];

export type SomeonePreview = {
    name: string;
    mainProfile: ProfileModel;
    model: SomeoneModel;
    email: string;
    avatar?: string;
};

type SelectedGroup = {name: string; model: GroupModel};
type SelectSomeone = {
    name: string;
    model: SomeoneModel;
    mainProfile: ProfileModel;
};

/**
 * Build the Contacts page
 *
 */
export default function LeuteView(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    notifications: Notifications;
    blacklistModel: BlacklistModel;
    getTrustView?: (
        hashes: (SHA256Hash | SHA256IdHash)[] | SHA256Hash | SHA256IdHash | undefined
    ) => ReactElement;
    startGroupChat?: (
        group: GroupModel,
        topicModel?: TopicModel,
        notifications?: Notifications
    ) => ReactElement;
}): ReactElement {
    const [excludePersonIds, setExcludePersonIds] = useState<SHA256IdHash<Person>[]>(
        props.blacklistModel.blacklistGroupModel.persons
    );
    const {someones} = useSomeonesPreview(props.leuteModel, {personIds: excludePersonIds});
    const [me, ...others] = someones;
    const {groups} = useGroupsPreview(props.leuteModel);
    const appBarContext = useContext(AppBarContext);
    const {setMenuEntries, selectMenuEntry} = useContext(MenuContext);
    const i18n = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        function getExcludePersonIds(): void {
            setExcludePersonIds(props.blacklistModel.blacklistGroupModel.persons);
        }
        return props.blacklistModel.onUpdated(getExcludePersonIds);
    }, [props.blacklistModel]);

    useEffect(() => {
        selectMenuEntry(() => (selectedMenuEntry: string) => {
            switch (selectedMenuEntry) {
                case MENU_ENTRY.ADD_SOMEONE:
                    navigate('/someone/create');
                    break;
                case MENU_ENTRY.ADD_GROUP:
                    navigate('/group/create');
                    break;
            }
        });
        // get new contextValue on menu opening
    }, [appBarContext.contextValue.isRightBtnClicked]);

    /**
     * Set the popupMenu states when the displayed view is LEUTE.
     */
    useEffect(() => {
        setMenuEntries([MENU_ENTRY.ADD_SOMEONE, MENU_ENTRY.ADD_GROUP]);
        // clear the popup menu states when the component is unmounted
        return () => {
            setMenuEntries([]);
            selectMenuEntry(() => {});
        };
        // mount / unmount only
    }, []);

    function selectGroup(group: SelectedGroup): void {
        navigate(`/group/${group.model.groupIdHash}`);
    }

    function selectSomeone(someone: SelectSomeone): void {
        navigate(`/someone/${someone.model.idHash}`);
    }

    function displayOthersList(): ReactElement {
        return (
            <CollapsibleComponent
                headline={
                    <div className="collapsible-headline">
                        {i18n.t('leute.lists.someones').toUpperCase()}
                        <div>({others.length})</div>
                    </div>
                }
                content={
                    <>
                        {others.map((someone, index) => (
                            <Fragment key={index}>
                                <SomeoneListEntry
                                    leuteModel={props.leuteModel}
                                    someone={someone}
                                    onSelect={selectSomeone}
                                    topicModel={props.topicModel}
                                    notifications={props.notifications}
                                    disableTrustIndicator={true}
                                    rightAddons={
                                        props.getTrustView
                                            ? props.getTrustView(
                                                  someone.mainProfile.loadedVersion
                                                      ? [
                                                            someone.mainProfile.loadedVersion,
                                                            someone.mainProfile.personId
                                                        ]
                                                      : [someone.mainProfile.personId]
                                              )
                                            : undefined
                                    }
                                />
                                {index !== someones.length - 1 && <Divider variant={'middle'} />}
                            </Fragment>
                        ))}
                    </>
                }
            />
        );
    }

    function displayGroupsList(): ReactElement {
        return (
            <CollapsibleComponent
                headline={
                    <div className="collapsible-headline">
                        {i18n.t('leute.lists.groups').toUpperCase()}
                        <div>({groups.length})</div>
                    </div>
                }
                content={
                    <>
                        {groups.map((group, index) => (
                            <Fragment key={index}>
                                <ListElement
                                    onClick={() => selectGroup(group)}
                                    extra={
                                        <>
                                            <AvatarPreview
                                                src={
                                                    group.model.picture
                                                        ? getURL(group.model.picture)
                                                        : undefined
                                                }
                                            />
                                            {i18n.t(`leute.group.names.${group.name}`) ===
                                            `leute.group.names.${group.name}`
                                                ? group.name
                                                : i18n.t(`leute.group.names.${group.name}`)}
                                            {props.startGroupChat &&
                                                props.startGroupChat(
                                                    group.model,
                                                    props.topicModel,
                                                    props.notifications
                                                )}
                                        </>
                                    }
                                />

                                {index !== groups.length - 1 && <Divider variant={'middle'} />}
                            </Fragment>
                        ))}
                    </>
                }
            />
        );
    }

    return (
        <div className="cnt-container">
            {me && (
                <SomeoneListEntry
                    leuteModel={props.leuteModel}
                    someone={me}
                    onSelect={selectSomeone}
                    topicModel={props.topicModel}
                    disableTrustIndicator={true}
                    rightAddons={
                        props.getTrustView
                            ? props.getTrustView(
                                  me.mainProfile.loadedVersion
                                      ? [me.mainProfile.loadedVersion, me.mainProfile.personId]
                                      : [me.mainProfile.personId]
                              )
                            : undefined
                    }
                />
            )}
            {displayGroupsList()}
            {displayOthersList()}
        </div>
    );
}
