import type {ReactElement} from 'react';
import {Button, ListItem} from '@mui/material';

import './AddListItem.css';

/**
 * @params props.name
 * @params props.onClick
 * @params props.className
 */
export default function AddListItem(props: {
    name: string;
    onClick: () => void;
    className?: string;
}): ReactElement {
    return (
        <ListItem
            className={`add-list-item${props.className ? ` ${props.className}` : ''}`}
            onClick={props.onClick}
        >
            <Button className="btn-add" size="small" onClick={props.onClick}>
                {'+'}
            </Button>
            <div className="btn-add-text">{props.name}</div>
        </ListItem>
    );
}
