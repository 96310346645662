import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';
import Typography from '@mui/material/Typography/Typography.js';

import type {SHA256Hash} from '@refinio/one.core/lib/util/type-checks.js';

import {downloadBlob} from '@/utils/Utils.js';
import ToggleDetails from '@/root/chat/attachmentViews/utils/ToggleDetails.js';
import CertificateDetails from '@/root/chat/attachmentViews/utils/CertificateDetails.js';
import {useBlobDescriptor} from './hooks.js';

/**
 * Helper component.
 * Extends BlobAttachmentDetails, but loads the info from hash.
 *
 * @param props.originalHash
 * @param props.getCertificatePopupView
 * @param props.onError
 * @returns
 */
export default function HashBlobAttachmentDetails(props: {
    hash: SHA256Hash;
    getCertificatePopupView?: (onClose: () => void) => ReactElement;
    onError: (error: Error) => void;
}): ReactElement {
    return (
        <ToggleDetails>
            <Details hash={props.hash} onError={props.onError} />
            {props.getCertificatePopupView && (
                <CertificateDetails certificateView={props.getCertificatePopupView} />
            )}
        </ToggleDetails>
    );
}

/**
 * Helper component.
 * Extends BlobAttachmentDetails to load the details of the original image,
 * but only on toggle of the details.
 *
 * @param props.originalHash
 * @param props.getCertificatePopupView
 * @param props.onError
 * @returns
 */
function Details(props: {hash: SHA256Hash; onError: (error: Error) => void}): ReactElement {
    const i18n = useTranslation();
    const blobDescriptor = useBlobDescriptor(props.hash, props.onError);
    if (!blobDescriptor) {
        return <CircularProgress />;
    }
    const blob = new Blob([blobDescriptor.data], {type: blobDescriptor.type});
    const filename = blobDescriptor.name;

    return (
        <div className="attachment-details">
            <Typography sx={{fontSize: 12}} color="text.secondary">
                {i18n.t('chat.attachment.details.fileName')}
            </Typography>
            <Typography className={'instance-setting-item-margin'} variant="body1" component="div">
                {filename}
            </Typography>
            <Typography sx={{fontSize: 12}} color="text.secondary">
                {i18n.t('chat.attachment.details.fileSize')}
            </Typography>
            <Typography className={'instance-setting-item-margin'} variant="body1" component="div">
                {blob.size}
            </Typography>
            <Button
                sx={{width: 1}}
                variant="outlined"
                className="mui-outlined-button"
                onClick={() => {
                    downloadBlob(blob, filename);
                }}
            >
                {i18n.t('buttons.common.save')}
            </Button>
        </div>
    );
}
