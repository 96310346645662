import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button/Button.js';

import bodyImage from './resources/images/body.png';
import bodySectorImage from './resources/images/bodySector.png';
import bodySectorSelectedRedImage from './resources/images/bodySectorSelectedRed.png';
import bodySectorSelectedGreenImage from './resources/images/bodySectorSelectedGreen.png';
import './ExaminationBodySectorSelector.css';

const bodySectors = ['left-breast', 'right-breast', 'left-armpit', 'right-armpit'] as const;
export type BodySector = (typeof bodySectors)[number];

/**
 * @param date
 * @returns
 */
function getDayMonthYearFormat(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
}

/**
 * @param props.selectedBodySector
 * @param props.nextExaminationDates
 * @param props.onSelectedSection
 * @param props.startExamination
 * @returns
 */
export default function ExaminationBodySectorSelector(props: {
    selectedBodySector: BodySector | undefined;
    nextExaminationDates: Record<BodySector, Date | undefined>;
    onSelectedSection: (bodySector: BodySector | undefined) => void;
    startExamination: () => void;
}): ReactElement {
    const i18n = useTranslation();

    function startExamination(): void {
        if (!props.selectedBodySector) {
            return;
        }
        props.startExamination();
    }

    function getInformation(): ReactElement {
        if (props.selectedBodySector === undefined) {
            return <>{i18n.t('ecpire.selector.notSelectedText')}</>;
        }
        if (props.nextExaminationDates) {
            const nextExaminationDate = props.nextExaminationDates[props.selectedBodySector];
            if (nextExaminationDate) {
                return (
                    <span>
                        {i18n.t('ecpire.selector.noNeedForExamination')}
                        {getDayMonthYearFormat(nextExaminationDate)}
                    </span>
                );
            }
        }
        return (
            <Button variant="contained" onClick={startExamination}>
                {i18n.t('ecpire.selector.examination')}
            </Button>
        );
    }

    return (
        <div className="ecpire-body-selector-container">
            <div className="selector-image-container">
                <div className="selector-image">
                    <img src={bodyImage} className="body-image" alt="body sector selector" />
                    {props.selectedBodySector && (
                        <div
                            className={`sector ${props.selectedBodySector} selected`}
                            onClick={() => props.onSelectedSection(undefined)}
                        >
                            <img
                                src={
                                    props.nextExaminationDates &&
                                    props.nextExaminationDates[props.selectedBodySector]
                                        ? bodySectorSelectedGreenImage
                                        : bodySectorSelectedRedImage
                                }
                                alt="body sector selected"
                            />
                        </div>
                    )}
                    {bodySectors.map((bodySector, index) => (
                        <div
                            key={index}
                            className={`sector ${bodySector}`}
                            onClick={() => props.onSelectedSection(bodySector)}
                        >
                            <img src={bodySectorImage} alt="body sector" />
                        </div>
                    ))}
                </div>
            </div>
            <div className="selector-information-container">
                <div className="selector-information">{getInformation()}</div>
            </div>
        </div>
    );
}
