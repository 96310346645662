import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks';
import type TrustedKeysManager from '@refinio/one.models/lib/models/Leute/TrustedKeysManager';
import type {Person} from '@refinio/one.core/lib/recipes';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile';

import type {IdObjectType, ObjectType} from '@refinio/one.ui/lib/ui/views/debug/getAnyObject';

export function getCreateCertificateCallback(
    trust: TrustedKeysManager
): (data: SHA256Hash, type: string) => Promise<void> {
    return async (data: SHA256Hash, type: string) => await createCertificate(trust, data, type);
}

export async function createCertificate(
    trust: TrustedKeysManager,
    data: SHA256Hash,
    type: string
): Promise<void> {
    if (type === 'AffirmationCertificate') {
        await trust.affirm(data);
    } else if (type === 'TrustKeysCertificate') {
        await trust.certify('TrustKeysCertificate', {
            profile: data as unknown as SHA256Hash<Profile>
        });
    } else if (type === 'RightToDeclareTrustedKeysForEverybodyCertificate') {
        await trust.certify('RightToDeclareTrustedKeysForEverybodyCertificate', {
            beneficiary: data as unknown as SHA256IdHash<Person>
        });
    } else if (type === 'RightToDeclareTrustedKeysForSelfCertificate') {
        await trust.certify('RightToDeclareTrustedKeysForSelfCertificate', {
            beneficiary: data as unknown as SHA256IdHash<Person>
        });
    } else if (type === 'SignupCertificate') {
        await trust.certify('SignupCertificate', {
            person: data as unknown as SHA256IdHash<Person>
        });
    }
}

export function getCertificateMenu(object: ObjectType | IdObjectType): string[] {
    const menu: string[] = [];

    if (object.type === 'Object') {
        menu.push('AffirmationCertificate');
    }

    if (object.type === 'Object' && object.obj.$type$ === 'Profile') {
        menu.push('TrustKeysCertificate');
    }

    if (object.type === 'IdObject' && object.obj.$type$ === 'Person') {
        menu.push('RightToDeclareTrustedKeysForEverybodyCertificate');
    }

    if (object.type === 'IdObject' && object.obj.$type$ === 'Person') {
        menu.push('RightToDeclareTrustedKeysForSelfCertificate');
    }

    return menu;
}

export function getIssuerMenu(): string[] {
    return ['TBD'];
}
