import {useEffect} from 'react';

import {useEditPictureContext} from '@/context/EditPictureContext.js';

export function useUpdateOnUploadedImage(update: (image: ArrayBuffer) => void) {
    const {editedPicture, setEditedPicture} = useEditPictureContext();

    useEffect(() => {
        if (editedPicture !== undefined) {
            update(editedPicture);
            setEditedPicture(undefined);
        }
    }, [editedPicture, update, setEditedPicture]);
}
