import type {NavigationDrawerItem} from '@refinio/one.ui/lib/ui/components/menu/NavigationDrawer.js';

import i18n from '@/i18n.js';

/**
 * Returns the list of the debug menu items
 */
export function getLeuteDebugMenuItems(
    defaultItems: NavigationDrawerItem[]
): NavigationDrawerItem[] {
    return [
        ...defaultItems,
        ...[
            {
                name: i18n.t('menu.debug.versionViewer'),
                path: '/debug/versionViewer'
            },
            {
                name: i18n.t('menu.debug.access'),
                path: '/debug/access'
            },
            {
                name: i18n.t('menu.debug.loggerSettings'),
                path: '/debug/loggerSettings'
            },
            {
                name: i18n.t('menu.debug.objDump'),
                path: '/debug/objDump'
            },
            {
                name: i18n.t('menu.debug.profileKeyView'),
                path: '/debug/profileKeyView'
            },
            {
                name: i18n.t('menu.debug.objectEvents'),
                path: '/debug/objectEvents'
            },
            {
                name: i18n.t('menu.debug.ioStatistics'),
                path: '/debug/ioStatistics'
            }
        ]
    ];
}
