import type {ReactElement} from 'react';
import {useEffect, useState, useContext, useCallback} from 'react';
import type {To} from 'react-router-dom';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {Someone} from '@refinio/one.models/lib/recipes/Leute/Someone.js';
import SomeoneModel from '@refinio/one.models/lib/models/Leute/SomeoneModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import {AppBarContext, APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {DARK_ORANGE} from '@/components/Constants.js';
import {updateProfilePersonDescription} from '@/hooks/contact/utils.js';
import type {ProfileDetails} from '@/root/profile/common/types.js';
import SomeoneProfileDetails from '@/root/profile/common/SomeoneProfileDetails.js';
import PersonIdSelector from '@/root/profile/common/PersonIdSelector.js';
import {createProfile, useBackgroundColor} from '@/utils/Utils.js';

export default function ProfileCreate(props: {
    leuteModel: LeuteModel;
    doneRoute: To;
    cancelRoute: To | number;
}): ReactElement {
    const navigate = useNavigate();
    const i18n = useTranslation();
    const [profileDetails, setProfileDetails] = useState<ProfileDetails>({
        name: '',
        personEmail: '',
        avatar: undefined
    });
    const [personIds, setPersonIds] = useState<SHA256IdHash<Person>[]>([]);
    const params = useParams<{someoneId: SHA256IdHash<Someone>}>();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const appBarContext = useContext(AppBarContext);
    useBackgroundColor(DARK_ORANGE);

    const finishCreate = useCallback(async () => {
        if (params.someoneId === undefined) {
            return;
        }

        // UI input checks
        if (profileDetails.name === '' || profileDetails.name === undefined) {
            setNotificationMessage(i18n.t('errors.profile.empty.name'));
            setNotificationType(NOTIFICATION.Error);
            return;
        }

        const profile = await createProfile(
            props.leuteModel,
            params.someoneId,
            profileDetails.personId
        );
        await updateProfilePersonDescription(profile, profileDetails);
        await profile.saveAndLoad();
        navigate(
            typeof props.doneRoute === 'string'
                ? props.doneRoute.toString().replace(':profileId', profile.idHash)
                : props.doneRoute,
            {
                replace: true
            }
        );
    }, [profileDetails, props.leuteModel, props.doneRoute, params.someoneId]);

    const cancelCreate = useCallback(() => {
        if (!isNaN(Number(props.cancelRoute))) {
            navigate(Number(props.cancelRoute));
        } else {
            navigate(props.cancelRoute as To);
        }
    }, [navigate, props.cancelRoute]);

    // keep up to date the cancel and done buttons on the menu
    useEffect(() => {
        appBarContext.setContextValue({
            ...appBarContext.contextValue,
            mode: APP_BAR_MODE.EDIT,
            hide: false,
            title: i18n.t('leute.profile.newProfile'),
            leftBtnCallback: cancelCreate,
            rightBtnCallback: finishCreate
        });
    }, [finishCreate, cancelCreate]);

    // extract personIds from someone
    useEffect(() => {
        async function getSomeonePersonIds(): Promise<void> {
            if (!params.someoneId) {
                return;
            }
            const someoneModel = await SomeoneModel.constructFromLatestVersion(params.someoneId);
            setPersonIds(someoneModel.identities());
        }

        getSomeonePersonIds().catch(ignored => {
            setNotificationMessage(i18n.t('errors.profile.gettingPersonIds'));
            setNotificationType(NOTIFICATION.Error);
        });
    }, [params.someoneId]);

    return (
        <SomeoneProfileDetails
            profileDetails={profileDetails}
            updateProfileDetails={setProfileDetails}
            title={i18n.t('leute.profile.title')}
            bellowNameAddon={
                <PersonIdSelector
                    value={profileDetails.personId}
                    onSelect={personId => {
                        setProfileDetails({
                            ...profileDetails,
                            personId: personId
                        });
                    }}
                    options={personIds}
                />
            }
        />
    );
}
