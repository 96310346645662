import type {QuestionnaireResponse} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

const nameLinkId = 'name';
const emailLinkId = 'email';
const personImageLinkId = 'personImage';
const certificateLinkId = 'certificate';

export function usePatientOnboarding(): {
    extract: {
        name: (response: QuestionnaireResponse) => string | undefined;
        email: (response: QuestionnaireResponse) => string | undefined;
        image: (response: QuestionnaireResponse) => {base64: string; mime: string} | undefined;
        certificateChecked: (response: QuestionnaireResponse) => boolean | undefined;
    };
} {
    function extractName(response: QuestionnaireResponse): string | undefined {
        const namesData = response.item.filter(i => i.linkId === nameLinkId);

        if (namesData && namesData.length > 0 && namesData[0]) {
            const nameData = namesData[0];
            if (nameData.answer.length > 0) {
                const nameAnswer = nameData.answer[0];
                if (nameAnswer.valueString) {
                    return nameAnswer.valueString;
                }
            }
        }

        return undefined;
    }

    function extractImage(
        response: QuestionnaireResponse
    ): {base64: string; mime: string} | undefined {
        const imagesData = response.item.filter(i => i.linkId === personImageLinkId);

        if (imagesData && imagesData.length > 0 && imagesData[0]) {
            const imageData = imagesData[0];
            if (imageData.answer.length > 0) {
                const imageAnswer = imageData.answer[0];
                if (imageAnswer.valueAttachment) {
                    return {
                        base64: imageAnswer.valueAttachment.data,
                        mime: imageAnswer.valueAttachment.contentType
                    };
                }
            }
        }

        return undefined;
    }

    function extractEmail(response: QuestionnaireResponse): string | undefined {
        const emailsData = response.item.filter(i => i.linkId === emailLinkId);

        if (emailsData && emailsData.length > 0 && emailsData[0]) {
            const emailData = emailsData[0];
            if (emailData.answer.length > 0) {
                const emailAnswer = emailData.answer[0];
                if (emailAnswer.valueString) {
                    return emailAnswer.valueString;
                }
            }
        }

        return undefined;
    }

    function certificateChecked(response: QuestionnaireResponse): boolean | undefined {
        const certificatesData = response.item.filter(i => i.linkId === certificateLinkId);

        if (certificatesData && certificatesData.length > 0 && certificatesData[0]) {
            const certificateData = certificatesData[0];
            if (certificateData.answer.length > 0) {
                const certificateAnswer = certificateData.answer[0];
                if (certificateAnswer.valueCoding) {
                    return certificateAnswer.valueCoding.code === '1';
                }
            }
        }

        return undefined;
    }

    return {
        extract: {name: extractName, email: extractEmail, certificateChecked, image: extractImage}
    };
}
