import type {ReactElement} from 'react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

import Divider from '@mui/material/Divider/Divider.js';
import List from '@mui/material/List/List.js';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile.js';
import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';

import StartChat from '@/components/startChat/StartChat.js';
import ProfileListEntry from '@/root/someone/someoneId/ProfileListEntry.js';

export default function CollapsibleProfilesList(props: {
    leuteModel: LeuteModel;
    profiles: ProfileModel[];
    mainProfileHash: SHA256IdHash<Profile> | undefined;
    onSelectProfile: (profile: ProfileModel) => void;
    topicModel: TopicModel;
    hideListLength?: boolean;
    title?: string;
    showOnlyDefaultLabel?: boolean;
    hideIdentityInfo?: boolean;
    entryAddon?: (profile: ProfileModel) => ReactElement;
}): ReactElement {
    const i18n = useTranslation();
    return (
        <CollapsibleComponent
            headline={
                <div className="collapsible-headline">
                    {props.title ? props.title : i18n.t('leute.lists.profile')}
                    <div>{!props.hideListLength ? `(${props.profiles.length})` : ''}</div>
                </div>
            }
            content={
                <List>
                    {props.profiles.length > 0 &&
                        props.profiles.map((profile, index) => (
                            <Fragment key={index}>
                                <ProfileListEntry
                                    leuteModel={props.leuteModel}
                                    isMainProfile={profile.idHash === props.mainProfileHash}
                                    profileModel={profile}
                                    onSelect={() => props.onSelectProfile(profile)}
                                    topicModel={props.topicModel}
                                    hideDetailedInformation={props.showOnlyDefaultLabel}
                                    showDefaultLabel={props.showOnlyDefaultLabel}
                                    hideIdentityInfo={props.hideIdentityInfo}
                                    entryAddon={
                                        props.entryAddon
                                            ? props.entryAddon
                                            : (profileModel: ProfileModel) =>
                                                  props.topicModel && (
                                                      <div
                                                          className="right-alignment"
                                                          onClick={e => e.stopPropagation()}
                                                      >
                                                          <StartChat
                                                              leuteModel={props.leuteModel}
                                                              topicModel={props.topicModel}
                                                              receiverProfile={profileModel}
                                                          />
                                                      </div>
                                                  )
                                    }
                                />
                                {index < props.profiles.length - 1 ? (
                                    <Divider variant={'middle'} />
                                ) : null}
                            </Fragment>
                        ))}
                </List>
            }
        />
    );
}
