import {useRef, type ReactElement, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Card, CircularProgress, Dialog, DialogContent} from '@mui/material';

import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';
import type {SHA256Hash} from '@refinio/one.core/lib/util/type-checks.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import {calculateIdHashOfObj} from '@refinio/one.core/lib/util/object.js';
import {getNthVersionMapHash} from '@refinio/one.core/lib/version-map-query.js';
import {implode} from '@refinio/one.core/lib/microdata-imploder.js';
import {explode} from '@refinio/one.core/lib/microdata-exploder.js';
import {isString} from '@refinio/one.core/lib/util/type-checks-basic.js';

import {downloadBlob, isTypeArray, readBlobAsText} from '@/utils/Utils.js';
import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import './DetailedInstanceSettingsView.css';

async function restoreInstance(data: Blob): Promise<boolean> {
    const dataText = await readBlobAsText(data);

    if (dataText) {
        const parsed = JSON.parse(dataText) as unknown;
        if (isTypeArray(parsed, isString)) {
            const microdataArray = parsed as string[];
            for (const microdata of microdataArray) {
                try {
                    await explode(microdata);
                } catch (e) {
                    console.error(e);
                    return false;
                }
            }
        }

        return true;
    }

    return false;
}

export default function BackupSection(props: {channelManager: ChannelManager}): ReactElement {
    const i18n = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    const toggleUpload = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click();
        } else {
            console.error('input ref not found');
        }
    }, [inputRef]);

    async function exportInstance(): Promise<void> {
        setLoading(true);
        const backupFile = await backupInstance(props.channelManager);
        const backupBlob = new Blob([backupFile]);
        downloadBlob(backupBlob, 'backup.html');
        setLoading(false);
    }

    return (
        <div className="backup-section-container">
            <input
                ref={inputRef}
                type="file"
                multiple={false}
                style={{display: 'none'}}
                onChange={async e => {
                    // we are expecting one file, any more or less we ignore
                    if (e.target.files?.length === 1) {
                        setLoading(true);
                        if (await restoreInstance(e.target.files[0])) {
                            setNotificationMessage(i18n.t('settings.importBackupSuccessful'));
                            setNotificationType(NOTIFICATION.Success);
                        } else {
                            setNotificationMessage(i18n.t('settings.importBackupUnsuccessful'));
                            setNotificationType(NOTIFICATION.Error);
                        }
                        setLoading(false);
                    }
                }}
            />
            <Dialog
                open={loading}
                onClose={(_event, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        // prevent closing
                        return false;
                    }
                }}
            >
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
            <CollapsibleComponent
                content={
                    <Card
                        className={'instance-settings-wrapper instance-settings cnt-someone-card'}
                        sx={{minWidth: 275}}
                    >
                        <Button
                            variant="outlined"
                            className="backup-button export"
                            onClick={exportInstance}
                        >
                            {i18n.t('settings.exportBackup')}
                        </Button>
                        <Button
                            variant="outlined"
                            className="backup-button import"
                            onClick={toggleUpload}
                        >
                            {i18n.t('settings.importBackup')}
                        </Button>
                    </Card>
                }
                headline={<>{i18n.t('settings.backup')}</>}
            />
        </div>
    );
}

async function backupInstance(channelManager: ChannelManager): Promise<Blob> {
    const hashesToImplode: SHA256Hash[] = [];
    const channelsInfo = await channelManager.channels();
    await Promise.all(
        channelsInfo.map(async channelInfo => {
            const channelIdHash = await calculateIdHashOfObj({
                $type$: 'ChannelInfo',
                id: channelInfo.id,
                owner: channelInfo.owner
            });
            const channelHash = await getNthVersionMapHash(channelIdHash);
            return hashesToImplode.push(channelHash);
        })
    );

    const microdataArray: string[] = [];

    for (const hashToImplode of hashesToImplode) {
        microdataArray.push(await implode(hashToImplode));
    }

    return new Blob([JSON.stringify(microdataArray)], {type: 'text/html'});
}
