import {useCallback, useRef, useState} from 'react';

/**
 * This returns a function that can be used to force a rerender.
 *
 * This is sometimes required, when registering for an update of a complex data structure
 * where only the content changes, but not the address, so updating a state would not trigger
 * a re-render.
 */
export function useForceRender(): () => void {
    const [, setRedrawState] = useState(false);
    const stateRef = useRef(true);

    return useCallback(() => {
        setRedrawState(stateRef.current);
        stateRef.current = !stateRef.current;
    }, [setRedrawState]);
}
