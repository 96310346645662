import {useLayoutEffect, useState} from 'react';

/**
 * Used to change the color of the background to white.
 */
export function changeBackgroundColorToWhite(): void {
    const rootHTMLElement = document.getElementById('root');

    if (rootHTMLElement) {
        rootHTMLElement.classList.add('white-background');
    }
}

/**
 * Used to remove the white background.
 */
export function removeWhiteBackgroundColor(): void {
    const rootHTMLElement = document.getElementById('root');

    if (rootHTMLElement) {
        rootHTMLElement.classList.remove('white-background');
    }
}

/**
 * Custom hook for getting the size of the window.
 * @returns {number[]} - width and height of the window.
 */
export function useWindowSize(): number[] {
    const [size, setSize] = useState<[number, number]>([0, 0]);
    useLayoutEffect(() => {
        function updateSize(): void {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
