import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const SpecificFeedback: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/SpecificFeedback',
    name: 'specific_feedback_edda',
    title: 'Specific Feedback (Edda)',
    status: 'active',
    item: [
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'edda_software',
            prefix: '1',
            required: false,
            text: 'EDDA.One software',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'edda_download',
                    prefix: '1_1',
                    required: false,
                    text: 'Downloading and starting EDDA',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'group',
                    disabledDisplay: 'protected',
                    linkId: 'edda_enrollment',
                    prefix: '1_2',
                    required: false,
                    text: 'Patient enrollment'
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'create_patient',
                    prefix: '1_2_1',
                    required: false,
                    text: 'Create a new patient',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'onboarding_questionnaire',
                    prefix: '1_2_2',
                    required: false,
                    text: 'Onboarding questionnaire',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'time_consumption',
                    prefix: '1_2_3',
                    required: false,
                    text: 'Time consumption',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'group',
                    disabledDisplay: 'protected',
                    linkId: 'dhis_questionnaire',
                    prefix: '1_3',
                    required: false,
                    text: 'CC screening questionnaire (DHIS questionnaire)'
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'read_items',
                    prefix: '1_3_1',
                    required: false,
                    text: 'to read the items',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'document_answers',
                    prefix: '1_3_2',
                    required: false,
                    text: 'document the question-answers',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'document_time',
                    prefix: '1_3_3',
                    required: false,
                    text: 'time consumption to document',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'comp_digital_handwriting',
                    prefix: '1_3_4',
                    required: false,
                    text: 'Comparison between digital and handwriting DHIS documentation',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Give a low rating digital < handwriting',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high_handwriting'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Give a medium rating digital = handwriting',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high_handwriting'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Give a high rating of digital > handwriting',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high_handwriting'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'pref_documentation system',
                    prefix: '1_3_5',
                    required: false,
                    text: 'Preference of documantation system',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Give a low rating digital < handwriting',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high_handwriting'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Give a medium rating digital = handwriting',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high_handwriting'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Give a high rating of digital > handwriting',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high_handwriting'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'serum_hpv_test',
            prefix: '2',
            required: false,
            text: 'Serum-based HPV Lateral Flow Test',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'hpv_inform',
                    prefix: '2_1',
                    required: false,
                    text: 'Convenience to inform the patient',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'hpv_serum_sampling',
                    prefix: '2_2',
                    required: false,
                    text: 'Convenience of serum sampling',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'hpv_test_plate',
                    prefix: '2_3',
                    required: false,
                    text: 'Convenience to use the test plate',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'hpv_read_test_result',
                    prefix: '2_4',
                    required: false,
                    text: 'Convenience to read test result',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'hpv_transform_edda',
                    prefix: '2_5',
                    required: false,
                    text: 'Convenience to transfer the data in the EDDA.One software',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'digital_col_gyn',
            prefix: '3',
            required: false,
            text: 'Digital colposcopy Gynius device',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'gyn_conv',
                    prefix: '3_1',
                    required: false,
                    text: 'Convenience to perform the examination',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'integer',
                    disabledDisplay: 'protected',
                    linkId: 'gyn_time',
                    prefix: '3_2',
                    required: false,
                    text: 'time needed to do it (hrs)',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 1
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 99
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'gyn_image_conv',
                    prefix: '3_3',
                    required: false,
                    text: 'Convenience to take the cervical images in EDDA.One',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'low',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'medium',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'high',
                                system: 'http://refinio.net/edda/valueCoding/low_med_high'
                            }
                        }
                    ]
                },
                {
                    type: 'integer',
                    disabledDisplay: 'protected',
                    linkId: 'gyn_image_time',
                    prefix: '3_4',
                    required: false,
                    text: 'time consumption to do it (min)',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 1
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 120
                        }
                    ]
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'edda_colposcopy',
            prefix: '4',
            required: false,
            text: 'Convenience to use the EDDA.One colposcopy questionnaire',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'low',
                        system: 'http://refinio.net/edda/valueCoding/low_med_high'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'medium',
                        system: 'http://refinio.net/edda/valueCoding/low_med_high'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'high',
                        system: 'http://refinio.net/edda/valueCoding/low_med_high'
                    }
                }
            ]
        }
    ]
};
