import type {ReactElement} from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';

import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type {OptionalQuestionnaireContextData} from '@refinio/one.ui/lib/ui/views/questionnaire/context.js';
import {OptionalQuestionnaireContext} from '@refinio/one.ui/lib/ui/views/questionnaire/context.js';
import {APP_BAR_MODE, AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import SimpleQuestionnaireView from '@refinio/one.ui/lib/ui/views/questionnaire/SimpleQuestionnaireView.js';
import type {QuestionnaireResponse} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type {Message} from '@refinio/one.ui/lib/ui/views/questionnaire/types.js';
import {MESSAGE_TYPE} from '@refinio/one.ui/lib/ui/components/alert/StyledAlert.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import {useQuestionnaireResponse} from '@/hooks/questionnaire/hooks.js';

import './QuestionnaireDisplay.css';

/**
 *
 * @param props.questionnaireModel
 * @param props.questionnaire
 * @param props.onFinish
 * @param props.onCancel Optional. Default: undefined. Callback on cancel. Overwrites navigate back.
 * @returns
 */
export default function QuestionnaireScreen(props: {
    questionnaireModel: QuestionnaireModel;
    questionnaire: Questionnaire;
    onFinish: (response: QuestionnaireResponse) => Promise<void> | void;
    onCancel?: () => void;
}): ReactElement {
    const goBack = useNavigateBack();
    const [questionnaireContextValue, setQuestionnaireContextValue] =
        useState<OptionalQuestionnaireContextData>({saving: false});
    const response = useQuestionnaireResponse(props.questionnaireModel);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    /**
     * Use optional questionnaire context to trigger validate and save in the view itself
     */
    const saveQuestionnaire = useCallback(async () => {
        setQuestionnaireContextValue(questionnaireContextValue => ({
            ...questionnaireContextValue,
            saving: !questionnaireContextValue.saving ? true : questionnaireContextValue.saving
        }));
    }, []);

    const handleMessage = useCallback(
        (message: Message) => {
            if (message.type === MESSAGE_TYPE.error) {
                setNotificationMessage(message.text);
                setNotificationType(NOTIFICATION.Error);
            }
        },
        [setNotificationMessage, setNotificationType]
    );

    if (response === null) {
        return <CircularProgress />;
    }

    return (
        <QuestionnaireAppBar
            questionnaire={props.questionnaire}
            goBack={props.onCancel ? props.onCancel : goBack}
            saveQuestionnaire={saveQuestionnaire}
        >
            <OptionalQuestionnaireContext.Provider
                value={{
                    contextValue: questionnaireContextValue,
                    setContextValue: setQuestionnaireContextValue
                }}
            >
                <SimpleQuestionnaireView
                    onFullscreenRequest={true}
                    initialResponse={response}
                    readonly={response !== undefined}
                    questionnaire={props.questionnaire}
                    onSuccessfulSubmit={props.onFinish}
                    autoscroll={true}
                    onMessage={handleMessage}
                />
            </OptionalQuestionnaireContext.Provider>
        </QuestionnaireAppBar>
    );
}

export function QuestionnaireAppBar(props: {
    questionnaire: Questionnaire;
    goBack: () => Promise<void> | void;
    saveQuestionnaire: () => Promise<void> | void;
    children: ReactElement | ReactElement[];
}): ReactElement {
    const location = useLocation();
    const appBarContext = useContext(AppBarContext);

    /**
     * setup appBar
     */
    useEffect(() => {
        const oldContextValue = appBarContext.contextValue;

        async function setupAppBar() {
            if (!props.questionnaire) {
                appBarContext.setContextValue({
                    ...appBarContext.contextValue,
                    title: ' ',
                    mode: APP_BAR_MODE.CHEVRON,
                    leftBtnCallback: props.goBack
                });
                return;
            }
            const queryParams = new URLSearchParams(location.search);
            const response = queryParams.get('response');
            if (response) {
                appBarContext.setContextValue(contextValue => ({
                    ...contextValue,
                    title: props.questionnaire.title ? props.questionnaire.title : ' ',
                    mode: APP_BAR_MODE.CHEVRON,
                    leftBtnCallback: props.goBack
                }));
            } else {
                appBarContext.setContextValue(contextValue => ({
                    ...contextValue,
                    title: props.questionnaire.title ? props.questionnaire.title : ' ',
                    mode: APP_BAR_MODE.EDIT,
                    leftBtnCallback: props.goBack,
                    rightBtnCallback: props.saveQuestionnaire
                }));
            }
        }

        setupAppBar().catch(console.error);

        return () => {
            appBarContext.setContextValue(oldContextValue);
        };
        // appBarContext will cause recursion,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, props.saveQuestionnaire, props.goBack, props.questionnaire]);

    return <>{props.children}</>;
}
