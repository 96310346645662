import {useTheme} from '@mui/material/styles';
import React from 'react';
import type {ReactElement} from 'react';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton/IconButton.js';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined.js';

import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type {QuestionnaireResponses} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import QuestionnaireView from '@refinio/one.ui/lib/ui/views/questionnaire/QuestionnaireView.js';

import QuestionnaireResponsesAttachmentDetails from './QuestionnaireResponsesAttachmentDetails.js';

/**
 * @param props.oneObject
 * @param props.questionnaireModel needed for questionnaire response view
 * @param props.getCertificatePopupView callback to get certificate popup view
 * @param props.onError Optional. Default console.error
 * @returns
 */
export default function QuestionnaireResponsesAttachmentView(props: {
    questionnaireResponses: QuestionnaireResponses;
    questionnaireModel: QuestionnaireModel;
    getCertificatePopupView: (onClose: () => void) => ReactElement;
    onError?: (e: Error) => void;
}): ReactElement {
    const i18n = useTranslation();
    const [isObjectViewOpened, setIsObjectViewOpened] = useState<boolean>(false);
    const theme = useTheme();

    const toggleObjectView = useCallback(() => {
        setIsObjectViewOpened(oldValue => !oldValue);
    }, []);

    if (props.questionnaireResponses.response.length === 0) {
        const error = new Error('QuestionnaireResponses length should not be 0');
        props.onError ? props.onError(error) : console.error(error);
        return <></>;
    }

    if (props.questionnaireResponses.response.length > 1) {
        const error = new Error('QuestionnaireResponses length greater than 1 is not supported');
        props.onError ? props.onError(error) : console.error(error);
        return <></>;
    }

    return (
        <div className="attachment-box hash-attachment">
            <IconButton
                className="attachment-button"
                sx={{'&:hover': {backgroundColor: 'transparent'}, padding: 0}}
                onClick={toggleObjectView}
            >
                <InsertDriveFileOutlined className="icon-avatar-wrapper attachment-icon" />
                <div className="text">{i18n.t('chat.attachment.type.quesetionnaireResponse')}</div>
            </IconButton>
            {props.questionnaireResponses && (
                <>
                    <QuestionnaireResponsesAttachmentDetails
                        onViewObjectRequest={toggleObjectView}
                        getCertificatePopupView={props.getCertificatePopupView}
                    />
                    {isObjectViewOpened && (
                        <AppBarFullScreenPopup
                            background={theme.palette.background.default}
                            title={i18n.t('chat.attachment.type.quesetionnaireResponse')}
                            mode="chevron"
                            className="chat-attachment-questionnaire-view"
                            onClose={toggleObjectView}
                        >
                            <QuestionnaireView
                                questionnaireModel={props.questionnaireModel}
                                questionnaireResponse={props.questionnaireResponses.response[0]}
                            />
                        </AppBarFullScreenPopup>
                    )}
                </>
            )}
        </div>
    );
}
