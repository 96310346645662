import React, {useState} from 'react';
import type {ReactElement, ReactNode} from 'react';

import IconButton from '@mui/material/IconButton/IconButton.js';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown.js';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp.js';

/**
 * @param props.children
 * @returns
 */
export default function ToggleDetails(props: {children: ReactNode}): ReactElement {
    const [showDetails, setShowDetails] = useState<boolean>(false);

    function toggleDetails(): void {
        setShowDetails(!showDetails);
    }

    return (
        <>
            <div className="chat-bubble-attachment-details-toggle-button-container">
                <IconButton
                    onClick={toggleDetails}
                    className="chat-bubble-attachment-details-toggle-button"
                >
                    {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </div>
            {showDetails && props.children}
        </>
    );
}
