import {useEffect, useState} from 'react';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicRoom from '@refinio/one.models/lib/models/Chat/TopicRoom.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';

export default function useChatHeader(
    topicRoom: TopicRoom | undefined,
    leuteModel: LeuteModel
): string {
    const [chatHeader, setChatHeader] = useState<string>('Loading');

    useEffect(() => {
        function loadHeader() {
            if (topicRoom === undefined) {
                return;
            }

            if (topicRoom.topic.id.indexOf('<->') > -1) {
                const [personId1, personId2] = topicRoom.topic.id.split('<->');
                const personName1 = leuteModel.getPersonName(personId1 as SHA256IdHash<Person>);
                const personName2 = leuteModel.getPersonName(personId2 as SHA256IdHash<Person>);
                setChatHeader(
                    `${personName1} (${personId1.substring(
                        0,
                        7
                    )}) <-> ${personName2} (${personId2.substring(0, 7)})`
                );
                return;
            }

            setChatHeader(topicRoom.topic.id);
        }

        loadHeader();
        return leuteModel.onProfileUpdate(loadHeader);
    }, [setChatHeader, leuteModel, topicRoom]);

    return chatHeader;
}
