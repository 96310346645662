import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';

import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type TrustedKeysManager from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';

import type {
    CertificateSetting,
    ExpandedCertificateData,
    SettingIconType,
    TrustObject
} from './types.js';
import {getTrustObject} from './getTrustObject.js';
import {objectEvents} from '@refinio/one.models/lib/misc/ObjectEventDispatcher.js';
import {getIcon} from './utils.js';
import type {CertificateData} from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';

export function useTrustObject(
    hash: SHA256Hash | SHA256IdHash,
    onError: (error: Error) => void = console.error
): TrustObject | undefined {
    const [obj, setObj] = useState<TrustObject | undefined>(undefined);

    useEffect(() => {
        let unmounted = false;
        async function getTrustObjData(): Promise<void> {
            const trustObj = await getTrustObject(hash);
            if (!unmounted) {
                setObj(trustObj);
            }
        }

        getTrustObjData().catch(onError);

        return () => {
            unmounted = true;
        };
    }, [hash, onError]);

    return obj;
}

export function useTrustObjects(
    hashes: (SHA256Hash | SHA256IdHash)[],
    onError: (error: Error) => void = console.error
): TrustObject[] {
    const [objs, setObjs] = useState<TrustObject[]>([]);

    useEffect(() => {
        let unmounted = false;
        async function getTrustObjData(): Promise<void> {
            const trustObjs: TrustObject[] = [];

            for (const hash of hashes) {
                trustObjs.push(await getTrustObject(hash));
            }

            if (!unmounted) {
                setObjs(trustObjs);
            }
        }

        getTrustObjData().catch(onError);

        return () => {
            unmounted = true;
        };
    }, [hashes, onError, setObjs]);

    return objs;
}

export function useExpandedCertificates(
    trustObjects: TrustObject[],
    trustedKeysManager: TrustedKeysManager,
    certificateSettings: CertificateSetting[],
    onError: (error: Error) => void = console.error
): ExpandedCertificateData[] {
    const [certificates, setCertificates] = useState<ExpandedCertificateData[]>([]);

    useEffect(() => {
        let unmounted = false;
        async function getCertificates(): Promise<void> {
            const certs: ExpandedCertificateData[] = [];

            for (const trustObject of trustObjects) {
                const certificatesData = await trustedKeysManager.getCertificates(
                    trustObject.hash as SHA256Hash | SHA256IdHash
                );

                for (const certificateData of certificatesData) {
                    const settings = certificateSettings.filter(
                        cs => cs.certificateRecipeName === certificateData.certificate.$type$
                    );
                    for (const setting of settings) {
                        if (
                            setting.isCreatable === undefined ||
                            (await setting.isCreatable(trustObject as never))
                        ) {
                            certs.push({...certificateData, trustObject, setting: setting});
                            break;
                        }
                    }
                }
            }

            if (!unmounted) {
                setCertificates(certs);
            }
        }

        getCertificates().catch(onError);

        const disconnectListeners: (() => void)[] = [];
        for (const trustObject of trustObjects) {
            if (trustObject.type === 'Object') {
                disconnectListeners.push(
                    objectEvents.onUnversionedObject(
                        () => {
                            getCertificates().catch(onError);
                        },
                        'useExpandedCertificates: New signature',
                        'Signature'
                    )
                );
                // the current way the updater works, there is no need for more than one
                // as it is not hash specific
                break;
            }
        }

        return () => {
            unmounted = true;
            for (const disconnectListener of disconnectListeners) {
                disconnectListener();
            }
        };
    }, [trustObjects, trustedKeysManager, onError]);

    return certificates;
}

/**
 *
 * @param setting
 * @param trustObject
 * @param certificateData
 */
export function useCertificateIcon(
    setting: CertificateSetting,
    trustObject: TrustObject,
    certificateData: CertificateData
): ReactElement | string | undefined {
    const [icon, setIcon] = useState<ReactElement | string | undefined>(undefined);

    useEffect(() => {
        async function loadIcon(): Promise<void> {
            setIcon(await getIcon(setting, trustObject, certificateData));
        }
        loadIcon().catch(console.error);
    }, [certificateData, trustObject, setting]);

    return icon;
}

/**
 * @param trustObjects
 * @param trustedKeysManager
 * @param certificateSettings
 * @param onError
 */
export function useCertificateIcons(
    trustObjects: TrustObject[],
    trustedKeysManager: TrustedKeysManager,
    certificateSettings: CertificateSetting[],
    onError: (error: Error) => void = console.error
): SettingIconType[] {
    const [icons, setIcons] = useState<SettingIconType[]>([]);

    useEffect(() => {
        let unmounted = false;
        async function loadCertificateIcons(): Promise<void> {
            const newIcons: Record<string, SettingIconType> = {};

            for (const trustObject of trustObjects) {
                const certificatesData = await trustedKeysManager.getCertificates(
                    trustObject.hash as SHA256Hash | SHA256IdHash
                );

                for (const certificateData of certificatesData) {
                    const settings = certificateSettings.filter(
                        cs => cs.certificateRecipeName === certificateData.certificate.$type$
                    );
                    for (const setting of settings) {
                        const newIcon = await getIcon(setting, trustObject, certificateData);
                        if (newIcon) {
                            newIcons[setting.settingUniqueId] = newIcon;
                        }
                    }
                }
            }

            if (!unmounted) {
                setIcons(Object.values(newIcons));
            }
        }

        loadCertificateIcons().catch(onError);

        const disconnectListeners: (() => void)[] = [];
        for (const trustObject of trustObjects) {
            if (trustObject.type === 'Object') {
                disconnectListeners.push(
                    objectEvents.onUnversionedObject(
                        () => {
                            loadCertificateIcons().catch(onError);
                        },
                        'useExpandedCertificates: New signature',
                        'Signature'
                    )
                );
                // the current way the updater works, there is no need for more than one
                // as it is not hash specific
                break;
            }
        }

        return () => {
            unmounted = true;
            for (const disconnectListener of disconnectListeners) {
                disconnectListener();
            }
        };
    }, [trustObjects, trustedKeysManager, certificateSettings, onError]);

    return icons;
}
