import type {FormEvent, ReactElement} from 'react';
import {useContext, useEffect, useRef, useState} from 'react';

import Button from '@mui/material/Button/Button.js';
import IconButton from '@mui/material/IconButton/IconButton.js';
import AddIcon from '@mui/icons-material/Add.js';

import ImageCapturePopup from '@refinio/one.ui/lib/ui/views/imageCapture/ImageCapturePopup.js';

import leute_logo from '@/resources/images/leute-logo.svg';
import i18n from '@/i18n.js';
import {ONBOARDING_PROGRESS} from '@/components/onboarding/Onboarding.js';
import {getFileExtension, getInputAcceptedTypes, getURL} from '@/utils/Utils.js';
import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {FILE_FORMATS} from '@/components/Constants.js';
import {MenuContext} from '@/context/MenuContext.js';
import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import {useReferenceMenuPosition} from '@/hooks/menu/menu.js';
import PictureEdit from './PictureEdit.js';

import '@/components/onboarding/Onboarding.css';
import './PicturePrompt.css';

/**
 * Represents the picture prompt which is displayed in the onboarding process.
 * @param props
 * @param props.onDone - callback used to provide data to the parent component.
 * @param props.currentStep - the current step displayed in progress.
 * @param props.onDoneButtonText
 * @param props.logoSrc Optional. Default leute logo src.
 */
export default function PicturePrompt(props: {
    onDone: (avatar: ArrayBuffer | undefined) => void;
    currentStep: number;
    onDoneButtonText: string;
    logoSrc?: string;
}): ReactElement {
    // the state used to keep the data from the text field
    const buttonRef = useRef<HTMLLabelElement | null>(null);
    const [image, setImage] = useState<ArrayBuffer | undefined>();
    const [isEditMode, setEditMode] = useState(false);

    const [editedImage, setEditedImage] = useState<ArrayBuffer | undefined>();

    // initial sizes of the uploaded picture
    const [naturalHeight, setNaturalHeight] = useState<number | undefined>();
    const [naturalWidth, setNaturalWidth] = useState<number | undefined>();

    // popup menu control
    const {setMenuEntries, selectMenuEntry, isOpen, setMenuReference} = useContext(MenuContext);
    useReferenceMenuPosition();
    const containerRef = useRef<HTMLDivElement | null>(null);

    // camera control
    const [showCamera, setShowCamera] = useState<boolean>(false);

    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    // popup menu setup
    useEffect(() => {
        if (containerRef.current === null) {
            return;
        }
        setMenuEntries([MENU_ENTRY.SELECT_IMAGES, MENU_ENTRY.TAKE_IMAGE]);
        selectMenuEntry(() => (entrySelected: string) => {
            isOpen(false);
            switch (entrySelected) {
                case MENU_ENTRY.SELECT_IMAGES:
                    browseFile();
                    break;
                case MENU_ENTRY.TAKE_IMAGE:
                    setShowCamera(true);
                    break;
            }
        });
        setMenuReference(containerRef.current);
    }, [containerRef, isOpen, selectMenuEntry, setMenuEntries, setMenuReference, setShowCamera]);

    /**
     * Upload the file from the device and set the image that will be edited.
     * @param event - event triggered by uploading a file.
     */
    function uploadFile(event: FormEvent<EventTarget>): void {
        let uploadedFile: File | undefined;

        if (event.target instanceof HTMLInputElement) {
            if (event.target.files !== null) {
                uploadedFile = event.target.files[0];
            }
        }

        if (!uploadedFile) {
            return;
        }

        const uploadedFileExtension = getFileExtension(uploadedFile.name)?.toLowerCase();

        // if the file doesn't have an extension, display the error
        if (uploadedFileExtension === undefined) {
            /** catch any errors regarding conversions **/
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('common.errors.fileUpload.corruptedFile');
            return;
        }

        // if the file extension doesn't exist in the accepted file format array then display the error
        if (FILE_FORMATS.find(element => element === uploadedFileExtension) === undefined) {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('common.errors.fileUpload.pictureFormatNotAccepted');
            return;
        }

        const reader = new FileReader();

        reader.onload = function (e) {
            if (e.target !== null && uploadedFile !== undefined) {
                // set the image to undefined because if the user will upload the same picture in row
                // the picture editor will not appear
                setImage(undefined);
                setNaturalWidth(undefined);
                setNaturalHeight(undefined);
                setImage(e.target.result as ArrayBuffer);
                setEditMode(true);
            }
        };

        reader.readAsArrayBuffer(uploadedFile);
    }

    /**
     * Used to click the hidden button which allows the user to browse files.
     */
    function browseFile(): void {
        if (buttonRef.current !== null) {
            buttonRef.current.click();
        }
    }

    /**
     * Used to get the image initial height and width.
     * @param event - event triggered after the image is loaded.
     */
    function getImageInitialSize(event: HTMLImageElement): void {
        setNaturalHeight(event.naturalHeight);
        setNaturalWidth(event.naturalWidth);
    }

    /**
     * Save the information provided by this component.
     */
    function addPictureCallback(): void {
        props.onDone(editedImage);
    }

    const picturePromptRoot = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (picturePromptRoot.current !== null) {
            picturePromptRoot.current.focus();
        }
    }, [picturePromptRoot]);

    return (
        <>
            <div className="popup-anchor" ref={containerRef}></div>
            {showCamera && (
                <ImageCapturePopup
                    onFileChange={async (file: File) => {
                        setShowCamera(false);
                        setImage(undefined);
                        setNaturalWidth(undefined);
                        setNaturalHeight(undefined);
                        setImage(await file.arrayBuffer());
                        setEditMode(true);
                    }}
                    active={showCamera}
                    onClose={() => setShowCamera(false)}
                />
            )}
            {image !== undefined && isEditMode ? (
                naturalHeight !== undefined &&
                naturalWidth !== undefined && (
                    <PictureEdit
                        image={image}
                        setEditMode={setEditMode}
                        setEditedImage={setEditedImage}
                        imageNaturalHeight={naturalHeight}
                        imageNaturalWidth={naturalWidth}
                    />
                )
            ) : (
                <div
                    className="information-prompt-container"
                    tabIndex={0}
                    ref={picturePromptRoot}
                    onKeyDown={event => {
                        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                            addPictureCallback();
                        }
                    }}
                >
                    <>
                        <div className="information-prompt">
                            <img
                                className="logo"
                                alt="app-logo"
                                src={props.logoSrc ? props.logoSrc : leute_logo}
                            />
                            <div
                                className={` question h1 ${
                                    editedImage !== undefined ? 'margin' : ''
                                }`}
                            >
                                {i18n.t('onboarding.picturePrompt.askForPicture')}
                            </div>
                            <div className="add-image-container body">
                                {editedImage !== undefined ? (
                                    <img
                                        className="image-preview"
                                        src={getURL(editedImage)}
                                        onClick={() => isOpen(true)}
                                        alt="avatar-preview"
                                    />
                                ) : (
                                    <IconButton className="add-image" onClick={() => isOpen(true)}>
                                        <AddIcon />
                                    </IconButton>
                                )}
                                <div className="add-image-info body2">
                                    {i18n.t('onboarding.picturePrompt.pictureInformation')}
                                </div>
                            </div>
                        </div>
                        <div className="information-handler">
                            <div className="set-information-container">
                                <div className="left-buttons"></div>
                                <div className="progress">
                                    {props.currentStep +
                                        '/' +
                                        (Object.keys(ONBOARDING_PROGRESS).length - 2)}
                                </div>
                                <div className="right-buttons">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className="body ok-btn"
                                        onClick={addPictureCallback}
                                    >
                                        {props.onDoneButtonText}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                    <Button
                        ref={buttonRef}
                        variant="contained"
                        component="label"
                        className="btn-input"
                        onChange={uploadFile}
                    >
                        <input type="file" hidden accept={getInputAcceptedTypes(FILE_FORMATS)} />
                    </Button>
                </div>
            )}
            {image !== undefined && (
                <img
                    src={getURL(image)}
                    onLoad={event => getImageInitialSize(event.target as HTMLImageElement)}
                    onError={() => {
                        setImage(undefined);
                        setNotificationType(NOTIFICATION.Error);
                        setNotificationMessage('common.errors.fileUpload.corruptedFile');
                    }}
                    alt="edit"
                    className="drawing-canvas"
                />
            )}
        </>
    );
}
