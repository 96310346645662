import type {ReactElement} from 'react';

import CloseIcon from '@mui/icons-material/Close.js';

import './BackButton.css';

export default function BackButton(props: {functionality: () => void}): ReactElement {
    return (
        <div className="cnt-back-button" onClick={props.functionality}>
            <CloseIcon />
        </div>
    );
}
