import type {ReactElement} from 'react';
import {useCallback, useContext, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {useBackgroundColor} from '@/utils/Utils.js';
import SomeoneProfileDetails from '@/root/profile/common/SomeoneProfileDetails.js';
import i18n from '@/i18n.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {DARK_ORANGE} from '@/components/Constants.js';
import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {
    updateProfileCommunicationEndpoints,
    updateProfilePersonDescription
} from '@/hooks/contact/utils.js';
import {useProfileDetails, useProfileModel} from '@/hooks/contact/profileHooks.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import {useEditMode} from '@/hooks/appBar/common.js';

/**
 * Used to edit the main profile of someone. The name and the avatar are overwritten,
 * and for the email there are two possibilities: override an existing email or add a new email.
 */
export default function EditProfile(): ReactElement {
    const {
        contextValue: {mode},
        setContextValue
    } = useContext(AppBarContext);
    const navigate = useNavigate();
    const goBack = useNavigateBack();

    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const params = useParams<{profileId: SHA256IdHash<Profile>}>();

    const profileModel = useProfileModel(params.profileId);
    const [profileDetails, setProfileDetails] = useProfileDetails(profileModel);

    // Update the profile with the new information provided by the user.
    const updateProfile = useCallback(async () => {
        if (profileModel === undefined) {
            return;
        }
        try {
            await updateProfilePersonDescription(profileModel, profileDetails);
            await updateProfileCommunicationEndpoints(profileModel, profileDetails);
            await profileModel.saveAndLoad();
            goBack();
        } catch (e) {
            setNotificationMessage(i18n.t('errors.someone.edit'));
            setNotificationType(NOTIFICATION.Error);
            navigate(`/profile/${params.profileId}`, {replace: true});
        }
    }, [
        navigate,
        goBack,
        params.profileId,
        profileDetails,
        profileModel,
        setNotificationMessage,
        setNotificationType
    ]);

    useEditMode(goBack, updateProfile);
    useBackgroundColor(DARK_ORANGE);

    // each time when the users change the profile details, update the callbacks
    useEffect(() => {
        if (mode === APP_BAR_MODE.EDIT) {
            setContextValue(contextValue => ({
                ...contextValue,
                rightBtnCallback: updateProfile,
                leftBtnCallback: () => {
                    setContextValue(contextValue => ({
                        ...contextValue,
                        mode: APP_BAR_MODE.VIEW
                    }));
                    navigate(-1);
                }
            }));
        }
    }, [mode, setContextValue, navigate, updateProfile]);

    return (
        <SomeoneProfileDetails
            profileDetails={profileDetails}
            updateProfileDetails={setProfileDetails}
            title={i18n.t('leute.profile.title')}
        />
    );
}
