import * as dateFns from 'date-fns';

import {readBlobAsArrayBuffer} from '@refinio/one.core/lib/storage-blob.js';
import {getIdObject, getObjectByIdHash} from '@refinio/one.core/lib/storage-versioned-objects.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type {
    OrganisationName,
    PersonName,
    ProfileImage
} from '@refinio/one.models/lib/recipes/Leute/PersonDescriptions.js';
import type {
    CommunicationEndpointTypes,
    Email,
    PhoneNumber
} from '@refinio/one.models/lib/recipes/Leute/CommunicationEndpoints.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel.js';
import type SomeoneModel from '@refinio/one.models/lib/models/Leute/SomeoneModel.js';
import {shortenHash} from '@refinio/one.ui/lib/ui/views/utils/Utils';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks';
import type {Person} from '@refinio/one.core/lib/recipes';

import type {SomeonePreview} from '@/root/contacts/LeuteView.js';
import i18n from '@/i18n.js';
import {getURL, saveImageAsBLOB} from '@/utils/Utils.js';
import type {
    OneInstanceCommunicationEndpoint,
    ProfileDetails
} from '@/root/profile/common/types.js';

export async function getProfileAvatar(profileModel: ProfileModel): Promise<string | undefined> {
    const profileImage = profileModel.descriptionsOfType('ProfileImage')[0];
    if (profileImage !== undefined) {
        return getURL(await readBlobAsArrayBuffer(profileImage.image));
    }

    return undefined;
}

export async function addEmailEndpoint(profileModel: ProfileModel): Promise<void> {
    profileModel.communicationEndpoints.push({
        $type$: 'Email',
        email: ''
    });
    await profileModel.saveAndLoad();
}

export function setProfileImageAtIndex(
    profileModel: ProfileModel,
    newProfileImage: ProfileImage
): void {
    // TODO do we want to keep the old images? It doesn't make sense for the moment,
    // we only have one active profile image
    const imageIndex = profileModel.personDescriptions.findIndex(
        pd => pd.$type$ === 'ProfileImage'
    );
    if (imageIndex === -1) {
        profileModel.personDescriptions.push(newProfileImage);
    } else {
        profileModel.personDescriptions[imageIndex] = newProfileImage;
    }
}

export function initializeEnpoints(profile: ProfileModel) {
    const endpoints: CommunicationEndpointTypes = {$type$: 'Email', email: ''};
    if (profile.communicationEndpoints.find(ce => ce.$type$ === 'Email') === undefined) {
        profile.communicationEndpoints.push(endpoints);
    }
}

/**
 * Used to get all the profiles of a group.
 * @param leuteModel - the leute model.
 * @param groupModel - the group model.
 */
export async function getProfilesOfGroup(
    leuteModel: LeuteModel,
    groupModel: GroupModel
): Promise<ProfileModel[]> {
    const profilesOfGroup: ProfileModel[] = [];

    for await (const personId of groupModel.persons) {
        const someone = await leuteModel.getSomeone(personId);
        if (someone) {
            const profiles = await someone.profiles(personId);
            const defaultProfile = profiles.filter(profile => profile.profileId === 'default');
            profilesOfGroup.push(...defaultProfile);
        }
    }

    return profilesOfGroup;
}

/**
 * Getting the profiles for all someones.
 * @param someones - the array of someones.
 */
export async function getProfilesOfAllSomeones(someones: SomeoneModel[]): Promise<ProfileModel[]> {
    const profiles: ProfileModel[] = [];

    for (const someone of someones) {
        profiles.push(...(await someone.profiles()));
    }

    return profiles;
}

/**
 * Used to update the profile person description with new information.
 * The avatar and the person name are overwritten.
 * @param profile - the profile which is updated.
 * @param profileDetails - new information about the profile.
 */
export async function updateProfilePersonDescription(
    profile: ProfileModel,
    profileDetails: ProfileDetails
): Promise<void> {
    // handling unique name as person description
    const existingPersonName = profile.personDescriptions.find(
        personDescriptor => personDescriptor.$type$ === 'PersonName'
    ) as PersonName | undefined;

    const addedPersonName =
        profileDetails.name !== '' ? profileDetails.name : i18n.t('common.unknown');

    if (existingPersonName) {
        existingPersonName.name = addedPersonName;
    } else {
        const personName: PersonName = {
            $type$: 'PersonName',
            name: addedPersonName
        };
        profile.personDescriptions.push(personName);
    }

    // handling unique organisation name as person description
    if (profileDetails.organisationName !== undefined) {
        const existingOrganisationName = profile.personDescriptions.find(
            personDescriptor => personDescriptor.$type$ === 'OrganisationName'
        ) as PersonName | undefined;

        if (existingOrganisationName) {
            existingOrganisationName.name = profileDetails.organisationName;
        } else {
            const organisationName: OrganisationName = {
                $type$: 'OrganisationName',
                name: profileDetails.organisationName
            };
            profile.personDescriptions.push(organisationName);
        }
    }

    // handling unique avatar as person description
    const existingProfileImage = profile.personDescriptions.find(
        personDescriptor => personDescriptor.$type$ === 'ProfileImage'
    ) as ProfileImage | undefined;

    if (profileDetails.avatar !== undefined) {
        const blobImage = await saveImageAsBLOB(profileDetails.avatar);
        if (existingProfileImage) {
            existingProfileImage.image = blobImage;
        } else {
            const profileImage: ProfileImage = {
                $type$: 'ProfileImage',
                image: blobImage
            };
            profile.personDescriptions.push(profileImage);
        }
    }
}

/**
 * Used to update the profile communication endpoints with new information.
 * Currently just the email is supported.
 * @param profile - the profile which is updated.
 * @param profileDetails - new information about the profile.
 */
export async function updateProfileCommunicationEndpoints(
    profile: ProfileModel,
    profileDetails: ProfileDetails
): Promise<void> {
    if (profileDetails.email !== undefined) {
        profile.communicationEndpoints = profile.communicationEndpoints.filter(
            communicationEndpoint => communicationEndpoint.$type$ !== 'Email'
        );
        profileDetails.email.forEach(email => {
            if (email !== '') {
                const emailObject: Email = {
                    $type$: 'Email',
                    email: email
                };
                profile.communicationEndpoints.push(emailObject);
            }
        });
    }

    if (profileDetails.phoneNumber !== undefined) {
        profile.communicationEndpoints = profile.communicationEndpoints.filter(
            communicationEndpoint => communicationEndpoint.$type$ !== 'PhoneNumber'
        );
        profileDetails.phoneNumber.forEach(phoneNumber => {
            if (phoneNumber !== '') {
                const phoneNumberObject: PhoneNumber = {
                    $type$: 'PhoneNumber',
                    number: phoneNumber
                };
                profile.communicationEndpoints.push(phoneNumberObject);
            }
        });
    }
}

/**
 * Used to load the communication endpoints of a profile.
 * @param profileDetails
 * @param profileModel
 */
export async function loadProfileCommunicationEndpoints(
    profileDetails: ProfileDetails,
    profileModel: ProfileModel
): Promise<ProfileDetails> {
    const emails: string[] = [];
    const phoneNumbers: string[] = [];
    const oneInstanceEndpoint: OneInstanceCommunicationEndpoint[] = [];

    const profileObj = await getObjectByIdHash(profileModel.idHash);
    const communicationEndpoints = profileObj.obj.communicationEndpoint;

    profileModel.communicationEndpoints.forEach((personDescription, index) => {
        if (personDescription.$type$ === 'Email') {
            emails.push(personDescription.email);
        }
        if (personDescription.$type$ === 'PhoneNumber') {
            phoneNumbers.push(personDescription.number);
        }
        if (personDescription.$type$ === 'OneInstanceEndpoint') {
            oneInstanceEndpoint.push({
                hash: communicationEndpoints[index],
                personId: personDescription.personId,
                instanceId: personDescription.instanceId
            });
        }
    });
    profileDetails.email = emails;
    profileDetails.phoneNumber = phoneNumbers;
    profileDetails.oneInstanceEndpoint = oneInstanceEndpoint;
    profileDetails.personEmail = await getPersonEmail(profileModel.personId);
    return profileDetails;
}

export async function getPersonEmail(personId: SHA256IdHash<Person>): Promise<string> {
    const person = await getIdObject(personId);
    return person.email.indexOf('@') > -1 ? person.email : shortenHash(person.email, 7);
}

/**
 * Used to load the profile person descriptions of a profile.
 * @param profileModel
 */
export async function loadProfilePersonDescription(
    profileModel: ProfileModel
): Promise<ProfileDetails> {
    const details: ProfileDetails = {
        avatar: undefined,
        name: '',
        personEmail: await getPersonEmail(profileModel.personId)
    };

    for await (const personDescription of profileModel.personDescriptions) {
        if (personDescription.$type$ === 'OrganisationName') {
            details.organisationName = personDescription.name;
        }

        if (personDescription.$type$ === 'PersonName') {
            details.name = personDescription.name;
        }

        if (personDescription.$type$ === 'ProfileImage') {
            details.avatar = await readBlobAsArrayBuffer(personDescription.image);
        }

        // extract the latest status of the profile
        if (
            personDescription.$type$ === 'PersonStatus' ||
            personDescription.$type$ === 'PersonImage'
        ) {
            if (
                details.status === undefined ||
                dateFns.isAfter(personDescription.timestamp, details.status.timestamp)
            ) {
                details.status = personDescription;
            }
        }
    }

    details.personId = profileModel.personId;

    return details;
}

export async function buildSomeonePreview(
    leuteModel: LeuteModel,
    someoneModel: SomeoneModel
): Promise<SomeonePreview> {
    const mainProfile = await someoneModel.mainProfile();

    return {
        name: leuteModel.getPersonName(mainProfile.personId),
        mainProfile: mainProfile,
        model: someoneModel,
        email: await getPersonEmail(mainProfile.personId),
        avatar: await getProfileAvatar(mainProfile)
    };
}
