import type {ReactElement, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

import TextFieldEntry from '@/components/textFieldEntry/TextFieldEntry.js';

import './SomeoneProfileDetails.css';

export default function SendMessage(props: {children?: ReactNode}): ReactElement {
    const i18n = useTranslation();

    return (
        <div className="trust-section">
            <TextFieldEntry
                label={i18n.t('leute.profile.communicationEndpoints.oneInstanceEndpoint.message')}
                onDone={(_: string) => {}}
                value={i18n.t(
                    'leute.profile.communicationEndpoints.oneInstanceEndpoint.startMessage'
                )}
                disableUnderline={true}
            />
            {props.children}
        </div>
    );
}
