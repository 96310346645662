import type {ReactElement} from 'react';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Divider from '@mui/material/Divider/Divider.js';
import List from '@mui/material/List/List.js';

import {getInstanceOwnerIdHash} from '@refinio/one.core/lib/instance.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {OneObjectTypes, Person} from '@refinio/one.core/lib/recipes.js';
import type {Topic} from '@refinio/one.models/lib/recipes/ChatRecipes.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type TrustedKeysManager from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';
import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';

import {loadProfilePersonDescription} from '@/hooks/contact/utils.js';
import {getURL} from '@/utils/Utils.js';
import SelectableChatListElement from './SelectableChatListElement.js';

type PersonTopicDetails = {
    topic: Topic;
    name: string;
    imgURL: string | undefined;
    isSigned: boolean;
};

type GroupTopicDetails = {
    topic: Topic;
    name: string;
    imgURL: string | undefined;
};

/**
 * Helper to determine name of topic
 * @param name
 * @returns
 */
function getName(name: string | undefined): string {
    return name ? name : 'unknown';
}

/**
 * Helper to extract URL image from buffer
 * @param data
 * @returns
 */
function getImgURL(data: ArrayBuffer | undefined): string | undefined {
    return data ? getURL(data) : undefined;
}

/**
 * Helper to determine if hash is signed
 * @param me
 * @param hash
 * @param trust
 * @returns
 */
async function getIsSigned(
    me: SHA256IdHash<Person>,
    hash: SHA256Hash<OneObjectTypes> | undefined,
    trust: TrustedKeysManager
): Promise<boolean> {
    return hash ? await trust.isAffirmedBy(hash, me) : false;
}

/**
 * Get needed information about other people
 * @param leuteModel
 * @param topicModel
 * @param onError
 * @returns
 */
function usePersonsTopicDetails(
    leuteModel: LeuteModel,
    topicModel: TopicModel,
    onError: (error: Error) => void = console.error
): PersonTopicDetails[] {
    const [personsTopicDetails, setPersonTopicDetails] = useState<PersonTopicDetails[]>([]);

    useEffect(() => {
        async function getOthers() {
            const me = getInstanceOwnerIdHash();
            if (me === undefined) {
                throw Error('Can not find instance owner');
            }
            const others = await leuteModel.others();
            const otherProfiles = await Promise.all(others.map(o => o.mainProfile()));
            const newPersonsTopicDetails: PersonTopicDetails[] = [];
            for (let i = 0; i < otherProfiles.length; i++) {
                const profileDetails = await loadProfilePersonDescription(otherProfiles[i]);
                newPersonsTopicDetails.push({
                    topic: await topicModel.createOneToOneTopic(me, otherProfiles[i].personId),
                    name: getName(profileDetails.name),
                    imgURL: getImgURL(profileDetails.avatar),
                    isSigned: await getIsSigned(
                        me,
                        otherProfiles[i].loadedVersion,
                        leuteModel.trust
                    )
                });
            }
            setPersonTopicDetails(newPersonsTopicDetails);
        }
        getOthers().catch(onError);
        // mount only
    }, []);

    return personsTopicDetails;
}

function useGroupsTopicDetails(
    leuteModel: LeuteModel,
    topicModel: TopicModel,
    onError: (error: Error) => void = console.error
): GroupTopicDetails[] {
    const [groupTopicDetails, setGroupTopicDetails] = useState<GroupTopicDetails[]>([]);

    useEffect(() => {
        async function getGroupTopic(name: string): Promise<Topic> {
            switch (name) {
                case 'everyone':
                    return await topicModel.createEveryoneTopic();
                case 'glue.one':
                    return await topicModel.createGlueTopic();
                default:
                    return await topicModel.createGroupTopic(name);
            }
        }
        async function loadGroupTopicDetails() {
            const groups = await leuteModel.groups();
            const newGroupTopicDetails: GroupTopicDetails[] = [];
            for (let i = 0; i < groups.length; i++) {
                const groupTopic = await getGroupTopic(groups[i].name);
                newGroupTopicDetails.push({
                    topic: groupTopic,
                    name: getName(groups[i].name),
                    imgURL: getImgURL(groups[i].picture)
                });
            }
            setGroupTopicDetails(newGroupTopicDetails);
        }
        loadGroupTopicDetails().catch(onError);
        // mount only
    }, []);

    return groupTopicDetails;
}

/**
 * @param props.leuteModel
 * @param props.topicModel
 * @param props.onChange topic is toggled
 * @param props.disableGroups hide the groups topics
 * @returns
 */
export default function SelectableTopicList(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    onChange: (selected: Topic[]) => void;
    disableGroups?: boolean;
}): ReactElement {
    const i18n = useTranslation();
    const personsTopicDetails = usePersonsTopicDetails(props.leuteModel, props.topicModel);
    const groupsTopicDetails = useGroupsTopicDetails(props.leuteModel, props.topicModel);
    const [shareWithTopics, setShareWithTopics] = useState<Topic[]>([]);
    const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>([]);

    function getShareWithTopicToggle(
        topicDetails: PersonTopicDetails | GroupTopicDetails
    ): (event: unknown, checked: boolean) => void {
        return (_event: unknown, checked: boolean): void => {
            let newSetOfTopicDetails = [...shareWithTopics];
            if (checked) {
                newSetOfTopicDetails.push(topicDetails.topic);
            } else {
                newSetOfTopicDetails = newSetOfTopicDetails.filter(
                    td => td.id !== topicDetails.topic.id
                );
            }
            setShareWithTopics(newSetOfTopicDetails);
            setSelectedTopicIds(newSetOfTopicDetails.map(td => td.id));
            props.onChange(newSetOfTopicDetails);
        };
    }

    return (
        <>
            <CollapsibleComponent
                headline={<>{i18n.t('leute.lists.someones').toUpperCase()}</>}
                content={
                    <List>
                        {personsTopicDetails.map((personTopicDetails, index) => (
                            <Fragment key={index}>
                                <SelectableChatListElement
                                    name={personTopicDetails.name}
                                    imgURL={personTopicDetails.imgURL}
                                    isSigned={personTopicDetails.isSigned}
                                    onChange={getShareWithTopicToggle(personTopicDetails)}
                                    isChecked={selectedTopicIds.includes(
                                        personTopicDetails.topic.id
                                    )}
                                />
                                {index !== personsTopicDetails.length - 1 && (
                                    <Divider variant={'middle'} />
                                )}
                            </Fragment>
                        ))}
                    </List>
                }
            />
            {!props.disableGroups && (
                <CollapsibleComponent
                    headline={<>{i18n.t('leute.lists.groups').toUpperCase()}</>}
                    content={
                        <List>
                            {groupsTopicDetails.map((groupTopicDetails, index) => (
                                <Fragment key={index}>
                                    <SelectableChatListElement
                                        name={groupTopicDetails.name}
                                        imgURL={groupTopicDetails.imgURL}
                                        onChange={getShareWithTopicToggle(groupTopicDetails)}
                                        isChecked={selectedTopicIds.includes(
                                            groupTopicDetails.topic.id
                                        )}
                                    />
                                    {index !== groupsTopicDetails.length - 1 && (
                                        <Divider variant={'middle'} />
                                    )}
                                </Fragment>
                            ))}
                        </List>
                    }
                />
            )}
        </>
    );
}
