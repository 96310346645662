import type {ReactElement} from 'react';
import {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {useNavigateBack} from '@/hooks/navigation';

import BodyTemperatureModel from '@refinio/one.models/lib/models/BodyTemperatureModel.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';

import CreateBodyTemperature from './CreateBodyTemperature.js';

export default function CreateBodyTemperatureOwnerAdapter(props: {
    bodyTemperatureModel: BodyTemperatureModel;
    channelManager: ChannelManager;
}): ReactElement {
    const params = useParams<{owner: SHA256IdHash<Person>}>();
    const navigateBack = useNavigateBack();
    const post = useCallback(
        async (temp: number) => {
            if (
                params.owner &&
                !(await props.channelManager.hasChannel(
                    BodyTemperatureModel.channelId,
                    params.owner
                ))
            ) {
                await props.channelManager.createChannel(
                    BodyTemperatureModel.channelId,
                    params.owner
                );
            }
            await props.bodyTemperatureModel.addBodyTemperature(temp, undefined, params.owner);
            navigateBack();
        },
        [props.bodyTemperatureModel]
    );

    return (
        <CreateBodyTemperature
            bodyTemperatureModel={props.bodyTemperatureModel}
            overwritePost={post}
        />
    );
}
