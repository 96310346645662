export function readFileAsImage(file: File): HTMLImageElement {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const imageData = new Image();
    reader.onload = () => {
        imageData.src = reader.result as string;
    };
    return imageData;
}

export function getCanvasFromImage(image: HTMLImageElement): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    if (context) {
        context.drawImage(image, 0, 0);
    } else {
        throw new Error('Could not get context from canvas');
    }
    return canvas;
}

export function canvasToImageFile(
    canvas: HTMLCanvasElement,
    fileName: string,
    fileType: string
): Promise<File> {
    return new Promise(resolve => {
        canvas.toBlob(blob => {
            if (blob) {
                resolve(new File([blob], fileName, {type: fileType}));
            } else {
                throw new Error('Could not create image file');
            }
        }, fileType);
    });
}

export type SimpleBox = {
    bottomRight: [number, number];
    topLeft: [number, number];
};

export function drawBoundingBoxes(canvas: HTMLCanvasElement, boundingBoxes: SimpleBox[]): void {
    const context = canvas.getContext('2d');
    if (context) {
        boundingBoxes.forEach(boundingBox => {
            const start = boundingBox.topLeft;
            const end = boundingBox.bottomRight;
            const size = [end[0] - start[0], end[1] - start[1]];
            context.fillStyle = 'rgba(255, 0, 0, 0.5)';
            context.fillRect(start[0], start[1], size[0], size[1]);
        });
    } else {
        throw new Error('Could not get context from canvas');
    }
}
