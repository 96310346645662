import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Badge from '@mui/material/Badge/Badge.js';

import type Notifications from '@refinio/one.models/lib/models/Notifications.js';
import TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel.js';

import {CHAT} from '@/components/Constants.js';

export default function startGroupChat(
    group: GroupModel,
    topicModel?: TopicModel,
    notifications?: Notifications
): ReactElement {
    switch (group.name) {
        case 'everyone':
            return (
                <StartChat
                    topicId={TopicModel.EVERYONE_TOPIC_ID}
                    topicModel={topicModel}
                    notifications={notifications}
                />
            );
        case 'glue.one':
            return (
                <StartChat
                    topicId={TopicModel.GLUE_TOPIC_ID}
                    topicModel={topicModel}
                    notifications={notifications}
                />
            );
        default:
            return <></>;
    }
}

/**
 * When either topicModel or notifications is missing, does not load unread messages indicator
 * @param props.topicId
 * @param props.topicModel
 * @param props.notifications
 * @returns
 */
export function StartChat(props: {
    topicId: string;
    topicModel?: TopicModel;
    notifications?: Notifications;
}): ReactElement {
    const navigate = useNavigate();
    const [unreadMessages, setUnreadMessages] = useState<number>(0);

    useEffect(() => {
        if (props.notifications === undefined || props.topicModel === undefined) {
            return;
        }
        function updateUnreadMessages(): void {
            if (props.notifications === undefined) {
                return;
            }
            setUnreadMessages(props.notifications.getNotificationCountForTopic(props.topicId));
        }
        updateUnreadMessages();
        return props.topicModel.onUpdated.listen(updateUnreadMessages);
    }, [props.notifications, props.topicId, props.topicModel]);

    return (
        <div className="right-alignment" onClick={e => e.stopPropagation()}>
            <div className="signed-container">
                <Badge
                    badgeContent={unreadMessages}
                    variant={'dot'}
                    color="secondary"
                    className="unread-message-indicator"
                >
                    <div
                        className="start-chat-icon"
                        onClick={() => {
                            navigate(`/chat/${props.topicId}`);
                        }}
                    >
                        {CHAT}
                    </div>
                </Badge>
            </div>
        </div>
    );
}
