import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const EcpireBreastLeftQuestionnaireUrl =
    'http://refinio.one/questionaire/ecpire_breast_left';
export const EcpireBreastLeftQuestionnaireName = 'ecpire_breast_left';
export const EcpireBreastLeftQuestionnaire: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: EcpireBreastLeftQuestionnaireUrl,
    name: EcpireBreastLeftQuestionnaireName,
    title: 'Left Breast',
    status: 'active',
    item: [
        {
            linkId: 'date',
            prefix: '1',
            text: 'Date',
            type: 'date',
            required: true,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'changes',
            prefix: '2',
            text: 'Haben Sie Veränderungen im Bereich der Brust wahrgenommen?',
            type: 'choice',
            required: true,
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Ja',
                        system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/YesNo_en'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Nein',
                        system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/YesNo_en'
                    }
                }
            ]
        },
        {
            linkId: 'signs',
            prefix: '3',
            text: 'Welcher dieser Anzeichen/Veränderungen tritt bei ihnen auf?',
            type: 'open-choice',
            required: true,
            disabledDisplay: 'protected',
            enableWhen: [
                {
                    question: 'changes',
                    operator: '=',
                    answerCoding: {
                        code: '0'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Neu aufgetretene Knoten oder Verhärtungen in der Brust',
                        system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/BreastCancerSigns_en'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display:
                            'Einziehung der Haut an einer Stelle oder Einziehung einer Brustwarze',
                        system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/BreastCancerSigns_en'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Veränderungen der Brustwarze',
                        system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/BreastCancerSigns_en'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display:
                            'Einseitige wasserklare oder blutige Absonderungen aus der Brustwarze',
                        system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/BreastCancerSigns_en'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display:
                            'Eine plötzlich auftretende, nicht mehr abklingende Hautrötung im Bereich der Brust',
                        system: 'http://refinio.one/questionaire/painQuestionnaire/valueCoding/BreastCancerSigns_en'
                    }
                }
            ]
        }
    ]
};
