import type {ReactElement} from 'react';

import Card from '@mui/material/Card/Card.js';
import CardActions from '@mui/material/CardActions/CardActions.js';
import CardContent from '@mui/material/CardContent/CardContent.js';

import './ContactCard.css';

export default function ContactCard(props: {
    content: ReactElement;
    action?: ReactElement;
}): ReactElement {
    return (
        <Card className="cnt-someone-card">
            <CardContent>{props.content}</CardContent>
            {props.action !== undefined && <CardActions>{props.action}</CardActions>}
        </Card>
    );
}
