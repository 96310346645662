import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import AssignmentIcon from '@mui/icons-material/Assignment.js';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile.js';
import CameraAltIcon from '@mui/icons-material/CameraAlt.js';
import ThermostatIcon from '@mui/icons-material/Thermostat.js';
import WaterDropIcon from '@mui/icons-material/WaterDrop.js';
import MenuBookIcon from '@mui/icons-material/MenuBook.js';
import ScienceIcon from '@mui/icons-material/Science.js';

import WidgetItem from '@/components/widgetMenu/WidgetItem.js';

export const BaseAddItemType = {
    questionnaire: 'questionnaire',
    document: 'document',
    image: 'image',
    bodyTemperature: 'bodyTemperature',
    wbc: 'wbc',
    diary: 'diary',
    labratory: 'labratory'
} as const;

const icon: Record<keyof typeof BaseAddItemType, ReactElement> = {
    questionnaire: <AssignmentIcon />,
    document: <InsertDriveFileIcon />,
    image: <CameraAltIcon />,
    bodyTemperature: <ThermostatIcon />,
    wbc: <WaterDropIcon />,
    diary: <MenuBookIcon />,
    labratory: <ScienceIcon />
} as const;

export default function BaseAddItem(props: {
    type: (typeof BaseAddItemType)[keyof typeof BaseAddItemType];
    onClick: () => void;
    title?: string;
}): ReactElement {
    const i18n = useTranslation();
    return (
        <WidgetItem
            icon={icon[props.type]}
            title={props.title ?? i18n.t(`home.widget.${props.type}`)}
            onClick={props.onClick}
        />
    );
}
