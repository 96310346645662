import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

export function DisclaimerBody(): ReactElement {
    const i18n = useTranslation();
    return (
        <>
            <div className="disclaimer-welcome h1">{i18n.t('onboarding.disclaimer.welcome')}</div>
            <div className="disclaimer-description body">
                {i18n.t('onboarding.disclaimer.description')} <br />
                <br />
                {i18n.t('onboarding.disclaimer.advantage1')}
                <br /> {i18n.t('onboarding.disclaimer.advantage2')}
                <br />
                {i18n.t('onboarding.disclaimer.advantage3')} <br />
                {i18n.t('onboarding.disclaimer.advantage4')} <br />
                <br />
            </div>
            <div className="onboarding-information body1">
                {i18n.t('onboarding.disclaimer.information')}
            </div>
            <br />
            <div className="disclaimer-description body">
                {i18n.t('onboarding.disclaimer.message')}
            </div>
        </>
    );
}
