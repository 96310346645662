import type {ReactElement} from 'react';

import './WidgetItem.css';

export default function WidgetItem(props: {
    icon: ReactElement;
    title: string;
    onClick: () => void;
}): ReactElement {
    return (
        <div className="widget-item-container">
            <div className="widget-item" onClick={props.onClick}>
                <div className="icon">{props.icon}</div>
                <div className="title">{props.title}</div>
            </div>
        </div>
    );
}
