import React from 'react';
import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton/IconButton.js';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined.js';

import type {BlobDescriptor} from '@refinio/one.models/lib/models/BlobCollectionModel.js';

import BlobAttachmentDetails from '@/root/chat/attachmentViews/blobDescriptor/BlobAttachmentDetails.js';

/**
 * @param data
 * @param filename
 * @param type
 */
function downloadDocument(data: ArrayBuffer, filename: string, type: string): void {
    const file = new Blob([data], {type: type});
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);

    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
}

/**
 * @param props.blobDescriptor
 * @param props.getCertificatePopupView callback to get certificate popup view
 * @returns
 */
export default function DocumentAttachmentView(props: {
    blobDescriptor: BlobDescriptor;
    getCertificatePopupView: (onClose: () => void) => ReactElement;
}): ReactElement {
    const i18n = useTranslation();

    return (
        <div className="attachment-box document-attachment">
            <IconButton
                onClick={() => {
                    downloadDocument(
                        props.blobDescriptor.data,
                        props.blobDescriptor.name,
                        props.blobDescriptor.type
                    );
                }}
                aria-label="download file"
                className="attachment-button"
            >
                <InsertDriveFileOutlined className="icon-avatar-wrapper attachment-icon" />
                <div className="text">{i18n.t('chat.attachment.type.document')}</div>
            </IconButton>
            <BlobAttachmentDetails
                blobDescriptor={props.blobDescriptor}
                getCertificatePopupView={props.getCertificatePopupView}
            />
        </div>
    );
}
