import type {AnchorHTMLAttributes, ClassAttributes, ReactElement} from 'react';
import type {ExtraProps} from 'react-markdown';

export default function MarkdownLinkTemplate(
    props: ClassAttributes<HTMLAnchorElement> & AnchorHTMLAttributes<HTMLAnchorElement> & ExtraProps
): ReactElement {
    return (
        <a href={props.href} target="_blank" rel="noopener noreferrer">
            {props.children}
        </a>
    );
}
