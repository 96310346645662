import type {ReactElement} from 'react';

import Avatar from '@mui/material/Avatar/Avatar.js';
import Devices from 'mdi-material-ui/Devices.js';
import AccountBoxOutline from 'mdi-material-ui/AccountBoxOutline.js';
import Refresh from 'mdi-material-ui/Refresh.js';
import InformationOutline from 'mdi-material-ui/InformationOutline.js';
import AccountVoice from 'mdi-material-ui/AccountVoice.js';
import Chat from 'mdi-material-ui/Chat.js';
import FountainPen from 'mdi-material-ui/FountainPen.js';
import FormSelect from 'mdi-material-ui/FormSelect.js';
import FileCopyIcon from '@mui/icons-material/FileCopy.js';

import './Icon.css';

export const ICONS = {
    Contacts: AccountBoxOutline,
    Devices: Devices,
    FileCopy: FileCopyIcon,
    Info: InformationOutline,
    Refresh: Refresh,
    Status: AccountVoice,
    Chat: Chat,
    Questionnaire: FormSelect,
    Journal: FountainPen
} as const;

/**
 * Builds an avatar with an icon, whose name it receives as a parameter
 * @param props
 * @param props.name - icon name
 * @returns {ReactElement}
 */
export function Icon(props: {name: string}): ReactElement {
    const IconType = ICONS[props.name as keyof typeof ICONS];

    return (
        <Avatar className={`icon-avatar-wrapper ${props.name}`}>
            <IconType className="icon-avatar" />
        </Avatar>
    );
}
