import type {ReactElement} from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type SomeoneModel from '@refinio/one.models/lib/models/Leute/SomeoneModel.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import i18n from '@/i18n.js';
import GroupMembersPicker from '@/root/group/groupId/edit/members/GroupMembersPicker.js';
import {
    getDefaultProfile,
    getDefaultProfilesOfAllIdentities,
    useBackgroundColor
} from '@/utils/Utils.js';
import {useEditPictureContext} from '@/context/EditPictureContext.js';
import {DARK_ORANGE} from '@/components/Constants.js';
import GroupDetailsForm from '@/root/group/common/GroupDetailsForm.js';
import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import {usePersonId} from '@/hooks/contact/commonHooks';
import {useEditMode} from '@/hooks/appBar/common.js';

import '../groupId/Group.css';

const GROUP_VIEW = {
    ADD_PICTURE_NAME: 'ADD_PICTURE_NAME',
    ADD_MEMBERS: 'ADD_MEMBERS'
} as const;

type GroupView = (typeof GROUP_VIEW)[keyof typeof GROUP_VIEW];

export type DetailedGroup = {
    picture: ArrayBuffer | undefined;
    name: string;
};

export function useAllDefaultProfilesOfAllIdentities(leuteModel: LeuteModel): ProfileModel[] {
    const [profiles, setProfiles] = useState<ProfileModel[]>([]);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const mePersonId = usePersonId(leuteModel);

    useEffect(() => {
        let ignored = false;

        async function getAllDefaultProfilesOfAllIdentities(): Promise<void> {
            if (ignored || !mePersonId) {
                return;
            }

            const defaultProfiles = await getDefaultProfilesOfAllIdentities(leuteModel);

            if (!ignored) {
                setProfiles(defaultProfiles);
            }
        }

        getAllDefaultProfilesOfAllIdentities().catch(_ => {
            setNotificationMessage(i18n.t('errors.someone.profiles'));
            setNotificationType(NOTIFICATION.Error);
        });

        return () => {
            ignored = true;
        };
    }, [mePersonId]);

    return profiles;
}

/**
 *
 * @param props
 * @param props.profiles
 * @param props.leuteModel
 * @param props.onDone - called when the user finished the actions within this view.
 */
export function CreateGroup(props: {leuteModel: LeuteModel}): ReactElement {
    const DEFAULT_GROUP_DETAILS: DetailedGroup = {
        name: '',
        picture: undefined
    };

    useBackgroundColor(DARK_ORANGE);
    const navigate = useNavigate();
    const navigateBack = useNavigateBack();

    const {editedPicture} = useEditPictureContext();
    const appBarContext = useContext(AppBarContext);
    const allDefaultProfilesOfAllIdentities = useAllDefaultProfilesOfAllIdentities(
        props.leuteModel
    );
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    const [groupProfile, setGroupProfile] = useState<DetailedGroup>(DEFAULT_GROUP_DETAILS);
    const [view, setView] = useState<GroupView>(GROUP_VIEW.ADD_PICTURE_NAME);
    const [groupMembers, setGroupMembers] = useState<SHA256IdHash<Person>[]>([]);

    /**
     * Builds the group based on the {@link GROUP_VIEW}. On {@link GROUP_VIEW.ADD_MEMBERS} the
     * group will be saved.
     */
    const buildGroup = useCallback(async () => {
        switch (view) {
            case GROUP_VIEW.ADD_PICTURE_NAME:
                setView(GROUP_VIEW.ADD_MEMBERS);
                break;
            case GROUP_VIEW.ADD_MEMBERS:
                const group = await props.leuteModel.createGroup();
                group.name = i18n.t('leute.group');
                group.persons.push(...groupMembers);
                group.name = groupProfile.name !== '' ? groupProfile.name : 'Group';
                group.picture = groupProfile.picture;
                try {
                    await group.saveAndLoad();
                    navigateBack();
                } catch (e) {
                    setNotificationMessage(i18n.t('errors.group.create'));
                    setNotificationType(NOTIFICATION.Error);
                    navigate('/', {replace: true});
                }
                break;
        }
    }, [
        groupMembers,
        groupProfile.name,
        groupProfile.picture,
        navigate,
        navigateBack,
        props.leuteModel,
        setNotificationMessage,
        setNotificationType,
        view
    ]);

    useEditMode(navigateBack, buildGroup);

    /**
     * Each time when the users change the picture, update the onDoneCallback
     */
    useEffect(() => {
        if (editedPicture !== undefined) {
            setGroupProfile({...groupProfile, picture: editedPicture});
            appBarContext.setContextValue({
                ...appBarContext.contextValue,
                rightBtnCallback: async () => await buildGroup(),
                leftBtnCallback: navigateBack
            });
        }
    }, [editedPicture]);

    /**
     * Each time when the users change the group members, update the onDoneCallback
     */
    useEffect(() => {
        if (appBarContext.contextValue.mode === APP_BAR_MODE.EDIT) {
            appBarContext.setContextValue({
                ...appBarContext.contextValue,
                rightBtnCallback: buildGroup
            });
        }
    }, [view, groupMembers]);

    /**
     * Renders the right component based on {@link GROUP_VIEW}
     */
    function renderView(): ReactElement {
        switch (view) {
            case GROUP_VIEW.ADD_PICTURE_NAME:
                return (
                    <GroupDetailsForm
                        groupDetails={groupProfile}
                        onDone={(name: string) => {
                            setGroupProfile({
                                ...groupProfile,
                                name: name
                            });
                        }}
                    />
                );
            case GROUP_VIEW.ADD_MEMBERS:
                return (
                    <GroupMembersPicker
                        profiles={allDefaultProfilesOfAllIdentities}
                        updateGroupMembers={setGroupMembers}
                        groupMembers={groupMembers}
                        leuteModel={props.leuteModel}
                    />
                );
            default:
                return <></>;
        }
    }

    return <>{renderView()}</>;
}
