import React from 'react';
import {useCallback} from 'react';
import type {ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';

import type ConnectionsModel from '@refinio/one.models/lib/models/ConnectionsModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import InvitationInput from '@refinio/one.ui/lib/ui/views/invitation/InvitationInput.js';
import type IoMManager from '@refinio/one.models/lib/models/IoM/IoMManager.js';
import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';
import IOPConnectionsTable from '@refinio/one.ui/lib/ui/views/connections/IOPConnectionsTable.js';
import IOMConnectionsTable from '@refinio/one.ui/lib/ui/views/connections/IOMConnectionsTable.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {useParing} from '@/utils/pairing.js';

import './ConnectionsView.css';

export default function ConnectionsView(props: {
    connectionsModel: ConnectionsModel;
    iomManager: IoMManager;
    leuteModel: LeuteModel;
    onError?: (error: Error) => void;
}): ReactElement {
    const onError = props.onError ? props.onError : console.error;
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const i18n = useTranslation();
    const navigate = useNavigate();
    const tryPairing = useParing(props.connectionsModel, props.iomManager.requestManager, () =>
        setError(i18n.t('errors.connection.invitationNotValid'))
    );

    /**
     * Showing error to the user
     * @param error translated string
     */
    const setError = useCallback(
        (error: string) => {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage(error);
        },
        [setNotificationType, setNotificationMessage]
    );

    return (
        <div className="connections-view-container">
            <CollapsibleComponent
                headline={<>{i18n.t('connections.invitations')}</>}
                content={
                    <InvitationInput connectWithInvitationLink={tryPairing} checkCurrentURL={true}>
                        <Button
                            style={{width: '100%'}}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                navigate('/qr-scanner');
                            }}
                        >
                            {i18n.t('buttons.camera.qrScanner')}
                        </Button>
                    </InvitationInput>
                }
            />
            <CollapsibleComponent
                headline={<>{i18n.t('connections.internetOfMe')}</>}
                content={
                    <IOMConnectionsTable
                        connectionsModel={props.connectionsModel}
                        leuteModel={props.leuteModel}
                        onError={onError}
                    >
                        <div className="flex-align-right padding8">
                            <Button variant="outlined" onClick={() => navigate('/inviteDevice')}>
                                {i18n.t('connections.addDevice')}
                            </Button>
                        </div>
                    </IOMConnectionsTable>
                }
            />
            <CollapsibleComponent
                headline={<>{i18n.t('connections.internetOfPeople')}</>}
                content={
                    <IOPConnectionsTable
                        connectionsModel={props.connectionsModel}
                        leuteModel={props.leuteModel}
                        iomRequestManager={props.iomManager}
                        onError={onError}
                    >
                        <div className="flex-align-right padding8">
                            <Button variant="outlined" onClick={() => navigate('/invitePartner')}>
                                {i18n.t('connections.addPerson')}
                            </Button>
                        </div>
                    </IOPConnectionsTable>
                }
            />
        </div>
    );
}
