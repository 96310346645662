import {useTheme} from '@mui/material/styles';
import {useCallback, type ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import type WbcDiffModel from '@refinio/one.models/lib/models/WbcDiffModel.js';
import type {
    WbcMeasurement,
    WbcObservation
} from '@refinio/one.models/lib/recipes/WbcDiffRecipes.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {useNavigateBack} from '@/hooks/navigation.js';
import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import WBCMeasurements from '../WBCMeasurements.js';
import '../WBC.css';

export default function CreateWBCDiff(props: {
    wbcDiffModel: WbcDiffModel;
    overwritePost?: (wbcObservation: WbcObservation) => Promise<void>;
}): ReactElement {
    const i18n = useTranslation();
    const goBack = useNavigateBack();
    const navigate = useNavigate();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const [wbcObservation, setWbcObservation] = useState<WbcObservation>({
        $type$: 'WbcObservation',
        acquisitionTime: '',
        Leukocytes: {unit: '', value: ''}
    });
    const theme = useTheme();

    const onFinish = useCallback(async () => {
        if (wbcObservation.Leukocytes.value !== '') {
            if (props.overwritePost) {
                await props.overwritePost({
                    ...wbcObservation,
                    acquisitionTime: new Date().toDateString()
                });
                return;
            }
            await props.wbcDiffModel.postObservation({
                ...wbcObservation,
                acquisitionTime: new Date().toISOString()
            });
            navigate('/journal');
        } else {
            setNotificationMessage(i18n.t('wbc.error.fillRequiredFields'));
            setNotificationType(NOTIFICATION.Error);
        }
    }, [wbcObservation, props.wbcDiffModel]);

    function onChange(key: keyof WbcObservation, value: WbcMeasurement) {
        setWbcObservation(o => ({...o, [key]: value}));
    }

    return (
        <AppBarFullScreenPopup
            background={theme.palette.background.default}
            mode={APP_BAR_MODE.EDIT}
            onClose={goBack}
            title={i18n.t('wbc.title')}
            onFinish={onFinish}
        >
            <WBCMeasurements value={wbcObservation} onChange={onChange} />
        </AppBarFullScreenPopup>
    );
}
