import type {ReactElement} from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Group, Person} from '@refinio/one.core/lib/recipes.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import i18n from '@/i18n.js';
import {useGroup} from '@/hooks/contact/groupHooks.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import {useEditMode} from '@/hooks/appBar/common.js';
import {useAllDefaultProfilesOfAllIdentities} from '@/root/group/create/CreateGroup.js';
import GroupMembersPicker from './GroupMembersPicker.js';
import type BlacklistModel from '@/model/BlacklistModel.js';

import '@/root/group/groupId/Group.css';

/**
 * Edit Group Members Component
 * @param props
 * @param props.leuteModel
 * @param props.blacklistModel
 */
export default function EditGroupMembers(props: {
    leuteModel: LeuteModel;
    blacklistModel: BlacklistModel;
}): ReactElement {
    const [groupMembers, setGroupMembers] = useState<readonly SHA256IdHash<Person>[]>([]);
    const appBarContext = useContext(AppBarContext);
    const params = useParams<{groupId: SHA256IdHash<Group>}>();

    const group = useGroup(params.groupId, props.blacklistModel);
    const allDefaultProfilesOfAllIdentities = useAllDefaultProfilesOfAllIdentities(
        props.leuteModel
    );
    const navigate = useNavigate();
    const goBack = useNavigateBack();

    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    useEffect(() => {
        if (group === undefined) {
            return;
        }
        setGroupMembers(group.persons);
    }, [group]);

    /**
     * Editing the group with the selected persons
     */
    const createNewGroupWithMembers = useCallback(async () => {
        if (group === undefined) {
            setNotificationMessage(i18n.t('errors.group.update'));
            setNotificationType(NOTIFICATION.Error);
            navigate('/', {replace: true});
            return;
        }

        try {
            group.persons = [...groupMembers];
            await group.saveAndLoad();
            navigate(-1);
        } catch (e) {
            setNotificationMessage(i18n.t('errors.group.update'));
            setNotificationType(NOTIFICATION.Error);
            navigate('/', {replace: true});
        }
    }, [group, groupMembers, navigate, setNotificationMessage, setNotificationType]);

    useEditMode(goBack, createNewGroupWithMembers);

    /**
     * Each time when the users change the group members, update the onDoneCallback
     */
    useEffect(() => {
        if (appBarContext.contextValue.mode === APP_BAR_MODE.EDIT) {
            appBarContext.setContextValue({
                ...appBarContext.contextValue,
                rightBtnCallback: createNewGroupWithMembers
            });
        }
    }, [groupMembers, group]);

    return (
        <GroupMembersPicker
            profiles={allDefaultProfilesOfAllIdentities}
            leuteModel={props.leuteModel}
            updateGroupMembers={setGroupMembers}
            groupMembers={groupMembers}
        />
    );
}
