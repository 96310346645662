import {useCallback, useEffect, useState} from 'react';

import type {PopoverOrigin} from '@mui/material/Popover/Popover.js';

import type {AppBarData} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import type {PopupMenuEntry} from '@refinio/one.ui/lib/ui/components/popupMenu/PopupMenu.js';
import PopupMenu from '@refinio/one.ui/lib/ui/components/popupMenu/PopupMenu.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import type Model from './model/Model.js';
import {AppRouter} from './root/AppRouter.js';
import type {NotificationType} from '@/components/notification/SnackbarNotification.js';
import SnackbarNotification, {
    NOTIFICATION,
    NotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {MenuContext} from './context/MenuContext.js';
import EditPicture from './components/pictureEditor/EditPicture.js';
import {EditPictureContext} from './context/EditPictureContext.js';
import {useVHPhoneFixById} from './hooks/device/window.js';
import {getPopupMenuContent} from './components/popupMenu/PopupMenu.js';

/**
 * Builds the UI component which is the main component of the application
 */
export default function Ui(props: {model: Model; app: string}) {
    const [contextValue, setContextValue] = useState<AppBarData>({
        title: '',
        hide: false,
        isLeftBtnClicked: false,
        isRightBtnClicked: false,
        mode: APP_BAR_MODE.BROWSE
    });
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [notificationType, setNotificationType] = useState<NotificationType>(
        NOTIFICATION.Success
    );

    // popup popupMenu states
    const [menuAnchorOrigin, setMenuAnchorOrigin] = useState<PopoverOrigin | undefined>(undefined);
    const [menuEntries, setMenuEntries] = useState<PopupMenuEntry[]>([]);
    const [menuAnchor, setMenuAnchor] = useState(document.getElementById('root'));
    const [menuClassName, setMenuClassName] = useState<string | undefined>('app-bar-cnt-menu');
    const [selectMenuEntry, setSelectMenuEntry] = useState<(selectedEntry: string) => void>(
        () => {}
    );
    const [isMenuOpen, setMenuOpen] = useState(contextValue.isRightBtnClicked);

    const setMenuReference = useCallback(
        (value: HTMLElement | null) =>
            setMenuAnchor(value ? value : document.getElementById('root')),
        [setMenuAnchor]
    );

    // when the more button is clicked then we need to display the popupMenu
    useEffect(() => {
        setMenuOpen(contextValue.isRightBtnClicked);
    }, [contextValue.isRightBtnClicked]);

    // reset menu class name on close
    useEffect(() => {
        if (!isMenuOpen && menuClassName !== 'app-bar-cnt-menu') {
            setMenuClassName('app-bar-cnt-menu');
        }
    }, [isMenuOpen, menuClassName]);

    // picture editor context states
    const [originalPicture, setOriginalPicture] = useState<ArrayBuffer | undefined>();
    const [editedPicture, setEditedPicture] = useState<ArrayBuffer | undefined>();
    const [originalPictureDimensions, setOriginalPictureDimensions] = useState<{
        naturalWidth: number;
        naturalHeight: number;
    }>({naturalWidth: 0, naturalHeight: 0});

    useVHPhoneFixById('root');

    /**
     * We want to ensure that flags are always raised on specific modes
     */
    function getAppBarContext() {
        if (contextValue.mode === APP_BAR_MODE.BROWSE || contextValue.mode === APP_BAR_MODE.VIEW) {
            return {
                ...contextValue,
                leftBtnCallback: () => {
                    setContextValue({...contextValue, isLeftBtnClicked: true});
                },
                rightBtnCallback: () => {
                    setContextValue({...contextValue, isRightBtnClicked: true});
                }
            };
        }
        if (contextValue.mode === APP_BAR_MODE.CHEVRON_VIEW) {
            return {
                ...contextValue,
                rightBtnCallback: () => {
                    setContextValue({...contextValue, isRightBtnClicked: true});
                }
            };
        }
        return contextValue;
    }

    return (
        <AppBarContext.Provider value={{contextValue: getAppBarContext(), setContextValue}}>
            <NotificationContext.Provider
                value={{
                    setNotificationMessage: setNotificationMessage,
                    setNotificationType: setNotificationType
                }}
            >
                <MenuContext.Provider
                    value={{
                        setMenuEntries: setMenuEntries,
                        setMenuReference,
                        selectMenuEntry: setSelectMenuEntry,
                        isOpen: setMenuOpen,
                        setMenuClassName: setMenuClassName,
                        setMenuAnchorOrigin: setMenuAnchorOrigin
                    }}
                >
                    <EditPictureContext.Provider
                        value={{
                            originalPicture,
                            setOriginalPicture,
                            originalPictureDimensions,
                            setOriginalPictureDimensions,
                            editedPicture,
                            setEditedPicture
                        }}
                    >
                        <AppRouter model={props.model} app={props.app} />

                        <SnackbarNotification
                            message={notificationMessage}
                            setMessageCallback={setNotificationMessage}
                            type={notificationType}
                        />
                        {menuEntries.length > 0 && (
                            <PopupMenu
                                entries={getPopupMenuContent(menuEntries)}
                                onMenuEntrySelected={entry => {
                                    selectMenuEntry(entry);
                                    setContextValue({...contextValue, isRightBtnClicked: false});
                                }}
                                isVisible={isMenuOpen}
                                anchor={menuAnchor}
                                anchorOrigin={menuAnchorOrigin}
                                className={menuClassName}
                                onClose={() => {
                                    setMenuOpen(false);
                                    setContextValue({...contextValue, isRightBtnClicked: false});
                                }}
                            />
                        )}
                        {originalPicture !== undefined &&
                            originalPictureDimensions.naturalWidth !== 0 &&
                            originalPictureDimensions.naturalHeight !== 0 && (
                                <EditPicture
                                    originalImage={originalPicture}
                                    onDone={() => {
                                        setOriginalPicture(undefined);
                                        setOriginalPictureDimensions({
                                            naturalWidth: 0,
                                            naturalHeight: 0
                                        });
                                    }}
                                />
                            )}
                    </EditPictureContext.Provider>
                </MenuContext.Provider>
            </NotificationContext.Provider>
        </AppBarContext.Provider>
    );
}
