import {useCallback, useContext, useEffect, useState} from 'react';
import type {FormEvent, ReactElement} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import Button from '@mui/material/Button/Button.js';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {DARK_ORANGE, FILE_FORMATS} from '@/components/Constants.js';
import ContactCard from '@/components/card/ContactCard.js';
import TextFieldEntry from '@/components/textFieldEntry/TextFieldEntry.js';
import i18n from '@/i18n.js';
import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {useProfileModel} from '@/hooks/contact/profileHooks.js';
import {useEditMode} from '@/hooks/appBar/common.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import {
    getUploadImageError,
    getInputAcceptedTypes,
    getURL,
    saveImageAsBLOB,
    useBackgroundColor
} from '@/utils/Utils.js';

import './SomeoneProfileStatus.css';

export const STATUS_INFORMATION = {
    LOCATION: 'location',
    TEXT: 'text',
    IMAGE: 'image'
} as const;

/** The type definition based on the STATUS_INFORMATION value. **/
export type StatusInformation = (typeof STATUS_INFORMATION)[keyof typeof STATUS_INFORMATION];

export type Status = {
    type: 'PersonStatus' | 'PersonImage';
    location: string;
    text: string;
    image?: ArrayBuffer;
};

/**
 * Used to create a person status or a person image based on data collected from the user.
 * Will represent the new status of the profile.
 * @param profileModel
 * @param statusData
 */
async function createStatus(profileModel: ProfileModel, statusData: Status): Promise<void> {
    // if the user selected the image status but he entered just the location then
    // we need to store it as a 'PersonStatus' because image property of 'PersonImage'
    // doesn't support undefined
    if (statusData.type === 'PersonImage' && statusData.image === undefined) {
        profileModel.personDescriptions.push({
            $type$: 'PersonStatus',
            location: statusData.location,
            value: '',
            timestamp: Date.now()
        });
        return;
    }

    // Create the new status and add it to the profile person descriptions
    switch (statusData.type) {
        case 'PersonStatus':
            profileModel.personDescriptions.push({
                $type$: statusData.type,
                location: statusData.location,
                value: statusData.text,
                timestamp: Date.now()
            });
            break;
        case 'PersonImage':
            if (statusData.image !== undefined) {
                profileModel.personDescriptions.push({
                    $type$: statusData.type,
                    location: statusData.location,
                    image: await saveImageAsBLOB(statusData.image),
                    timestamp: Date.now()
                });
            }
            break;
    }
}

/**
 * The component allow the user to enter the new data for his profile status.
 * Based on the props.statusInformation content the view is different.
 * - If the props.statusInformation contains the STATUS_INFORMATION.TEXT then the
 * user is allowed to enter a text and the location.
 * - If the props.statusInformation contains the STATUS_INFORMATION.IMAGE then the
 * user is allowed to enter an image and the location.
 * @param props
 */
export default function SomeoneProfileStatus(props: {
    statusInformation: StatusInformation[];
    leuteModel: LeuteModel;
}): ReactElement {
    const params = useParams<{profileId: SHA256IdHash<Profile>}>();
    const profileModel = useProfileModel(params.profileId);
    const appBarContext = useContext(AppBarContext);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    const navigate = useNavigate();
    const goBack = useNavigateBack();

    useBackgroundColor(DARK_ORANGE);
    const [statusData, setStatusData] = useState<Status>({
        text: '',
        type: decideStatusType(props.statusInformation),
        image: undefined,
        location: ''
    });

    /**
     * Used to add the new status for a profile.
     */
    const addStatus = useCallback(async () => {
        if (profileModel === undefined) {
            setNotificationMessage(i18n.t('errors.profile.status'));
            setNotificationType(NOTIFICATION.Error);
            navigate(`/profile/${params.profileId}`, {replace: true});
            return;
        }

        if (
            statusData !== undefined &&
            (statusData.location !== '' || statusData.text !== '' || statusData.image !== undefined)
        ) {
            try {
                await createStatus(profileModel, statusData);
                await profileModel.saveAndLoad();
                goBack();
            } catch (e) {
                setNotificationMessage(i18n.t('errors.profile.status'));
                setNotificationType(NOTIFICATION.Error);
                navigate(`/profile/${params.profileId}`, {replace: true});
            }
        }
    }, [
        navigate,
        goBack,
        params.profileId,
        profileModel,
        setNotificationMessage,
        setNotificationType,
        statusData
    ]);

    useEditMode(goBack, addStatus);

    function decideStatusType(
        statusInformation: StatusInformation[]
    ): 'PersonStatus' | 'PersonImage' {
        if (statusInformation.includes(STATUS_INFORMATION.TEXT)) {
            return 'PersonStatus';
        } else if (statusInformation.includes(STATUS_INFORMATION.IMAGE)) {
            return 'PersonImage';
        }

        setNotificationMessage(i18n.t('errors.profile.status'));
        setNotificationType(NOTIFICATION.Error);
        navigate('/', {replace: true});
        throw new Error('Could not retrieve status information.');
    }

    // each time when the user change the status information, update the callbacks of the appbar
    useEffect(() => {
        if (appBarContext.contextValue.mode === APP_BAR_MODE.EDIT) {
            appBarContext.setContextValue({
                ...appBarContext.contextValue,
                rightBtnCallback: addStatus,
                leftBtnCallback: goBack
            });
        }
    }, [statusData, appBarContext, navigate, goBack]);

    /**
     * Upload the file from the device.
     * @param event - event triggered by uploading a file.
     */
    function uploadFile(event: FormEvent<EventTarget>): void {
        let uploadedFile: File | undefined;

        if (event.target instanceof HTMLInputElement) {
            if (event.target.files !== null) {
                uploadedFile = event.target.files[0];
            }
        }

        if (!uploadedFile) {
            return;
        }

        const error = getUploadImageError(uploadedFile);

        if (error !== '') {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage(error);
            return;
        }

        const reader = new FileReader();

        reader.onload = async function (e) {
            if (e.target !== null && uploadedFile !== undefined) {
                setStatusData({...statusData, image: e.target.result as ArrayBuffer});
            }
        };

        reader.readAsArrayBuffer(uploadedFile);
    }

    /**
     * Represents the view where the user will introduce the new data for the status.
     */
    function addStatusView(): ReactElement {
        return (
            <>
                {props.statusInformation.map((info, index) =>
                    info === STATUS_INFORMATION.IMAGE ? (
                        <Button
                            key={index}
                            component="label"
                            className={`upload-status-image ${
                                statusData.image === undefined ? 'border' : ''
                            }`}
                            onChange={uploadFile}
                        >
                            {statusData.image !== undefined ? (
                                <img
                                    className="image"
                                    src={getURL(statusData.image)}
                                    alt="status"
                                />
                            ) : (
                                i18n.t('onboarding.picturePrompt.pictureInformation')
                            )}
                            <input
                                type="file"
                                hidden
                                accept={getInputAcceptedTypes(FILE_FORMATS)}
                            />
                        </Button>
                    ) : (
                        <div key={index}>
                            <TextFieldEntry
                                disableUnderline={false}
                                label={i18n.t(`leute.status.${info}`)}
                                onDone={(value: string) => {
                                    setStatusData({...statusData, [info]: value});
                                }}
                                value={statusData[info]}
                            />
                        </div>
                    )
                )}
            </>
        );
    }

    return (
        <div className="cnt-someone-details">
            <div className="cnt-title">{i18n.t('leute.status.title')}</div>
            <ContactCard content={addStatusView()} />
        </div>
    );
}
