import type {ReactElement} from 'react';
import {useContext, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import StartChat from '@/components/startChat/StartChat.js';
import {MenuContext} from '@/context/MenuContext.js';
import {useProfileDetails, useProfileModel} from '@/hooks/contact/profileHooks.js';
import SomeoneProfileDetails from '@/root/profile/common/SomeoneProfileDetails.js';
import type {StatusInformation} from './status/create/SomeoneProfileStatus.js';
import {STATUS_INFORMATION} from './status/create/SomeoneProfileStatus.js';

/**
 * Display all the profiles of someone. Currently, just the main profile of someone is displayed.
 * @param props
 * @param props.profileModel
 */
export default function ProfileView(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    getInformationAddon?: (hashes: (SHA256Hash | SHA256IdHash)[]) => ReactElement;
}): ReactElement {
    const i18n = useTranslation();
    const appBarContext = useContext(AppBarContext);
    const {setMenuEntries, selectMenuEntry} = useContext(MenuContext);

    const params = useParams<{profileId: SHA256IdHash<Profile>}>();

    const navigate = useNavigate();

    const profileModel = useProfileModel(params.profileId);
    const [profileDetails] = useProfileDetails(profileModel, props.leuteModel);

    /** When the view is rendered for the first time then update the context popupMenu. **/
    useEffect(() => {
        setMenuEntries([MENU_ENTRY.EDIT_SOMEONE_PROFILE]);
        // mount only
    }, []);

    useEffect(() => {
        selectMenuEntry(() => (selectedMenuEntry: string) => {
            if (selectedMenuEntry === MENU_ENTRY.EDIT_SOMEONE_PROFILE) {
                navigate(`/profile/${params.profileId}/edit`);
            }
        });
        // refresh appBarContext for callback on right btn click
    }, [appBarContext.contextValue.isRightBtnClicked, navigate, params.profileId]);

    function onStatusSelected(statusInformation: StatusInformation[]): void {
        if (statusInformation.includes(STATUS_INFORMATION.IMAGE)) {
            navigate(`/profile/${params.profileId}/status/image`);
        }
        if (statusInformation.includes(STATUS_INFORMATION.TEXT)) {
            navigate(`/profile/${params.profileId}/status/text`);
        }
    }

    const addon =
        props.getInformationAddon !== undefined && profileModel
            ? props.getInformationAddon(
                  profileModel.loadedVersion
                      ? [profileModel.loadedVersion, profileModel.personId]
                      : [profileModel.personId]
              )
            : undefined;

    return profileModel === undefined ? (
        <></>
    ) : (
        <>
            <SomeoneProfileDetails
                profileDetails={profileDetails}
                title={i18n.t('leute.profile.title')}
                onAddStatus={onStatusSelected}
                instanceInformationAddon={addon}
                sendMessageElement={
                    <StartChat
                        leuteModel={props.leuteModel}
                        receiverProfile={profileModel}
                        topicModel={props.topicModel}
                    />
                }
            />
        </>
    );
}
