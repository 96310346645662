import type {ReactElement} from 'react';
import Typography from '@mui/material/Typography/Typography.js';

import {getLicenseForCertificate} from '@refinio/one.models/lib/misc/Certificates/LicenseRegistry.js';
import type {OneCertificateTypeNames} from '@refinio/one.models/lib/recipes/Certificates/CertificateRecipes.js';

import './TrustView.css';

export default function CertificateDescription(props: {
    certificateType?: OneCertificateTypeNames | '' | undefined;
}): ReactElement {
    if (!props.certificateType || props.certificateType.length === 0) {
        return <></>;
    }

    try {
        return (
            <Typography className="description">
                {getLicenseForCertificate(props.certificateType).description}
            </Typography>
        );
    } catch (e) {
        return <Typography className="description">{`Failed to get description: ${e}`}</Typography>;
    }
}
