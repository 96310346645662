import React from 'react';
import type {ReactElement, FC} from 'react';

import type {BlobDescriptor} from '@refinio/one.models/lib/models/BlobCollectionModel.js';

import type {AttachmentViewFactoryAdditionalData} from '@/root/chat/attachmentViews/types.js';
import VideoAttachmentView from './subtypes/VideoAttachmentView.js';
import AudioAttachmentView from './subtypes/AudioAttachmentView.js';
import ImageAttachmentView from './subtypes/ImageAttachmentView.js';
import ThumbnailImageAttachmentView from './subtypes/ThumbnailImageAttachmentView.js';
import DocumentAttachmentView from './subtypes/DocumentAttachmentView.js';

const BlobAttachments = {
    video: 'video',
    image: 'image',
    thumbnailImage: 'thumbnailImage',
    audio: 'audio',
    document: 'document'
} as const;

type BlobAttachmentTypes = keyof typeof BlobAttachments;

const BlobAttachmentView: Record<
    BlobAttachmentTypes,
    FC<{
        blobDescriptor: BlobDescriptor;
        getCertificatePopupView: (onClose: () => void) => ReactElement;
        onError?: (error: Error) => void;
        additionalData?: AttachmentViewFactoryAdditionalData;
    }>
> = {
    video: VideoAttachmentView,
    image: ImageAttachmentView,
    thumbnailImage: ThumbnailImageAttachmentView,
    audio: AudioAttachmentView,
    document: DocumentAttachmentView
};

/**
 * Helper function to determine the type of blob from descriptor
 * @param blobDescriptor
 * @returns
 */
function getBlobDescriptorType(
    blobDescriptor: BlobDescriptor,
    additionalData?: AttachmentViewFactoryAdditionalData
): BlobAttachmentTypes {
    if (blobDescriptor.type.includes('video')) {
        return BlobAttachments.video;
    } else if (blobDescriptor.type.includes('image')) {
        if (additionalData && additionalData.originalHash) {
            return BlobAttachments.thumbnailImage;
        }
        return BlobAttachments.image;
    } else if (blobDescriptor.type.includes('audio')) {
        return BlobAttachments.audio;
    } else {
        return BlobAttachments.document;
    }
}

/**
 * @param props.blobDescriptor
 * @param props.getCertificatePopupView callback to get certificate popup view
 * @returns
 */
export default function BlobDescriptorAttachmentView(props: {
    blobDescriptor: BlobDescriptor;
    getCertificatePopupView: (onClose: () => void) => ReactElement;
    onError?: (error: Error) => void;
    additionalData?: AttachmentViewFactoryAdditionalData;
}): ReactElement {
    return React.createElement(
        BlobAttachmentView[getBlobDescriptorType(props.blobDescriptor, props.additionalData)],
        {
            blobDescriptor: props.blobDescriptor,
            getCertificatePopupView: props.getCertificatePopupView,
            onError: props.onError,
            additionalData: props.additionalData
        }
    );
}
