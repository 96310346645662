import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const ExampleQuestionnaire: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://refinio.one/questionaire/exampleQuestionnaire',
    name: 'Example Questionnaire',
    title: 'Example Questionnaire',
    status: 'active',
    item: [
        {
            linkId: 'sectionFirst',
            prefix: '0',
            text: 'The first section shows you the answer types we currently support.',
            type: 'display',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'topicsInt',
            prefix: '1',
            type: 'open-choice',
            text: 'Which topics are you interested in?',
            required: false,
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Politics_en',
                        version: '1.0',
                        code: '1',
                        display: 'Politics'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Engineering_en',
                        version: '1.0',
                        code: '2',
                        display: 'Engineering'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/SocialStudies_en',
                        version: '1.0',
                        code: '3',
                        display: 'Social studies'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Arts_en',
                        version: '1.0',
                        code: '4',
                        display: 'Arts'
                    }
                }
            ]
        },
        {
            linkId: 'birthdate',
            prefix: '2',
            type: 'date',
            text: 'When were your born?',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'langNumb',
            prefix: '3',
            type: 'integer',
            text: 'How many languages do you speak?',
            required: false,
            disabledDisplay: 'protected',
            extension: [
                {
                    url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                    valueInteger: 99
                },
                {
                    url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                    valueInteger: 1
                }
            ]
        },
        {
            linkId: 'eyecolor',
            prefix: '4',
            type: 'choice',
            text: 'Which eye color do you have?',
            required: false,
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Blue_en',
                        version: '1.0',
                        code: '1',
                        display: 'Blue'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Brown_en',
                        version: '1.0',
                        code: '2',
                        display: 'Brown'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Green_en',
                        version: '1.0',
                        code: '3',
                        display: 'Green'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Other_en',
                        version: '1.0',
                        code: '4',
                        display: 'Other'
                    }
                }
            ]
        },
        {
            linkId: 'name',
            prefix: '5',
            type: 'string',
            text: 'What is your name?',
            required: false,
            disabledDisplay: 'protected',
            maxLength: 50
        },
        {
            linkId: 'likeStrawberries',
            prefix: '6',
            text: 'Do you like strawberries?',
            type: 'slider',
            required: false,
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                        version: '1.0',
                        code: '0',
                        display: 'no, I hate it'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_de',
                        version: '1.0',
                        code: '2',
                        display: ''
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                        version: '1.0',
                        code: '3',
                        display: ''
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                        version: '1.0',
                        code: '4',
                        display: ''
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                        version: '1.0',
                        code: '5',
                        display: 'neutral'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                        version: '1.0',
                        code: '6',
                        display: ''
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                        version: '1.0',
                        code: '7',
                        display: ''
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                        version: '1.0',
                        code: '8',
                        display: ''
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                        version: '1.0',
                        code: '9',
                        display: ''
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                        version: '1.0',
                        code: '10',
                        display: 'I love strawberries'
                    }
                }
            ]
        },
        {
            linkId: 'sectionSecond',
            prefix: '7',
            text: 'This section shows you examples of more than one answer type for the same question. ',
            type: 'display',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'catLegs',
            prefix: '8',
            text: 'How many legs does a cat have?',
            type: 'group',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'catLegs_int',
                    prefix: '8_1',
                    type: 'integer',
                    required: false,
                    disabledDisplay: 'protected',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 10
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 1
                        }
                    ],
                    enableWhen: [
                        {
                            question: 'catLegs_UK',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ]
                },
                {
                    linkId: 'catLegs_UK',
                    prefix: '8_2',
                    type: 'open-choice',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Unknown_en',
                                version: '1.0',
                                code: '1',
                                display: "I don't know "
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'pets',
            prefix: '9',
            text: 'Which pets do you have?',
            type: 'group',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'petsChoice',
                    prefix: '9_1',
                    type: 'open-choice',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Cat_en',
                                version: '1.0',
                                code: '0',
                                display: 'Cat'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Dog_en',
                                version: '1.0',
                                code: '1',
                                display: 'Dog'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/Mouse_en',
                                version: '1.0',
                                code: '2',
                                display: 'Mouse'
                            }
                        }
                    ]
                },
                {
                    linkId: 'pets_txt',
                    prefix: '9_2',
                    type: 'string',
                    text: 'Others?',
                    required: false,
                    maxLength: 200,
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            linkId: 'sectionThird',
            prefix: '10',
            text: 'This section shows you examples of groups of questions. ',
            type: 'display',
            required: false,
            disabledDisplay: 'protected'
        },
        {
            linkId: 'hunger',
            prefix: '11',
            text: 'Hunger',
            type: 'group',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'hungerScale',
                    prefix: '11_1',
                    text: 'How hungry are you?',
                    type: 'slider',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                                version: '1.0',
                                code: '0',
                                display: 'just ate, not hungry at all'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                                version: '1.0',
                                code: '1'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                                version: '1.0',
                                code: '2',
                                display: ''
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                                version: '1.0',
                                code: '3',
                                display: ''
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                                version: '1.0',
                                code: '4',
                                display: ''
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                                version: '1.0',
                                code: '5',
                                display: 'I could have a snack'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                                version: '1.0',
                                code: '6',
                                display: ''
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                                version: '1.0',
                                code: '7',
                                display: ''
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                                version: '1.0',
                                code: '8',
                                display: ''
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                                version: '1.0',
                                code: '9',
                                display: ''
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/rate_en',
                                version: '1.0',
                                code: '10',
                                display: 'almost starving'
                            }
                        }
                    ]
                },
                {
                    linkId: 'eatWhat',
                    prefix: '11_2',
                    type: 'string',
                    text: 'What do you want to eat?',
                    required: false,
                    maxLength: 100,
                    disabledDisplay: 'protected',
                    enableWhen: [
                        {
                            question: 'hungerScale',
                            operator: '>',
                            answerCoding: {
                                code: '5'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'fruitsLike',
            prefix: '12',
            text: 'Please fill in how much you like the listed fruits. ',
            type: 'group',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'fruitsLike_ban',
                    prefix: '12_1',
                    type: 'integer',
                    text: 'Banana',
                    required: false,
                    disabledDisplay: 'protected',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 10
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 0
                        }
                    ]
                },
                {
                    linkId: 'fruitsLike_straw',
                    prefix: '12_2',
                    type: 'integer',
                    text: 'Strawberry',
                    required: false,
                    disabledDisplay: 'protected',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 10
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 0
                        }
                    ]
                },
                {
                    linkId: 'fruitsLike_pine',
                    prefix: '12_3',
                    type: 'integer',
                    text: 'Pineapple',
                    required: false,
                    disabledDisplay: 'protected',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 10
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 0
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'activities',
            prefix: '13',
            text: 'Which activities do you do in your free time?',
            type: 'group',
            required: false,
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'activities_skate',
                    prefix: '13_1',
                    type: 'choice',
                    text: 'Skateboarding',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '2',
                                display: 'No'
                            }
                        }
                    ]
                },
                {
                    linkId: 'activities_read',
                    prefix: '13_2',
                    type: 'choice',
                    text: 'Reading',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '2',
                                display: 'No'
                            }
                        }
                    ]
                },
                {
                    linkId: 'activities_knit',
                    prefix: '13_3',
                    type: 'choice',
                    text: 'Knitting',
                    required: false,
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/exampleQuestionnaire/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '2',
                                display: 'No'
                            }
                        }
                    ]
                }
            ]
        }
    ]
};
