import React from 'react';
import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';
import Typography from '@mui/material/Typography/Typography.js';

import type {BlobDescriptor} from '@refinio/one.models/lib/models/BlobCollectionModel.js';

import ToggleDetails from '@/root/chat/attachmentViews/utils/ToggleDetails.js';
import CertificateDetails from '@/root/chat/attachmentViews/utils/CertificateDetails.js';

/**
 * @param {Blob} file
 * @param {string} filename
 */
function downloadBlob(file: Blob, filename: string): void {
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);

    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
}

/**
 * @param props.blobDescriptor
 * @param props.getCertificatePopupView Optional. Default undefined. callback to get certificate popup view
 * @returns
 */
export default function BlobAttachmentDetails(props: {
    blobDescriptor: BlobDescriptor;
    getCertificatePopupView?: (onClose: () => void) => ReactElement;
}): ReactElement {
    const blob = new Blob([props.blobDescriptor.data], {type: props.blobDescriptor.type});
    const filename = props.blobDescriptor.name;
    const i18n = useTranslation();

    return (
        <ToggleDetails>
            <div className="attachment-details">
                <Typography sx={{fontSize: 12}} color="text.secondary">
                    {i18n.t('chat.attachment.details.fileName')}
                </Typography>
                <Typography
                    className={'instance-setting-item-margin'}
                    variant="body1"
                    component="div"
                >
                    {filename}
                </Typography>
                <Typography sx={{fontSize: 12}} color="text.secondary">
                    {i18n.t('chat.attachment.details.fileSize')}
                </Typography>
                <Typography
                    className={'instance-setting-item-margin'}
                    variant="body1"
                    component="div"
                >
                    {blob.size}
                </Typography>
                <Button
                    sx={{width: 1}}
                    variant="outlined"
                    className="mui-outlined-button"
                    onClick={() => {
                        downloadBlob(blob, filename);
                    }}
                >
                    {i18n.t('buttons.common.save')}
                </Button>
            </div>
            {props.getCertificatePopupView && (
                <CertificateDetails certificateView={props.getCertificatePopupView} />
            )}
        </ToggleDetails>
    );
}
