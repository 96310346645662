import {useState, type ReactElement, useEffect} from 'react';

import type TrustedKeysManager from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';

import type {CertificateSetting, SettingIconType} from './common/types.js';
import {type TrustObject} from './common/types.js';
import {NotSignedIcon} from './common/utils.js';
import {useCertificateIcons} from './common/hooks.js';

import './TrustView.css';

export default function SignedIndicator(props: {
    trustObjects: TrustObject[];
    trustedKeysManager: TrustedKeysManager;
    onClick: () => void;
    certificateSettings: CertificateSetting[];
    additinalIndicators?: SettingIconType[];
    noCertificateIcon?: boolean;
    onError?: (error: Error) => void;
}): ReactElement {
    const certificateIcons = useCertificateIcons(
        props.trustObjects,
        props.trustedKeysManager,
        props.certificateSettings,
        props.onError ? props.onError : console.error
    );
    const icons: SettingIconType[] = [
        ...(props.additinalIndicators ? props.additinalIndicators : []),
        ...certificateIcons
    ];

    return (
        <div className="trust-indicator-icons-container" onClick={props.onClick}>
            {icons.length > 0 ? icons.map(i => i) : NotSignedIcon}
        </div>
    );
}
