import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const ColposcopyExaminationRecord: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://refinio.one/questionaire/ColposcopyExaminationRecord',
    name: 'colposcopy_examination_record',
    title: 'Colposcopy Examination Record',
    status: 'active',
    item: [
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'info',
            prefix: '1',
            required: false,
            text: 'Patient Info',
            item: [
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'id',
                    prefix: '1_1',
                    required: false,
                    text: 'ID:'
                },
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'name',
                    prefix: '1_2',
                    required: false,
                    text: 'Name:'
                },
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'adress',
                    prefix: '1_3',
                    required: false,
                    text: 'Adress:'
                },
                {
                    type: 'date',
                    disabledDisplay: 'protected',
                    linkId: 'birth_date',
                    prefix: '1_4',
                    required: false,
                    text: 'Birthdate:'
                },
                {
                    type: 'date',
                    disabledDisplay: 'protected',
                    linkId: 'exam_date',
                    prefix: '1_5',
                    required: false,
                    text: 'Exam. Date:'
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'med_his',
                    prefix: '1_6',
                    required: false,
                    text: 'Medical History:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Menopausal',
                                system: 'http://refinio.net/edda/valueCoding/med_history'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Pregnant',
                                system: 'http://refinio.net/edda/valueCoding/med_history'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Smoker',
                                system: 'http://refinio.net/edda/valueCoding/med_history'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Oral Contraceptive Use',
                                system: 'http://refinio.net/edda/valueCoding/med_history'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'hiv_status',
                    prefix: '1_7',
                    required: false,
                    text: 'HIV Status:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Positive',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Negative',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        },
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Not Tested',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        }
                    ]
                },
                {
                    type: 'integer',
                    disabledDisplay: 'protected',
                    linkId: 'child',
                    prefix: '1_8',
                    required: false,
                    text: 'NO. of Children:',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                            valueInteger: 0
                        },
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueInteger: 99
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'prev_screening_tests',
            prefix: '2',
            required: false,
            text: 'Previous Screening Tests',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'hpv_status',
                    prefix: '2_1_1',
                    required: false,
                    text: 'HPV Status:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Positive',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Negative',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        },
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Not Tested',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        }
                    ]
                },
                {
                    type: 'date',
                    disabledDisplay: 'protected',
                    linkId: 'hpv_date',
                    prefix: '2_1_2',
                    required: false,
                    text: 'Date:',
                    enableBehavior: 'any',
                    enableWhen: [
                        {
                            question: 'hpv_status',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        },
                        {
                            question: 'hpv_status',
                            operator: '=',
                            answerCoding: {
                                code: '0'
                            }
                        }
                    ]
                },

                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'via_status',
                    prefix: '2_2_1',
                    required: false,
                    text: 'VIA Status:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Positive',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Negative',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        },
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Not Tested',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        }
                    ]
                },
                {
                    type: 'date',
                    disabledDisplay: 'protected',
                    linkId: 'via_date',
                    prefix: '2_2_2',
                    required: false,
                    text: 'Date:',
                    enableBehavior: 'any',
                    enableWhen: [
                        {
                            question: 'via_status',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        },
                        {
                            question: 'via_status',
                            operator: '=',
                            answerCoding: {
                                code: '0'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'vili_status',
                    prefix: '2_3_1',
                    required: false,
                    text: 'VILI Status:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Positive',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Negative',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        },
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Not Tested',
                                system: 'http://refinio.net/edda/valueCoding/PosNeg'
                            }
                        }
                    ]
                },
                {
                    type: 'date',
                    disabledDisplay: 'protected',
                    linkId: 'vili_date',
                    prefix: '2_3_2',
                    required: false,
                    text: 'Date:',
                    enableBehavior: 'any',
                    enableWhen: [
                        {
                            question: 'vili_status',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        },
                        {
                            question: 'vili_status',
                            operator: '=',
                            answerCoding: {
                                code: '0'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'cytology_status',
                    prefix: '2_4_1',
                    required: false,
                    text: 'Cytology Status:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'HSIL',
                                system: 'http://refinio.net/edda/valueCoding/Cytology'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'LSIL',
                                system: 'http://refinio.net/edda/valueCoding/Cytology'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Negative',
                                system: 'http://refinio.net/edda/valueCoding/Cytology'
                            }
                        },
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Not Tested',
                                system: 'http://refinio.net/edda/valueCoding/Cytology'
                            }
                        }
                    ]
                },
                {
                    type: 'date',
                    disabledDisplay: 'protected',
                    linkId: 'cytology_date',
                    prefix: '2_4_2',
                    required: false,
                    text: 'Date:',
                    enableBehavior: 'any',
                    enableWhen: [
                        {
                            question: 'cytology_status',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        },
                        {
                            question: 'cytology_status',
                            operator: '=',
                            answerCoding: {
                                code: '2'
                            }
                        },
                        {
                            question: 'cytology_status',
                            operator: '=',
                            answerCoding: {
                                code: '0'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'symptoms_choice',
                    prefix: '2_5_1',
                    required: false,
                    text: 'Symptoms:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Abnormal Bleeding',
                                system: 'http://refinio.net/edda/valueCoding/symptoms'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Bleeding After Coitus',
                                system: 'http://refinio.net/edda/valueCoding/symptoms'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Abnormal Discharge',
                                system: 'http://refinio.net/edda/valueCoding/symptoms'
                            }
                        }
                    ]
                },
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'symptoms_other',
                    prefix: '2_5_2',
                    required: false,
                    text: 'Other:'
                },

                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'prev_cerv_treatment_choice',
                    prefix: '2_6_1',
                    required: false,
                    text: 'Previous Cervix Treatment:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Yes',
                                system: 'http://refinio.net/edda/valueCoding/yes_no'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'No',
                                system: 'http://refinio.net/edda/valueCoding/yes_no'
                            }
                        }
                    ]
                },
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'prev_cerv_treamtent_txt',
                    prefix: '2_6_2',
                    required: false,
                    text: '',
                    enableWhen: [
                        {
                            question: 'prev_cerv_treatment_choice',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'colp_exam',
            prefix: '3',
            required: false,
            text: 'Colposcopy Examination'
        },
        {
            type: 'attachment',
            disabledDisplay: 'protected',
            linkId: 'no_filter',
            prefix: '3_1',
            required: false,
            text: 'No Filter Image',
            item: [
                {
                    type: 'attachment',
                    disabledDisplay: 'protected',
                    linkId: 'aceto',
                    prefix: '3_2',
                    required: false,
                    text: 'Aceto Stained Image'
                },
                {
                    type: 'attachment',
                    disabledDisplay: 'protected',
                    linkId: 'green',
                    prefix: '3_3',
                    required: false,
                    text: 'Green Filter Image'
                },
                {
                    type: 'attachment',
                    disabledDisplay: 'protected',
                    linkId: 'iodine',
                    prefix: '3_4',
                    required: false,
                    text: 'Iodine Stained Image'
                },
                {
                    type: 'attachment',
                    disabledDisplay: 'protected',
                    linkId: 'misc1',
                    prefix: '3_5',
                    required: false,
                    text: 'Misc Image 1'
                },
                {
                    type: 'attachment',
                    disabledDisplay: 'protected',
                    linkId: 'misc2',
                    prefix: '3_6',
                    required: false,
                    text: 'Misc Image 2'
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'gen_assesment',
            prefix: '4',
            required: false,
            text: 'General Assesment',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'cervix_choice',
                    prefix: '4_1_1',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Adequate',
                                system: 'http://refinio.net/edda/valueCoding/adequate_inadequate'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Inadequate',
                                system: 'http://refinio.net/edda/valueCoding/adequate_inadequate'
                            }
                        }
                    ]
                },
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'cervix_txt',
                    prefix: '4_1_2',
                    required: false,
                    text: '',
                    enableWhen: [
                        {
                            question: 'cervix_choice',
                            operator: '=',
                            answerCoding: {
                                code: '0'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'sqj_visibility',
                    prefix: '4_2',
                    required: false,
                    text: 'SQJ Visibility:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Visible',
                                system: 'http://refinio.net/edda/valueCoding/visibility'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Partially Visible',
                                system: 'http://refinio.net/edda/valueCoding/visibility'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Not Visible',
                                system: 'http://refinio.net/edda/valueCoding/visibility'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'trans_zone_type',
                    prefix: '4_3',
                    required: false,
                    text: 'Transformation Zone Type',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Type 1',
                                system: 'http://refinio.net/edda/valueCoding/transformation'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Type 2',
                                system: 'http://refinio.net/edda/valueCoding/transformation'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Type 3',
                                system: 'http://refinio.net/edda/valueCoding/transformation'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'normal_findings',
            prefix: '5',
            required: false,
            text: 'Normal Colposcopic Findings',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'squamous_epithelium',
                    prefix: '5_1',
                    required: false,
                    text: 'Original Squamous Epithelium:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Mature',
                                system: 'http://refinio.net/edda/valueCoding/mature_atrophic'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Atrophic',
                                system: 'http://refinio.net/edda/valueCoding/mature_atrophic'
                            }
                        },
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'N/A',
                                system: 'http://refinio.net/edda/valueCoding/mature_atrophic'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'colmunar_epithelium',
                    prefix: '5_2',
                    required: false,
                    text: 'Colmunar Epithelium Ectophy:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Absent',
                                system: 'http://refinio.net/edda/valueCoding/absent_present'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Present',
                                system: 'http://refinio.net/edda/valueCoding/absent_present'
                            }
                        },
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'N/A',
                                system: 'http://refinio.net/edda/valueCoding/absent_present'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'metaplastic_epithelium',
                    prefix: '5_3',
                    required: false,
                    text: 'Metaplastic Squamous Epithelium:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Nabothian Cyst',
                                system: 'http://refinio.net/edda/valueCoding/squamous_epithelium'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Crypt (gland) openings',
                                system: 'http://refinio.net/edda/valueCoding/squamous_epithelium'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'abnormal_findings',
            prefix: '6',
            required: false,
            text: 'Abnormal Colposcopic Findings',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'location_lesion_zone',
                    prefix: '6_1_1',
                    required: false,
                    text: 'Location of the leasion:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Inside T-zone',
                                system: 'http://refinio.net/edda/valueCoding/location_lesion'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Outside T-zone',
                                system: 'http://refinio.net/edda/valueCoding/location_lesion'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'On polyp',
                                system: 'http://refinio.net/edda/valueCoding/location_lesion'
                            }
                        }
                    ]
                },
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'location_lesion_clock',
                    prefix: '6_1_2',
                    required: false,
                    text: ''
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'quadrants',
                    prefix: '6_2',
                    required: false,
                    text: 'No. of Quadrants:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: '1',
                                system: 'http://refinio.net/edda/valueCoding/quadrant'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '2',
                                system: 'http://refinio.net/edda/valueCoding/quadrant'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '3',
                                system: 'http://refinio.net/edda/valueCoding/quadrant'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '4',
                                system: 'http://refinio.net/edda/valueCoding/quadrant'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: 'On Polyp',
                                system: 'http://refinio.net/edda/valueCoding/quadrant'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: 'Inside Canal',
                                system: 'http://refinio.net/edda/valueCoding/quadrant'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fraction_covered',
                    prefix: '6_3',
                    required: false,
                    text: 'Fraction of Cervix Covered:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: '<25%',
                                system: 'http://refinio.net/edda/valueCoding/covered'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '25-50%',
                                system: 'http://refinio.net/edda/valueCoding/covered'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '50-75%',
                                system: 'http://refinio.net/edda/valueCoding/covered'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '>75%',
                                system: 'http://refinio.net/edda/valueCoding/covered'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: 'On Polyp',
                                system: 'http://refinio.net/edda/valueCoding/covered'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: 'Inside Canal',
                                system: 'http://refinio.net/edda/valueCoding/covered'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'sweden_score',
            prefix: '7',
            required: false,
            text: 'Sweden Score',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'aceto_uptake',
                    prefix: '7_1',
                    required: false,
                    text: 'Aceto Uptake:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Nil or Transparent',
                                system: 'http://refinio.net/edda/valueCoding/aceto_uptake'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Thin, Milky',
                                system: 'http://refinio.net/edda/valueCoding/aceto_uptake'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Distinct, Stearin',
                                system: 'http://refinio.net/edda/valueCoding/aceto_uptake'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'margin',
                    prefix: '7_2',
                    required: false,
                    text: 'Margin:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Nil or Transparent',
                                system: 'http://refinio.net/edda/valueCoding/margin'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Sharp but irregular, jagged, satellites',
                                system: 'http://refinio.net/edda/valueCoding/margin'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Sharp and even, different in level',
                                system: 'http://refinio.net/edda/valueCoding/margin'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'vessels',
                    prefix: '7_3',
                    required: false,
                    text: 'Vessels:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Fine, regular',
                                system: 'http://refinio.net/edda/valueCoding/vessels'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Absent',
                                system: 'http://refinio.net/edda/valueCoding/vessels'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Coarse or atypical vessels',
                                system: 'http://refinio.net/edda/valueCoding/vessels'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'lesion_size',
                    prefix: '7_4',
                    required: false,
                    text: 'Lesion Size:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: '<5mm',
                                system: 'http://refinio.net/edda/valueCoding/lesion_size'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '5-15mm or 2 quadrants',
                                system: 'http://refinio.net/edda/valueCoding/lesion_size'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '+15mm, 3-4 quadrants, or endocevically undefind',
                                system: 'http://refinio.net/edda/valueCoding/lesion_size'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'iodine_uptake',
                    prefix: '7_5',
                    required: false,
                    text: 'Iodine Uptake:',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Brown',
                                system: 'http://refinio.net/edda/valueCoding/Iodine_uptake'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Faint or patchy yellow',
                                system: 'http://refinio.net/edda/valueCoding/Iodine_uptake'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Distinctly yellow',
                                system: 'http://refinio.net/edda/valueCoding/Iodine_uptake'
                            }
                        }
                    ]
                },
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'notes',
                    prefix: '7_6',
                    required: false,
                    text: 'Notes:'
                },
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'management_remark',
                    prefix: '7_7',
                    required: false,
                    text: 'Management Remark:'
                },
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'exam_name',
                    prefix: '7_8',
                    required: false,
                    text: 'Exam Name:'
                }
            ]
        }
    ]
};
