import {useCallback, type ReactElement} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import type DocumentModel from '@refinio/one.models/lib/models/DocumentModel.js';
import type JournalModel from '@refinio/one.models/lib/models/JournalModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type Notifications from '@refinio/one.models/lib/models/Notifications.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';

import PageNotFound from '@/components/PageNotFound.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import PatientList from './list/PatientList.js';
import PatientCreate from './create/PatientCreate.js';
import PatientView from './view/PatientView.js';

export default function PatientsRouter(props: {
    questionnaireModel: QuestionnaireModel;
    leuteModel: LeuteModel;
    channelManager: ChannelManager;
    topicModel: TopicModel;
    notifications: Notifications;
    journalModel: JournalModel;
    documentModel: DocumentModel;
    getTrustView?: (hashes: (SHA256Hash | SHA256IdHash)[]) => ReactElement;
}): ReactElement {
    const i18n = useTranslation();
    const navigate = useNavigate();
    const goToListView = useCallback(() => navigate('./list'), [navigate]);

    return (
        <Routes>
            <Route
                index
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.patients')}
                        />
                        <PatientList
                            leuteModel={props.leuteModel}
                            topicModel={props.topicModel}
                            notifications={props.notifications}
                            viewRoute={'./view/:someoneId'}
                            createRoute={'./create'}
                            getInformationAddon={props.getTrustView}
                        />
                    </>
                }
            />
            <Route
                path="view/:someoneId"
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.CHEVRON}
                            title={i18n.t('malawi_demo.title.patient')}
                            leftBtnCallback={goToListView}
                        />
                        <PatientView
                            leuteModel={props.leuteModel}
                            topicModel={props.topicModel}
                            notifications={props.notifications}
                            journalModel={props.journalModel}
                            questionnaireModel={props.questionnaireModel}
                            documentModel={props.documentModel}
                            channelManager={props.channelManager}
                        />
                    </>
                }
            />
            <Route
                path="list"
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.patients')}
                        />
                        <PatientList
                            leuteModel={props.leuteModel}
                            topicModel={props.topicModel}
                            notifications={props.notifications}
                            viewRoute={'../view/:someoneId'}
                            createRoute={'../create'}
                        />
                    </>
                }
            />
            <Route
                path="create"
                element={
                    <PatientCreate
                        questionnaireModel={props.questionnaireModel}
                        leuteModel={props.leuteModel}
                        channelManager={props.channelManager}
                        topicModel={props.topicModel}
                        listRoute="../list"
                        patientRoute="../view/:someoneId"
                    />
                }
            />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}
