import type {ChangeEvent, ReactElement} from 'react';
import {useContext, useEffect, useRef, useState} from 'react';

import IconButton from '@mui/material/IconButton/IconButton.js';
import AccountTreeIcon from '@mui/icons-material/AccountTree.js';

import type {PopupMenuEntry} from '@refinio/one.ui/lib/ui/components/popupMenu/PopupMenu.js';
import ImageCapturePopup from '@refinio/one.ui/lib/ui/views/imageCapture/ImageCapturePopup.js';

import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import {MenuContext} from '@/context/MenuContext.js';
import type {AiAlgorithm, AiResult} from '@/utils/AIUtils.js';
import {AI_ALGORITHMS, detectFaces} from '@/utils/AIUtils.js';
import {
    canvasToImageFile,
    drawBoundingBoxes,
    getCanvasFromImage,
    readFileAsImage
} from '@/utils/ImageUtils.js';

interface CallbackFunction {
    (aiResult: AiResult): void;
}

async function handleImage(
    file: File | undefined,
    activeAlgorithm: AiAlgorithm | undefined,
    cb: CallbackFunction
): Promise<void> {
    if (file === undefined || activeAlgorithm === undefined) {
        throw new Error('file or activeAlgorithm is undefined');
    }
    let result: AiResult;
    switch (activeAlgorithm) {
        case AI_ALGORITHMS.FACE_DETECTOR: {
            const image = readFileAsImage(file);
            const facePredictions = await detectFaces(image);
            let text: string;
            let files: Array<File>;
            if (facePredictions.length > 0) {
                text = `${facePredictions.length} faces detected`;
                // Draw bounding boxes
                const canvas = getCanvasFromImage(image);
                drawBoundingBoxes(canvas, facePredictions);
                files = [
                    file,
                    await canvasToImageFile(canvas, `with_faces__${file.name}`, file.type)
                ];
                console.warn(files);
                // Convert canvas to file
            } else {
                text = 'No faces detected';
                files = [file];
            }
            result = {algorithm: AI_ALGORITHMS.FACE_DETECTOR, files, text};
            break;
        }
        default:
            throw new Error('Unknown algorithm');
    }
    cb(result);
}

function extractImageFromInputEvent(event: ChangeEvent<HTMLInputElement>) {
    if (event === null || event.target === null || event.target.files === null) {
        return;
    }
    if (event.target.files && event.target.files.length) {
        return event.target.files[0];
    }
}

export default function ChatAiSelector(props: {
    loading: boolean;
    onResultChange: CallbackFunction;
}): ReactElement {
    const menuRef = useRef<HTMLDivElement>(null);
    const faceRef = useRef<HTMLInputElement>(null);

    // This is horrible and can lead to inconsistent state to store the active algo here.
    const [activeAlgorithm, setActiveAlgorithm] = useState<AiAlgorithm | undefined>(undefined);
    const [showCamera, setShowCamera] = useState<boolean>(false);

    function toggleCamera(): void {
        setShowCamera((s: boolean) => !s);
    }

    const {setMenuEntries, selectMenuEntry, isOpen, setMenuReference, setMenuClassName} =
        useContext(MenuContext);

    useEffect(() => {
        setMenuReference(menuRef.current);
    });
    function toggleFileMenu() {
        // menu reference is the anchor for the menu popup
        setMenuEntries([
            MENU_ENTRY.TAKE_IMAGE_FACE_DETECTOR,
            MENU_ENTRY.SELECT_IMAGES_FACE_DETECTOR
        ]);
        selectMenuEntry(() => entrySelected);
        setMenuReference(menuRef.current);
        setMenuClassName('menu-chat-ai');
        isOpen(true);
    }

    function entrySelected(value: PopupMenuEntry) {
        switch (value) {
            case MENU_ENTRY.SELECT_IMAGES_FACE_DETECTOR:
                setActiveAlgorithm(AI_ALGORITHMS.FACE_DETECTOR);
                if (null !== faceRef.current) {
                    faceRef.current.click();
                }
                break;
            case MENU_ENTRY.TAKE_IMAGE_FACE_DETECTOR:
                setActiveAlgorithm(AI_ALGORITHMS.FACE_DETECTOR);
                toggleCamera();
                break;
        }
        isOpen(false);
    }

    return (
        <div ref={menuRef}>
            {showCamera && (
                <ImageCapturePopup
                    onFileChange={(file: File) =>
                        handleImage(file, activeAlgorithm, props.onResultChange)
                    }
                    active={showCamera}
                    onClose={toggleCamera}
                />
            )}
            <input
                ref={faceRef}
                type="file"
                style={{display: 'none'}}
                onChange={event =>
                    handleImage(
                        extractImageFromInputEvent(event),
                        activeAlgorithm,
                        props.onResultChange
                    )
                }
            />
            <IconButton disabled={props.loading} onClick={toggleFileMenu} aria-label="send file">
                <AccountTreeIcon className="icon-avatar-wrapper" />
            </IconButton>
        </div>
    );
}
